import { forwardRef } from "react";
import Select, { components } from "react-select";
import styled, { css } from "styled-components";
import { Checkbox } from "./Checkbox";
import { IOption } from "./Select";

interface Props {
	value: string[];
	onChange: (...event: any[]) => void;
	placeholder?: string;
	labelText?: string;
	options: IOption[] | any;
	maxWidth?: string;
	setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
	error?: boolean;
	extraButton?: boolean;
}
interface WrapperProps {
	maxWidth?: string;
	error?: boolean;
	extraButton?: boolean;
}

const Option = (props: any) => {
	return (
		<div>
			<components.Option {...props}>
				<Checkbox checked={props.isSelected} label={""} />
				<label style={{ cursor: "pointer" }}>{props.label}</label>
			</components.Option>
		</div>
	);
};

const MenuList = (props: any) => {
	return (
		<>
			<components.MenuList {...props}>
				<div>{props.children}</div>
			</components.MenuList>
			{props.extraButton && (
				<RequestAddition onClick={props.onClick}>Request Addition</RequestAddition>
			)}
		</>
	);
};

export const MultiSelect = forwardRef(
	(
		{
			value,
			onChange,
			extraButton = true,
			placeholder,
			labelText,
			options,
			maxWidth,
			setModalOpen,
			error,
		}: Props,
		ref: any
	) => {
		const onClickBtn = () => {
			setModalOpen?.(true);
		};

		const onChangeValue = (newValue: any) => {
			onChange(newValue.map((el: any) => el.value));
		};

		const getElement = () => {
			const newArr = [];
			for (let i = 0; i < value.length; i++) {
				const newItem = options.find((el: IOption) => el.value === value[i]);
				if (newItem) {
					newArr.push(newItem);
				}
			}
			return newArr;
		};

		return (
			<Label ref={ref}>
				{labelText && <LabelText>{labelText}</LabelText>}
				<Wrapper extraButton={extraButton} error={error} maxWidth={maxWidth}>
					<Select
						classNamePrefix="custom-select"
						options={options}
						isMulti
						closeMenuOnSelect={false}
						hideSelectedOptions={false}
						components={{
							Option,
							MenuList: (props) => (
								<MenuList extraButton={extraButton} onClick={onClickBtn} {...props} />
							),
						}}
						onChange={onChangeValue}
						placeholder={placeholder}
						value={getElement()}
					/>
				</Wrapper>
			</Label>
		);
	}
);

const Wrapper = styled.div<WrapperProps>`
	max-width: ${({ maxWidth }) => maxWidth};
	& .custom-select {
		&__control {
			padding: 0px 10px 0px 4px !important;
			height: 47px !important;
			background-color: #f7f7f7 !important;
			border-radius: 8px !important;
			border: 1px solid transparent !important;
			color: #000 !important;
			outline: none !important;
			box-shadow: none;
			${(props) => {
				return (
					props.error &&
					css`
						border: 1px solid red !important;
						background: #fff !important;
					`
				);
			}}
			&--is-focused {
				background: #fff !important;
				border: 1px solid #ebebeb !important;
			}
		}
		&__clear-indicator {
			display: none;
		}
		&__multi-value {
			position: relative;
			background: transparent;
			&:not(:first-child) {
				&::after {
					content: ",";
					position: absolute;
					bottom: 3px;
					left: -3px;
				}
			}
		}
		&__multi-value__remove {
			display: none;
		}
		&__multi-value__label {
			font-size: 14px;
			color: #000 !important;
		}

		&__indicator-separator {
			display: none;
		}
		&__value-container {
			cursor: text;
		}
		&__indicators {
			cursor: pointer;
		}
		&__menu {
			border-radius: 12px;
			border: none;
			position: relative;
			box-shadow: 0px 8px 41px rgba(0, 0, 0, 0.1);
			${({ extraButton }) => {
				if (extraButton) {
					return css`
						padding: 8px 8px 64px;
					`;
				} else {
					return css`
						padding: 8px;
					`;
				}
			}}
			&--is-acive {
				& .btn {
					display: block;
					z-index: 5;
				}
			}
		}
		&__option {
			color: #000;
			font-size: 14px;
			cursor: pointer;
			display: flex;
			align-items: center;
			&:hover {
				background: transparent;
			}
			&--is-focused {
				background: transparent;
				color: #8562ce;
			}
			&--is-selected {
				background: transparent;
				font-weight: 700;
			}
			&--is-acive {
				background: transparent;
			}
		}
		&__single-value {
			color: #000;
		}
	}
`;

const LabelText = styled.div`
	margin-bottom: 8px;
	color: #282828;

	font-weight: bold;
`;

const Label = styled.label`
	width: 100%;
`;
const RequestAddition = styled.div`
	position: absolute;
	bottom: 16px;
	left: 16px;
	padding: 8px 12px;
	font-size: 12px;
	line-height: 16px;
	border: 1px solid #000000;
	border-radius: 8px;
	background: #fff;
	cursor: pointer;
`;
