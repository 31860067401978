export const cardNumberStyle = {
	base: {
		padding: "12px 8px 12px 16px",
		maxWidth: "324px",
		width: "100%",
		height: "47px",
		background: "#f7f7f7",
		borderRadius: "8px",
		border: "1px solid transparent",
		color: "#000",
		outline: "none",
	},
};

export const cardExpiryStyle = {
	base: {
		padding: "12px 8px 12px 16px",
		maxWidth: "164px",
		width: "100%",
		height: "47px",
		background: "#f7f7f7",
		borderRadius: "8px",
		border: "1px solid transparent",
		color: "#000",
		outline: "none",
	},
};

export const cardCvcStyle = {
	base: {
		padding: "12px 8px 12px 16px",
		maxWidth: "164px",
		width: "100%",
		height: "47px",
		background: "#f7f7f7",
		borderRadius: "8px",
		border: "1px solid transparent",
		color: "#000",
		outline: "none",
	},
};
