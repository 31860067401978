import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/axiosInstance";
import { Showroom } from "../../../models/IPhoto";

export const getPhotos = createAsyncThunk<Showroom, string, { rejectValue?: any }>(
	"arbuyer/getPhotos",
	async (filters, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `/artbuyer/showroom/photos${filters}`,
				transformRequest: [
					(data: any, headers: any) => {
						delete headers["X-AUTH-TOKEN"];
						return data;
					},
				],
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const getFilterPhotos = createAsyncThunk<Showroom, string, { rejectValue?: any }>(
	"arbuyer/getFilterPhotos",
	async (filters, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `/artbuyer/showroom/photos${filters}`,
				transformRequest: [
					(data: any, headers: any) => {
						delete headers["X-AUTH-TOKEN"];
						return data;
					},
				],
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const getUserPhotos = createAsyncThunk<Showroom, any, { rejectValue?: any }>(
	"arbuyer/getUserPhotos",
	async (data, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `/artbuyer/showroom/photos/user/${data.userId}${data.parameters}`,
				transformRequest: [
					(data: any, headers: any) => {
						delete headers["X-AUTH-TOKEN"];
						return data;
					},
				],
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const getArtbuyerPhoto = createAsyncThunk<any, string, { rejectValue?: any }>(
	"artbuyer/getPhotoById",
	async (id, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `/artbuyer/showroom/photo/${id}`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
