import styled, { css } from "styled-components";
import { camera, social1, social2, social3, social4, social5 } from "../static/images";

import { Button, Modal } from "./UI";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { AuthRoles } from "../models/IProfile";
import { countries } from "../utils/countries";
import { ProfileGallery } from "./ProfileGallery";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { INewGalleryItem } from "../models/IGallery";
import { EditUploadedImages } from "./Modals/EditUploadedImages";
import {
	additionalSkillsValues,
	assetsValues,
	servicesValues,
	skillLevels,
} from "../utils/selectValues";
import {
	addFavourite,
	getFavourites,
	removeFavourite,
} from "../store/reducers/artbuyerFavorites/action";
import { Photoshoot } from "./Modals/Photoshoot";
import { CornerMessage } from "./UI/CornerMessage";
import { TimeStamp } from "../utils/variables";

export const UserAccount = () => {
	const [selectedImagesToEdit, setSelectedImagesToEdit] = useState<INewGalleryItem[]>([]);
	const [isEditImagesInfo, setIsEditImagesInfo] = useState(false);
	const [isNeedPhotoOpen, setIsNeedPhotoOpen] = useState(false);
	const [isPhotoshootOpen, setIsPhotoshootOpen] = useState(false);
	const [imagesGallerySort, setImagesGallerySort] = useState(0);
	const [isAdded, setIsAdded] = useState(false);
	const [Sedcards, setSedcards] = useState<any>([]);
	const [Archive, setArchive] = useState<any>([]);
	const [isJobRequestSended, setIsJobRequestSended] = useState(false);

	const { user } = useAppSelector((state) => state.authData);

	const photographerAbout = useAppSelector((state) => state.profile.userAccountInfo);

	const { userSkills } = useAppSelector((state) => state.photographerSkills);
	const { favorites } = useAppSelector((state) => state.favorites);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { showAllByAuthor } = useAppSelector((state) => state.photos);

	useEffect(() => {
		if (showAllByAuthor.photos.length === 0 && photographerAbout.id === 0) {
			navigate("/");
		}
		const getFavouritesFunc = async () => {
			const parameters = `?page=1&pageSize=300&timestamp=${TimeStamp}`;

			await dispatch(getFavourites(parameters));
		};
		if (user?.role === AuthRoles.Artbuyer) {
			getFavouritesFunc();
		}
	}, []);

	useEffect(() => {
		if (user?.role === AuthRoles.Artbuyer) {
			const isAdded = favorites.find((el) => el.photographerId === photographerAbout.id);

			setIsAdded(!!isAdded);
		}
	}, [favorites]);

	useEffect(() => {
		setArchive(showAllByAuthor.photos);
		showAllByAuthor.photos.map((el) => {
			if (el.sedcard === true) {
				setSedcards((prev: any) => [...prev, el]);
			}
		});
	}, [showAllByAuthor]);

	const getCountry = () => {
		return countries.find((el) => el.value === photographerAbout.countryId)?.label;
	};

	const getStringSkills = (array: string[]) => {
		let firstString = false;
		return array.map((el) => {
			if (firstString) {
				return `, ${el}`;
			} else {
				firstString = true;
				return el;
			}
		});
	};
	const getSkillsValue = (arr: { value: string; label: string }[], skills: string[]) => {
		return skills.map((el) => {
			let value = "";
			arr.forEach((obj) => {
				if (el === obj.value) {
					value = obj.label;
				}
			});
			return value;
		});
	};
	const getSkillValue = (arr: { value: string; label: string }[], skill: string) => {
		return arr.map((el) => {
			if (el.value === skill) {
				return el.label;
			}
		});
	};

	const onSendJobRequest = () => {
		setIsPhotoshootOpen(true);
	};
	const onSendImageRequest = () => {
		setIsNeedPhotoOpen(true);
	};

	const onAddToFavorites = async () => {
		if (!isAdded) {
			const json = {
				photographerIds: [photographerAbout.id],
			};
			await dispatch(addFavourite(json));
		} else {
			const json = {
				photographerIds: [photographerAbout.id],
			};
			await dispatch(removeFavourite(json));
		}

		const parameters = `?page=1&pageSize=300&timestamp=${TimeStamp}`;

		await dispatch(getFavourites(parameters));
	};

	return (
		<>
			{" "}
			<About>
				<AvatarBorder>
					<AvatarWrapper>
						<img
							src={
								photographerAbout.avatarURL
									? `${process.env.REACT_APP_DOMEN}${photographerAbout.avatarURL}`
									: camera
							}
							alt="camera"
						/>
					</AvatarWrapper>
				</AvatarBorder>
				<AboutName>
					{photographerAbout.firstName} {photographerAbout.lastName}
				</AboutName>
				<AboutPlace>
					{photographerAbout.city}, {getCountry()}
				</AboutPlace>
				<AboutThemes>{photographerAbout.title}</AboutThemes>
				<AboutLink href={photographerAbout.siteURL} target={"_blank"}>
					{photographerAbout.siteURL}
				</AboutLink>
				<SocialList>
					{photographerAbout.facebookUserId && (
						<li>
							<a href={photographerAbout.facebookUserId} target={"_blank"}>
								<img src={social1} alt="social1" />
							</a>
						</li>
					)}
					{photographerAbout.twitterUserId && (
						<li>
							<a href={photographerAbout.twitterUserId} target={"_blank"}>
								<img src={social2} alt="social2" />
							</a>
						</li>
					)}
					{photographerAbout.instagramUserId && (
						<li>
							<a href={photographerAbout.instagramUserId} target={"_blank"}>
								<img src={social3} alt="social3" />
							</a>
						</li>
					)}
					{photographerAbout.linkedInId && (
						<li>
							<a href={photographerAbout.linkedInId} target={"_blank"}>
								<img src={social4} alt="social4" />
							</a>
						</li>
					)}
					{photographerAbout.vimeoId && (
						<li>
							<a href={photographerAbout.vimeoId} target={"_blank"}>
								<img src={social5} alt="social5" />
							</a>
						</li>
					)}
				</SocialList>
				{user?.role === AuthRoles.Artbuyer && (
					<CreatorProfileButtons>
						<ButtonWrapper>
							<Button onClick={onSendJobRequest} theme={"secondary"}>
								Send Job Request
							</Button>
						</ButtonWrapper>
						<ButtonWrapper>
							<Button onClick={onSendImageRequest} theme={"secondary"}>
								Send Image Request
							</Button>
						</ButtonWrapper>
						<ButtonWrapper>
							{isAdded ? (
								<AddToFavorites onClick={onAddToFavorites} isAdded={isAdded}>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M6.75 6.75C6.75 5.64543 7.64543 4.75 8.75 4.75H15.25C16.3546 4.75 17.25 5.64543 17.25 6.75V19.25L12 14.75L6.75 19.25V6.75Z"
											fill="white"
											stroke="white"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</AddToFavorites>
							) : (
								<AddToFavorites onClick={onAddToFavorites}>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M6.75 6.75C6.75 5.64543 7.64543 4.75 8.75 4.75H15.25C16.3546 4.75 17.25 5.64543 17.25 6.75V19.25L12 14.75L6.75 19.25V6.75Z"
											stroke="black"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</AddToFavorites>
							)}
						</ButtonWrapper>
					</CreatorProfileButtons>
				)}

				<AboutText>{photographerAbout.about}</AboutText>
				<Facts>
					<FactsItem>
						<FactsItemTitle>Skill Level</FactsItemTitle>
						<div>{getSkillValue(skillLevels, userSkills.skillLevel) || ""}</div>
					</FactsItem>
					<FactsItem>
						<FactsItemTitle>Assets</FactsItemTitle>
						<div>
							{getStringSkills(getSkillsValue(assetsValues, userSkills.assets) || []) || ""}
						</div>
					</FactsItem>
					<FactsItem>
						<FactsItemTitle>Additional Services</FactsItemTitle>
						<div>
							{getStringSkills(
								getSkillsValue(servicesValues, userSkills.additionalServices) || []
							) || ""}
						</div>
					</FactsItem>
					<FactsItem>
						<FactsItemTitle>Additional Skills</FactsItemTitle>
						<div>
							{getStringSkills(
								getSkillsValue(additionalSkillsValues, userSkills.additionalSkills) || []
							) || ""}
						</div>
					</FactsItem>
				</Facts>
			</About>
			<ProfileGallery
				selectedImages={selectedImagesToEdit}
				setSelectedImages={setSelectedImagesToEdit}
				sedCards={Sedcards}
				archive={Archive}
				userAccount
				setImagesGallerySort={setImagesGallerySort}
			/>
			<Modal
				noPadding
				noRadius
				isOpen={isEditImagesInfo}
				onClose={() => setIsEditImagesInfo(false)}>
				<EditUploadedImages
					onCloseEditImages={setIsEditImagesInfo}
					isEditImages={true}
					imagesToEdit={selectedImagesToEdit}
					setSelectedImagesToEdit={setSelectedImagesToEdit}
					setIsEditImagesInfo={setIsEditImagesInfo}
				/>
			</Modal>
			<Modal
				isOpen={isPhotoshootOpen}
				size="small"
				noPadding
				onClose={() => setIsPhotoshootOpen(false)}>
				<Photoshoot
					isJobRequestSended={isJobRequestSended}
					setIsJobRequestSended={setIsJobRequestSended}
					onClose={() => setIsPhotoshootOpen(false)}
					userId={2909}
				/>
			</Modal>
			<Modal
				isOpen={isNeedPhotoOpen}
				size="small"
				noPadding
				onClose={() => setIsNeedPhotoOpen(false)}>
				<Photoshoot
					isJobRequestSended={isJobRequestSended}
					setIsJobRequestSended={setIsJobRequestSended}
					onClose={() => setIsNeedPhotoOpen(false)}
					userId={2909}
					onePhoto
				/>
			</Modal>
			<CornerMessage
				span="Success! "
				text={"Your job request has been sent"}
				setIsAddedToCart={setIsJobRequestSended}
				isAddedToCart={isJobRequestSended}
			/>
		</>
	);
};

const About = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 16px;
	line-height: 24px;
`;
const AvatarWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 120px;
	height: 120px;
	background: #000;
	border-radius: 50%;
	transition: 0.2s;
	& > img {
		transition: 0.2s;
		width: 120px;
		height: 120px;
		border-radius: 50%;
	}
`;

const AvatarBorder = styled.div`
	width: 120px;
	margin: 0 auto;
	height: 120px;
	border-radius: 50%;
	border: 1px solid transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 40px;
	transition: 0.2s;
	border: 1px solid #000000;
	& > div {
		width: 108px;
		height: 108px;
		& > img {
			width: 108px;
			object-fit: cover;
			height: 108px;
		}
	}
`;
const AboutName = styled.h2`
	margin-bottom: 4px;
	font-weight: 700;
	font-size: 64px;
	line-height: 80px;
`;
const AboutPlace = styled.div`
	margin-bottom: 15px;
`;
const AboutThemes = styled.div`
	margin-bottom: 8px;
`;
const AboutLink = styled.a`
	margin-bottom: 24px;
	color: #8562ce;
	text-decoration: underline;
`;
const SocialList = styled.ul`
	display: flex;
	margin-bottom: 24px;

	& > li {
		:not(:last-child) {
			margin-right: 8px;
		}

		& > a {
			width: 32px;
			border-radius: 50%;
			display: inline-block;
			height: 32px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}
	}
`;
const CreatorProfileButtons = styled.div`
	display: flex;
`;
const ButtonWrapper = styled.div`
	text-align: center;
	margin-bottom: 24px;
`;
const AboutText = styled.div`
	max-width: 752px;
	text-align: center;
	margin-bottom: 40px;
`;
const Facts = styled.div`
	max-width: 1136px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 16px;
`;
const FactsItem = styled.div`
	width: 560px;
	border-radius: 8px;
	background: #f7f7f7;
	padding: 8px 8px 8px 16px;
`;
const FactsItemTitle = styled.h4`
	margin-bottom: 4px;
	font-weight: 600;
`;
const AddToFavorites = styled.div<any>`
	cursor: pointer;
	border: 1px solid #000;
	border-radius: 8px;
	transition: all 0.2s;
	position: relative;
	max-height: 50px;
	height: 44px;
	width: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	color: #000;
	margin-right: 8px;

	${({ isAdded }) => {
		if (isAdded) {
			return css`
				background: #8562ce;
				border: 1px solid #8562ce;
				&:hover {
					background: #7557b5;
					background: #7557b5;
				}
			`;
		} else {
			return css`
				&:hover {
					color: rgb(133, 98, 206);
					border: 1px solid rgb(133, 98, 206);
					& > svg {
						& > path {
							stroke: rgb(133, 98, 206);
						}
					}
				}
			`;
		}
	}}
`;
