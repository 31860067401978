import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Button, Input } from "../UI";
import { IImageRequest } from "../../models/IImageRequest";
import { useAppDispatch } from "../../hooks/redux";
import {
	counterOfferRequest,
	rejectRequest,
	revealRequest,
} from "../../store/reducers/photographerImageRequest/action";

interface Props {
	element: IImageRequest;
	onClose: () => void;

	getRequests: () => Promise<void>;
	getAllRequests: () => Promise<void>;
}

export const PriceRequestPhotographer = ({
	element,
	onClose,
	getRequests,
	getAllRequests,
}: Props) => {
	const [isCounterOffer, setIsCounterOffer] = useState(false);
	const [price, setPrice] = useState<number>();
	const [message, setMessage] = useState("");

	const dispatch = useAppDispatch();

	const onReloadRequests = async () => {
		getRequests();
		getAllRequests();
	};
	const onModalClose = () => {
		onClose();
		document.body.style.overflow = "auto";
	};

	const onAcceptOffer = async () => {
		const json = {
			requestId: element.id,
		};
		await dispatch(revealRequest(JSON.stringify(json)));
		onReloadRequests();
		onModalClose();
	};
	const onCounterOffer = () => {
		setIsCounterOffer(true);
	};

	const onSendOffer = async () => {
		const json = {
			requestId: element.id,
			usage: element.usage,
			territory: element.territory,
			duration: element.duration,
			price: price,
			currency: "currency",
			message: message,
		};
		if (price) {
			await dispatch(counterOfferRequest(JSON.stringify(json)));
			onReloadRequests();
			onModalClose();
		}
	};

	const onRejectOffer = async () => {
		await dispatch(rejectRequest(element.id));
		onReloadRequests();
		onModalClose();
	};

	return (
		<Wrapper>
			<Title>Price Request</Title>
			<WrapperInner style={false ? { flexDirection: "row-reverse" } : { flexDirection: "row" }}>
				<WrapperContent>
					<WrapperContentItem>
						<ImageAbout>
							<ImageAboutItem>
								<h4>Usage</h4>
								<p>{element.usage}</p>
							</ImageAboutItem>
							<ImageAboutItem>
								<h4>Territory</h4>
								<p>{element.territory}</p>
							</ImageAboutItem>
							<ImageAboutItem>
								<h4>Price</h4>
								<p>
									{element.currency === "USD" ? "$" : "€"} {element.price}.00
								</p>
							</ImageAboutItem>
							<ImageAboutItem>
								<h4>Duration</h4>
								<p>{element.duration}</p>
							</ImageAboutItem>
						</ImageAbout>
						{isCounterOffer ? (
							<YourCounterOffer>
								<Subtitle>Your counter offer</Subtitle>
								<SubText>
									Please enter your price below to send a counter offer to the buyer.
								</SubText>
								<PriceInput currency={element.currency === "USD"}>
									<Input
										type="number"
										withCurrency
										value={price}
										onChange={(e) => setPrice(+e.target.value)}
										maxWidth="208px"
									/>
								</PriceInput>
								<InputWrapper>
									<Input
										value={message}
										onChange={(e) => setMessage(e.target.value)}
										maxWidth="100%"
										placeholder="Message"
									/>
								</InputWrapper>
								<PhotographerOfferButtons>
									<Button onClick={() => onSendOffer()} margin="0px 8px 0px 0px">
										Send Offer
									</Button>

									<Button
										onClick={() => setIsCounterOffer(false)}
										margin="0px 8px 0px 0px"
										theme="noline">
										Reject Request
									</Button>
								</PhotographerOfferButtons>
							</YourCounterOffer>
						) : (
							<PhotographerOfferButtons>
								<Button onClick={() => onAcceptOffer()} margin="0px 8px 0px 0px">
									Accept Offer
								</Button>

								<Button onClick={() => onCounterOffer()} theme="outline">
									Counter Offer
								</Button>

								<Button onClick={() => onRejectOffer()} margin="0px 8px 0px 0px" theme="noline">
									Reject Request
								</Button>
							</PhotographerOfferButtons>
						)}
					</WrapperContentItem>
				</WrapperContent>
				<ImageInner>
					<img
						src={`${process.env.REACT_APP_DOMEN}${element.photo.imageDetails.LIGHTBOX.url}`}
						alt="reqImg"
					/>
				</ImageInner>
			</WrapperInner>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding: 40px;
	background: #f7f7f7;
	border-radius: 24px;
	width: 840px;
	max-height: 93vh;
	overflow-y: auto;
`;
const Title = styled.div`
	margin-bottom: 16px;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
`;
const WrapperContent = styled.div`
	width: 540px;
`;
const WrapperInner = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: row-reverse;
`;
const ImageInner = styled.div`
	width: 180px;
	& > img {
		width: 180px;
	}
`;
const PriceInput = styled.div<any>`
	margin-bottom: 8px;
	width: 208px;
	position: relative;
	&::after {
		position: absolute;
		${({ currency }) => {
			if (currency) {
				return css`
					content: "$";
				`;
			} else {
				return css`
					content: "€";
				`;
			}
		}}
		top: 14px;
		left: 16px;
		font-size: 14px;
		line-height: 20px;
		font-weight: 600;
	}
`;
const WrapperContentItem = styled.div`
	background: #fff;
	padding: 24px;
	border-radius: 8px;
	margin-bottom: 4px;
`;
const ImageAbout = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 16px;
`;
const ImageAboutItem = styled.div`
	& > h4 {
		font-weight: 600;
		font-size: 12px;
		line-height: 18px;
		margin-bottom: 4px;
	}
`;
const PhotographerOffer = styled.div`
	background: #fff;
	padding: 24px;
	border-radius: 8px;
`;
const PhotographerOfferButtons = styled.div`
	display: flex;
	margin-top: 24px;
`;
const Subtitle = styled.div`
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 4px;
`;
const SubText = styled.div`
	font-size: 12px;
	line-height: 16px;
	margin-bottom: 16px;
`;
const YourCounterOffer = styled.div`
	margin-top: 24px;
`;
const InputWrapper = styled.div`
	margin-bottom: 8px;
`;
