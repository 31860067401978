import styled from "styled-components";

import { useNavigate } from "react-router-dom";

import { SignIn } from "../../components/Modals/SignInModal";
import { arrowBack } from "../../static/images";

export const SignInPage = () => {
	const navigate = useNavigate();

	const onBackButtonClick = () => {
		navigate(-1);
	};

	return (
		<Wrapper>
			<FormWrapper>
				<BackButton onClick={onBackButtonClick}>
					<img src={arrowBack} alt="arrowBack" />
					<BackButtonText>Back to Search Results</BackButtonText>
				</BackButton>
				<SignIn />
			</FormWrapper>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const FormWrapper = styled.div`
	box-shadow: 0px 8px 41px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	padding: 40px;
`;
const BackButton = styled.div`
	position: absolute;
	top: 28px;
	left: 28px;
	display: flex;
	align-items: center;
	cursor: pointer;
`;
const BackButtonText = styled.div`
	color: #505050;
	margin-left: 8px;
`;

export { SignIn };
