import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Button, Checkbox, DeleteTag, ErrorWrapper, Input, RadioBtns } from "../UI";
import ImageViewer from "react-simple-image-viewer";
import { useClickOutside } from "../../hooks/useClickOutside";
import { IImageInfo } from "../../models/IGallery";
import {
	getPhotographerPhotos,
	updatePhotographerPhoto,
} from "../../store/reducers/photographerGallery/action";
import { useForm } from "react-hook-form";
import { TimeStamp } from "../../utils/variables";
import { getStripeStatus } from "../../store/reducers/stripe/action";
import { useNavigate } from "react-router-dom";

interface Props {
	onClose: () => void;
	image: IImageInfo;
	onCloseFileInfo?: React.Dispatch<React.SetStateAction<boolean>>;
	setIsEdited?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditFileInfo = ({ onClose, image, setIsEdited, onCloseFileInfo }: Props) => {
	const [radioInfo, setRadioInfo] = useState("High-Res");
	const [pricing, setPricing] = useState("Upon Request");
	const [modelRelease, setModelRelease] = useState("Possible");
	const [addTagValue, setAddTagValue] = useState("");
	const [isHideUsername, setIsHideUsername] = useState(false);
	const [isAvailableToUpload, setIsAvailableToUpload] = useState(false);

	const [isTagsInputOpen, setIsTagsInputOpen] = useState(false);

	const [currentImage, setCurrentImage] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState(false);

	const [isStripeConnected, setIsStripeConntected] = useState(true);
	const [isFillBillinForm, setIsFullBillingForm] = useState(true);

	const [currentTags, setCurrentTags] = useState<string[]>([]);

	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm();

	useEffect(() => {
		setRadioInfo(image?.originalFile ? "High-Res" : "Low-Res");
		setPricing(
			(image?.pricing === "CUSTOM" && "Custom Rights Ready") ||
				(image?.pricing === "NEGOTIATE_LATER" && "Upon Request") ||
				"Rights Ready"
		);
		setModelRelease(
			(image?.releaseAvailable === "YES" && "Yes") ||
				(image?.releaseAvailable === "NO" && "No") ||
				"Possible"
		);
		setIsHideUsername(image.incognito);
		setCurrentTags(image.tags || []);
		reset({ ...image });
	}, [image]);

	useEffect(() => {
		if (radioInfo === "Low-Res") {
			setPricing("Upon Request");
		}
	}, [radioInfo]);

	const openImageViewer = useCallback(() => {
		setCurrentImage(0);
		setIsViewerOpen(true);
	}, []);

	const closeImageViewer = () => {
		setCurrentImage(0);
		setIsViewerOpen(false);
	};

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const photograpgerMarketPlace = useAppSelector((state) => state.profile.photographerMarketPlace);

	useEffect(() => {
		const getStatus = async () => {
			const { payload } = await dispatch(getStripeStatus());
			setIsStripeConntected(payload.connected);
		};

		getStatus();
		setIsFullBillingForm(
			!!photograpgerMarketPlace.billingAddress &&
				!!photograpgerMarketPlace.billingCity &&
				!!photograpgerMarketPlace.billingCompany &&
				!!photograpgerMarketPlace.billingFirstName &&
				!!photograpgerMarketPlace.billingLastName &&
				!!photograpgerMarketPlace.billingZip &&
				!!photograpgerMarketPlace.countryId
		);
	}, []);

	const onSettingsClick = () => {
		navigate("/creator/profile/settings");
		onCloseFileInfo?.(false);
		document.body.style.overflow = "auto";
	};

	useEffect(() => {
		setIsAvailableToUpload((!isFillBillinForm || !isStripeConnected) && pricing !== "Upon Request");
	}, [isFillBillinForm, isStripeConnected, pricing]);

	const onEditBtn = async (data: any) => {
		const releaseAvailable = () => {
			if (modelRelease === "Yes") {
				return "YES";
			} else if (modelRelease === "No") {
				return "NO";
			} else {
				return "POSSIBLE";
			}
		};
		const onPricing = () => {
			if (pricing === "Custom Rights Ready") {
				return "CUSTOM";
			} else if (pricing === "Upon Request") {
				return "NEGOTIATE_LATER";
			} else {
				return "ARTBRIEF_PRICING";
			}
		};

		const parameters = {
			title: "test",
			tags: currentTags,
			pricing: onPricing(),
			priceOnline: data.priceOnline,
			priceEditorial: data.priceEditorial,
			priceExtended: data.priceExtended,
			currency: "string",
			originalFile: radioInfo === "High-Res",
			releaseAvailable: releaseAvailable(),
			incognito: isHideUsername,
		};
		const formData = new FormData();

		formData.append("photoId", String(image.photoId));
		formData.append("json", JSON.stringify(parameters));

		await dispatch(updatePhotographerPhoto({ formData, photoId: image.photoId }));

		onClose();
		// onCloseFileInfo?.(false);
		const filters = `?tab=MY&page=1&pageSize=2000&timestamp=${TimeStamp}`;

		await dispatch(getPhotographerPhotos(filters));
		setIsEdited?.((prev) => !prev);
	};
	const onCancelBtn = () => {
		onClose();
	};
	const onDeleteTag = (el: string) => {
		const item = currentTags.find((i) => i === el);
		setCurrentTags((prev) => prev.filter((el) => el !== item));

		setIsTagsInputOpen(false);
	};
	const handleKeyDowm = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key == "Enter") {
			setCurrentTags((prev) => [...prev, addTagValue]);
			setAddTagValue("");
		}
	};
	const clickRef = useRef<any>();

	const onClickOutsideTags = () => {
		setIsTagsInputOpen(false);
		setAddTagValue("");
	};
	useClickOutside(clickRef, onClickOutsideTags);

	return (
		<div>
			<Content>
				<FileInfo>
					<Subtitle>Editing photo information</Subtitle>
					<EditTags>
						<h3>Edit tags</h3>
						<p>Add multiple keywords separated by enter</p>
						<TagsWrapper ref={clickRef} onClick={() => setIsTagsInputOpen(true)}>
							{currentTags.map((el, i) => (
								<DeleteTag onDelete={() => onDeleteTag(el)} key={i}>
									{el}
								</DeleteTag>
							))}

							{isTagsInputOpen && (
								<TagsWrapperInput
									autoFocus
									value={addTagValue}
									onChange={(e) => setAddTagValue(e.target.value)}
									onKeyDown={(e) => handleKeyDowm(e)}
								/>
							)}
						</TagsWrapper>
					</EditTags>
					<RadioWrapper>
						<h3>Image File</h3>
						<RadioBtns
							value={radioInfo}
							setValue={setRadioInfo}
							options={[
								{ value: "High-Res", text: "" },
								{ value: "Low-Res", text: "" },
							]}
							name="ChangeImageFile"
						/>
					</RadioWrapper>
					<RadioWrapper>
						<h3>Pricing</h3>
						<RadioBtns
							value={pricing}
							setValue={setPricing}
							disabled={radioInfo === "Low-Res"}
							options={[
								{ value: "Rights Ready", text: "" },
								{ value: "Upon Request", text: "" },
								{ value: "Custom Rights Ready", text: "" },
							]}
							name="ChangePricing"
						/>
					</RadioWrapper>
					{isAvailableToUpload && (
						<StripeError>
							<h3>
								In order to sell photos online, you need first to go to account settings and
								complete these steps:
							</h3>
							<p>1. Fill out your payout/billing information in the marketplace section.</p>
							<p>2. Connect stripe to get paid out instantly.</p>
							<div onClick={onSettingsClick}>Account settings</div>
						</StripeError>
					)}
					{pricing === "Custom Rights Ready" && (
						<div style={{ marginBottom: "24px" }}>
							<InputsWrapper>
								<Input
									error={!!errors?.priceOnline}
									{...register("priceOnline", {
										required: "required",
										min: 20,
									})}
									maxWidth="90px"
									labelText="Online"
									type="number"
								/>
								<Input
									error={!!errors?.editorial}
									{...register("priceEditorial", {
										required: "required",
										min: 20,
									})}
									maxWidth="90px"
									labelText="Editorial"
									type="number"
								/>
								<Input
									error={!!errors?.extended}
									{...register("priceExtended", {
										required: "required",
										min: 20,
									})}
									maxWidth="90px"
									labelText="Extended"
									type="number"
								/>
							</InputsWrapper>
							<ErrorWrapper>
								<p
									style={
										(errors?.priceOnline || errors?.priceEditorial || errors?.priceExtended) && {
											display: "block",
										}
									}>
									Minimal price - 20$
								</p>
							</ErrorWrapper>
						</div>
					)}
					<RadioWrapper>
						<h3>Model / Property release avialable</h3>
						<RadioBtns
							value={modelRelease}
							setValue={setModelRelease}
							options={[
								{ value: "Possible", text: "" },
								{ value: "Yes", text: "" },
								{ value: "No", text: "" },
							]}
							name="ModelRelease"
						/>
					</RadioWrapper>
					<Checkbox checked={isHideUsername} onValueChange={setIsHideUsername} label="Private" />
				</FileInfo>
				<ImageInfo>
					<ImageInfoHeader>
						<ImageInfoHeaderRight>
							<Button onClick={onCancelBtn} theme="secondary">
								Cancel
							</Button>
							<Button disabled={isAvailableToUpload} onClick={handleSubmit(onEditBtn)}>
								Update
							</Button>
						</ImageInfoHeaderRight>
					</ImageInfoHeader>
					<ImageWrapper onClick={() => openImageViewer()}>
						<img
							src={`${process.env.REACT_APP_DOMEN}${image.imageDetails.LIGHTBOX.url}`}
							alt="img"
						/>
					</ImageWrapper>
					{isViewerOpen && (
						<ImageViewer
							src={[`${process.env.REACT_APP_DOMEN}${image.imageDetails.LIGHTBOX.url}`]}
							currentIndex={currentImage}
							disableScroll={true}
							closeOnClickOutside={true}
							onClose={closeImageViewer}
							backgroundStyle={{
								backgroundColor: "rgb(44 37 37 / 70%)",
							}}
						/>
					)}
				</ImageInfo>
			</Content>
		</div>
	);
};
const ImageInfoHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 32px;
	width: 100%;
`;
const ImageInfoHeaderRight = styled.div`
	display: flex;
	justify-content: space-between;
`;
const Subtitle = styled.h3`
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 32px;
`;
const FileInfo = styled.div`
	width: 340px;
	display: flex;
	flex-direction: column;
	height: 86vh;
	overflow-y: auto;
	padding-right: 8px;
`;
const RadioWrapper = styled.div`
	h3 {
		margin-bottom: 16px;
		font-size: 16px;
		line-height: 24px;
		font-weight: 600;
	}
	margin-bottom: 24px;
`;

const Content = styled.div`
	display: flex;
	justify-content: space-between;
`;

const ImageInfo = styled.div`
	width: calc(100% - 340px);
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px 0 0 0;
	margin-top: -15px;
	& .styles-module_image__2hdkJ {
		height: 60vh;
	}
`;
const ImageWrapper = styled.div`
	height: 70vh;
	max-width: 50vw;
	& > img {
		height: 70vh;
		max-width: 50vw;
	}
`;
const TagsWrapper = styled.div`
	background: #f7f7f7;
	border-radius: 8px;
	min-height: 60px;
	padding: 12px;
	margin-bottom: 24px;
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
`;
const TagsWrapperInput = styled.input`
	border: none;
	background: transparent;
	outline: none;
	padding-left: 6px;
`;
const EditTags = styled.div`
	h3 {
		margin-bottom: 4px;
		font-size: 16px;
		line-height: 24px;
		font-weight: 600;
	}
	p {
		margin-bottom: 8px;
	}
`;
const InputsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	/* margin-bottom: 24px; */
`;
const StripeError = styled.div`
	margin-top: -10px;
	margin-bottom: 20px;
	& > h3 {
		font-size: 16px;
		line-height: 20px;
		color: #b40a0a;
		margin-bottom: 5px;
	}
	& > p {
		font-size: 14px;
	}
	& > div {
		text-decoration: underline;
		font-size: 20px;
		line-height: 22px;
		color: rgb(133, 98, 206);
		margin-top: 5px;
		cursor: pointer;
		transition: all.2s;
		&:hover {
			color: rgb(135 116 174);
			transition: all.2s;
		}
	}
`;
