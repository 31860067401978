import React, { useEffect, useState } from "react";
import { useController, useForm } from "react-hook-form";
import styled, { css } from "styled-components";
import { ErrorWrapper, Input, Button, CustomSelect } from "../UI";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAppDispatch } from "../../hooks/redux";
import { postRequest } from "../../store/reducers/imageRequest/action";
import { PhotosList } from "../../models/IBrifing";
import { CurrencyArray } from "./Photoshoot";

interface Props {
	onClose: React.Dispatch<React.SetStateAction<boolean>>;
	photoId: number | undefined;
	el?: PhotosList;
	isPriceRequestSended: boolean;
	setIsPriceRequestSended: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PriceRequest = ({ onClose, photoId, setIsPriceRequestSended, el }: Props) => {
	const [currencyValue, setCurrencyValue] = useState("USD");

	const err = "The filed is required";

	const priseRequestValidationSchema = Yup.object().shape({
		firstName: Yup.string().required(err),
		email: Yup.string().email().required(err),
		phone: Yup.string(),
		usage: Yup.string().required(err),
		territory: Yup.string().required(err),
		duration: Yup.string().required(err),
		budget: Yup.string().required(err),
	});

	const formOptions = { resolver: yupResolver(priseRequestValidationSchema) };

	const {
		control,
		register,
		formState: { errors },
		reset,
		handleSubmit,
	} = useForm(formOptions);

	useEffect(() => {
		console.log(currencyValue);
	}, [currencyValue]);

	const { field: currencyField } = useController({
		name: "Currency",
		control,
	});

	const dispatch = useAppDispatch();

	useEffect(() => {
		reset({
			email: localStorage.getItem("userEmail"),
		});
	}, []);

	const onSubmit = (formData: any) => {
		console.log(currencyValue);

		onClose(false);
		const data = JSON.stringify({
			photoId: photoId,
			name: formData.firstName,
			usage: formData.usage,
			territory: formData.territory,
			duration: formData.duration,
			price: formData.budget,
			currency: currencyValue,
			email: formData.email,
			phone: formData.phone,
			message: "string",
			releaseAvailable: "YES",
		});
		dispatch(postRequest(data));
		document.body.style.overflow = "hidden";
		setIsPriceRequestSended(true);
		setTimeout(() => {
			setIsPriceRequestSended(false);
		}, 4000);
	};

	return (
		<Wrapper>
			<Title>Price request</Title>
			<HeaderWrapper>
				<Text>
					Please complete the below form to place a price request and receive a quote from
					photographer. Your contact information is only shown to the photographers, if you agree to
					their quote and decide to buy the image.
				</Text>
				{el && (
					<ImageToRequest
						src={`${process.env.REACT_APP_DOMEN}${el.imageDetails.LIGHTBOX.url}`}
						alt="img"
					/>
				)}
			</HeaderWrapper>
			<InputWrapper>
				<Input
					autoFocus={true}
					error={!!errors?.firstName}
					{...register("firstName")}
					labelText="Name"
				/>
				<ErrorWrapper>
					<p style={errors?.firstName && { display: "block" }}>
						{errors?.firstName && `${errors?.firstName?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper>
				<Input error={!!errors?.email} {...register("email")} labelText="E-mail" />
				<ErrorWrapper>
					<p style={errors?.email && { display: "block" }}>
						{errors?.email && `${errors?.email?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper>
				<Input
					type="number"
					error={!!errors?.phone}
					{...register("phone")}
					labelText="Phone Number (optionally)"
				/>
				<ErrorWrapper>
					<p style={errors?.phone && { display: "block" }}>
						{errors?.phone && `${errors?.phone?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper>
				<Input error={!!errors?.usage} {...register("usage")} labelText="Usage" />
				<ErrorWrapper>
					<p style={errors?.usage && { display: "block" }}>
						{errors?.usage && `${errors?.usage?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper>
				<Input error={!!errors?.duration} {...register("duration")} labelText="Duration" />
				<ErrorWrapper>
					<p style={errors?.duration && { display: "block" }}>
						{errors?.duration && `${errors?.duration?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper>
				<Input error={!!errors?.territory} {...register("territory")} labelText="Territory" />
				<ErrorWrapper>
					<p style={errors?.territory && { display: "block" }}>
						{errors?.territory && `${errors?.territory?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper style={{ marginBottom: "24px" }}>
				{/* <PriceInput currency>
					<Input
						withCurrency
						maxWidth="232px"
						error={!!errors?.budget}
						{...register("budget")}
						labelText="Budget"
						type="number"
						padding="12px 34px 12px 16px"
					/>
				</PriceInput> */}
				<div style={{ display: "flex" }}>
					<Input
						error={!!errors?.budget}
						{...register("budget")}
						type={"number"}
						labelText="Budget"
						labelMaxWidth="162px"
						maxWidth="162px"
					/>
					<SelectWrapper>
						<div style={{ marginTop: "28px" }}>
							<CustomSelect
								options={CurrencyArray}
								value={currencyValue}
								onChange={setCurrencyValue}
								isSearchable={false}
								maxWidth="90px"
								labelMaxWidth="90px"
								ref={currencyField.ref}
								smallSize={true}
							/>
						</div>
					</SelectWrapper>
				</div>

				<ErrorWrapper>
					<p style={errors?.budget && { display: "block" }}>
						{errors?.budget && `${errors?.budget?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<Button type={"submit"} onClick={handleSubmit(onSubmit)} width={"100%"}>
				Send Request
			</Button>
		</Wrapper>
	);
};

const SelectWrapper = styled.div`
	margin-left: 4px;
	margin-right: 50px;
`;

const Wrapper = styled.form`
	width: 560px;
	max-height: 85vh;
	text-align: left;
	margin-top: 10px;
	overflow-y: auto;
	padding: 40px;
`;
const Title = styled.h3`
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	margin-bottom: 16px;
`;
const Text = styled.div``;
const InputWrapper = styled.div`
	margin-bottom: 8px;
`;
const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 16px;
`;
const ImageToRequest = styled.img`
	margin-left: 16px;
	max-width: 180px;
	max-height: 124px;
`;
const PriceInput = styled.div<any>`
	margin-bottom: 8px;
	margin-top: 16px;
	width: 208px;
	position: relative;
	&::after {
		position: absolute;
		${({ currency }) => {
			if (currency) {
				return css`
					content: "$";
				`;
			} else {
				return css`
					content: "€";
				`;
			}
		}}
		top: 41px;
		left: 16px;
		font-size: 14px;
		line-height: 20px;
		font-weight: 600;
	}
`;
