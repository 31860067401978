import { createSlice } from "@reduxjs/toolkit";
import { IPhotographerSkills } from "../../../models/IProfile";
import { IMessage } from "../../../models/IMessage";
import { getBriefingsMessages } from "./action";

interface MessagesState {
	messages: IMessage[];
}

const initialState: MessagesState = {
	messages: [],
};

export const messagesBriefing = createSlice({
	name: "user/messagesBriefing",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getBriefingsMessages.fulfilled, (state, action) => {
			state.messages = action.payload.messages.reverse();
		});
	},
});

export const { reducer: messagesBriefingReducer, actions: messagesBriefingActions } =
	messagesBriefing;

export const {} = messagesBriefingActions;
