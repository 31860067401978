import React, { useState, useRef, useEffect } from "react";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import styled, { css } from "styled-components";

function formatDate(date: any) {
	const month = date.toLocaleString("default", { month: "short" });
	const day = date.getDate();
	return `${day} ${month}`;
}

interface Props {
	label: string;
	byLeftSide?: boolean;
	date: Date;
	setDate: React.Dispatch<React.SetStateAction<Date>>;
}

export const DatePicker = ({ label, date, setDate, byLeftSide = false }: Props) => {
	const [showCalendar, setShowCalendar] = useState(false);
	const inputRef = useRef<any>();

	const handleSelect = (date: any) => {
		setDate(date);
		setShowCalendar(false);
	};

	const handleClickOutside = (event: any) => {
		if (inputRef.current && !inputRef.current.contains(event.target)) {
			setShowCalendar(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<Wrapper
			ref={inputRef}
			style={{
				position: "relative",
				width: "232px",
			}}>
			<Label>{label}</Label>
			<Input
				type="text"
				value={formatDate(date)}
				onClick={() => setShowCalendar((prev) => !prev)}
				readOnly
				style={{
					width: "100%",
					boxSizing: "border-box",
					paddingRight: "32px",
				}}
			/>
			<div
				style={{
					position: "absolute",
					top: showCalendar ? "36px" : "42px",
					right: "14px",
					transform: showCalendar ? "rotate(180deg)" : "rotate(0deg)",
					transformOrigin: "center",
					transition: "transform 0.2s ease-in-out",
				}}>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
					<path d="M7.4 7.4l4.6 4.6 4.6-4.6 1.4 1.4-6 6-6-6z" />
				</svg>
			</div>
			{showCalendar && (
				<DateRangeDiv byLeftSide={byLeftSide}>
					<Calendar
						date={date}
						onChange={handleSelect}
						showMonthAndYearPickers={false}
						showDateDisplay={false}
						minDate={new Date()}
						direction="vertical"
						scroll={{ enabled: true }}
						color="#8562ce"
					/>
				</DateRangeDiv>
			)}
		</Wrapper>
	);
};
const Wrapper = styled.div`
	position: relative;
`;
const DateRangeDiv = styled.div<any>`
	position: absolute;
	top: 80px;
	${({ byLeftSide }) => {
		if (byLeftSide) {
			return css`
				left: 0px;
			`;
		} else {
			return css`
				right: 0;
			`;
		}
	}}
	z-index: 5;
`;
const Label = styled.div`
	margin-bottom: 8px;
	font-weight: 600;
	color: #282828;
`;

const Input = styled.input`
	padding: 12px 40px 12px 16px;
	box-sizing: border-box;
	width: 221px;
	border: 1px solid transparent;
	border-radius: 8px;
	background: #f7f7f7;
	outline: none;
`;
