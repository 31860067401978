import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAppSelector } from "../hooks/redux";

import { ProfileGallery } from "./ProfileGallery";
import { useNavigate } from "react-router-dom";

export const ShowAllByAuthor = () => {
	const [sedcards, setSedcards] = useState<any>([]);
	const { Sedcards, Archive } = useAppSelector((state) => state.gallery);
	const { showAllByAuthor } = useAppSelector((state) => state.photos);

	const photographerAbout = useAppSelector((state) => state.profile.userAccountInfo);

	const navigate = useNavigate();

	useEffect(() => {
		if (showAllByAuthor.photos.length === 0) {
			navigate("/");
		}
	}, []);

	useEffect(() => {
		showAllByAuthor.photos.map((el) => {
			if (el.sedcard === true) {
				setSedcards((prev: any) => [...prev, el]);
			}
		});
	}, [showAllByAuthor]);

	const onTitleClick = () => {
		navigate("/creator/profile/userAccount");
	};
	return (
		<Wrapper>
			<Title onClick={onTitleClick}>
				{photographerAbout.firstName} {photographerAbout.lastName}
			</Title>
			<ProfileGallery
				showAllByAuthorArchive={showAllByAuthor.photos}
				showAllByAuthorSedcard={sedcards}
				sedCards={Sedcards}
				archive={Archive}
			/>
		</Wrapper>
	);
};

const Wrapper = styled.div``;
const Title = styled.h2`
	font-weight: 700;
	font-size: 64px;
	line-height: 80px;
	cursor: pointer;
	text-align: center;
`;
