import { createSlice } from "@reduxjs/toolkit";
import { getAllJobRequests, getJobRequests, postBriefing } from "./action";
import { Briefing } from "../../../models/IBrifing";

interface ImageRequests {
	jobRequests: Briefing[];
	isLoading: boolean;
	isAllLoading: boolean;
	sendingBriefing: boolean;
	allRequestsCount: number;
	ActiveRequestsCount: number;
	DraftsRequestsCount: number;
	ClosedRequestsCount: number;
}

const initialState: ImageRequests = {
	jobRequests: [],
	isLoading: false,
	isAllLoading: false,
	sendingBriefing: false,
	allRequestsCount: 0,
	ActiveRequestsCount: 0,
	DraftsRequestsCount: 0,
	ClosedRequestsCount: 0,
};

export const artbuyerJobRequest = createSlice({
	name: "user/artbuyerImageRequest",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(postBriefing.fulfilled, (state) => {
				state.sendingBriefing = false;
			})
			.addCase(postBriefing.rejected, (state) => {
				state.sendingBriefing = false;
			})
			.addCase(postBriefing.pending, (state) => {
				state.sendingBriefing = true;
			})
			.addCase(getJobRequests.fulfilled, (state, action) => {
				state.jobRequests = action.payload.briefings;
				state.isLoading = false;
			})
			.addCase(getAllJobRequests.fulfilled, (state, action) => {
				state.ActiveRequestsCount = 0;
				state.ClosedRequestsCount = 0;
				state.DraftsRequestsCount = 0;
				state.allRequestsCount = 0;

				for (let i = 0; i < action.payload.briefings.length; i++) {
					const status = action.payload.briefings[i].status;
					state.allRequestsCount++;
					if (status === "PUBLISHED") {
						state.ActiveRequestsCount++;
					} else if (status === "DRAFT") {
						state.DraftsRequestsCount++;
					} else {
						state.ClosedRequestsCount++;
					}
				}
				state.isAllLoading = false;
			})
			.addCase(getAllJobRequests.pending, (state, action) => {
				state.isAllLoading = true;
			})
			.addCase(getAllJobRequests.rejected, (state, action) => {
				state.isLoading = false;
			})
			.addCase(getJobRequests.pending, (state, action) => {
				state.isLoading = true;
			})
			.addCase(getJobRequests.rejected, (state, action) => {
				state.isLoading = false;
			});
	},
});

export const { reducer: artbuyerJobRequestReducer, actions: artbuyerJobRequestActions } =
	artbuyerJobRequest;

export const {} = artbuyerJobRequestActions;
