import styled from "styled-components";
import { Button, Input, Modal } from "../UI";
import { useForm } from "react-hook-form";
import { ISuggestedImageInfo, PhotosList } from "../../models/IBrifing";
import { useState } from "react";
import { ChooseImageToCart } from "./ChooseImageToCart";

interface Props {
	el?: PhotosList;
	currentImageInfo: ISuggestedImageInfo;
	onClose: () => void;
}

export const ImageInfo = ({ el, currentImageInfo, onClose }: Props) => {
	const [editOpen, setEditOpen] = useState(false);
	const {
		register,
		formState: {},
		handleSubmit,
	} = useForm();

	const onSendMessage = (data: any) => {
		setEditOpen(true);
	};

	return (
		<Wrapper>
			<Title>Image Info</Title>
			<ImageInfoInner>
				<ContentWrapper>
					<ul>
						<li>Original file / Mobile</li>
						<li>Max available file size: 10 Mb</li>
						<li>Available format: jpg</li>
						<li>Release: No</li>
					</ul>
					<form onSubmit={handleSubmit(onSendMessage)}>
						<InputWrapper>
							<Input
								{...register("message")}
								maxWidth="100%"
								placeholder="Type your comment here"
							/>
						</InputWrapper>
						<Button type="submit">Buy Photo</Button>
					</form>
				</ContentWrapper>
				<ImageWrapper>
					<img src={`${process.env.REACT_APP_DOMEN}${el?.imageDetails.LIGHTBOX.url}`} alt="img" />
				</ImageWrapper>
			</ImageInfoInner>
			<Modal padding="40px 32px" isOpen={editOpen} onClose={() => setEditOpen(false)}>
				<ChooseImageToCart
					onCloseModal={onClose}
					onClose={() => setEditOpen(false)}
					el={currentImageInfo}
				/>
			</Modal>
		</Wrapper>
	);
};
const Wrapper = styled.div`
	width: 944px;
	max-height: 85vh;
	text-align: left;
	margin-top: 10px;
	overflow-y: auto;
	padding: 40px;
`;
const Title = styled.h3`
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	margin-bottom: 16px;
`;
const ImageInfoInner = styled.div`
	display: flex;
`;
const ContentWrapper = styled.div`
	width: 424px;
	& > ul {
		margin-bottom: 24px;
		& > li {
			margin-bottom: 4px;
		}
	}
`;
const InputWrapper = styled.div`
	margin-bottom: 24px;
`;
const ImageWrapper = styled.div`
	margin-left: 24px;
	width: 432px;
	max-height: 544px;
	display: flex;
	justify-content: center;
	& > img {
		max-width: 432px;
		max-height: 544px;
	}
`;
