import styled from "styled-components";

export const Pricing = () => {
	return (
		<Wrapper>
			<Title>Pricing</Title>
			<Text>
				Browse our database for images. You can buy & download pictures online or request pricing by
				contacting the creators directly. Posting image & job request is free, as well as receiving
				suggestions. Creators determine which images they sell and which price model they choose.
			</Text>
			<PricingInner>
				<PricingItem>
					<PricingItemHeader>
						<h3>Upon Request</h3>
						<p>Usage rights: custom</p>
						<p>Pricing: custom</p>
					</PricingItemHeader>
					<PricingItemContent style={{ gridTemplateRows: "repeat(1, 1fr)" }}>
						<div>
							<p>Request any usage rights and negotiate the price directly with the creator</p>
						</div>
					</PricingItemContent>
				</PricingItem>
				<PricingItem>
					<PricingItemHeader>
						<h3>Usage Rights</h3>
						<p>How the images can be used</p>
					</PricingItemHeader>
					<PricingItemContent>
						<div>
							<h3 style={{ marginBottom: "8px" }}>Online</h3>
							<p>Unlimited, worldwide commercial usage for online media</p>
						</div>
						<div>
							<h3 style={{ marginBottom: "8px" }}>Editorial</h3>
							<p>Unlimited, worldwide editorial usage for online and print media</p>
						</div>
						<div>
							<h3 style={{ marginBottom: "8px" }}>Extended</h3>
							<p>Unlimited, worldwide commercial usage for all media</p>
						</div>
					</PricingItemContent>
				</PricingItem>
				<PricingItem>
					<PricingItemHeader>
						<h3>Rights Ready</h3>
						<p>Usage rights: fixed</p>
						<p>Pricing: fixed</p>
					</PricingItemHeader>
					<PricingItemContent>
						<div>
							<h3>€ 20</h3>
						</div>
						<div>
							<h3>€ 80</h3>
						</div>
						<div>
							<h3>€ 250</h3>
						</div>
					</PricingItemContent>
				</PricingItem>
				<PricingItem>
					<PricingItemHeader>
						<h3>Custom Rights Ready</h3>
						<p>Usage rights: fixed</p>
						<p>Pricing: custom</p>
					</PricingItemHeader>
					<PricingItemContent>
						<div>
							<p>Custom by creator</p>
						</div>
						<div>
							<p>Custom by creator</p>
						</div>
						<div>
							<p>Custom by creator</p>
						</div>
					</PricingItemContent>
				</PricingItem>
			</PricingInner>
			<PricingFooter>
				<div>Creator has to approve sale</div>
				<div></div>
				<div>Instant purchase & download</div>
			</PricingFooter>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding: 40px;
	background: #f7f7f7;
	border-radius: 24px;
	height: 100%;
`;
const Title = styled.div`
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	margin-bottom: 8px;
`;
const Text = styled.div`
	margin-bottom: 24px;
	max-width: 800px;
`;
const PricingInner = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 4px;
	color: #fff;
	height: calc(81% - 52px);
`;
const PricingItem = styled.div`
	text-align: center;
`;
const PricingItemHeader = styled.div`
	padding: 16px 24px;
	background: #8562ce;
	border-radius: 8px 8px 0px 0px;
	margin-bottom: 2px;
	height: 104px;
	& > h3 {
		font-weight: 700;
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 8px;
	}
`;
const PricingItemContent = styled.div`
	height: calc(100% - 107px);
	display: grid;
	grid-template-rows: repeat(3, 1fr);
	grid-gap: 2px;
	& > div {
		background: #fff;
		padding: 16px 24px;
		color: #000;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		& > h3 {
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
		}
	}
`;
const PricingFooter = styled.div`
	display: grid;
	margin-top: 2px;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 4px;
	& > div {
		padding: 16px 24px;
		color: #000000;
		text-align: center;
		background: #f1edf7;
		&:nth-child(2) {
			background: transparent;
		}
		&:nth-child(3) {
			grid-column: 3 / 5;
		}
	}
`;
