import React from "react";
import styled, { css } from "styled-components";
import { updated } from "../../static/images";

interface Props {
  isUpdated: boolean;
}

export const ProfiileUpdated = ({ isUpdated }: Props) => {
  return (
    <Wrapper isUpdated={isUpdated}>
      <img src={updated} alt="updatedSvg" />
      <WrapperText>Profile updated successfully</WrapperText>
    </Wrapper>
  );
};

const Wrapper = styled.div<Props>`
  background: #f1edf7;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  ${({ isUpdated }) => {
    return isUpdated
      ? css`
          height: 40px;
          margin: -25px 0 40px;
          visibility: visible;
        `
      : css`
          height: 0;
          visibility: hidden;
          margin: 0;
        `;
  }}
`;
const WrapperText = styled.div`
  margin-left: 10px;
`;
