import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "./CheckoutForm";
import "../../styles/cart.scss";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// A reference to Stripe.js initialized with a fake API key.
// Sign in to see examples prefilled with your key.
const promise = loadStripe("pk_test_dw1PQ1HnQrOg6vm1H6QwZMsw");

interface Props {
	isValid: boolean;
	clientSecret: string;
	setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
	setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
	setIsCardComplete: React.Dispatch<React.SetStateAction<boolean>>;
	basketIds: number[];
	userCountry: number;
}

export default function FormWrapper({
	isValid,
	basketIds,
	setIsValid,
	setIsDisabled,
	clientSecret,
	userCountry,
	setIsCardComplete,
}: Props) {
	return (
		<div className="App">
			<Elements stripe={promise}>
				<CheckoutForm
					setIsCardComplete={setIsCardComplete}
					userCountry={userCountry}
					basketIds={basketIds}
					setIsDisabled={setIsDisabled}
					setIsValid={setIsValid}
					clientSecret={clientSecret}
					isValid={isValid}
				/>
			</Elements>
		</div>
	);
}
