import { useForm } from "react-hook-form";
import styled from "styled-components";
import { SubTitle } from ".";
import { ButtonWrapper, InputWrapper } from "../PublicInfo";
import { Button, ErrorWrapper, PasswordInput } from "../UI";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
	updateArtbuyerProfile,
	updatePhotographerProfile,
} from "../../store/reducers/profile/action";
import { changePasswordSchema } from "../../utils/YupCchems";
import { AuthRoles } from "../../models/IProfile";

interface Props {
	onUpdateInfo: () => void;
}

export const PasswordsForm = ({ onUpdateInfo }: Props) => {
	const formOptions = { resolver: yupResolver(changePasswordSchema) };
	const dispatch = useAppDispatch();

	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm({ ...formOptions });

	const { user } = useAppSelector((state) => state.authData);

	const onSubmit = async (data: any) => {
		const { payload, meta } = await dispatch(
			user?.role === AuthRoles.Photographer
				? updatePhotographerProfile({
						oldPassword: data.currentPassword,
						newPassword: data.newPassword,
				  })
				: updateArtbuyerProfile({
						oldPassword: data.currentPassword,
						newPassword: data.newPassword,
				  })
		);
		if (meta.requestStatus === "fulfilled") {
			reset({ currentPassword: "", newPassword: "", repeatNewPassword: "" });
			onUpdateInfo();
		}
		localStorage.setItem("token", payload.data.token);
	};

	const err = "The filed is required";

	return (
		<PasswordForm id="password">
			<SubTitle style={{ marginBottom: "24px" }}>Password</SubTitle>
			<InputWrapper>
				<PasswordInput
					error={!!errors?.currentPassword}
					{...register("currentPassword", {
						required: err,
					})}
					labelText="Current Password"
				/>
				<ErrorWrapper>
					<p style={errors?.currentPassword && { display: "block" }}>
						{errors?.currentPassword && `${errors?.currentPassword?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper>
				<PasswordInput
					error={!!errors?.newPassword}
					{...register("newPassword", {
						required: err,
					})}
					labelText="New Password"
				/>
				<ErrorWrapper>
					<p style={errors?.newPassword && { display: "block" }}>
						{errors?.newPassword && `${errors?.newPassword?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper>
				<PasswordInput
					error={!!errors?.repeatNewPassword}
					{...register("repeatNewPassword", {
						required: err,
					})}
					labelText="Repeat New Password"
				/>
				<ErrorWrapper>
					<p style={errors?.repeatNewPassword && { display: "block" }}>
						{errors?.repeatNewPassword && `${errors?.repeatNewPassword?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<ButtonWrapper>
				<Button onClick={handleSubmit(onSubmit)}>Update</Button>
			</ButtonWrapper>
		</PasswordForm>
	);
};
const PasswordForm = styled.form`
	margin-bottom: 48px;
`;
