import React, { useState } from "react";
import styled from "styled-components";
import { Briefing } from "../../models/IBrifing";
import { Button, Modal } from "../UI";
import { DeleteRequestModal } from "./DeleteRequest";
import { useAppDispatch } from "../../hooks/redux";
import { updateBriefing } from "../../store/reducers/artbuyerJobRequests/action";
import { PhotoshootEdit } from "./PhotoshootEdit";
import { formatDate } from "../JobRequests";
import { getStatus } from "../../utils/getSmth";

interface Props {
	element: Briefing | undefined;
	onClose: () => void;
	getRequests: () => void;
	setIsChangedToPublished: React.Dispatch<React.SetStateAction<boolean>>;
}

export const JobRequestDraft = ({
	element,
	onClose,
	getRequests,
	setIsChangedToPublished,
}: Props) => {
	const [isDeleteRequestOpen, setIsDeleteRequestOpen] = useState(false);
	const [isPhotoshootOpen, setIsPhotoshootOpen] = useState(false);

	const dispatch = useAppDispatch();

	const onDeleteRequest = () => {
		setIsDeleteRequestOpen(true);
	};

	const onClosePhotoshoot = () => {
		setIsPhotoshootOpen(false);
		onClose();
	};
	const onPublishRequest = async () => {
		const json = { ...element, status: "PUBLISHED" };

		await dispatch(updateBriefing(JSON.stringify(json)));
		getRequests();
		onClose();
		setIsChangedToPublished(true);
		document.body.style.overflow = "auto";
		setTimeout(() => {
			setIsChangedToPublished(false);
		}, 4000);
	};
	const onEditRequest = () => {
		setIsPhotoshootOpen(true);
	};

	return (
		<JobRequest>
			<JobReqeustHeader>
				<JobRequestTitle>Job Request</JobRequestTitle>

				<JobRequestStatus style={{ color: getStatus(element?.status || "").color }}>
					{getStatus(element?.status || "").value}
				</JobRequestStatus>

				<DeleteRequest onClick={onDeleteRequest}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M6.75 7.75L7.59115 17.4233C7.68102 18.4568 8.54622 19.25 9.58363 19.25H14.4164C15.4538 19.25 16.319 18.4568 16.4088 17.4233L17.25 7.75"
							stroke="#505050"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M10.5 11V16M13.5 11V16"
							stroke="#505050"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M9.75 7.5V6.75C9.75 5.64543 10.6454 4.75 11.75 4.75H12.25C13.3546 4.75 14.25 5.64543 14.25 6.75V7.5"
							stroke="#505050"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M5 7.75H19"
							stroke="#505050"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
					Delete Request
				</DeleteRequest>
				<div>
					<Button onClick={onEditRequest} theme={"secondary"}>
						Edit Request
					</Button>
				</div>
				<div>
					<Button onClick={() => onPublishRequest()}>Publish</Button>
				</div>
			</JobReqeustHeader>

			<JobRequestAbout>
				<JobRequestSubtitle>{element?.title}</JobRequestSubtitle>
				<JobRequestAboutDesc>{element?.description}</JobRequestAboutDesc>
				<JobRequestInner>
					<div>
						<JobRequestItemTitle>Budget</JobRequestItemTitle>
						<div>
							{element?.currency === "USD" ? "$" : "€"} {element?.budget} /{" "}
							{element?.budgetTotal ? "For all" : "Per day"}
						</div>
					</div>
					<div>
						<JobRequestItemTitle>Total Shooting Days</JobRequestItemTitle>
						<div>{element?.totalShootingDays ? element.totalShootingDays : "-"}</div>
					</div>
					<div>
						<JobRequestItemTitle>Approx Shooting Period</JobRequestItemTitle>
						<div>
							{formatDate(element?.approxShootingEnd)} – {formatDate(element?.approxShootingStart)}
						</div>
					</div>
					<div>
						<JobRequestItemTitle>Briefing Start</JobRequestItemTitle>
						<div>{formatDate(element?.dateCreate)}</div>
					</div>
					<div>
						<JobRequestItemTitle>Submission Deadline</JobRequestItemTitle>
						<div>{formatDate(element?.dateExpire)}</div>
					</div>
				</JobRequestInner>
			</JobRequestAbout>
			<JobRequestImages>
				<JobRequestSubtitle>Visual References</JobRequestSubtitle>
				<JobRequestImagesInner>
					{element?.examplesList.map((el, index) => {
						return (
							<img
								key={index}
								src={`${process.env.REACT_APP_DOMEN}${el.imageDetails.LIGHTBOX?.url}`}
								alt=""
							/>
						);
					})}
				</JobRequestImagesInner>
			</JobRequestImages>

			<Modal
				size="small"
				isOpen={isDeleteRequestOpen}
				onClose={() => setIsDeleteRequestOpen(false)}>
				<DeleteRequestModal
					getRequests={getRequests}
					setFileInfoOpen={onClose}
					fileToDelete={element?.id}
					modalOpen={isDeleteRequestOpen}
					setModalOpen={() => setIsDeleteRequestOpen(false)}
				/>
			</Modal>

			<Modal
				hidden={true}
				isOpen={isPhotoshootOpen}
				size="small"
				noPadding
				onClose={() => setIsPhotoshootOpen(false)}>
				<PhotoshootEdit
					getRequests={getRequests}
					onClosePhotoshoot={onClosePhotoshoot}
					element={element}
				/>
			</Modal>
		</JobRequest>
	);
};

const JobRequest = styled.div`
	width: 872px;
	background: #fff;
	margin-right: 16px;
	padding: 24px;
`;
const JobReqeustHeader = styled.div`
	display: flex;
	margin-bottom: 24px;
	align-items: center;
`;
const JobRequestTitle = styled.div`
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
`;
const JobRequestStatus = styled.div`
	color: #ffaa06;
	margin-right: 12px;
	margin-left: auto;
`;
const DeleteRequest = styled.div`
	padding: 13px 20px 10px 10px;
	color: #505050;
	background: #f7f7f7;
	border-radius: 8px;
	display: flex;
	align-items: center;
	margin-right: 8px;
	cursor: pointer;
	transition: 0.2s;

	&:hover {
		transition: 0.2s;
		color: #ee4747;
		& > svg {
			& > path {
				stroke: #ee4747;
				transition: 0.2s;
			}
		}
	}
	& > svg {
		& > path {
			transition: 0.2s;
		}
		margin-right: 8px;
	}
`;
const JobRequestAbout = styled.div`
	padding-bottom: 24px;
	border-bottom: 1px solid #ebebeb;
`;
const JobRequestSubtitle = styled.div`
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 8px;
`;
const JobRequestAboutDesc = styled.div`
	margin-bottom: 24px;
`;
const JobRequestInner = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 16px;
`;
const JobRequestItemTitle = styled.div`
	font-weight: 600;
	color: #282828;
	margin-bottom: 8px;
`;
const JobRequestImages = styled.div`
	margin-top: 24px;
`;
const JobRequestImagesInner = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	& > img {
		width: 96px;
		max-height: 132px;
	}
`;
