import { useEffect, useState } from "react";
import styled from "styled-components";
import { Briefing } from "../../models/IBrifing";
import { Button, Modal } from "../UI";
import { DeleteRequestModal } from "./DeleteRequest";
import { useAppDispatch } from "../../hooks/redux";
import { PhotoshootEdit } from "./PhotoshootEdit";
import {
	getJobRequestNotInterested,
	getJobRequestTrack,
	getJobRequestUnTrack,
	getphotographerBriefing,
} from "../../store/reducers/photographerJobRequests/action";
import { formatDate } from "../JobRequests";

interface Props {
	element: Briefing | undefined;
	onClose: () => void;
	getRequests: () => void;
}

export const JobRequestConfirm = ({ element, onClose, getRequests }: Props) => {
	const [isDeleteRequestOpen, setIsDeleteRequestOpen] = useState(false);
	const [currentBrief, setCurrentBrief] = useState<any>({});
	const [isPhotoshootOpen, setIsPhotoshootOpen] = useState(false);

	const dispatch = useAppDispatch();

	useEffect(() => {
		const getBrief = async () => {
			const { payload } = await dispatch(getphotographerBriefing(element?.id || 0));
			setCurrentBrief(payload);
		};
		getBrief();
	}, []);

	const onTrackRequest = async () => {
		await dispatch(getJobRequestTrack(element?.id || 0));
		getRequests();
		onClose();
	};

	const onUnTrackRequest = async () => {
		await dispatch(getJobRequestNotInterested(element?.id || 0));
		getRequests();
		onClose();
	};

	const onClosePhotoshoot = () => {
		setIsPhotoshootOpen(false);
		onClose();
	};

	return (
		<JobRequest>
			<JobReqeustHeader style={{ justifyContent: "space-between" }}>
				<JobRequestTitle>Job Request</JobRequestTitle>
				<div style={{ display: "flex", gap: "8px" }}>
					<Button onClick={onUnTrackRequest} theme={"red"}>
						Not Interested
					</Button>
					<Button onClick={onTrackRequest}>Interested: Start Messaging</Button>
				</div>
			</JobReqeustHeader>
			<JobRequestAbout>
				<JobRequestSubtitle>{currentBrief.title}</JobRequestSubtitle>
				<JobRequestAboutDesc>{currentBrief.description}</JobRequestAboutDesc>
				<JobRequestInner>
					<JobRequestItem>
						<JobRequestItemTitle>Budget</JobRequestItemTitle>
						<JobRequestItemText>
							{element?.currency === "USD" ? "$" : "€"} {element?.budget} / Per day
						</JobRequestItemText>
					</JobRequestItem>
					<JobRequestItem>
						<JobRequestItemTitle>Total Shooting Days</JobRequestItemTitle>
						<JobRequestItemText>
							{element?.totalShootingDays ? element.totalShootingDays : "-"}
						</JobRequestItemText>
					</JobRequestItem>
					<JobRequestItem>
						<JobRequestItemTitle>Approx Shooting Period</JobRequestItemTitle>
						<JobRequestItemText>
							{formatDate(element?.approxShootingStart)} - {formatDate(element?.approxShootingEnd)}
						</JobRequestItemText>
					</JobRequestItem>
					<JobRequestItem>
						<JobRequestItemTitle>Briefing Start</JobRequestItemTitle>
						<JobRequestItemText>{formatDate(element?.dateCreate)}</JobRequestItemText>
					</JobRequestItem>
					<JobRequestItem>
						<JobRequestItemTitle>Submission Deadline</JobRequestItemTitle>
						<JobRequestItemText>{formatDate(element?.dateExpire)}</JobRequestItemText>
					</JobRequestItem>
				</JobRequestInner>
			</JobRequestAbout>
			<JobRequestImages>
				<JobRequestSubtitle>Visual References</JobRequestSubtitle>
				<JobRequestImagesInner>
					{element?.examplesList.map((el, index) => {
						return (
							<img
								key={index}
								src={`${process.env.REACT_APP_DOMEN}${el.imageDetails.LIGHTBOX?.url}`}
								alt=""
							/>
						);
					})}
				</JobRequestImagesInner>
			</JobRequestImages>
			<Modal
				size="small"
				isOpen={isDeleteRequestOpen}
				onClose={() => setIsDeleteRequestOpen(false)}>
				<DeleteRequestModal
					getRequests={getRequests}
					setFileInfoOpen={onClose}
					fileToDelete={element?.id}
					modalOpen={isDeleteRequestOpen}
					setModalOpen={() => setIsDeleteRequestOpen(false)}
				/>
			</Modal>
			<Modal
				hidden={true}
				isOpen={isPhotoshootOpen}
				size="small"
				noPadding
				onClose={() => setIsPhotoshootOpen(false)}>
				<PhotoshootEdit
					getRequests={getRequests}
					onClosePhotoshoot={onClosePhotoshoot}
					element={element}
				/>
			</Modal>
		</JobRequest>
	);
};

const JobRequest = styled.div`
	width: 872px;
	background: #fff;
	margin-right: 16px;
	padding: 24px;
`;
const JobReqeustHeader = styled.div`
	display: flex;
	margin-bottom: 24px;
	align-items: center;
	padding-right: 40px;
`;
const JobRequestTitle = styled.div`
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
`;

const JobRequestAbout = styled.div`
	padding-bottom: 24px;
	border-bottom: 1px solid #ebebeb;
`;
const JobRequestSubtitle = styled.div`
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 8px;
`;
const JobRequestAboutDesc = styled.div`
	margin-bottom: 24px;
`;
const JobRequestInner = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 16px;
`;
const JobRequestItem = styled.div``;
const JobRequestItemTitle = styled.div`
	font-weight: 600;
	color: #282828;
	margin-bottom: 8px;
`;
const JobRequestItemText = styled.div``;
const JobRequestImages = styled.div`
	margin-top: 24px;
`;
const JobRequestImagesInner = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	& > img {
		width: 96px;
		max-height: 132px;
	}
`;
