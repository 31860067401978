import styled from "styled-components";
import { IImageRequest } from "../../models/IImageRequest";

interface Props {
	img: string;
	file: IImageRequest | null;
}

export const PaymentInfo = ({ file, img }: Props) => {
	return (
		<WrapperContent>
			<WrapperContentItem>
				<ImageAbout>
					<ImageAboutItem>
						<h4>Resolution</h4>
						<p>1685 px × 2240 px</p>
					</ImageAboutItem>
					<ImageAboutItem>
						<h4>Duration</h4>
						<p>{file?.duration}</p>
					</ImageAboutItem>
					<ImageAboutItem>
						<h4>File Format</h4>
						<p>{file?.photo.imageDetails.LIGHTBOX.extension}</p>
					</ImageAboutItem>
					<ImageAboutItem>
						<h4>Model Release</h4>
						<p>Possible</p>
					</ImageAboutItem>

					<ImageAboutItem>
						<h4>Usage</h4>
						<p>{file?.usage}</p>
					</ImageAboutItem>
					<ImageAboutItem>
						<h4>Price</h4>
						<p>
							{file?.currency === "USD" ? "$" : "€"} {file?.price}.00
						</p>
					</ImageAboutItem>
					<ImageAboutItem>
						<h4>Territory</h4>
						<p>{file?.territory}</p>
					</ImageAboutItem>
					<ImageAboutItem>
						<h4>VSBLE Space Fee</h4>
						<p>15%</p>
					</ImageAboutItem>
					<ImageAboutItem>
						<h4>Release Attach</h4>
						<p>No</p>
					</ImageAboutItem>
				</ImageAbout>
				<ImageInner>
					<img src={img} alt="reqImg" />
				</ImageInner>
			</WrapperContentItem>
		</WrapperContent>
	);
};

const WrapperContent = styled.div`
	margin: 0 auto;
	width: 752px;
`;
const ImageInner = styled.div`
	width: 180px;
	& > img {
		width: 180px;
	}
`;
const WrapperContentItem = styled.div`
	display: flex;
	background: #fff;
	padding: 24px;
	border-radius: 8px;
	margin-bottom: 4px;
`;
const ImageAbout = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 42px;
	grid-gap: 16px;
	width: 540px;
`;
const ImageAboutItem = styled.div`
	& > h4 {
		font-weight: 600;
		font-size: 12px;
		line-height: 18px;
		margin-bottom: 4px;
	}
`;
