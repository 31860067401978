export const skillLevels = [
	{ value: "", label: "Skill level" },
	{ value: "PROFESSIONAL", label: "Proffesional" },
	{ value: "UP_AND_COMMING", label: "Up & Coming" },
	{ value: "BEGINNER", label: "Newcomer" },
];
export const assetsValues = [
	{ value: "", label: "Assets" },
	{ value: "OWN_STUDIO", label: "Own studio" },
	{ value: "OWN_EQUIPMENT", label: "Own Equipment" },
	{ value: "DRIVERS_LICENCE", label: "Drivers License" },
	{ value: "OWN_CAR", label: "Own Car" },
];
export const servicesValues = [
	{ value: "", label: "Additional service" },
	{ value: "PHOTO_RETOUCH", label: "Photo Retouch" },
	{ value: "LOCATION_SCOUTING", label: "Location Scounting" },
	{ value: "VIDEO", label: "Video" },
	{ value: "VIDEO_GRADING", label: "Video Grading" },
	{ value: "VIDEO_CUTTING", label: "Video Cutting" },
	{ value: "CASTING", label: "Custing" },
	{ value: "PRODUCTION_SERVICES", label: "Production Services" },
];
export const additionalSkillsValues = [
	{ value: "", label: "Additional skills" },
	{ value: "DESIGN", label: "Design" },
	{ value: "PROGRAMMING", label: "Programming" },
	{ value: "STORYTELLING", label: "StoryTelling" },
	{ value: "ART_DIRECTION", label: "Art Direction" },
	{ value: "WRITING", label: "Writing" },
	{ value: "IDEA_GENERATION", label: "Idea Generation" },
	{ value: "MUSIC", label: "Music" },
	{ value: "PUBLIC_SPEAKING", label: "Public Speaking" },
	{ value: "CREATIVE_RESEARCH", label: "Creative Research" },
];
export const availableFormatValues = [
	{ value: "JPG", label: "jpg" },
	{ value: "TIFF", label: "tiff" },
	{ value: "RAW", label: "raw" },
];
