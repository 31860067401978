import React from "react";
import styled from "styled-components";
import { useAppDispatch } from "../../hooks/redux";
import { Button } from "../UI";

import { deleteJobRequest } from "../../store/reducers/artbuyerJobRequests/action";

interface Props {
	modalOpen: boolean;
	setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setFileInfoOpen: React.Dispatch<React.SetStateAction<boolean>> | undefined;
	fileToDelete: number | undefined;
	getRequests: () => void;
}

export const DeleteRequestModal = ({
	setModalOpen,
	getRequests,
	setFileInfoOpen,
	fileToDelete,
}: Props) => {
	const dispatch = useAppDispatch();

	const onDelete = async () => {
		if (fileToDelete) {
			await dispatch(deleteJobRequest(fileToDelete));
		}

		setModalOpen(false);
		setFileInfoOpen?.(false);
		getRequests();
		document.body.style.overflow = "auto";
	};
	const onReset = () => {
		setModalOpen(false);
	};

	return (
		<div>
			<RequestModalWrapper>
				<RequestModalTitle>Are you sure , you want to delete this request?</RequestModalTitle>
				<ThanksModalText>
					You will not be able to restore the request after deletion!
				</ThanksModalText>
				<ModalButtonWrapper>
					<Button theme={"outline"} type="button" onClick={onReset} width="232px">
						Cancel
					</Button>
					<Button theme="red" type="button" onClick={onDelete} width="232px">
						Yes, delete this request
					</Button>
				</ModalButtonWrapper>
			</RequestModalWrapper>
		</div>
	);
};

const RequestModalTitle = styled.div`
	margin-bottom: 24px;
	text-align: center;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
`;
const ModalButtonWrapper = styled.div`
	display: flex;
`;
const RequestModalWrapper = styled.div`
	width: 480px;
	padding: 5px 8px;
`;
const ThanksModalText = styled.div`
	margin-top: -8px;
	text-align: center;
	margin-bottom: 24px;
`;
