import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { SubTitle } from ".";
import { useAppDispatch } from "../../hooks/redux";
import { authDataActions } from "../../store/reducers/authData";
import { DeleteAccountConfirm } from "../Modals/DeleteAccountConfirm";
import { DeleteAccountReq } from "../Modals/DeleteAccountReq";
import { InputWrapper } from "../PublicInfo";
import { Button, Modal, PasswordInput } from "../UI";

export const DeleteAccountForm = () => {
	const [isModalOpen, setIsOpenModal] = useState(false);
	const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);
	const [isDeleteAccount, setIsDeleteAccount] = useState(false);
	const [password, setPassword] = useState({});
	const { register, handleSubmit } = useForm();
	const dispatch = useAppDispatch();

	const onSubmit = async (data: any) => {
		setPassword(data);

		setIsDeleteAccountModalOpen(true);
	};
	useMemo(() => {
		if (isDeleteAccount && !isModalOpen) {
			dispatch(authDataActions.setLogout());
		}
	}, [isDeleteAccount, isModalOpen]);

	return (
		<DeleteAccount>
			<SubTitle>Delete Account</SubTitle>
			<Text>
				All images wil be removed from ongoing briefings, the showroom and our servers. This action
				can not be undone.
			</Text>
			<InputWrapper>
				<PasswordInput
					{...register("currentPassword")}
					labelText="Your Current Password"
					placeholder="Password"
				/>
			</InputWrapper>
			<Button onClick={handleSubmit(onSubmit)} theme={"danger"}>
				Request Account Deletion
			</Button>
			<Modal size="small" isOpen={isModalOpen} onClose={() => setIsOpenModal(false)}>
				<DeleteAccountReq modalOpen={isModalOpen} setModalOpen={() => setIsOpenModal(false)} />
			</Modal>
			<Modal
				size="small"
				isOpen={isDeleteAccountModalOpen}
				onClose={() => setIsDeleteAccountModalOpen(false)}>
				<DeleteAccountConfirm
					setIsOpenModal={setIsOpenModal}
					setIsDeleteAccount={setIsDeleteAccount}
					password={password}
					modalOpen={isDeleteAccountModalOpen}
					setModalOpen={() => setIsDeleteAccountModalOpen(false)}
				/>
			</Modal>
		</DeleteAccount>
	);
};

const DeleteAccount = styled.div`
	margin-bottom: 48px;
`;

export const Text = styled.div`
	color: #282828;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 24px;
`;
