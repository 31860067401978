import { createSlice } from "@reduxjs/toolkit";
import { IPhotographerSkills } from "../../../models/IProfile";
import {
	getAnotherPhotographerSkills,
	getPhotographerSkills,
	getSkillsPhotographer,
} from "./action";

interface ProfileState {
	photographerSkills: IPhotographerSkills;
	userSkills: IPhotographerSkills;
}

const initialState: ProfileState = {
	photographerSkills: {
		skillLevel: "",
		assets: [],
		additionalServices: [],
		additionalSkills: [],
	},
	userSkills: {
		skillLevel: "",
		assets: [],
		additionalServices: [],
		additionalSkills: [],
	},
};

export const photographerSkills = createSlice({
	name: "user/notifications",
	initialState,
	reducers: {
		photographerSkillsUpdating: (state, action) => {
			state.photographerSkills = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getPhotographerSkills.fulfilled, (state, action) => {
				state.photographerSkills = action.payload;
			})
			.addCase(getAnotherPhotographerSkills.fulfilled, (state, action) => {
				state.userSkills = action.payload;
			})
			.addCase(getSkillsPhotographer.fulfilled, (state, action) => {
				state.userSkills = action.payload;
			});
	},
});

export const { reducer: photographerSkillsReducer, actions: photographerSkillsActions } =
	photographerSkills;

export const { photographerSkillsUpdating } = photographerSkillsActions;
