import { createSlice } from "@reduxjs/toolkit";
import { INewCartGroup } from "../../../models/ICart";
import { cartProcess, getCart } from "./action";

interface GalleryState {
	cart: INewCartGroup[];
	checkoutItem: INewCartGroup | null;
	isLoading: boolean;
	paymentInfo: any;
}

const initialState: GalleryState = {
	cart: [],
	checkoutItem: null,
	isLoading: false,
	paymentInfo: {},
};

export const artbuyerCart = createSlice({
	name: "user/artbuyerCart",
	initialState,
	reducers: {
		onAddToCheckout: (state, action) => {
			state.checkoutItem = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getCart.fulfilled, (state, action) => {
				state.cart = action.payload.basketGroups;
				state.isLoading = false;
			})
			.addCase(cartProcess.fulfilled, (state, action) => {
				state.paymentInfo = action.payload.basketGroups;
			})
			.addCase(getCart.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getCart.rejected, (state) => {
				state.isLoading = false;
			});
	},
});

export const { reducer: artbuyerCartReducer, actions: artbuyerCartActions } = artbuyerCart;

export const { onAddToCheckout } = artbuyerCartActions;
