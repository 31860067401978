import { useEffect } from "react";
import styled from "styled-components";
import { ContainerItem } from "./ContainerItem";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { getCart } from "../../store/reducers/cart/action";
import { IsLoading } from "../UI/IsLoading";

export const Cart = () => {
	const { cart, isLoading } = useAppSelector((state) => state.cart);
	const dispatch = useAppDispatch();

	let cartCountNotifications = 0;

	for (let i = 0; i < cart.length; i++) {
		cartCountNotifications += cart[i].basketListModels.length;
	}

	useEffect(() => {
		localStorage.setItem("cartCount", String(cartCountNotifications));
		dispatch(getCart("PENDING"));
	}, [cartCountNotifications]);

	return (
		<Wrapper>
			{isLoading ? (
				<IsLoading />
			) : (
				<>
					<Title>Summary</Title>
					<div>
						{cart.map((el, i) => (
							<ContainerItem key={i} cartItemsArr={el} />
						))}
					</div>
				</>
			)}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1136px;
	margin: 0 auto;
`;
const Title = styled.h2`
	margin-bottom: 40px;
	font-weight: 700;
	font-size: 40px;
	line-height: 48px;
`;
