import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { infoSvg } from "../../static/images";
import { Tooltip } from "./Tooltip";

interface IOption {
	value: string;
	text: string;
	toolTip?: ReactNode;
}

interface Props {
	options: IOption[];
	name: string;
	value?: string | number;
	setValue: React.Dispatch<React.SetStateAction<string>>;
	disabled?: boolean;
	toolTip?: boolean;
	displayFlex?: boolean;
}

export const RadioBtns = ({
	options,
	toolTip = true,
	name,
	value,
	setValue,
	disabled = false,
	displayFlex = false,
}: Props) => {
	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
	};

	return (
		<Wrapper displayFlex={displayFlex}>
			{options.map((el: IOption, i) => {
				return (
					<WrapperItem displayFlex={displayFlex} key={i}>
						<Label>
							<Input
								disabled={disabled}
								value={el.value}
								checked={el.value === value}
								onChange={onChange}
								type="radio"
								name={name}
							/>
							<CustomRadio className="custom-radio"></CustomRadio>
							{el.value}
							{el.toolTip && (
								//@ts-ignore
								<Tooltip html={el.toolTip}>
									<SvgsWrapper>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<g clipPath="url(#clip0_889_10573)">
												<path
													d="M12 13V15"
													stroke="#999999"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M12 10C12.5523 10 13 9.55228 13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9C11 9.55228 11.4477 10 12 10Z"
													fill="#999999"
												/>
												<path
													d="M12 19.25C16.0041 19.25 19.25 16.0041 19.25 12C19.25 7.99594 16.0041 4.75 12 4.75C7.99594 4.75 4.75 7.99594 4.75 12C4.75 16.0041 7.99594 19.25 12 19.25Z"
													stroke="#999999"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</g>
											<defs>
												<clipPath id="clip0_889_10573">
													<rect width="24" height="24" fill="white" />
												</clipPath>
											</defs>
										</svg>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<g clipPath="url(#clip0_889_10573)">
												<path
													d="M12 13V15"
													stroke="#505050"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M12 10C12.5523 10 13 9.55228 13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9C11 9.55228 11.4477 10 12 10Z"
													fill="#505050"
												/>
												<path
													d="M12 19.25C16.0041 19.25 19.25 16.0041 19.25 12C19.25 7.99594 16.0041 4.75 12 4.75C7.99594 4.75 4.75 7.99594 4.75 12C4.75 16.0041 7.99594 19.25 12 19.25Z"
													stroke="#505050"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</g>
											<defs>
												<clipPath id="clip0_889_10573">
													<rect width="24" height="24" fill="white" />
												</clipPath>
											</defs>
										</svg>
									</SvgsWrapper>
								</Tooltip>
							)}
						</Label>
						<WrapperItemText>{el.text}</WrapperItemText>
					</WrapperItem>
				);
			})}
		</Wrapper>
	);
};

const Wrapper = styled.div<any>`
	${({ displayFlex }) => {
		if (displayFlex) {
			return css`
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
			`;
		}
	}}
`;
const WrapperItem = styled.div<any>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	&:not(:last-child) {
		margin-bottom: 8px;
	}
	${({ displayFlex }) => {
		if (displayFlex) {
			return css`
				&:not(:last-child) {
					margin-bottom: 0px;
				}
			`;
		}
	}}
`;
const WrapperItemText = styled.div`
	font-weight: 600;
`;
const Input = styled.input<any>`
	width: 0;
	height: 0;
	opacity: 0;
	/* transform: translateY(2000px); */
	&:checked + .custom-radio {
		background: #8562ce;
		&::before {
			display: block;
		}
		${(props) => {
			if (props.disabled) {
				return css`
					background: #c3b3e6;
				`;
			}
		}}
	}
`;
const Label = styled.label`
	display: flex;
	align-items: center;
	color: #000;

	cursor: pointer;
	& > svg {
	}
`;
const SvgsWrapper = styled.div`
	height: 24px;
	& > svg:nth-child(2) {
		display: none;
	}
	&:hover {
		& > svg:nth-child(2) {
			display: block;
		}
		& > svg:nth-child(1) {
			display: none;
		}
	}
`;
const CustomRadio = styled.span`
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background: #ebebeb;
	margin-right: 10px;
	position: relative;
	&::before {
		display: none;
		content: "";
		position: absolute;
		width: 10px;
		height: 10px;
		top: 7px;
		left: 7px;
		border-radius: 50%;
		background: #fff;
	}
`;
