import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/axiosInstance";

export const deletePhotographer = createAsyncThunk<any, any, { rejectValue?: unknown }>(
	"user/deletePhotographer",
	async (data, thunkAPI) => {
		try {
			const response = await api({
				method: "DELETE",
				url: "/photographer/user/deleteMe",
				data: {
					data,
				},
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
