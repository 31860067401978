import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { IImageRequest } from "../../models/IImageRequest";
import { Button, Input, Modal } from "../UI";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useNavigate } from "react-router-dom";
import {
	counterOfferRequest,
	getImageRequestInfo,
	rejectRequest,
	revealRequest,
} from "../../store/reducers/photographerImageRequest/action";
import { onPaymentRequest } from "../../store/reducers/photographerImageRequest";
import {
	getArtbuyerColor,
	getBuyerStatus,
	getPhotographerColor,
	getPhotographerStatus,
} from "../../utils/getSmth";
import { getStripeStatus } from "../../store/reducers/stripe/action";

interface Props {
	element: IImageRequest;
	onClose?: () => void;
	getRequests: () => Promise<void>;
	getAllRequests: () => Promise<void>;
}

export const PriceRequestOffer = ({ element, onClose, getRequests, getAllRequests }: Props) => {
	const [requestInfo, setRequestInfo] = useState<any>({});
	const [isCounterOffer, setIsCounterOffer] = useState(false);
	const [price, setPrice] = useState("");
	const [message, setMessage] = useState("");

	const [isStripeConnected, setIsStripeConntected] = useState(true);
	const [isFillBillinForm, setIsFullBillingForm] = useState(true);

	const [isAvailableToUpload, setIsAvailableToUpload] = useState(false);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const onCounterOfferClick = () => {
		setIsCounterOffer(true);
	};

	const onReloadRequests = async () => {
		getRequests();
		getAllRequests();
	};

	const navigate = useNavigate();

	const onModalClose = () => {
		document.body.style.overflow = "auto";
		onClose?.();
	};
	const dispatch = useAppDispatch();

	useEffect(() => {
		const getInfo = async () => {
			const { payload } = await dispatch(getImageRequestInfo(element.id));
			setRequestInfo(payload);
		};
		getInfo();
	}, []);

	const photograpgerMarketPlace = useAppSelector((state) => state.profile.photographerMarketPlace);

	useEffect(() => {
		const getStatus = async () => {
			const { payload } = await dispatch(getStripeStatus());
			setIsStripeConntected(payload.connected);
		};

		getStatus();
		setIsFullBillingForm(
			!!photograpgerMarketPlace.billingAddress &&
				!!photograpgerMarketPlace.billingCity &&
				!!photograpgerMarketPlace.billingCompany &&
				!!photograpgerMarketPlace.billingFirstName &&
				!!photograpgerMarketPlace.billingLastName &&
				!!photograpgerMarketPlace.billingZip &&
				!!photograpgerMarketPlace.countryId
		);
	}, []);

	const onSettingsClick = () => {
		navigate("/creator/profile/settings");
		onClose?.();
		setIsModalOpen(false);
		document.body.style.overflow = "auto";
	};

	useEffect(() => {
		setIsAvailableToUpload(!isFillBillinForm || !isStripeConnected);
	}, [isFillBillinForm, isStripeConnected]);

	const onRejectRequest = () => {
		onModalClose();
	};
	const onSendOffer = async () => {
		const json = {
			requestId: element.id,
			usage: element.usage,
			territory: element.territory,
			duration: element.duration,
			price: price,
			currency: "currency",
			message: message,
		};
		if (price) {
			await dispatch(counterOfferRequest(JSON.stringify(json)));
			onReloadRequests();
			onModalClose();
		}
	};
	const onAcceptOffer = async () => {
		const json = {
			requestId: element.id,
		};
		await dispatch(revealRequest(JSON.stringify(json)));
		onReloadRequests();
		onModalClose();
	};
	const onCancelRequest = async () => {
		await dispatch(rejectRequest(element.id));
		onReloadRequests();
		onModalClose();
	};
	const onSendPaymentRequest = () => {
		if (isAvailableToUpload) {
			setIsModalOpen(true);
		} else {
			dispatch(onPaymentRequest(element));
			document.body.style.overflow = "auto";
			navigate("/creator/profile/sendPayment");
		}
	};

	return (
		<Wrapper>
			<Title>Price Request</Title>
			<WrapperInner>
				{(element.status === "pending" && (
					<WrapperContent>
						<WrapperContentItem>
							<ImageAbout>
								<ImageAboutItem>
									<h4>Usage</h4>
									<p>{element.usage}</p>
								</ImageAboutItem>
								<ImageAboutItem>
									<h4>Territory</h4>
									<p>{element.territory}</p>
								</ImageAboutItem>
								<ImageAboutItem>
									<h4>Price</h4>
									<p>
										{element.currency === "USD" ? "$" : "€"} {element.initialPrice}.00
									</p>
								</ImageAboutItem>
								<ImageAboutItem>
									<h4>Duration</h4>
									<p>{element.duration}</p>
								</ImageAboutItem>
							</ImageAbout>

							{isCounterOffer ? (
								<CounterOffer>
									<Subtitle style={{ marginBottom: "4px" }}>Your counter offer</Subtitle>
									<CounterOfferText>
										Please enter your price below to send a counter offer to the buyer.
									</CounterOfferText>
									<CounterOfferForm
										onSubmit={(e) => {
											onSendOffer();
											e.preventDefault();
										}}>
										<PriceInput currency={element.currency === "USD"}>
											<Input
												withCurrency
												value={price}
												onChange={(e) => setPrice(e.target.value)}
												type="number"
												padding="12px 34px 12px 28px"
												maxWidth={"208px"}
											/>
										</PriceInput>
										<Input
											value={message}
											onChange={(e) => setMessage(e.target.value)}
											placeholder="Message"
										/>
										<PhotographerOfferButtons>
											<Button type="submit" margin="0px 8px 0px 0px">
												Send Offer
											</Button>
											<Button onClick={onRejectRequest} margin="0px 8px 0px 0px" theme="noline">
												Reject Request
											</Button>
										</PhotographerOfferButtons>
									</CounterOfferForm>
								</CounterOffer>
							) : (
								<PhotographerOfferButtons>
									<Button onClick={onAcceptOffer} margin="0px 8px 0px 0px">
										Accept Offer
									</Button>
									<Button onClick={onCounterOfferClick} theme="outline">
										Counter Offer
									</Button>
									<Button onClick={onCancelRequest} margin="0px 8px 0px 0px" theme="noline">
										Cancel Request
									</Button>
								</PhotographerOfferButtons>
							)}
						</WrapperContentItem>
					</WrapperContent>
				)) ||
					((element.status === "COUNTER_OFFER_PHOTOGRAPHER" ||
						element.status === "Accepted" ||
						element.status === "Cancelled" ||
						"Rejected") && (
						<WrapperContent>
							<WrapperContentItem>
								<ImageAbout>
									<ImageAboutItem>
										<h4>Usage</h4>
										<p>{element.usage}</p>
									</ImageAboutItem>
									<ImageAboutItem>
										<h4>Territory</h4>
										<p>{element.territory}</p>
									</ImageAboutItem>
									<ImageAboutItem>
										<h4>Price</h4>
										<p>
											{element.currency === "USD" ? "$" : "€"} {element.price}.00
										</p>
									</ImageAboutItem>
									<ImageAboutItem>
										<h4>Duration</h4>
										<p>{element.duration}</p>
									</ImageAboutItem>
								</ImageAbout>
							</WrapperContentItem>
							{(element.status === "CONFIRMED" || element.status === "COUNTER_OFFER_ACCEPTED") && (
								<AcceptedOffer>
									<h3>You have accepted buyer’s offer</h3>
									<Instructions>
										<h4>Contact buyer and finalize the sale directly</h4>
										<p>0% comission</p>
										<ul>
											<li>You write the invoice</li>
											<li>You determine payment terms</li>
										</ul>
									</Instructions>
									<ImageAbout>
										<ImageAboutItem>
											<h4>Name</h4>
											<p>{requestInfo?.name}</p>
										</ImageAboutItem>
										<ImageAboutItem>
											<h4>Phone</h4>
											<p>{requestInfo?.phone}</p>
										</ImageAboutItem>
										<ImageAboutItem>
											<h4>E-mail</h4>
											<p>{requestInfo?.email}</p>
										</ImageAboutItem>
									</ImageAbout>
									<PaymentOnline>
										<Instructions>
											<h4>Use VSBLE.Space online checkout & payment</h4>
											<p>15% comission</p>
											<ul>
												<li>Imidiate payment</li>
												<li>Automatic invoicing to client</li>
												<li>Your name doesn’t appear on clients invoice</li>
												<li>
													You will receive a Free invoice with clients details for you records
												</li>
											</ul>
										</Instructions>
										{(requestInfo !== undefined &&
											requestInfo.paymentRequest?.status === "AWAIT" && (
												<Button disabled theme="danger">
													Awaiting Payment
												</Button>
											)) ||
											(requestInfo.paymentRequest?.status === "PAID" && (
												<Button disabled theme="danger" onClick={onSendPaymentRequest}>
													Paid
												</Button>
											)) || <Button onClick={onSendPaymentRequest}>Send Payment Request</Button>}
									</PaymentOnline>
								</AcceptedOffer>
							)}
							<PhotographerOffer>
								<div
									style={{
										display: "flex",
										alignItems: "flex-start",
										justifyContent: "space-between",
									}}>
									<Subtitle>Buyer’s Offer</Subtitle>
									<ItemStatus
										style={{
											color:
												element.status === "COUNTER_OFFER_PHOTOGRAPHER"
													? "##EE4747"
													: getPhotographerColor(element.status),
										}}>
										{getBuyerStatus(element.status)}
									</ItemStatus>
								</div>
								<ImageAbout>
									<ImageAboutItem>
										<h4>Price</h4>
										<p>
											{element.currency === "USD" ? "$" : "€"}{" "}
											{element.priceBuyer ? element.priceBuyer : element.price}.00
										</p>
									</ImageAboutItem>
									<ImageAboutItem>
										<h4>Territory</h4>
										<p>{element.territory}</p>
									</ImageAboutItem>
									<ImageAboutItem>
										<h4>Model Release</h4>
										<p>{requestInfo?.releaseAvailable === "YES" ? "Possible" : "Not possible"}</p>
									</ImageAboutItem>
									<ImageAboutItem>
										<h4>Duration</h4>
										<p>{element.duration}</p>
									</ImageAboutItem>
									{requestInfo.messageBuyer && (
										<ImageAboutItem>
											<h4>Message</h4>
											<p>{requestInfo.messageBuyer}</p>
										</ImageAboutItem>
									)}
								</ImageAbout>
								{element.status === "COUNTER_OFFER_BUYER" &&
									(isCounterOffer ? (
										<CounterOffer>
											<Subtitle style={{ marginBottom: "4px" }}>Your counter offer</Subtitle>
											<CounterOfferText>
												Please enter your price below to send a counter offer to the buyer.
											</CounterOfferText>
											<CounterOfferForm
												onSubmit={(e) => {
													onSendOffer();
													e.preventDefault();
												}}>
												<PriceInput currency={element.currency === "USD"}>
													<Input
														value={price}
														withCurrency
														onChange={(e) => setPrice(e.target.value)}
														type="number"
														padding="12px 34px 12px 28px"
														maxWidth={"208px"}
													/>
												</PriceInput>
												<Input
													value={message}
													onChange={(e) => setMessage(e.target.value)}
													placeholder="Message"
												/>
												<PhotographerOfferButtons>
													<Button type="submit" margin="0px 8px 0px 0px">
														Send Offer
													</Button>
													<Button onClick={onRejectRequest} margin="0px 8px 0px 0px" theme="noline">
														Reject Request
													</Button>
												</PhotographerOfferButtons>
											</CounterOfferForm>
										</CounterOffer>
									) : (
										<PhotographerOfferButtons>
											<Button onClick={onAcceptOffer} margin="0px 8px 0px 0px">
												Accept Offer
											</Button>
											<Button onClick={onCounterOfferClick} theme="outline">
												Counter Offer
											</Button>
											<Button onClick={onCancelRequest} margin="0px 8px 0px 0px" theme="noline">
												Cancel Request
											</Button>
										</PhotographerOfferButtons>
									))}
							</PhotographerOffer>
							{element.status !== "CONFIRMED" &&
								element.status !== "REJECTED" &&
								element.status !== "COUNTER_OFFER_ACCEPTED" && (
									<YourOffer>
										<div
											style={{
												display: "flex",
												alignItems: "flex-start",
												justifyContent: "space-between",
											}}>
											<Subtitle>Your Counter Offer</Subtitle>
											<ItemStatus style={{ color: getArtbuyerColor(element.status) }}>
												{getPhotographerStatus(element.status)}
											</ItemStatus>
										</div>
										<ImageAbout>
											<ImageAboutItem>
												<h4>Price</h4>
												<p>
													{element.currency === "USD" ? "$" : "€"}{" "}
													{element.pricePhotographer || element.price}.00
												</p>
											</ImageAboutItem>
											<ImageAboutItem>
												<h4>Territory</h4>
												<p>{element.territory}</p>
											</ImageAboutItem>
											<ImageAboutItem>
												<h4>Model Release</h4>
												<p>
													{requestInfo?.releaseAvailable === "YES" ? "Possible" : "Not possible"}
												</p>
											</ImageAboutItem>
											<ImageAboutItem>
												<h4>Duration</h4>
												<p>{element.duration}</p>
											</ImageAboutItem>
											{requestInfo.messagePhotographer && (
												<ImageAboutItem>
													<h4>Message</h4>
													<p>{requestInfo.messagePhotographer}</p>
												</ImageAboutItem>
											)}
										</ImageAbout>
									</YourOffer>
								)}
						</WrapperContent>
					)) ||
					(element.status === "CONFIRMED" && <WrapperContent></WrapperContent>)}

				<ImageInner>
					<img
						src={`${process.env.REACT_APP_DOMEN}${element.photo.imageDetails.LIGHTBOX.url}`}
						alt="reqImg"
					/>
				</ImageInner>
			</WrapperInner>
			<Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
				<div>
					<StripeError>
						<h3>
							In order to sell photos online, you need first to go to account settings and complete
							these steps:
						</h3>
						<p>1. Fill out your payout/billing information in the marketplace section.</p>
						<p>2. Connect stripe to get paid out instantly.</p>
						<div onClick={onSettingsClick}>Account settings</div>
					</StripeError>
				</div>
			</Modal>
		</Wrapper>
	);
};

const CounterOffer = styled.div`
	margin-top: 24px;
`;
const Subtitle = styled.div`
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 4px;
`;
const CounterOfferText = styled.div`
	font-size: 12px;
	line-height: 16px;
	margin-bottom: 16px;
`;
const CounterOfferForm = styled.form``;
const PriceInput = styled.div<any>`
	margin-bottom: 8px;
	width: 208px;
	position: relative;
	&::after {
		position: absolute;
		${({ currency }) => {
			if (currency) {
				return css`
					content: "$";
				`;
			} else {
				return css`
					content: "€";
				`;
			}
		}}
		top: 14px;
		left: 16px;
		font-size: 14px;
		line-height: 20px;
		font-weight: 600;
	}
`;
const Wrapper = styled.div`
	padding: 40px;
	background: #f7f7f7;
	border-radius: 24px;
	width: 840px;
	max-height: 93vh;
	overflow-y: auto;
`;
const Title = styled.div`
	margin-bottom: 16px;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
`;
const WrapperContent = styled.div`
	width: 540px;
`;
const WrapperInner = styled.div`
	display: flex;
	justify-content: space-between;
`;
const ImageInner = styled.div`
	width: 180px;
	& > img {
		width: 180px;
	}
`;
const WrapperContentItem = styled.div`
	background: #fff;
	padding: 24px;
	border-radius: 8px;
	margin-bottom: 4px;
`;
const ImageAbout = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 16px;
`;
const ImageAboutItem = styled.div`
	& > h4 {
		font-weight: 600;
		font-size: 12px;
		line-height: 18px;
		margin-bottom: 4px;
	}
`;
const PhotographerOfferButtons = styled.div`
	display: flex;
	margin-top: 24px;
`;
const PhotographerOffer = styled.div`
	background: #fff;
	padding: 24px;
	border-radius: 8px;
`;
const ItemStatus = styled.div`
	color: #ee4747;
`;
const YourOffer = styled.div`
	background: #fff;
	padding: 24px;
	border-radius: 8px;
	margin-top: 4px;
`;
const AcceptedOffer = styled.div`
	padding: 24px;
	border-radius: 8px;
	background: #fff;
	border: 1px solid #62c78f;
	margin-bottom: 4px;
	& > h3 {
		font-weight: 700;
		font-size: 18px;
		line-height: 24px;
		color: #62c78f;
		margin-bottom: 24px;
	}
`;
const Instructions = styled.div`
	margin-bottom: 24px;
	& > h4 {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 4px;
	}
	& > p {
		font-weight: 600;
		color: #808080;
		margin-bottom: 16px;
	}
	& > ul {
		& > li {
			position: relative;
			padding-left: 20px;
			&::after {
				content: "";
				position: absolute;
				width: 4px;
				height: 4px;
				background: #000;
				left: 7px;
				top: 8px;
			}
		}
	}
`;
const PaymentOnline = styled.div`
	padding-top: 24px;
	border-top: 1px solid #ebebeb;
	margin-top: 24px;
`;
const StripeError = styled.div`
	& > h3 {
		font-size: 25px;
		line-height: 34px;
		color: #b40a0a;
		max-width: 800px;
		margin-bottom: 10px;
	}
	& > p {
		font-size: 18px;
		margin-bottom: 10px;
	}
	& > div {
		text-decoration: underline;
		font-size: 40px;
		line-height: 45px;
		margin-top: 10px;
		color: rgb(133, 98, 206);
		cursor: pointer;
		transition: all.2s;
		&:hover {
			color: rgb(135 116 174);
			transition: all.2s;
		}
	}
`;
