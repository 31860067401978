export const countries = [
    {label: "Afghanistan",value: 1},
    {label: "Åland Islands",value: 2},
    {label: "Albania",value: 3},
    {label: "Algeria",value: 4},
    {label: "American Samoa",value: 5},
    {label: "Andorra",value: 6},
    {label: "Angola",value: 7},
    {label: "Anguilla",value: 8},
    {label: "Antarctica",value: 9},
    {label: "Antigua & Barbuda",value: 10},
    {label: "Argentina",value: 11},
    {label: "Armenia",value: 12},
    {label: "Aruba",value: 13},
    {label: "Australia",value: 14},
    {label: "Austria",value: 15},
    {label: "Azerbaijan",value: 16},
    {label: "Bahamas",value: 17},
    {label: "Bahrain",value: 18},
    {label: "Bangladesh",value: 19},
    {label: "Barbados",value: 20},
    {label: "Belarus",value: 21},
    {label: "Belgium",value: 22},
    {label: "Belize",value: 23},
    {label: "Benin",value: 24},
    {label: "Bermuda",value: 25},
    {label: "Bhutan",value: 26},
    {label: "Bolivia",value: 27},
    {label: "Caribbean Netherlands",value: 28},
    {label: "Bosnia & Herzegovina",value: 29},
    {label: "Botswana",value: 30},
    {label: "Bouvet Island",value: 31},
    {label: "Brazil",value: 32},
    {label: "British Indian Ocean Territory",value: 33},
    {label: "Brunei",value: 34},
    {label: "Bulgaria",value: 35},
    {label: "Burkina Faso",value: 36},
    {label: "Burundi",value: 37},
    {label: "Cambodia",value: 38},
    {label: "Cameroon",value: 39},
    {label: "Canada",value: 40},
    {label: "Cape Verde",value: 41},
    {label: "Cayman Islands",value: 42},
    {label: "Central African Republic",value: 43},
    {label: "Chad",value: 44},
    {label: "Chile",value: 45},
    {label: "China",value: 46},
    {label: "Christmas Island",value: 47},
    {label: "Cocos (Keeling) Islands",value: 48},
    {label: "Colombia",value: 49},
    {label: "Comoros",value: 50},
    {label: "Congo - Brazzaville",value: 51},
    {label: "Congo - Kinshasa",value: 52},
    {label: "Cook Islands",value: 53},
    {label: "Costa Rica",value: 54},
    {label: "Côte d’Ivoire",value: 55},
    {label: "Croatia",value: 56},
    {label: "Cuba",value: 57},
    {label: "Curaçao",value: 58},
    {label: "Cyprus",value: 59},
    {label: "Czechia",value: 60},
    {label: "Denmark",value: 61},
    {label: "Djibouti",value: 62},
    {label: "Dominica",value: 63},
    {label: "Dominican Republic",value: 64},
    {label: "Ecuador",value: 65},
    {label: "Egypt",value: 66},
    {label: "El Salvador",value: 67},
    {label: "Equatorial Guinea",value: 68},
    {label: "Eritrea",value: 69},
    {label: "Estonia",value: 70},
    {label: "Ethiopia",value: 71},
    {label: "Falkland Islands (Islas Malvinas)",value: 72},
    {label: "Faroe Islands",value: 73},
    {label: "Fiji",value: 74},
    {label: "Finland",value: 75},
    {label: "France",value: 76},
    {label: "French Guiana",value: 77},
    {label: "French Polynesia",value: 78},
    {label: "French Southern Territories",value: 79},
    {label: "Gabon",value: 80},
    {label: "Gambia",value: 81},
    {label: "Georgia",value: 82},
    {label: "Germany",value: 83},
    {label: "Ghana",value: 84},
    {label: "Gibraltar",value: 85},
    {label: "Greece",value: 86},
    {label: "Greenland",value: 87},
    {label: "Grenada",value: 88},
    {label: "Guadeloupe",value: 89},
    {label: "Guam",value: 90},
    {label: "Guatemala",value: 91},
    {label: "Guernsey",value: 92},
    {label: "Guinea",value: 93},
    {label: "Guinea-Bissau",value: 94},
    {label: "Guyana",value: 95},
    {label: "Haiti",value: 96},
    {label: "Heard & McDonald Islands",value: 97},
    {label: "Vatican City",value: 98},
    {label: "Honduras",value: 99},
    {label: "Hong Kong",value: 100},
    {label: "Hungary",value: 101},
    {label: "Iceland",value: 102},
    {label: "India",value: 103},
    {label: "Indonesia",value: 104},
    {label: "Iran",value: 105},
    {label: "Iraq",value: 106},
    {label: "Ireland",value: 107},
    {label: "Isle of Man",value: 108},
    {label: "Israel",value: 109},
    {label: "Italy",value: 110},
    {label: "Jamaica",value: 111},
    {label: "Japan",value: 112},
    {label: "Jersey",value: 113},
    {label: "Jordan",value: 114},
    {label: "Kazakhstan",value: 115},
    {label: "Kenya",value: 116},
    {label: "Kiribati",value: 117},
    {label: "North Korea",value: 118},
    {label: "South Korea",value: 119},
    {label: "Kosovo",value: 120},
    {label: "Kuwait",value: 121},
    {label: "Kyrgyzstan",value: 122},
    {label: "Laos",value: 123},
    {label: "Latvia",value: 124},
    {label: "Lebanon",value: 125},
    {label: "Lesotho",value: 126},
    {label: "Liberia",value: 127},
    {label: "Libya",value: 128},
    {label: "Liechtenstein",value: 129},
    {label: "Lithuania",value: 130},
    {label: "Luxembourg",value: 131},
    {label: "Macao",value: 132},
    {label: "North Macedonia",value: 133},
    {label: "Madagascar",value: 134},
    {label: "Malawi",value: 135},
    {label: "Malaysia",value: 136},
    {label: "Maldives",value: 137},
    {label: "Mali",value: 138},
    {label: "Malta",value: 139},
    {label: "Marshall Islands",value: 140},
    {label: "Martinique",value: 141},
    {label: "Mauritania",value: 142},
    {label: "Mauritius",value: 143},
    {label: "Mayotte",value: 144},
    {label: "Mexico",value: 145},
    {label: "Micronesia",value: 146},
    {label: "Moldova",value: 147},
    {label: "Monaco",value: 148},
    {label: "Mongolia",value: 149},
    {label: "Montenegro",value: 150},
    {label: "Montserrat",value: 151},
    {label: "Morocco",value: 152},
    {label: "Mozambique",value: 153},
    {label: "Myanmar (Burma)",value: 154},
    {label: "Namibia",value: 155},
    {label: "Nauru",value: 156},
    {label: "Nepal",value: 157},
    {label: "Netherlands",value: 158},
    {label: "Curaçao",value: 159},
    {label: "New Caledonia",value: 160},
    {label: "New Zealand",value: 161},
    {label: "Nicaragua",value: 162},
    {label: "Niger",value: 163},
    {label: "Nigeria",value: 164},
    {label: "Niue",value: 165},
    {label: "Norfolk Island",value: 166},
    {label: "Northern Mariana Islands",value: 167},
    {label: "Norway",value: 168},
    {label: "Oman",value: 169},
    {label: "Pakistan",value: 170},
    {label: "Palau",value: 171},
    {label: "Palestine",value: 172},
    {label: "Panama",value: 173},
    {label: "Papua New Guinea",value: 174},
    {label: "Paraguay",value: 175},
    {label: "Peru",value: 176},
    {label: "Philippines",value: 177},
    {label: "Pitcairn Islands",value: 178},
    {label: "Poland",value: 179},
    {label: "Portugal",value: 180},
    {label: "Puerto Rico",value: 181},
    {label: "Qatar",value: 182},
    {label: "Réunion",value: 183},
    {label: "Romania",value: 184},
    {label: "Russia",value: 185},
    {label: "Rwanda",value: 186},
    {label: "St. Barthélemy",value: 187},
    {label: "St. Helena",value: 188},
    {label: "St. Kitts & Nevis",value: 189},
    {label: "St. Lucia",value: 190},
    {label: "St. Martin",value: 191},
    {label: "St. Pierre & Miquelon",value: 192},
    {label: "St. Vincent & Grenadines",value: 193},
    {label: "Samoa",value: 194},
    {label: "San Marino",value: 195},
    {label: "São Tomé & Príncipe",value: 196},
    {label: "Saudi Arabia",value: 197},
    {label: "Senegal",value: 198},
    {label: "Serbia",value: 199},
    {label: "Serbia",value: 200},
    {label: "Seychelles",value: 201},
    {label: "Sierra Leone",value: 202},
    {label: "Singapore",value: 203},
    {label: "Sint Maarten",value: 204},
    {label: "Slovakia",value: 205},
    {label: "Slovenia",value: 206},
    {label: "Solomon Islands",value: 207},
    {label: "Somalia",value: 208},
    {label: "South Africa",value: 209},
    {label: "South Georgia & South Sandwich Islands",value: 210},
    {label: "South Sudan",value: 211},
    {label: "Spain",value: 212},
    {label: "Sri Lanka",value: 213},
    {label: "Sudan",value: 214},
    {label: "Suriname",value: 215},
    {label: "Svalbard & Jan Mayen",value: 216},
    {label: "Eswatini",value: 217},
    {label: "Sweden",value: 218},
    {label: "Switzerland",value: 219},
    {label: "Syria",value: 220},
    {label: "Taiwan",value: 221},
    {label: "Tajikistan",value: 222},
    {label: "Tanzania",value: 223},
    {label: "Thailand",value: 224},
    {label: "Timor-Leste",value: 225},
    {label: "Togo",value: 226},
    {label: "Tokelau",value: 227},
    {label: "Tonga",value: 228},
    {label: "Trinidad & Tobago",value: 229},
    {label: "Tunisia",value: 230},
    {label: "Turkey",value: 231},
    {label: "Turkmenistan",value: 232},
    {label: "Turks & Caicos Islands",value: 233},
    {label: "Tuvalu",value: 234},
    {label: "Uganda",value: 235},
    {label: "Ukraine",value: 236},
    {label: "United Arab Emirates",value: 237},
    {label: "United Kingdom",value: 238},
    {label: "United States",value: 239},
    {label: "U.S. Outlying Islands",value: 240},
    {label: "Uruguay",value: 241},
    {label: "Uzbekistan",value: 242},
    {label: "Vanuatu",value: 243},
    {label: "Venezuela",value: 244},
    {label: "Vietnam",value: 245},
    {label: "British Virgin Islands",value: 246},
    {label: "U.S. Virgin Islands",value: 247},
    {label: "Wallis & Futuna",value: 248},
    {label: "Western Sahara",value: 249},
    {label: "Yemen",value: 250},
    {label: "Zambia",value: 251},
    {label: "Zimbabwe",value: 252}
];