import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { useClickOutside } from "../hooks/useClickOutside";
import { AuthRoles } from "../models/IProfile";

import { arrDown, arrUp, camera, headerLogo, searchSvg, uploadSvg } from "../static/images";
import { logout } from "../store/reducers/authData";

import { ResetPasswordModal } from "./Modals/ForgotPassword";
import { SignIn } from "./Modals/SignInModal";
import { SignUpModalComponent } from "./Modals/SignUpModal";

import { Button, Modal, SearchInput } from "./UI";
import { UploadImages } from "./Modals/UploadImages";
import { EditUploadedImages } from "./Modals/EditUploadedImages";
import { Pricing } from "./Modals/Pricing";
import { getCart } from "../store/reducers/cart/action";
import { clearUploadedData } from "../store/reducers/photographerGallery";

interface Props {
	isSignUp: boolean;
	setIsSignUp: React.Dispatch<React.SetStateAction<boolean>>;
	isLogInOpen: boolean;
	setIsLogInOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isForgorPassword: boolean;
	setIsForgotPassword: React.Dispatch<React.SetStateAction<boolean>>;
	setIsSearchActive: React.Dispatch<React.SetStateAction<boolean>>;
	isSearchActive: boolean;
	searchValue: string;
	setIsArchive?: React.Dispatch<React.SetStateAction<boolean>>;
	setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}

export const Header = ({
	isSignUp,
	setIsSignUp,
	setIsForgotPassword,
	isLogInOpen,
	setIsLogInOpen,
	isForgorPassword,
	setIsSearchActive,
	isSearchActive,
	searchValue,
	setSearchValue,
	setIsArchive,
}: Props) => {
	const navigate = useNavigate();
	const location = useLocation();

	const dispatch = useAppDispatch();

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isUploadImageModal, setIsUploadImagesModal] = useState(false);
	const [isEditImagesInfo, setIsEditImagesInfo] = useState(false);
	const [isPricingOpen, setIsPricingOpen] = useState(false);
	const [uploadedImages, setUploadImages] = useState<{ id: number; img: string }[]>([]);
	const [filesToUpload, setFilesToUpload] = useState<any[]>([]);

	const { isAuth, user } = useAppSelector((state) => state.authData);
	const { firstName, lastName, avatarURL, profession } = useAppSelector(
		(state) => state.profile.photographerAbout
	);
	const artbuyerProfile = useAppSelector((state) => state.profile.artbuyerProfile);

	const clickRef = useRef();

	const onClickOutsideMenu = () => {
		setIsMenuOpen(false);
	};

	useEffect(() => {
		if (user?.role === AuthRoles.Artbuyer && isAuth) {
			dispatch(getCart("PENDING"));
		}
	}, []);

	const { cart } = useAppSelector((state) => state.cart);

	const cartCount = localStorage.getItem("cartCount");
	let cartCountNotifications = 0;

	for (let i = 0; i < cart.length; i++) {
		cartCountNotifications += cart[i].basketListModels.length;
	}

	useClickOutside(clickRef, onClickOutsideMenu);

	const onSearchClick = (e: any) => {
		if (window.pageYOffset < 150) {
			setIsSearchActive((prev) => !prev);
		} else {
			setIsSearchActive(true);
		}
		window.scrollTo({ top: 0, behavior: "smooth" });
		navigate("");
	};

	const onLogin = () => {
		setIsLogInOpen(true);
	};

	const onImageRequestsClick = () => {
		navigate("/creator/profile/imageRequests");
	};
	const onJobRequestsClick = () => {
		navigate("/creator/profile/jobRequests");
	};

	const onSignUp = () => {
		setIsSignUp(true);
	};
	const onCartClick = () => {
		navigate("/creator/profile/cart");
	};
	const onFavoritesClick = () => {
		navigate("/creator/profile/favorites");
	};

	const onUploadImages = () => {
		setIsUploadImagesModal(true);
	};

	const onProfileMenuClick = () => {
		setIsMenuOpen((prev) => !prev);
	};
	const onHeaderClick = () => {
		navigate(isAuth ? "/creator/profile" : "/");
	};

	const onMyNameClick = () => {
		user?.role === AuthRoles.Photographer
			? navigate("/creator/profile/account")
			: navigate("/creator/profile/settings");

		setIsMenuOpen(false);
	};

	const onLogOut = () => {
		dispatch(logout());

		setIsMenuOpen(false);
		navigate("/");
	};

	const onAccountClick = () => {
		navigate("/creator/profile/account");
		setIsMenuOpen(false);
	};
	const onPublicInfoClick = () => {
		navigate("/creator/profile/public");
		setIsMenuOpen(false);
	};
	const onPricingClick = () => {
		setIsPricingOpen(true);
		setIsMenuOpen(false);
	};
	const onSettingsClick = () => {
		navigate("/creator/profile/settings");
		setIsMenuOpen(false);
	};

	const onSubmitUploadedImages = () => {
		setFilesToUpload([]);
	};

	return (
		<MainHeader
			darker={
				location.pathname.includes("/creator/profile/cart") ||
				location.pathname === "/creator/profile/imageRequests" ||
				location.pathname === "/creator/profile/jobRequests" ||
				location.pathname.includes("/creator/profile/sendPayment")
			}>
			<HeaderInner>
				<HeaderLogo onClick={onHeaderClick}>
					<img src={headerLogo} alt="logo" />
					{/* <Version>Version - {process.env.REACT_APP_VERSION}</Version> */}
				</HeaderLogo>

				<HeaderMenu>
					{
						// (location.pathname === "/creator/profile" || location.pathname === "/") &&
						isAuth ? (
							<SearchBtn onClick={(e) => onSearchClick(e)}>
								<SearchBtnSvg src={searchSvg} alt="searchSvg" />
								<span>Search</span>
							</SearchBtn>
						) : isSearchActive ? (
							<SearchInput
								onBlur={() => setIsSearchActive(false)}
								searchValue={searchValue}
								onChange={setSearchValue}
							/>
						) : (
							<SearchBtn onClick={(e) => onSearchClick(e)}>
								<SearchBtnSvg src={searchSvg} alt="searchSvg" />
								<span>Search</span>
							</SearchBtn>
						)
					}

					{isAuth ? (
						<>
							<HeaderNoLineBtn onClick={() => onImageRequestsClick()}>
								<HeaderNoLineBtnImg>
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M4.25879 0C3.03097 0 1.95191 0.813947 1.6146 1.99451L1.6146 1.99452L0.0338086 7.5273C0.0219079 7.56563 0.012998 7.60527 0.00735167 7.64596C0.00160249 7.68663 -0.000721199 7.7272 0.000193101 7.76732V13.25C0.000193101 14.7688 1.23142 16 2.75019 16H13.2502C14.769 16 16.0002 14.7688 16.0002 13.25V7.76736C16.0011 7.72709 15.9988 7.68636 15.993 7.64553C15.9873 7.60501 15.9784 7.56552 15.9666 7.52735L14.3857 1.99453L14.3857 1.99451C14.0484 0.813945 12.9695 0 11.7416 0H4.25879ZM14.2559 7L12.9435 2.40661L12.9434 2.40659C12.7901 1.86995 12.2997 1.5 11.7416 1.5H4.25879C3.7007 1.5 3.21021 1.86997 3.05689 2.4066L3.05688 2.40661L1.74449 7H5.17104C5.91247 7 6.45578 7.46784 6.77594 7.86955L1.52253 7.77686L1.52253 7.77686L6.77596 7.86957C6.78735 7.88386 6.79846 7.89806 6.80928 7.91216C7.08478 8.27098 7.5156 8.5 8.00019 8.5C8.48477 8.5 8.91559 8.271 9.19108 7.91219L6.77596 7.86957C6.77596 7.86956 6.77595 7.86956 6.77594 7.86955L9.19111 7.91216C9.50616 7.50178 10.0615 7 10.8293 7H14.2559ZM1.50019 13.25V8.5H5.17104C5.25084 8.5 5.41837 8.56363 5.61948 8.8256L5.61951 8.82564C6.16661 9.53822 7.02959 10 8.00019 10C8.9708 10 9.83378 9.53822 10.3809 8.82564L10.3809 8.82561C10.5821 8.56359 10.7495 8.5 10.8293 8.5H14.5002V13.25C14.5002 13.9404 13.9406 14.5 13.2502 14.5H2.75019C2.05983 14.5 1.50019 13.9404 1.50019 13.25Z"
											fill="#505050"
										/>
									</svg>
								</HeaderNoLineBtnImg>
								<div>Image Requests</div>
							</HeaderNoLineBtn>
							<HeaderNoLineBtn onClick={onJobRequestsClick}>
								<HeaderNoLineBtnImg>
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M6.75 1.5C6.05964 1.5 5.5 2.05964 5.5 2.75V3H10.5V2.75C10.5 2.05964 9.94038 1.5 9.25 1.5H6.75ZM4 2.75V3H2.75C1.23122 3 0 4.23122 0 5.75V13.25C0 14.7688 1.23122 16 2.75 16H13.25C14.7688 16 16 14.7688 16 13.25V5.75C16 4.23122 14.7688 3 13.25 3H12V2.75C12 1.23122 10.7688 0 9.25 0H6.75C5.23122 0 4 1.23122 4 2.75ZM2.75 4.5C2.05964 4.5 1.5 5.05964 1.5 5.75V8.5H4V7.75C4 7.33579 4.33579 7 4.75 7C5.16421 7 5.5 7.33579 5.5 7.75V8.5H10.5V7.75C10.5 7.33579 10.8358 7 11.25 7C11.6642 7 12 7.33579 12 7.75V8.5H14.5V5.75C14.5 5.05964 13.9404 4.5 13.25 4.5H2.75ZM10.5 10V10.25C10.5 10.6642 10.8358 11 11.25 11C11.6642 11 12 10.6642 12 10.25V10H14.5V13.25C14.5 13.9404 13.9404 14.5 13.25 14.5H2.75C2.05964 14.5 1.5 13.9404 1.5 13.25V10H4V10.25C4 10.6642 4.33579 11 4.75 11C5.16421 11 5.5 10.6642 5.5 10.25V10H10.5Z"
											fill="#505050"
										/>
									</svg>
								</HeaderNoLineBtnImg>
								<div>Job Requests</div>
							</HeaderNoLineBtn>
							{user?.role === AuthRoles.Photographer ? (
								<div style={{ marginRight: "24px" }}>
									<Button theme={"withIcon"} onClick={onUploadImages} icon={uploadSvg}>
										Upload Images
									</Button>
								</div>
							) : (
								<>
									<HeaderNoLineBtn strokeSvg onClick={onFavoritesClick}>
										<HeaderNoLineBtnImg>
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<path
													d="M6.75 6.75C6.75 5.64543 7.64543 4.75 8.75 4.75H15.25C16.3546 4.75 17.25 5.64543 17.25 6.75V19.25L12 14.75L6.75 19.25V6.75Z"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
													stroke="#505050"
												/>
											</svg>
										</HeaderNoLineBtnImg>

										<div>Favorites</div>
									</HeaderNoLineBtn>
									<HeaderNoLineBtn onClick={onCartClick}>
										<HeaderNoLineBtnImg>
											<svg
												width="16"
												height="16"
												viewBox="0 0 16 16"
												fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M0 0.75C0 0.335786 0.335786 0 0.75 0H3C3.34415 0 3.64414 0.234223 3.72761 0.568098L4.33558 3H15.25C15.4791 3 15.6957 3.10473 15.8379 3.28435C15.9802 3.46396 16.0325 3.69875 15.9801 3.92178L14.3429 10.8799L14.3429 10.8799C14.0505 12.122 12.9422 13 11.666 13H7.5395C6.2917 13 5.20032 12.1599 4.88103 10.9537L4.88103 10.9537L3.0254 3.94354L3.02299 3.93425L3.02239 3.9319L3.02223 3.93127L2.41442 1.5H0.75C0.335786 1.5 0 1.16421 0 0.75ZM4.72436 4.5L6.33109 10.5699L6.33109 10.5699C6.47622 11.1181 6.97231 11.5 7.5395 11.5H11.666C12.246 11.5 12.7499 11.101 12.8827 10.5363L14.303 4.5H4.72436ZM6 16C6.55228 16 7 15.5523 7 15C7 14.4477 6.55228 14 6 14C5.44772 14 5 14.4477 5 15C5 15.5523 5.44772 16 6 16ZM14 15C14 15.5523 13.5523 16 13 16C12.4477 16 12 15.5523 12 15C12 14.4477 12.4477 14 13 14C13.5523 14 14 14.4477 14 15Z"
													fill="#505050"
												/>
											</svg>
										</HeaderNoLineBtnImg>
										<div>Cart</div>
										{cartCount && cartCountNotifications > +cartCount && (
											<CountNotification>{cartCountNotifications - +cartCount}</CountNotification>
										)}
									</HeaderNoLineBtn>
								</>
							)}

							<ProfileMenu ref={clickRef}>
								<ProfileMenuInner onClick={onProfileMenuClick}>
									<ProfileMenuImgInner active={!(avatarURL && artbuyerProfile.avatarURL)}>
										<ProfileMenuImg
											noAvatar={avatarURL || artbuyerProfile.avatarURL}
											src={
												avatarURL || artbuyerProfile.avatarURL
													? user?.role === AuthRoles.Photographer
														? `${process.env.REACT_APP_DOMEN}${avatarURL}`
														: `${process.env.REACT_APP_DOMEN}${artbuyerProfile.avatarURL}`
													: camera
											}
											alt="aboutMe"
										/>
									</ProfileMenuImgInner>
									<ProfileMenuArr src={isMenuOpen ? arrUp : arrDown} alt="menuArrow" />
								</ProfileMenuInner>
								{isMenuOpen && (
									<ProfileMenuOpen>
										<div style={{ cursor: "pointer" }} onClick={onMyNameClick}>
											{user?.role === AuthRoles.Photographer ? (
												<ProfileMenuOpenName>
													{firstName} {lastName}
												</ProfileMenuOpenName>
											) : (
												<ProfileMenuOpenName>
													{artbuyerProfile.firstName} {artbuyerProfile.lastName}
												</ProfileMenuOpenName>
											)}
											<ProfileMenuOpenPosition>
												{user?.role === AuthRoles.Artbuyer ? user?.role : profession}
											</ProfileMenuOpenPosition>
										</div>

										<ProfileMenuOpenContent>
											<ProfileMenuOpenList>
												{user?.role === AuthRoles.Artbuyer || (
													<li onClick={onAccountClick}>Profile</li>
												)}
												{user?.role === AuthRoles.Artbuyer || (
													<li onClick={onPublicInfoClick}>Public info</li>
												)}
												<li onClick={onPricingClick}>Pricing</li>
												<li onClick={onSettingsClick}>Account settings</li>
											</ProfileMenuOpenList>
										</ProfileMenuOpenContent>
										<LogoutBtn onClick={onLogOut}>Log Out</LogoutBtn>
									</ProfileMenuOpen>
								)}
							</ProfileMenu>
						</>
					) : (
						<>
							<Button onClick={onLogin} theme="secondary">
								Log In
							</Button>
							<Button onClick={onSignUp} theme="primary">
								Sign Up
							</Button>
						</>
					)}
				</HeaderMenu>
			</HeaderInner>

			{/* SIGNUP MODAL */}

			<Modal size="small" isOpen={isSignUp} onClose={() => setIsSignUp(false)}>
				<SignUpModalComponent />
			</Modal>

			{/* LOGIN MODAL */}

			<Modal size="small" isOpen={isLogInOpen} onClose={() => setIsLogInOpen(false)}>
				<SignIn
					setIsSignUp={setIsSignUp}
					setIsLogIn={setIsLogInOpen}
					forgotPassword={setIsForgotPassword}
				/>
			</Modal>

			{/* PASSWORD MODAL */}

			<Modal size="small" isOpen={isForgorPassword} onClose={() => setIsForgotPassword(false)}>
				<ResetPasswordModal
					setIsLogIn={setIsLogInOpen}
					forgotPassword={setIsForgotPassword}
					setIsSignUp={setIsSignUp}
				/>
			</Modal>

			{/* UPLOAD IMAGES MODAL  */}

			<Modal
				noPadding
				noRadius
				isOpen={isUploadImageModal}
				onClose={() => setIsUploadImagesModal(false)}>
				<UploadImages
					setFilesToUpload={setFilesToUpload}
					setUploadImages={setUploadImages}
					isEditImageOpen={isEditImagesInfo}
					setIsEditImageOpen={setIsEditImagesInfo}
					modalOpen={isUploadImageModal}
					setModalOpen={() => setIsUploadImagesModal(false)}
				/>
			</Modal>

			{/* EDIT UPLOAED IMAGES MODAL  */}

			<Modal
				noPadding
				noRadius
				isOpen={isEditImagesInfo}
				onClose={() => {
					setIsEditImagesInfo(false);
					dispatch(clearUploadedData());
				}}>
				<EditUploadedImages
					onCloseEditImages={setIsEditImagesInfo}
					setIsArchive={setIsArchive}
					files={filesToUpload}
					images={uploadedImages}
					setUploadImages={setUploadImages}
					setIsEditImagesInfo={setIsEditImagesInfo}
					onSubmit={onSubmitUploadedImages}
				/>
			</Modal>
			<Modal
				noPadding
				isOpen={isPricingOpen}
				size="fullwidth"
				onClose={() => setIsPricingOpen(false)}>
				<Pricing />
			</Modal>

			<HeaderLine />
		</MainHeader>
	);
};

const HeaderNoLineBtn = styled.div<any>`
	display: flex;
	align-items: center;
	justify-content: center;
	color: #505050;
	margin-right: 24px;
	cursor: pointer;
	transition: all.2s;

	&:hover {
		color: #000000;
		transition: all.2s;
		& > div {
			& > svg {
				& > path {
					${({ strokeSvg }) => {
						if (strokeSvg) {
							return css`
								stroke: #000000;
							`;
						} else {
							return css`
								fill: #000000;
							`;
						}
					}}
					transition: all.2s;
				}
			}
		}
	}
`;
const CountNotification = styled.div`
	margin-left: 8px;
	display: flex;
	width: 20px;
	padding: 2px 8px;
	justify-content: center;
	align-items: center;
	border-radius: 24px;
	background: var(--purple-90, #8562ce);
	font-size: 12px;
	color: #fff;
	font-weight: 400;
	line-height: 16px;
`;
const HeaderNoLineBtnImg = styled.div`
	width: 24px;
	height: 24px;
	margin-right: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	& > svg {
		& > path {
			transition: all.2s;
		}
	}
`;
const ProfileMenu = styled.div<any>``;
const ProfileMenuInner = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;
const ProfileMenuImgInner = styled.div<any>`
	border: 1px solid transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	transition: 0.2s;
	margin-right: 4px;

	&:hover {
		border: 1px solid #000;
		transition: 0.2s;

		& > img {
			transition: 0.2s;
			width: 35px;
			height: 35px;
		}
	}
`;
const ProfileMenuImg = styled.img<any>`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	transition: 0.2s;
	background: #000;
	${({ noAvatar }) => {
		if (!noAvatar) {
			return css`
				background: #000;
				padding: 5px 5px 6px 6px;
			`;
		}
	}}
`;
const ProfileMenuArr = styled.img`
	width: 24px;
	height: 24px;
`;
const ProfileMenuOpen = styled.div<any>`
	width: 216px;
	right: 0;
	top: 65px;
	z-index: 7;
	padding: 24px;
	position: absolute;
	box-shadow: 0px 8px 41px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	background: #fff;
`;
const ProfileMenuOpenName = styled.div`
	font-weight: 600;
`;
const ProfileMenuOpenPosition = styled.div`
	color: #808080;
`;
const ProfileMenuOpenContent = styled.div`
	margin: 14px 0px;
	padding: 14px 0px;
	border-top: 1px solid #505050;
	border-bottom: 1px solid #505050;
`;
const ProfileMenuOpenList = styled.ul`
	& > li {
		cursor: pointer;
		color: #505050;
		transition: 0.2s;
		&:hover {
			transition: 0.2s;

			color: #000000;
		}
		&:not(:last-child) {
			margin-bottom: 14px;
		}
	}
`;
const LogoutBtn = styled.div`
	color: #505050;
	cursor: pointer;
	transition: 0.2s;
	&:hover {
		transition: 0.2s;

		color: #000000;
	}
`;
export const SignInModal = styled.div``;
export const SignInModalForm = styled.form`
	width: 396px;
	margin: 0 auto;
`;
export const SignInModalFormLine = styled.div`
	height: 16px;
`;
export const SignInModalText = styled.div`
	color: #282828;
	text-align: center;
	& > a {
		color: #8562ce;
	}
`;
const MainHeader = styled.header<any>`
	margin: 0 auto;
	max-width: 1392px;
	width: 100%;
	background: #fff;
	background-color: ${(props) => (props.darker ? "#F7F7F7" : "#fff")};
	position: fixed;
	z-index: 4;
`;

const HeaderInner = styled.div`
	padding: 14px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const HeaderLogo = styled.div`
	cursor: pointer;
	margin-top: 10px;
	position: relative;
	& > img {
		margin-left: 2px;
		height: 24px;
	}
`;
const Version = styled.div`
	position: absolute;
	top: 25px;
	left: 5px;
	color: red;
	font-size: 10px;
	font-weight: 600;
`;
const HeaderMenu = styled.div`
	display: flex;
	align-items: center;
`;

const HeaderLine = styled.div`
	max-width: 1392px;
	width: 100%;
	height: 1px;
	background: var(--color-gray);
	margin: 0 auto;
`;
const SearchBtn = styled.div`
	margin-right: 24px;
	display: flex;
	align-items: center;
	color: var(--color-gray);
	cursor: pointer;
`;
const SearchBtnSvg = styled.img`
	margin-right: 13px;
	width: 14px;
	height: 14px;
`;

export const SignUpTitle = styled.h3`
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	margin-bottom: 40px;
	text-align: center;
`;
