import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/axiosInstance";

export const getphotographerJobRequests = createAsyncThunk<any, string, { rejectValue?: any }>(
	"photographer/getJobRequests",
	async (parameters, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `/photographer/briefings/list${parameters}`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const getphotographerBriefing = createAsyncThunk<any, number, { rejectValue?: any }>(
	"photographer/getphotographerBriefing",
	async (briefingId, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `/photographer/briefings/${briefingId}`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);

export const getSuggestedImage = createAsyncThunk<any, any, { rejectValue?: any }>(
	"artbuyer/getAllJobRequests",
	async (data, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `/photographer/briefings/${data.briefingId}/suggestedPhotos/${data.photoId}`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);

export const getSuggestedPhotos = createAsyncThunk<any, any, { rejectValue?: any }>(
	"photographer/getSuggestedPhotos",
	async (data, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `/photographer/briefings/${data.briefingId}/suggestedPhotos${data.parameters}`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const getAllphotographerJobRequests = createAsyncThunk<any, string, { rejectValue?: any }>(
	"photographer/getAllphotographerJobRequests",
	async (parameters, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `/photographer/briefings/list${parameters}`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const getJobRequestTrack = createAsyncThunk<any, number, { rejectValue?: any }>(
	"photographer/getJobRequestTrack",
	async (id, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `/photographer/briefings/${id}/track`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const removeSuggestion = createAsyncThunk<any, any, { rejectValue?: any }>(
	"photographer/removeSuggestion",
	async (data, thunkAPI) => {
		try {
			const response = await api({
				method: "DELETE",
				url: `/photographer/briefings/${data.briefingId}/removeSuggestion/${data.photoId}`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const getJobRequestUnTrack = createAsyncThunk<any, number, { rejectValue?: any }>(
	"photographer/getJobRequestUnTrack",
	async (id, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `/photographer/briefings/${id}/untrack`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const getJobRequestNotInterested = createAsyncThunk<any, number, { rejectValue?: any }>(
	"photographer/getJobRequestUnTrack",
	async (id, thunkAPI) => {
		try {
			const response = await api({
				method: "POST",
				url: `/photographer/briefings/${id}/notInterested`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);

export const suggestPhotos = createAsyncThunk<any, any, { rejectValue?: unknown }>(
	"photographer/suggestPhotos",
	async (data, thunkAPI) => {
		try {
			return await fetch(
				`${process.env.REACT_APP_DOMEN}/api/v1/photographer/briefings/suggestPhotos`,
				{
					method: "POST",
					body: data,
					headers: {
						"X-AUTH-TOKEN": `${localStorage.getItem("token")}`,
					},
				}
			).then((response) => response.json());
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const updateSuggestion = createAsyncThunk<any, any, { rejectValue?: unknown }>(
	"photographer/updateSuggestion",
	async (data, thunkAPI) => {
		try {
			return await fetch(
				`${process.env.REACT_APP_DOMEN}/api/v1/photographer/briefings/updateSuggestion`,
				{
					method: "POST",
					body: data,
					headers: {
						"X-AUTH-TOKEN": `${localStorage.getItem("token")}`,
					},
				}
			).then((response) => response.json());
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
