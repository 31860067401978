import React from "react";
import styled from "styled-components";

export const Imprint = () => {
	return (
		<Wrapper>
			<Block>
				<Title>Imprint</Title>
				<Subtitle>Information providedaccording to Sec. 5 German Telemedia Act (TMG):</Subtitle>
				<List>
					<li>ArtBrief UG (Haftungsbeschränkt)</li>
					<li>Methfesselstr. 16A</li>
					<li>22767 Hamburg</li>
				</List>
			</Block>
			<Block>
				<Subtitle>Represented by:</Subtitle>
				<List>
					<li>Wladimir Alexi</li>
				</List>
			</Block>
			<Block>
				<Subtitle>Contact:</Subtitle>
				<List>
					<li>Telephone: +49405379828 70</li>
					<li>Email: support@artbrief.com</li>
				</List>
			</Block>
			<Block>
				<Subtitle>Register entry:</Subtitle>
				<List>
					<li> Entry in the Handelsregister.</li>
					<li>Registering court:Hamburg</li>
					<li>Registration number: 144118</li>
				</List>
			</Block>
			<Block>
				<Subtitle>VAT:</Subtitle>
				<List>
					<li>VAT Id number according to Sec. 27 a GermanValue Added Tax Act:</li>
					<li>DE310810472</li>
				</List>
			</Block>
			<Block>
				<Subtitle>Dispute resolution</Subtitle>
				<List>
					<li>VAT Id number according to Sec. 27 a GermanValue Added Tax Act:</li>
					<li>DE310810472</li>
				</List>
			</Block>
			<Block>
				<Subtitle>Dispute resolution</Subtitle>
				<List>
					<li>The EuropeanCommission provides a platform for online dispute resolution (OS):</li>
					<li>
						<a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr.</a>
					</li>
					<li>Please find our email in the impressum/legal notice.</li>
				</List>
				<List>
					<li> We do not take part in onlinedispute resolutions at consumer arbitration boards.</li>
				</List>
			</Block>
			<Block>
				<Subtitle>Liability for Contents</Subtitle>
				<Text>
					As serviceproviders, we are liable for own contents of these websites according to Sec. 7,
					paragraph 1 GermanTelemedia Act (TMG). However, according to Sec. 8 to 10 German Telemedia
					Act (TMG), serviceproviders are not obligated to permanently monitor submitted or stored
					information or to search forevidences that indicate illegal activities.
				</Text>
				<Text>
					Legal obligations to removing information or to blockingthe use of information remain
					unchallenged. In this case, liability is only possible at the time of knowledgeabout a
					specific violation of law. Illegal contents will be removed immediately at the time we get
					knowledgeof them.
				</Text>
			</Block>
			<Block>
				<Subtitle>Liability for Links</Subtitle>
				<Text>
					Our offer includes links to external third party websites. Wehave no influence on the
					contents of those websites, therefore we cannot guarantee for those contents.Providers or
					administrators of linked websites are always responsible for their own contents.
				</Text>
				<Text>
					Thelinked websites had been checked for possible violations of law at the time of the
					establishment of the link.Illegal contents were not detected at the time of the linking. A
					permanent monitoring of the contents oflinked websites cannot be imposed without
					reasonable indications that there has been a violation of law.Illegal links will be
					removed immediately at the time we get knowledge of them.
				</Text>
			</Block>
			<Block>
				<Subtitle>Copyright</Subtitle>
				<Text>
					Contents and compilations published on these websites by the providers are subject to
					Germancopyright laws. Reproduction, editing, distribution as well as the use of any kind
					outside the scope of thecopyright law require a written permission of the author or
					originator. Downloads and copies of thesewebsites are permitted for private use only. The
					commercial use of our contents without permissionof the originator is prohibited.
				</Text>
				<Text>
					Copyright laws of third parties are respected as long as the contentson these websites do
					not originate from the provider. Contributions of third parties on this site areindicated
					as such. However, if you notice any violations of copyright law, please inform us. Such
					contentswill be removed immediately.
				</Text>
			</Block>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding-top: 88px;
	font-size: 40px;
	max-width: 752px;
	margin: 0 auto 120px;
`;
const Block = styled.div``;
const Title = styled.h3`
	text-align: center;
	margin-bottom: 14px;
	font-weight: 700;
	font-size: 40px;
	line-height: 48px;
`;
const Subtitle = styled.div`
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 16px;
`;
const Text = styled.div`
	font-size: 16px;
	line-height: 24px;
	color: #282828;
	margin-bottom: 16px;
`;

const List = styled.ul`
	margin-bottom: 18px;
	& > li {
		font-size: 16px;
		line-height: 24px;
		color: #282828;
		& > a {
			text-decoration: underline;
			color: inherit;
		}
	}
`;
