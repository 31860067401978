import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export const NotFoundPage = () => {
	const navigate = useNavigate();
	return (
		<Wrapper>
			<h1 style={{ fontSize: "200px" }}>404</h1>
			<h6>The page you’re looking for doesn’t exist.</h6>
			<span style={{ marginTop: "20px" }}>Please</span>
			<Button onClick={() => navigate(-1)}>Go Back</Button>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	min-height: 100vh;
	background-color: #b3b3b3;
	color: white;
	font-size: 40px;
`;

const Button = styled.button`
	margin: 10px 5px;
	cursor: pointer;
	background-color: #565656;
	border-radius: 4px;
	padding: 10px 20px;
	outline: none;
	border: none;
	color: white;
	font-size: 35px;
	font-weight: 600;
`;
