import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/axiosInstance";
import { IImageRequest } from "../../../models/IImageRequest";

export const getImageRequests = createAsyncThunk<IImageRequest[], string, { rejectValue?: any }>(
	"photographer/getImageRequests",
	async (parameters, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `photographer/showroom/requests${parameters}`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const getAllImageRequests = createAsyncThunk<IImageRequest[], string, { rejectValue?: any }>(
	"photographer/getAllImageRequests",
	async (parameters, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `photographer/showroom/requests${parameters}`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const getImageRequestInfo = createAsyncThunk<IImageRequest[], number, { rejectValue?: any }>(
	"photographer/getImageRequestInfo",
	async (id, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `photographer/showroom/requests/${id}`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);

export const rejectRequest = createAsyncThunk<IImageRequest[], any, { rejectValue?: any }>(
	"photographer/rejectRequest",
	async (data, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `photographer/showroom/requests/${data}/reject`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const counterOfferRequest = createAsyncThunk<IImageRequest[], any, { rejectValue?: any }>(
	"photographer/counterOfferRequest",
	async (data, thunkAPI) => {
		try {
			const response = await api({
				method: "POST",
				url: `photographer/showroom/requests/counterOffer`,
				data: data,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);

export const postPaymentRequest = createAsyncThunk<any, any, { rejectValue?: unknown }>(
	"photographer/counterOfferRequest",
	async (data, thunkAPI) => {
		try {
			return await fetch(
				`${process.env.REACT_APP_DOMEN}/api/v1/photographer/showroom/requests/${data.id}/paymentrequest`,
				{
					method: "POST",
					body: data.parameters,
					headers: {
						"X-AUTH-TOKEN": `${localStorage.getItem("token")}`,
					},
				}
			).then((response) => response.json());
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);

export const submitRequest = createAsyncThunk<any, any, { rejectValue?: any }>(
	"photographer/submitRequest",
	async (data, thunkAPI) => {
		try {
			const response = await api({
				method: "POST",
				url: `photographer/showroom/requests/${data.id}/paymentrequest/submit`,
				data: data,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);

export const revealRequest = createAsyncThunk<IImageRequest[], any, { rejectValue?: any }>(
	"photographer/revealRequest",
	async (data, thunkAPI) => {
		try {
			const response = await api({
				method: "POST",
				url: `photographer/showroom/requests/reveal`,
				data: data,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
