import { AuthRoles, IUserProfile } from "../../../models/IProfile";
import { createSlice } from "@reduxjs/toolkit";
import { getCountriesList } from "./action";

interface CountryInfo {
	id: number;
	englishName: string;
	shortName: string;
	tax: number;
}

interface StripeState {
	countries: CountryInfo[];
	selectCountries: {
		label: string;
		value: number;
	}[];
}

const initialState: StripeState = {
	countries: [],
	selectCountries: [],
};

export const stripeSlice = createSlice({
	name: "user/countries",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getCountriesList.fulfilled, (state, action) => {
			state.countries = action.payload;
			state.selectCountries = action.payload.map((el: any) => {
				return {
					label: el.englishName,
					value: el.id,
				};
			});
		});
	},
});

export const { reducer: countriesReducer, actions: countriesActions } = stripeSlice;
