import React from "react";
import styled from "styled-components";
import { Button } from "../UI";

interface Props {
	onPhotoshootOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onOneImageOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onClose: () => void;
}

export const JobRequest = ({ onPhotoshootOpen, onOneImageOpen, onClose }: Props) => {
	const onNeedPhotoClick = () => {
		onOneImageOpen(true);
		onClose();
	};
	const onNeedPhotoshootClick = () => {
		onPhotoshootOpen(true);
		onClose();
	};
	return (
		<Wrapper>
			<Title>Job Request</Title>
			<Text>Choose type</Text>
			<Text>
				Publish an image research request if you are looking for a photo to license
				<span>— Free</span>
			</Text>
			<Button onClick={onNeedPhotoClick} width="100%">
				I need a Photo
			</Button>
			<LineSection>
				<Line></Line>
				<LineText>or</LineText>
				<Line></Line>
			</LineSection>
			<Text>
				Publish a job posting if you are looking for a creator for your project <span>— Free</span>
			</Text>
			<Button onClick={onNeedPhotoshootClick} width="100%">
				I need a Job Request
			</Button>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	width: 480px;
	max-height: 75vh;
	text-align: left;
`;
const Title = styled.h3`
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	margin-bottom: 16px;
`;
const Text = styled.div`
	margin-bottom: 24px;
	& > span {
		font-weight: 600;
	}
`;
const LineSection = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	margin: 24px 0px;
`;
const Line = styled.div`
	height: 1px;
	background: #000;
	width: 212px;
`;
const LineText = styled.div`
	margin: 0 20px;
	font-size: 16px;
	line-height: 24px;
`;
