import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/axiosInstance";

export const addFavourite = createAsyncThunk<any, any, { rejectValue?: any }>(
	"artbuyer/addFavourite",
	async (json, thunkAPI) => {
		try {
			const response = await api({
				method: "POST",
				url: `/artbuyer/favourites`,
				data: json,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const removeFavourite = createAsyncThunk<any, any, { rejectValue?: any }>(
	"artbuyer/removeFavourite",
	async (json, thunkAPI) => {
		try {
			const response = await api({
				method: "DELETE",
				url: `/artbuyer/favourites`,
				data: json,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const getFavourites = createAsyncThunk<any, any, { rejectValue?: any }>(
	"artbuyer/getFavourites",
	async (parameters, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `/artbuyer/favourites${parameters}`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
