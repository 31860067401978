import { useCallback, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ImageViewer from "react-simple-image-viewer";

import { sliderArrL, sliderArrR } from "../../static/images";
import styled from "styled-components";

interface Props {
	images: string[];
	currentItem?: number;
	setCurrentItem: React.Dispatch<React.SetStateAction<number>> | undefined;
}

export const CustomSlider = ({ images, currentItem, setCurrentItem }: Props) => {
	const imagesToImageViewer = images.map((el) => `${process.env.REACT_APP_DOMEN}${el}`);

	const style = {
		width: "40px",
		height: "40px",
		left: "20px",
		top: "80px",
		padding: "8px",
		background: "#f7f7f7",
		borderRadius: "24px",
	};
	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <NextArrow style={style} />,
		prevArrow: <PrevArrow style={style} />,
		adaptiveHeight: true,
		beforeChange: (current: number, next: number) => setCurrentItem?.(next),
	};

	const [currentImage, setCurrentImage] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState(false);

	const openImageViewer = useCallback((index: number) => {
		setCurrentImage(index);
		setIsViewerOpen(true);
	}, []);

	const closeImageViewer = () => {
		setCurrentImage(0);
		setIsViewerOpen(false);
	};

	return (
		<Wrapper>
			<Slider initialSlide={currentItem || 0} {...settings}>
				{images.map((el, i) => (
					<SliderItem onClick={() => openImageViewer(i)} key={i}>
						<img src={`${process.env.REACT_APP_DOMEN}${el}`} alt="sliderItem" />
					</SliderItem>
				))}
			</Slider>
			{isViewerOpen && (
				<ImageViewer
					src={imagesToImageViewer}
					currentIndex={currentImage}
					disableScroll={true}
					closeOnClickOutside={true}
					onClose={closeImageViewer}
					leftArrowComponent={<div></div>}
					rightArrowComponent={<div></div>}
					backgroundStyle={{
						backgroundColor: "rgb(44 37 37 / 70%)",
					}}
				/>
			)}
		</Wrapper>
	);
};

const PrevArrow = (props: any) => {
	const { className, style, onClick } = props;

	return (
		<div onClick={onClick} className={className} style={{ ...style, marginLeft: "-20px" }}>
			<ArrowWrapper>
				<img src={sliderArrL} alt="arrRight" />
			</ArrowWrapper>
		</div>
	);
};
const NextArrow = (props: any) => {
	const { className, style, onClick } = props;

	return (
		<div onClick={onClick} className={className} style={{ ...style }}>
			<ArrowWrapper>
				<img src={sliderArrR} alt="arrRight" />
			</ArrowWrapper>
		</div>
	);
};

const ArrowWrapper = styled.div`
	width: 40px;
	height: 40px;
	left: 20px;
	top: 80px;
	padding: 8px;
	background: #f7f7f7;
	border-radius: 24px;
	z-index: 5;
	transition: all.2s;
	&:hover {
		background: #ebebeb;
		transition: all.2s;
	}
`;

const Wrapper = styled.div`
	width: 100%;
	& *:focus {
		outline: 0;
		outline: none;
	}
	& .slick-next {
		&::before {
			font-size: 0px !important;
		}
	}
	& .slick-prev {
		&::before {
			font-size: 0px !important;
		}
	}
	& .styles-module_image__2hdkJ {
		height: 60vh;
	}
`;
const SliderItem = styled.div`
	height: 62vh;
	width: 100%;
	padding: 0 20px;
	display: flex !important;
	overflow-x: hidden;
	justify-content: center;
	& > img {
		overflow-x: hidden;
	}
`;
