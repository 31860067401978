import { createSlice } from "@reduxjs/toolkit";
import { INotifications } from "../../../models/INotifications";
import { getUserNotifications } from "./action";

interface ProfileState {
	notifications: INotifications;
	isLoading: boolean;
	notificationsError: string | undefined;
}

const initialState: ProfileState = {
	notifications: {
		briefings: false,
		showrooms: false,
	},
	isLoading: false,
	notificationsError: undefined,
};

export const notificationsSlice = createSlice({
	name: "user/notifications",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getUserNotifications.pending, (state, action) => {
				state.isLoading = true;
			})
			.addCase(getUserNotifications.fulfilled, (state, action) => {
				state.notifications.briefings = action.payload.briefings;
				state.notifications.showrooms = action.payload.showrooms;

				state.isLoading = false;
			})
			.addCase(getUserNotifications.rejected, (state, action) => {
				state.notificationsError = action.payload as string;

				state.isLoading = false;
			});
	},
});

export const { reducer: notificationSliceReducer, actions: notificationSliceActions } =
	notificationsSlice;
