import { IArtbuyerTotalBuys, IPhotographerTotalSales } from "../../../models/ITotalSales";

import { createSlice } from "@reduxjs/toolkit";
import { getBuys, getSales, getTotalSales } from "./action";

interface ProfileState {
	photograperTotalSales: IPhotographerTotalSales[];
	artbuyerTotalBuys: IArtbuyerTotalBuys[];
	isLoading: boolean;
	totalSalesError: string | undefined;
}

const initialState: ProfileState = {
	photograperTotalSales: [
		{
			year: 0,
			sales: [],
		},
	],
	artbuyerTotalBuys: [
		{
			year: 0,
			sales: [],
		},
	],
	isLoading: false,
	totalSalesError: undefined,
};

export const totalSalesSlice = createSlice({
	name: "user/totalSales",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getSales.pending, (state, action) => {
				state.isLoading = true;
			})
			.addCase(getSales.fulfilled, (state, action) => {
				state.photograperTotalSales = action.payload.data;

				state.isLoading = false;
			})
			.addCase(getSales.rejected, (state, action) => {
				state.totalSalesError = action.payload as string;
				state.isLoading = false;
			})
			.addCase(getBuys.pending, (state, action) => {
				state.isLoading = true;
			})
			.addCase(getBuys.fulfilled, (state, action) => {
				console.log(action.payload.data);

				state.artbuyerTotalBuys = action.payload.data;
				state.isLoading = false;
			})
			.addCase(getBuys.rejected, (state, action) => {
				state.totalSalesError = action.payload as string;
				state.isLoading = false;
			});
	},
});

export const { reducer: totalSalesReducer, actions: totalSalesActions } = totalSalesSlice;
