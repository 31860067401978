import React, { useEffect, useRef } from "react";
import styled from "styled-components";

interface Props {
	placeholder?: string;
	value?: string;
	onChange?: React.Dispatch<React.SetStateAction<string>>;
	onBlur?: () => void;
	autofocus: boolean;
	isActive?: boolean;
	setIsSearchActive?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SearchOnPage = ({
	placeholder,
	onBlur,
	value,
	onChange,
	setIsSearchActive,
	autofocus,
	isActive = false,
	...other
}: Props) => {
	const searchRef = useRef<any>();

	useEffect(() => {
		return () => {
			setIsSearchActive?.(false);
		};
	}, []);

	return (
		<Wrapper
			ref={searchRef}
			value={value}
			onChange={(e) => onChange?.(e.target.value)}
			placeholder={placeholder}
			onBlur={onBlur}
			autoFocus={autofocus}
			{...other}
		/>
	);
};

const Wrapper = styled.input`
	font-weight: 700;
	font-size: 64px;
	line-height: 80px;
	color: #000;
	margin-top: 20px;
	margin-bottom: 56px;
	height: 80px;
	width: 100%;
	border: none;
	outline: none;
	text-align: center;
	&::placeholder {
		color: #ebebeb;
	}
`;
