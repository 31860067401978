import React, { forwardRef } from "react";
import styled, { css } from "styled-components";
import { checked } from "../../static/images";

interface Props {
	label?: string;
	checked?: boolean;
	onValueChange?: React.Dispatch<React.SetStateAction<boolean>>;
	disabled?: boolean;
}

export const Checkbox = forwardRef(
	({ label, onValueChange, disabled, checked, ...other }: Props, ref: Ref) => {
		return (
			<Label>
				<Input
					onChange={() => (onValueChange ? onValueChange(!checked) : null)}
					checked={checked}
					disabled={disabled}
					ref={ref}
					{...other}
					type="checkbox"
				/>
				<CustomCheckbox isDisabled={disabled} className="custom-checkbox"></CustomCheckbox>
				{label}
			</Label>
		);
	}
);

const Label = styled.label`
	cursor: pointer;
	display: flex;
	align-items: center;
`;
const Input = styled.input`
	width: 0;
	opacity: 0;
	transform: translateX(-2000px);
	&:checked + .custom-checkbox::after {
		opacity: 1;
	}
`;
const CustomCheckbox = styled.span<any>`
	width: 24px;
	height: 24px;
	border-radius: 8px;
	background: #f7f7f7;
	display: inline-block;
	margin-right: 10px;
	position: relative;
	&::after {
		content: "";
		left: 0;
		top: 0;
		position: absolute;
		width: 24px;
		height: 24px;
		background-image: url(${checked});
		opacity: 0;
		transition: 0.1s;
	}
	${({ isDisabled }) => {
		if (isDisabled) {
			return css`
				opacity: 0.6;
			`;
		}
	}}
`;

type Ref =
	| ((instance: HTMLInputElement | null) => void)
	| React.RefObject<HTMLInputElement>
	| null
	| undefined;
