import { useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { SubTitle } from ".";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
	updateArtbuyerProfile,
	updatePhotographerProfile,
} from "../../store/reducers/profile/action";
import { ButtonWrapper, InputWrapper } from "../PublicInfo";
import { Button, Checkbox, Input, ErrorWrapper } from "../UI";
import { AuthRoles } from "../../models/IProfile";

interface Props {
	onUpdateInfo: () => void;
}

export const BasicInformationForm = ({ onUpdateInfo }: Props) => {
	const photographerBasicInfo = useAppSelector((state) => state.profile.photographerBasicInfo);

	const { user } = useAppSelector((state) => state.authData);

	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm();
	const dispatch = useAppDispatch();

	useEffect(() => {
		reset({ ...photographerBasicInfo });
	}, [photographerBasicInfo]);

	const err = "The filed is required";

	const onSubmit = async (data: any) => {
		const { meta } = await dispatch(
			user?.role === AuthRoles.Photographer
				? updatePhotographerProfile(data)
				: updateArtbuyerProfile(data)
		);

		if (meta.requestStatus === "fulfilled") {
			onUpdateInfo();
		}
	};

	return (
		<BasicInformation id="basicInfo">
			<SubTitle>Basic Information</SubTitle>
			<Text>
				Your e-mail is used for all notifications concerning purchase request. It is also the
				address where the password reset e-mail is sent if you forget your password.
			</Text>
			<InputWrapper>
				<Input
					error={!!errors?.name}
					type="email"
					{...register("email", {
						required: err,
					})}
					labelText="E-mail"
				/>
				<ErrorWrapper>
					<p style={errors?.email && { display: "block" }}>
						{errors?.email && `${errors?.email?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper>
				<Input
					error={!!errors?.phone}
					type="tel"
					{...register("phone", {
						required: err,
					})}
					labelText="Phone"
				/>
				<ErrorWrapper>
					<p style={errors?.phone && { display: "block" }}>
						{errors?.phone && `${errors?.phone?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper>
				<Input
					error={!!errors?.name}
					{...register("name", {
						required: err,
					})}
					labelText="Username"
				/>
				<ErrorWrapper>
					<p style={errors?.name && { display: "block" }}>
						{errors?.name && `${errors?.name?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper>
				<Checkbox {...register("receiveNewsletter")} label="Receive Newsletter" />
			</InputWrapper>
			<ButtonWrapper>
				<Button onClick={handleSubmit(onSubmit)}>Update</Button>
			</ButtonWrapper>
		</BasicInformation>
	);
};

const BasicInformation = styled.form`
	margin-bottom: 48px;
`;

export const Text = styled.div`
	color: #282828;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 24px;
`;
