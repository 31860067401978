import { useEffect } from "react";
import { toast } from "react-toastify";
import { TextSwitcher } from "../TextSwitcher";

interface Props {
	showToast: boolean;
}

export const LoadingToast = ({ showToast }: Props) => {
	const showLoader = () => {
		toast.loading(<TextSwitcher />, {
			position: "top-right",
			autoClose: false,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
		});
	};
	const hideLoader = () => {
		toast.dismiss();
	};

	useEffect(() => {
		hideLoader();
		showToast && showLoader();
	}, [showToast]);

	return <div></div>;
};
