import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import { photoReducer } from "./reducers/photo";
import { authDataReducer } from "./reducers/authData";
import { profileSliceReducer } from "./reducers/profile/reducer";
import { notificationSliceReducer } from "./reducers/notifications";
import { totalSalesReducer } from "./reducers/totalSales";
import { deletePhotographerReducer } from "./reducers/deletePhotographer";
import { photographerGalleryReducer } from "./reducers/photographerGallery";
import { artbuyerFavoritesReducer } from "./reducers/artbuyerFavorites";
import { artbuyerCartReducer } from "./reducers/cart";
import { artbuyerImageRequestReducer } from "./reducers/imageRequest";
import { photographerSkillsReducer } from "./reducers/PhotographerSkills";
import { photographerImageRequestReducer } from "./reducers/photographerImageRequest";
import { artbuyerJobRequestReducer } from "./reducers/artbuyerJobRequests";
import { photographerJobRequestsReducer } from "./reducers/photographerJobRequests";
import { messagesBriefingReducer } from "./reducers/Messages";
import { stripeSliceReducer } from "./reducers/stripe";
import { countriesReducer } from "./reducers/countries";

export const store = configureStore({
	reducer: {
		profile: profileSliceReducer,
		photos: photoReducer,
		authData: authDataReducer,
		notification: notificationSliceReducer,
		totalSales: totalSalesReducer,
		deleteAccount: deletePhotographerReducer,
		gallery: photographerGalleryReducer,
		favorites: artbuyerFavoritesReducer,
		cart: artbuyerCartReducer,
		artbuyerImageRequests: artbuyerImageRequestReducer,
		photographerImageRequests: photographerImageRequestReducer,
		photographerSkills: photographerSkillsReducer,
		artbuyerJobRequests: artbuyerJobRequestReducer,
		photographerJobRequests: photographerJobRequestsReducer,
		briefingMessages: messagesBriefingReducer,
		stripe: stripeSliceReducer,
		countries: countriesReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
