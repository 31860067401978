import * as Yup from "yup";

export  const changePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required("Password is required")
      .min(5, "Password must be at least 6 characters"),
    newPassword: Yup.string()
      .required("Password is required")
      .min(5, "Password must be at least 6 characters"),
    repeatNewPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("newPassword")], "Passwords must match"),
  });