import { Photo } from "../models/IPhoto";

export const splitArrayToGalleryFormat = <T>(arr: any[] | Photo[] | undefined) => {
	const len = arr?.length;

	if (len) {
		const chunkSize = Math.floor(len / 4);

		let remainder = len % 4;

		let offset = 0;
		const result = [];

		for (let i = 0; i < 4; i++) {
			const chunkLength = chunkSize + (remainder > 0 ? 1 : 0);

			const chunk = arr?.slice(offset, offset + chunkLength);

			result.push(chunk);

			offset += chunkLength;

			remainder--;
		}
		return result;
	}
	return [];
};
