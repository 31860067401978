import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-scroll";
import { camera } from "../../static/images";
import { Button, CustomSelect, ErrorWrapper, Input, TextArea } from "../UI";
import { useController, useForm } from "react-hook-form";
import { SocialLinksForm } from "./SocialLinksForm";
import { SkillsForm } from "./SkillsForm";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { countries } from "../../utils/countries";
import {
	artbuyerProfile,
	photographerProfile,
	updateArbuyerAvatar,
	updateArtbuyerProfile,
	updateAvatar,
	updatePhotographerProfile,
} from "../../store/reducers/profile/action";
import { IsLoading } from "../UI/IsLoading";
import { getPhotographerSkills } from "../../store/reducers/PhotographerSkills/action";
import { photographerAboutUpating } from "../../store/reducers/profile";
import { AuthRoles } from "../../models/IProfile";

const selectOptions = [
	{ value: "photographer", label: "Photographer" },
	{ value: "model", label: "Model" },
	{ value: "makeUpArtist", label: "MakeUp Artist" },
	{ value: "stylist", label: "Stylist" },
	{ value: "illustraitor", label: "Illustraitor" },
];

interface Props {
	onUpdateInfo: () => void;
}

export const PublicInfo = ({ onUpdateInfo }: Props) => {
	const dispatch = useAppDispatch();
	const [titleValue, setTitleValue] = useState("");
	const [descValue, setDescValue] = useState("");

	const { photographerAbout, isLoading } = useAppSelector((state) => state.profile);
	const { user } = useAppSelector((state) => state.authData);
	const { selectCountries } = useAppSelector((state) => state.countries);

	const {
		register,
		formState: { errors },
		control,
		handleSubmit,
		reset,
	} = useForm();

	const { field: countryField } = useController({
		name: "countryId",
		control,
		rules: { required: true },
	});
	const { field: professionField } = useController({
		name: "profession",
		control,
		rules: { required: true },
	});

	useEffect(() => {
		reset({ ...photographerAbout });
		setTitleValue(photographerAbout.title);
		setDescValue(photographerAbout.about);
	}, [photographerAbout]);

	useEffect(() => {
		if (user?.role === AuthRoles.Photographer) {
			dispatch(photographerProfile());
			dispatch(getPhotographerSkills());
		} else {
			dispatch(artbuyerProfile());
		}
	}, []);

	const onAboutMeSubmit = async (data: any) => {
		const { meta } = await dispatch(
			user?.role === AuthRoles.Photographer
				? updatePhotographerProfile(data)
				: updateArtbuyerProfile(data)
		);

		if (meta.requestStatus === "fulfilled") {
			dispatch(photographerAboutUpating(data));
			onUpdateInfo();
		}
	};

	const err = "The filed is required";

	const onEditAvatar = async (e: any) => {
		const file = e.target.files[0];

		const formData = new FormData();
		formData.append("file", file);
		if (user?.role === AuthRoles.Photographer) {
			await dispatch(updateAvatar(formData));
		} else {
			await dispatch(updateArbuyerAvatar(formData));
		}
	};

	return isLoading ? (
		<IsLoading />
	) : (
		<Wrapper>
			<Title>Public Info</Title>

			<AvatarBorder noAvatar={!photographerAbout.avatarURL}>
				<AvatarWrapper>
					<img
						src={
							photographerAbout.avatarURL
								? `${process.env.REACT_APP_DOMEN}${photographerAbout.avatarURL}`
								: camera
						}
						alt="camera"
					/>
					<AvatarEdit>
						<input
							accept="image/*, .png, .jpg,.web"
							onChange={(e) => onEditAvatar(e)}
							type="file"
						/>
						Edit
					</AvatarEdit>
				</AvatarWrapper>
			</AvatarBorder>

			<AboutMeForm id="about" onSubmit={handleSubmit(onAboutMeSubmit)}>
				<AboutMeFormTitle>About Me</AboutMeFormTitle>
				<InputWrapper>
					<Input
						error={!!errors?.firstName}
						{...register("firstName", {
							required: err,
						})}
						labelText="Name"
					/>
					<ErrorWrapper>
						<p style={errors?.firstName && { display: "block" }}>
							{errors?.firstName && `${errors?.firstName?.message}`}
						</p>
					</ErrorWrapper>
				</InputWrapper>
				<InputWrapper>
					<Input
						error={!!errors?.lastName}
						{...register("lastName", {
							required: err,
						})}
						labelText="Surname"
					/>
					<ErrorWrapper>
						<p style={errors?.lastName && { display: "block" }}>
							{errors?.lastName && `${errors?.lastName?.message}`}
						</p>
					</ErrorWrapper>
				</InputWrapper>
				<InputWrapper>
					<CustomSelect
						error={!!errors?.profession}
						placeholder="Select your profession"
						ref={professionField.ref}
						value={professionField.value}
						onChange={professionField.onChange}
						labelText="Profession"
						options={selectOptions}
					/>
					<ErrorWrapper>
						<p style={errors?.profession && { display: "block" }}>
							{errors?.profession && `${errors?.profession?.message}`}
						</p>
					</ErrorWrapper>
				</InputWrapper>
				<InputWrapper style={{ display: "block" }}>
					<Input
						error={!!errors?.siteURL}
						{...register("siteURL", {
							required: err,
						})}
						placeholder="Link"
						labelText="Website"
					/>

					<WrapperComment>Your home page, blog, or company site.</WrapperComment>
					<ErrorWrapper>
						<p style={errors?.siteURL && { display: "block" }}>
							{errors?.siteURL && `${errors?.siteURL?.message}`}
						</p>
					</ErrorWrapper>
				</InputWrapper>
				<InputWrapper>
					<InputWrapper
						style={{
							display: "flex",
							justifyContent: "space-between",
							marginBottom: "0px",
						}}>
						<CustomSelect
							error={!!errors?.countryId}
							placeholder="Select Country"
							value={countryField.value}
							onChange={countryField.onChange}
							labelText="Home Base"
							options={selectCountries}
							maxWidth="276px"
							ref={countryField.ref}
						/>
						<div style={{ width: "100%", marginTop: "auto", marginLeft: "10px" }}>
							{" "}
							<Input
								error={!!errors?.city}
								placeholder="Town"
								maxWidth="276px"
								{...register("city", {
									required: err,
								})}
							/>
						</div>
					</InputWrapper>
					<ErrorWrapper>
						<p style={errors?.city && { display: "block" }}>
							{errors?.city && `${errors?.city?.message}`}
						</p>
					</ErrorWrapper>
				</InputWrapper>
				<InputWrapper>
					<Input
						error={!!errors?.title}
						{...register("title", {
							required: err,
						})}
						value={titleValue}
						onChange={(e) => setTitleValue(e.target.value)}
						placeholder="Ex. Aspring Fashion Photographer"
						labelText="Short Description / Title"
					/>
					<ErrorWrapper>
						<p style={errors?.title && { display: "block" }}>
							{errors?.title && `${errors?.title?.message}`}
						</p>
					</ErrorWrapper>
					{(titleValue ? titleValue?.length : 0) < 100 ? (
						<WordCount>{titleValue?.length ? titleValue?.length : 0}</WordCount>
					) : (
						<WordCountRed>-{titleValue?.length ? titleValue?.length - 100 : 0}</WordCountRed>
					)}
				</InputWrapper>
				<InputWrapper style={{ display: "block" }}>
					<TextArea
						error={!!errors?.about}
						{...register("about", {
							required: err,
						})}
						value={descValue}
						onChange={(e) => setDescValue(e.target.value)}
						placeholder="Ex. I am based in … working since… "
						labelText="Description / About me"
					/>
					{(descValue ? descValue?.length : 0) < 500 ? (
						<WordCount>{descValue ? descValue?.length : 0}</WordCount>
					) : (
						<WordCountRed>-{descValue ? descValue?.length - 100 : 0}</WordCountRed>
					)}
					<WrapperComment style={{ marginBottom: "0px" }}>
						Brief description for your profile. URLs are hyperlinked.
					</WrapperComment>
					<ErrorWrapper>
						<p style={errors?.about && { display: "block" }}>
							{errors?.about && `${errors?.about?.message}`}
						</p>
					</ErrorWrapper>
				</InputWrapper>
				<ButtonWrapper>
					<Button type="submit">Update</Button>
				</ButtonWrapper>
			</AboutMeForm>

			<SocialLinksForm onUpdateInfo={onUpdateInfo} />

			<SkillsForm onUpdateInfo={onUpdateInfo} />

			<AnchorsLinks>
				<AnchorsLinksList>
					<li>
						<Link
							to="about"
							spy={true}
							offset={-90}
							duration={500}
							smooth={true}
							activeClass="active">
							About Me
						</Link>
					</li>
					<li>
						<Link
							to="socialLinks"
							spy={true}
							offset={-90}
							duration={500}
							smooth={true}
							activeClass="active">
							Social Links
						</Link>
					</li>
					<li>
						<Link
							to="skills"
							activeClass="active"
							spy={true}
							offset={-90}
							duration={500}
							smooth={true}>
							Skills
						</Link>
					</li>
				</AnchorsLinksList>
			</AnchorsLinks>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	max-width: 560px;
	width: 100%;
	margin: 0 auto;
	text-align: center;
`;

const Title = styled.h2`
	margin-bottom: 40px;
	font-weight: 700;
	font-size: 64px;
	line-height: 80px;
`;
const AvatarWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 120px;
	height: 120px;
	border-radius: 50%;
	transition: 0.2s;
	position: relative;
	overflow: hidden;
	& > img {
		transition: 0.2s;
		width: 120px;
		height: 120px;
		object-fit: cover;
	}
`;

const AvatarBorder = styled.div<any>`
	width: 120px;
	margin: 0 auto;
	height: 120px;
	border-radius: 50%;
	border: 1px solid transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 40px;
	transition: 0.2s;
	${({ noAvatar }) => {
		if (noAvatar) {
			return css`
				& > div {
					background: #000;

					& > img {
						width: 48px;
						height: 48px;
					}
				}
				border: 1px solid #000000;
				& > div {
					width: 108px;
					height: 108px;
				}
				&:hover {
					& > div {
						& > label {
							opacity: 0.7;
						}
						& > img {
							width: 48px;
							height: 48px;
						}
					}
				}
			`;
		} else {
			return css`
				&:hover {
					border: 1px solid #000000;
					& > div {
						& > label {
							opacity: 0.7;
						}
						& > img {
							width: 108px;
							height: 108px;
							border-radius: 50%;
						}
					}
				}
			`;
		}
	}}
`;
const AvatarEdit = styled.label`
	position: absolute;
	bottom: 0px;
	height: 32px;
	left: 6px;
	right: 6px;
	background: #000;
	opacity: 0;
	transition: 0.2s;
	color: #fff;
	border-bottom-left-radius: 40px;
	border-bottom-right-radius: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	& > input {
		opacity: 0;
		visibility: hidden;
		position: absolute;
	}
`;
const AboutMeForm = styled.form`
	text-align: left;
	margin-bottom: 48px;
`;
export const AboutMeFormTitle = styled.h5`
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	margin-bottom: 24px;
`;
export const InputWrapper = styled.div`
	margin-bottom: 16px;
	position: relative;
`;
const WordCount = styled.div`
	position: absolute;
	top: 3px;
	right: 0;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #808080;
`;
const WordCountRed = styled.div`
	position: absolute;
	top: 3px;
	right: 0;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #d41212;
`;
const WrapperComment = styled.div`
	color: #808080;
	margin-top: 8px;
`;
export const ButtonWrapper = styled.div`
	margin-top: 24px;
`;

export const AnchorsLinks = styled.div`
	position: fixed;
	top: 232px;
	left: 24px;
	text-align: left;
`;
export const AnchorsLinksList = styled.ul`
	& > li {
		font-weight: 700;
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 8px;
		cursor: pointer;
		transition: 0.3s;

		& > a {
			color: #808080;
			&:hover {
				color: #000;
				transition: 0.3s;
			}
		}
		& .active {
			color: #000;
		}
	}
`;
