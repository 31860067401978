import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Button } from "../UI";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { uploadNewPhotos } from "../../store/reducers/photo";
import { getStripeStatus } from "../../store/reducers/stripe/action";
import { useNavigate } from "react-router-dom";

interface Props {
	setFilesToUpload: React.Dispatch<React.SetStateAction<any[]>>;
	setUploadImages: React.Dispatch<React.SetStateAction<{ id: number; img: string }[]>>;
	isEditImageOpen: boolean;
	setIsEditImageOpen: React.Dispatch<React.SetStateAction<boolean>>;
	modalOpen: boolean;
	setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UploadImages = ({
	setFilesToUpload,
	setIsEditImageOpen,
	setUploadImages,
	setModalOpen,
}: Props) => {
	const [dragging, setDragging] = useState(false);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	// const onSettingsClick = () => {
	// 	navigate("/creator/profile/settings");
	// 	setModalOpen(false);
	// };

	const onUploadImage = async (e: any) => {
		const files = e.target.files;
		// setFilesToUpload(files);

		const photosArray = [];
		const filesArray = [];

		for (let i = 0; i < files.length; i++) {
			filesArray.push({ id: i, file: files[i] });
			photosArray.push({ id: i, img: URL.createObjectURL(files[i]) });
		}
		setFilesToUpload(filesArray);

		dispatch(uploadNewPhotos(photosArray));
		setModalOpen(false);
		setUploadImages(photosArray);
		setIsEditImageOpen(true);
	};

	const onDropHandler = (e: any) => {
		setDragging(false);
		e.preventDefault();
		let files: any = [];
		const images = e.dataTransfer.files;
		for (let i = 0; i < images.length; i++) {
			if (images[i].type === "image/jpeg" || images[i].type === "image/png") {
				files = [...files, images[i]];
			}
		}
		if (files.length) {
			setFilesToUpload(files);

			const photosArray = [];
			const filesArray = [];

			for (let i = 0; i < files.length; i++) {
				photosArray.push({ id: i, img: URL.createObjectURL(files[i]) });
				filesArray.push({ id: i, file: files[i] });
			}

			dispatch(uploadNewPhotos(photosArray));
			setFilesToUpload(filesArray);
			setModalOpen(false);
			setUploadImages(photosArray);
			setIsEditImageOpen(true);
		}
	};

	return (
		<Wrapper>
			{/* {isStripeConnected && isFillBillinForm ?  */}
			{dragging ? (
				<UploadImagesContainer dragging>
					<UploadImagesTitle style={{ marginTop: "0" }}>Now let it go</UploadImagesTitle>
					<DraggingInput
						onDragStart={(e: any) => {
							e.preventDefault();
							setDragging(true);
						}}
						onDragLeave={(e: any) => {
							e.preventDefault();
							setDragging(false);
						}}
						onDragOver={(e: any) => {
							e.preventDefault();
							setDragging(true);
						}}
						onDrop={(e: any) => onDropHandler(e)}
						type="file"
						accept=".png, .jpg"
					/>
				</UploadImagesContainer>
			) : (
				<>
					<UploadImagesContainer dragging={dragging}>
						<UploadImagesTitle>
							Just drop your images here
							<br />
							<span>(JPG or PNG)</span>
						</UploadImagesTitle>
						<DraggingInput
							accept=".png, .jpg"
							onDragStart={(e: any) => {
								e.preventDefault();
								setDragging(true);
							}}
							onDragLeave={(e: any) => {
								e.preventDefault();
								setDragging(false);
							}}
							onDragOver={(e: any) => {
								e.preventDefault();
								setDragging(true);
							}}
							type="file"
						/>

						<ButtonWrapper>
							<Button>
								<AvatarEdit>
									<input
										accept=".png, .jpg"
										onChange={(e) => onUploadImage(e)}
										type="file"
										multiple
									/>
								</AvatarEdit>
								Select from Device
							</Button>
						</ButtonWrapper>
					</UploadImagesContainer>
				</>
			)}
			{/* : (
				<NotConnectedWrapper>
					Please connect your stripe account <br /> end fill the marketplace form <br />
					<div onClick={onSettingsClick}>Settings</div>
				</NotConnectedWrapper>
			)} */}
		</Wrapper>
	);
};
const Wrapper = styled.div`
	padding: 64px;
	height: 100%;
	width: 100vw;
	height: 100vh;
`;
const ButtonWrapper = styled.div`
	margin: auto 0px 24px;
`;
const UploadImagesContainer = styled.div<any>`
	height: 100%;
	background: #f7f7f7;
	transition: border 0.2s;
	${({ dragging }) => {
		if (dragging) {
			return css`
				border: 2px dashed #8562ce;
			`;
		} else {
			return css`
				border: 2px dashed #d7d7d7;
			`;
		}
	}}
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
`;
const UploadImagesTitle = styled.div`
	font-weight: 700;
	font-size: 80px;
	line-height: 80px;
	text-align: center;
	color: #8562ce;
	margin-top: 30vh;
	& > span {
		font-size: 40px;
		line-height: 40px;
	}
`;
const AvatarEdit = styled.label`
	position: absolute;
	bottom: 0px;
	height: 44px;
	left: 0px;
	right: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	& > input {
		opacity: 0;
		visibility: hidden;
		position: absolute;
	}
`;

const DraggingInput = styled.input`
	position: absolute;
	width: 100%;
	background: transparent;
	height: 100%;
	opacity: 0;
`;

const NotConnectedWrapper = styled.div`
	height: 100%;
	background: #f7f7f7;
	transition: border 0.2s;

	border: 2px dashed #d7d7d7;

	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
	font-size: 40px;
	line-height: 50px;
	text-align: center;
	& > div {
		color: rgb(133, 98, 206);
		cursor: pointer;
		transition: all.2s;
		&:hover {
			color: rgb(135 116 174);
			transition: all.2s;
		}
	}
`;
