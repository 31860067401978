import { Route, Routes } from "react-router-dom";
import { useState } from "react";

import {
	Home,
	CreatorProfile,
	SignInPage,
	CreatorSignUp,
	BuyerSignUp,
	NotFoundPage,
} from "../pages";
import { RequireAuth } from "../routes/RequireAuth";
import { AuthRoles } from "../models/IProfile";
import { ConnectStripe } from "../components/ConnectStripe";

const AppRouter = () => {
	const [searchValue, setSearchValue] = useState("");

	return (
		<Routes>
			{/* public routes */}

			<Route path="/connect/stripe" element={<ConnectStripe />} />
			<Route path="/signIn" element={<SignInPage />} />
			<Route path="/creator/signup" element={<CreatorSignUp />} />
			<Route path="/buyer/signup" element={<BuyerSignUp />} />
			<Route path="/login" element={<SignInPage />} />

			{/* private routes */}

			<Route element={<RequireAuth anonymous />}>
				<Route
					path="/*"
					element={<Home searchValue={searchValue} setSearchValue={setSearchValue} />}
				/>
			</Route>

			<Route element={<RequireAuth allowedRoles={[AuthRoles.Photographer, AuthRoles.Artbuyer]} />}>
				<Route
					path="/creator/profile/*"
					element={<CreatorProfile searchValue={searchValue} setSearchValue={setSearchValue} />}
				/>
			</Route>

			{/* notFound */}
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
};

export default AppRouter;
