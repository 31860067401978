import styled, { css } from "styled-components";
import { IImageRequest } from "../../models/IImageRequest";
import { Button, Input } from "../UI";
import { useAppDispatch } from "../../hooks/redux";
import {
	artbuyerCancelRequest,
	artbuyerConfirmCounterOffer,
	artbuyerRejectCounterOffer,
	getRequestDetails,
	postCounterOffer,
} from "../../store/reducers/imageRequest/action";
import { useEffect, useState } from "react";
import { rejectRequest } from "../../store/reducers/photographerImageRequest/action";

interface Props {
	element: IImageRequest;
	getRequests?: () => void;
	onClose?: () => void;
}

export const getColor = (status: string) => {
	switch (status) {
		case "pending":
		case "Accepted":
			return "#62C78F";

		case "Cancelled by buyer":
		case "Declined by you":
		case "Declined by photographer":
			return "#EE4747";

		default:
			return "#FFAA06";
	}
};
const getYourStatus = (status: string) => {
	switch (status) {
		case "NEW":
			return "pending";
		case "REJECTED":
			return "Declined by you";
		case "CONFIRMED":
			return "Accepted";
		case "COUNTER_OFFER_PHOTOGRAPHER":
			return "Declined by photographer";
		case "COUNTER_OFFER_BUYER":
			return "Awaiting feedback";
		case "COUNTER_OFFER_ACCEPTED":
			return "Accepted";
		case "COUNTER_OFFER_REJECTED":
			return "Declined by photographer";
		case "CANCELLED_BY_BUYER":
			return "Cancelled by buyer";
	}
};
const getPhotographerStatus = (status: string) => {
	switch (status) {
		case "NEW":
			return "pending";
		case "REJECTED":
			return "Declined by you";
		case "CONFIRMED":
			return "Accepted";
		case "COUNTER_OFFER_PHOTOGRAPHER":
			return "Awaiting feedback";
		case "COUNTER_OFFER_BUYER":
			return "Declined by you";
		case "COUNTER_OFFER_ACCEPTED":
			return "Accepted";
		case "COUNTER_OFFER_REJECTED":
			return "Declined by you";
		case "CANCELLED_BY_BUYER":
			return "Cancelled by buyer";
	}
};

export const PriceRequestAbout = ({ element, onClose, getRequests }: Props) => {
	const [requestInfo, setRequestInfo] = useState<any>();
	const [isCounterOffer, setIsCounterOffer] = useState(false);
	const [price, setPrice] = useState("");
	const [message, setMessage] = useState("");

	const dispatch = useAppDispatch();

	useEffect(() => {
		const getInfo = async () => {
			const { payload } = await dispatch(getRequestDetails(element.id));
			setRequestInfo(payload);
		};
		getInfo();
	}, []);

	const onCancelRequest = async () => {
		await dispatch(artbuyerCancelRequest(element.id));
		getRequests?.();
		onModalClose();
	};

	const onModalClose = () => {
		document.body.style.overflow = "auto";
		onClose?.();
	};

	const onConfirmOffer = async () => {
		await dispatch(artbuyerConfirmCounterOffer(element.id));
		getRequests?.();
		onModalClose();
	};
	const onRejectCounterOffer = async () => {
		await dispatch(artbuyerRejectCounterOffer(element.id));
		getRequests?.();
		onModalClose();
	};

	const onRejectRequest = async () => {
		await dispatch(artbuyerRejectCounterOffer(element.id));
		getRequests?.();
		onModalClose();
	};
	const onSendOffer = async () => {
		const json = {
			requestId: element.id,
			usage: element.usage,
			territory: element.territory,
			duration: element.duration,
			price: +price,
			currency: "currency",
			message: message,
		};
		if (price) {
			await dispatch(postCounterOffer(JSON.stringify(json)));
			onModalClose();
		}
		getRequests?.();
	};

	return (
		<Wrapper>
			<Title>Price Request</Title>
			<WrapperInner
				style={
					element.status === "CONFIRMED" || element.status === "COUNTER_OFFER_ACCEPTED"
						? { flexDirection: "row-reverse" }
						: { flexDirection: "row" }
				}>
				<WrapperContent>
					<WrapperContentItem>
						<ImageAbout>
							<ImageAboutItem>
								<h4>Usage</h4>
								<p>Cover for a magazine</p>
							</ImageAboutItem>
							<ImageAboutItem>
								<h4>Territory</h4>
								<p>Germany</p>
							</ImageAboutItem>
							<ImageAboutItem>
								<h4>Price</h4>
								<p>
									{element.currency === "USD" ? "$" : "€"} {element.price}.00
								</p>
							</ImageAboutItem>
							<ImageAboutItem>
								<h4>Duration</h4>
								<p>1 year</p>
							</ImageAboutItem>
						</ImageAbout>
					</WrapperContentItem>
					{((element.status === "CONFIRMED" || element.status === "COUNTER_OFFER_ACCEPTED") && (
						<>
							<ConfirmedWrapper>
								<p>
									You have accepted photographer’s offer. Following contact details have been sent,
									so the photographer can contact you and finalize the sale
								</p>
								<ImageAbout>
									<ImageAboutItem>
										<h4>Name</h4>
										<p>{requestInfo?.name}</p>
									</ImageAboutItem>
									<ImageAboutItem>
										<h4>Name</h4>
										<p>{requestInfo?.phone}</p>
									</ImageAboutItem>
									<ImageAboutItem>
										<h4>Name</h4>
										<p>{requestInfo?.email}</p>
									</ImageAboutItem>
								</ImageAbout>
							</ConfirmedWrapper>
							{element.status !== "CONFIRMED" && (
								<PhotographerOffer>
									<div
										style={{
											display: "flex",
											alignItems: "flex-start",
											justifyContent: "space-between",
										}}>
										<Subtitle>Photographer’s Offer</Subtitle>
										<ItemStatus
											style={{ color: getColor(getPhotographerStatus(element.status) || "") }}>
											{getPhotographerStatus(element.status)}
										</ItemStatus>
									</div>
									<ImageAbout>
										<ImageAboutItem>
											<h4>Price</h4>
											<p>
												{element.currency === "USD" ? "$" : "€"} {element.pricePhotographer}.00
											</p>
										</ImageAboutItem>
										<ImageAboutItem>
											<h4>Territory</h4>
											<p>Germany</p>
										</ImageAboutItem>
										<ImageAboutItem>
											<h4>Model Release</h4>
											<p>Possible</p>
										</ImageAboutItem>
										<ImageAboutItem>
											<h4>Duration</h4>
											<p>1 year</p>
										</ImageAboutItem>
										{requestInfo?.message && (
											<ImageAboutItem>
												<h4>Message</h4>
												<p>{requestInfo?.message}</p>
											</ImageAboutItem>
										)}
									</ImageAbout>
								</PhotographerOffer>
							)}
						</>
					)) ||
						(element.status !== "NEW" && (
							<>
								<PhotographerOffer>
									<div
										style={{
											display: "flex",
											alignItems: "flex-start",
											justifyContent: "space-between",
										}}>
										<Subtitle>Photographer’s Offer</Subtitle>
										<ItemStatus
											style={{ color: getColor(getPhotographerStatus(element.status) || "") }}>
											{getPhotographerStatus(element.status)}
										</ItemStatus>
									</div>
									<ImageAbout>
										<ImageAboutItem>
											<h4>Price</h4>
											<p>
												{element.currency === "USD" ? "$" : "€"} {element.pricePhotographer}.00
											</p>
										</ImageAboutItem>
										<ImageAboutItem>
											<h4>Territory</h4>
											<p>Germany</p>
										</ImageAboutItem>
										<ImageAboutItem>
											<h4>Model Release</h4>
											<p>Possible</p>
										</ImageAboutItem>
										<ImageAboutItem>
											<h4>Duration</h4>
											<p>1 year</p>
										</ImageAboutItem>
										{requestInfo?.message && (
											<ImageAboutItem>
												<h4>Message</h4>
												<p>{requestInfo?.message}</p>
											</ImageAboutItem>
										)}
									</ImageAbout>
									{element.status !== "CONFIRMED" &&
										element.status !== "COUNTER_OFFER_BUYER" &&
										element.status !== "REJECTED" &&
										element.status !== "CONFIRMED" &&
										element.status !== "COUNTER_OFFER_ACCEPTED" &&
										element.status !== "CANCELLED_BY_BUYER" &&
										element.status !== "COUNTER_OFFER_REJECTED" &&
										(isCounterOffer ? (
											<CounterOffer>
												<Subtitle style={{ marginBottom: "4px" }}>Your counter offer</Subtitle>
												<CounterOfferText>
													Please enter your price below to send a counter offer to the buyer.
												</CounterOfferText>
												<CounterOfferForm
													onSubmit={(e) => {
														onSendOffer();
														e.preventDefault();
													}}>
													<PriceInput currency={element.currency === "USD"}>
														<Input
															value={price}
															onChange={(e) => setPrice(e.target.value)}
															type="number"
															padding="12px 34px 12px 28px"
															maxWidth={"208px"}
														/>
													</PriceInput>
													<Input
														value={message}
														onChange={(e) => setMessage(e.target.value)}
														placeholder="Message"
													/>
													<PhotographerOfferButtons>
														<Button type="submit" margin="0px 8px 0px 0px">
															Send Offer
														</Button>
														<Button
															onClick={onRejectRequest}
															margin="0px 8px 0px 0px"
															theme="noline">
															Reject Request
														</Button>
													</PhotographerOfferButtons>
												</CounterOfferForm>
											</CounterOffer>
										) : (
											<PhotographerOfferButtons>
												<Button onClick={onConfirmOffer} margin="0px 8px 0px 0px">
													Accept Offer
												</Button>

												<Button onClick={() => setIsCounterOffer(true)} theme="outline">
													Counter Offer
												</Button>

												<Button
													onClick={onRejectCounterOffer}
													margin="0px 8px 0px 0px"
													theme="noline">
													Cancel Request
												</Button>
											</PhotographerOfferButtons>
										))}
								</PhotographerOffer>
								{element.status !== "" && (
									<YourOffer>
										<div
											style={{
												display: "flex",
												alignItems: "flex-start",
												justifyContent: "space-between",
											}}>
											<Subtitle>Your Counter Offer</Subtitle>
											<ItemStatus style={{ color: getColor(getYourStatus(element.status) || "") }}>
												{element.status === "REJECTED"
													? "Declined by photographer"
													: getYourStatus(element.status)}
											</ItemStatus>
										</div>
										<ImageAbout>
											<ImageAboutItem>
												<h4>Price</h4>
												<p>
													{element.currency === "USD" ? "$" : "€"}{" "}
													{element.priceBuyer ? element.priceBuyer : element.price}.00
												</p>
											</ImageAboutItem>
											<ImageAboutItem>
												<h4>Territory</h4>
												<p>{requestInfo?.territory}</p>
											</ImageAboutItem>
											<ImageAboutItem>
												<h4>Model Release</h4>
												<p>{requestInfo?.releaseAvailable}</p>
											</ImageAboutItem>
											<ImageAboutItem>
												<h4>Duration</h4>
												<p>{requestInfo?.duration}</p>
											</ImageAboutItem>
											{requestInfo?.messageBuyer && (
												<ImageAboutItem>
													<h4>Message</h4>
													<p>{requestInfo?.messageBuyer}</p>
												</ImageAboutItem>
											)}
										</ImageAbout>
									</YourOffer>
								)}
							</>
						))}
				</WrapperContent>
				<ImageInner>
					<img
						src={`${process.env.REACT_APP_DOMEN}${element.photo.imageDetails.LIGHTBOX.url}`}
						alt="reqImg"
					/>
				</ImageInner>
			</WrapperInner>
			{element.status === "NEW" && (
				<div style={{ marginTop: "10px", marginBottom: "-20px" }}>
					<Button onClick={onCancelRequest} theme="outline">
						Cancel Request
					</Button>
				</div>
			)}
		</Wrapper>
	);
};

const CounterOffer = styled.div`
	margin-top: 24px;
`;
const CounterOfferText = styled.div`
	font-size: 12px;
	line-height: 16px;
	margin-bottom: 16px;
`;
const PriceInput = styled.div<any>`
	margin-bottom: 8px;
	width: 208px;
	position: relative;
	&::after {
		position: absolute;
		${({ currency }) => {
			if (currency) {
				return css`
					content: "$";
				`;
			} else {
				return css`
					content: "€";
				`;
			}
		}}
		top: 14px;
		left: 16px;
		font-size: 14px;
		line-height: 20px;
		font-weight: 600;
	}
`;
const CounterOfferForm = styled.form``;
const ConfirmedWrapper = styled.div`
	background: #fff;
	padding: 24px;
	border-radius: 8px;
	border: 1px solid #62c78f;
	margin-bottom: 4px;
	& > p {
		margin-bottom: 24px;
	}
`;
const Wrapper = styled.div`
	padding: 40px;
	background: #f7f7f7;
	border-radius: 24px;
	width: 840px;
	max-height: 93vh;
	overflow-y: auto;
`;
const Title = styled.div`
	margin-bottom: 16px;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
`;
const WrapperContent = styled.div`
	width: 540px;
`;
const WrapperInner = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: row-reverse;
`;
const ImageInner = styled.div`
	width: 180px;
	& > img {
		width: 180px;
	}
`;
const WrapperContentItem = styled.div`
	background: #fff;
	padding: 24px;
	border-radius: 8px;
	margin-bottom: 4px;
`;
const ImageAbout = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 16px;
`;
const ImageAboutItem = styled.div`
	& > h4 {
		font-weight: 600;
		font-size: 12px;
		line-height: 18px;
		margin-bottom: 4px;
	}
`;
const PhotographerOffer = styled.div`
	background: #fff;
	padding: 24px;
	border-radius: 8px;
`;
const PhotographerOfferButtons = styled.div`
	display: flex;
	margin-top: 24px;
`;
const Subtitle = styled.div`
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 24px;
`;
const ItemStatus = styled.div`
	color: #ee4747;
`;
const YourOffer = styled.div`
	background: #fff;
	padding: 24px;
	border-radius: 8px;
	margin-top: 4px;
`;
function onReloadRequests() {
	throw new Error("Function not implemented.");
}
