import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-scroll";

import { AnchorsLinks, AnchorsLinksList } from "../PublicInfo";
import { Button } from "../UI";
import { arrDown, arrUp, connected } from "../../static/images";
import { BasicInformationForm } from "./BasicInformation";
import { PasswordsForm } from "./PasswordsForm";
import { MarketPlaceForm } from "./MarketPlace";
import { NotificationSettings } from "./NotificationSettings";
import { DeleteAccountForm } from "./DeleteAccount";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { RootState } from "../../store";
import { artbuyerProfile, photographerProfile } from "../../store/reducers/profile/action";
import { IsLoading } from "../UI/IsLoading";
import { getUserNotifications } from "../../store/reducers/notifications/action";
import { getBuyerInvoice, getSales, getTotalSales } from "../../store/reducers/totalSales/action";
import { AuthRoles } from "../../models/IProfile";
import {
	connectStripe,
	connectStripeAccount,
	detachStripeAccount,
	getStripeStatus,
} from "../../store/reducers/stripe/action";
import { Sale } from "../../models/ITotalSales";

interface Props {
	onUpdateInfo: () => void;
}

export const ProfileSettings = ({ onUpdateInfo }: Props) => {
	const [isSalesOpen, setIsSalesOpen] = useState(false);

	const { user } = useAppSelector((state) => state.authData);

	const dispatch = useAppDispatch();

	const { photograperTotalSales } = useAppSelector((state: RootState) => state.totalSales);
	const { isLoading } = useAppSelector((state: RootState) => state.profile);

	const { accountId, connected, connectUrl } = useAppSelector((state) => state.stripe);

	const onDisconnectPayment = async () => {
		await dispatch(detachStripeAccount({}));
		await dispatch(getStripeStatus());
	};

	useEffect(() => {
		dispatch(getBuyerInvoice(165));
		if (user?.role === AuthRoles.Photographer) {
			dispatch(photographerProfile());
			dispatch(getUserNotifications());
			dispatch(getSales());
			dispatch(getStripeStatus());
		} else {
			dispatch(artbuyerProfile());
		}
	}, []);

	return isLoading ? (
		<IsLoading />
	) : (
		<Wrapper>
			<Title>Settings</Title>

			<BasicInformationForm onUpdateInfo={onUpdateInfo} />

			<PasswordsForm onUpdateInfo={onUpdateInfo} />

			<ConnectPayment id="connectPayment">
				<SubTitle>Connect Payment Gateway</SubTitle>
				<Text>
					Please connect a payment gateway so we can pay out your sales. We also send you a sales
					statemt with each sale. The comission on online sales is only as low as 15%.
				</Text>
				<h5>Stripe</h5>
				{connected ? (
					<>
						<ConnectedStripe>Connected to {accountId}</ConnectedStripe>
						<Button theme={"secondary"} onClick={onDisconnectPayment}>
							Disconnect
						</Button>
					</>
				) : (
					<>
						<p>Connect your ArtBrief account to your Stripe account</p>
						<ConnectBtn href={connectUrl}>Connect</ConnectBtn>
					</>
				)}
			</ConnectPayment>

			<MarketPlaceForm onUpdateInfo={onUpdateInfo} />

			<NotificationSettings onUpdateInfo={onUpdateInfo} />

			<SalesStatements id="salesStatement">
				<SubTitle>Sales Statements</SubTitle>
				{photograperTotalSales.length ? (
					photograperTotalSales.map((byYear, index) => {
						return (
							<SalesStatementsWrapper key={index} isOpen={isSalesOpen}>
								<SalesStatementsItem
									isOpen={isSalesOpen}
									onClick={() => setIsSalesOpen(!isSalesOpen)}>
									<SalesStatementsArrow src={isSalesOpen ? arrUp : arrDown} />
									<SalesStatementsYear>{byYear.year}</SalesStatementsYear>
									<SalesStatementsCount>{byYear.sales.length} sales</SalesStatementsCount>
								</SalesStatementsItem>
								<SalesStatementsList isOpen={isSalesOpen}>
									{byYear.sales.map((el: any, i: number) => {
										return (
											<SalesStatementsListItem key={i}>
												<SalesStatementsListDate>{el.date}</SalesStatementsListDate>
												<SalesStatementsListPrice>€{el.amount}</SalesStatementsListPrice>
												<SalesStatementsListDownload href={el.invoiceLink}>
													Download Invoice
												</SalesStatementsListDownload>
											</SalesStatementsListItem>
										);
									})}
								</SalesStatementsList>
							</SalesStatementsWrapper>
						);
					})
				) : (
					<Text>Sorry, you don't have Purchases yet</Text>
				)}
			</SalesStatements>

			<DeleteAccountForm />

			<AnchorsLinks>
				<AnchorsLinksList>
					<li>
						<Link
							to="basicInfo"
							spy={true}
							offset={-90}
							duration={500}
							smooth={true}
							activeClass="active">
							Basic Information
						</Link>
					</li>
					<li>
						<Link
							to="password"
							spy={true}
							offset={-90}
							duration={500}
							smooth={true}
							activeClass="active">
							Password
						</Link>
					</li>
					<li>
						<Link
							to="connectPayment"
							activeClass="active"
							spy={true}
							offset={-90}
							duration={500}
							smooth={true}>
							Connect Payment Gateway
						</Link>
					</li>
					<li>
						<Link
							to="marketPlace"
							activeClass="active"
							spy={true}
							offset={-90}
							duration={500}
							smooth={true}>
							Marketplace
						</Link>
					</li>
					<li>
						<Link
							to="notifications"
							activeClass="active"
							spy={true}
							offset={-90}
							duration={500}
							smooth={true}>
							Notification Settings
						</Link>
					</li>
					<li>
						<Link
							to="salesStatement"
							activeClass="active"
							spy={true}
							offset={-90}
							duration={500}
							smooth={true}>
							Sales Statements
						</Link>
					</li>
				</AnchorsLinksList>
			</AnchorsLinks>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	max-width: 560px;
	margin: 0 auto;
`;
const Title = styled.h2`
	font-weight: 700;
	font-size: 64px;
	line-height: 80px;
	margin-bottom: 40px;
	text-align: center;
`;
export const SubTitle = styled.h4`
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	margin-bottom: 8px;
`;
export const Text = styled.div`
	color: #282828;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 24px;
`;

const ConnectPayment = styled.div`
	margin-bottom: 48px;
	& > h5 {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 8px;
	}
	& > p {
		font-size: 14px;
		line-height: 20px;
		color: #282828;
		margin-bottom: 25px;
	}
`;
const ConnectedStripe = styled.div`
	padding: 16px 18px 18px 50px;
	background: #f7f7f7;
	border-radius: 16px;
	height: 50px;
	position: relative;
	margin-bottom: 24px;
	&::after {
		width: 24px;
		height: 24px;
		content: "";
		position: absolute;
		top: 13px;
		left: 16px;
		background-image: url(${connected});
	}
`;
const SalesStatementsWrapper = styled.div<SalesProps>`
	border: 1px solid transparent;
	transition: 0.2s;
	&:hover {
		&:hover {
			${({ isOpen }) => {
				if (isOpen) {
					return css`
						border: 1px solid #ebebeb;
						border-radius: 18px;
						transition: 0.2s;
					`;
				} else {
					return css`
						border: 1px solid transparent;
					`;
				}
			}};
		}
	}
`;
const SalesStatements = styled.div`
	margin-bottom: 48px;
`;
const SalesStatementsItem = styled.div<SalesProps>`
	display: flex;
	align-items: center;
	padding: 16px 16px 16px 8px;
	background: #f7f7f7;
	border-radius: 16px;
	cursor: pointer;
	border: 1px solid #f7f7f7;
	transition: 0.2s;
	&:hover {
		${({ isOpen }) => {
			if (isOpen) {
				return css`
					border: 1px solid transparent;
				`;
			} else {
				return css`
					border: 1px solid #ebebeb;
				`;
			}
		}};
	}
`;
const SalesStatementsList = styled.div<SalesProps>`
	${({ isOpen }) => {
		if (isOpen) {
			return css`
				height: auto;
				opacity: 1;
				& > div {
					height: auto;
					&:not(:last-child) {
						margin-bottom: 16px;
					}
				}
			`;
		} else {
			return css`
				height: 0px;
				opacity: 0;
				padding: 0px !important;
				z-index: -5;
				& > div {
					height: 0px;
					&:not(:last-child) {
						margin-bottom: 0px;
					}
				}
			`;
		}
	}};
	transition: 0.1s;
	padding: 16px 16px 16px 42px;
	background: #f7f7f7;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
	margin-top: -12px;
`;
const SalesStatementsListItem = styled.div`
	display: flex;
	align-items: center;
`;
const SalesStatementsListDate = styled.div`
	color: #282828;
	font-weight: 600px;
`;
const SalesStatementsListPrice = styled.div`
	color: #282828;
	font-weight: 600px;

	margin-left: auto;
`;
const SalesStatementsListDownload = styled.a`
	margin-left: 56px;
	color: #8562ce;
	cursor: pointer;
	transition: 0.2s;

	&:hover {
		color: #7557b5;
	}
`;
const SalesStatementsArrow = styled.img`
	margin-right: 10px;
`;
const SalesStatementsCount = styled.div`
	color: #505050;
	margin-left: auto;
`;
const SalesStatementsYear = styled.div`
	color: #282828;
	font-weight: 600;
`;
const ConnectBtn = styled.a`
	padding: 12px 20px;
	cursor: pointer;
	border: 1px solid #000;
	margin: 0 auto;
	border-radius: 8px;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	position: relative;
	max-height: 50px;
	width: auto;
	background: #000;
	border: 1px solid #000;
	color: #fff;
	&:hover {
		background: rgb(133, 98, 206);
		border: 1px solid $rgb(133, 98, 206);
	}
`;
interface SalesProps {
	isOpen?: boolean;
}
