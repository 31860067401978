import { createAsyncThunk } from "@reduxjs/toolkit";

import { api } from "../../../api/axiosInstance";
import { ILogin, AuthResponse } from "../../../models/AuthData";
import { IProfile } from "../../../models/IProfile";

export const signUp = createAsyncThunk<AuthResponse, IProfile, { rejectValue?: unknown }>(
	"auth/signUp",
	async (profileData, thunkAPI) => {
		try {
			const response = await api({
				method: "POST",
				url: `/auth/signup`,
				data: {
					...profileData,
				},
			});

			return response.data;
		} catch (e: any) {
			console.log(e);

			return thunkAPI.rejectWithValue(e);
		}
	}
);

export const login = createAsyncThunk<AuthResponse, ILogin, { rejectValue?: unknown }>(
	"auth/login",
	async (profileData, thunkAPI) => {
		try {
			const response = await api({
				method: "POST",
				url: `/auth/login`,
				data: {
					...profileData,
				},
			});

			return response.data;
		} catch (e: any) {
			console.log(e);

			return thunkAPI.rejectWithValue(e);
		}
	}
);

export const resetPassword = createAsyncThunk<AuthResponse, ILogin, { rejectValue?: unknown }>(
	"auth/resetPassword",
	async (json, thunkAPI) => {
		try {
			const response = await api({
				method: "POST",
				url: `/auth/reset`,
				data: json,
			});

			return response.data;
		} catch (e: any) {
			console.log(e);

			return thunkAPI.rejectWithValue(e);
		}
	}
);

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
	try {
		return await api<void>({
			method: "GET",
			url: `/auth/logout`,
		});
	} catch (e: any) {
		console.log(e);

		return thunkAPI.rejectWithValue(e);
	}
});

export const userProfile = createAsyncThunk("user/profile", async (_, thunkAPI) => {
	try {
		return await api<void>({
			method: "GET",
			url: `/user/profile`,
		});
	} catch (e: any) {
		console.log(e);
		return thunkAPI.rejectWithValue(e);
	}
});
