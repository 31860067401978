import { useForm } from "react-hook-form";
import styled from "styled-components";
import {
	SignInModal,
	SignInModalForm,
	SignInModalFormLine,
	SignInModalText,
	SignUpTitle,
} from "../Header";
import { Button, ErrorWrapper, Input } from "../UI";
import { useAppDispatch } from "../../hooks/redux";
import { resetPassword } from "../../store/reducers/authData/action";
import { useState } from "react";

interface Props {
	forgotPassword: React.Dispatch<React.SetStateAction<boolean>>;
	setIsLogIn: React.Dispatch<React.SetStateAction<boolean>>;
	setIsSignUp: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ResetPasswordModal = ({ setIsLogIn, forgotPassword, setIsSignUp }: Props) => {
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm();
	const [isResetPassword, setIsResetPassword] = useState("");

	const dispatch = useAppDispatch();

	const onSendResetLink = async (data: any) => {
		const { meta } = await dispatch(resetPassword(data));
		if (meta.requestStatus === "fulfilled") {
			setIsResetPassword(data.email);
		}
	};

	const onSignIn = () => {
		setIsLogIn(true);
		forgotPassword(false);
	};

	const onSignUp = () => {
		setIsSignUp(true);
		setIsLogIn(false);
		forgotPassword(false);
	};
	return (
		<SignInModal>
			<SignUpTitle>Reset password</SignUpTitle>
			{isResetPassword ? (
				<ResetText>A link to reset your password has been sent to {isResetPassword}</ResetText>
			) : (
				<>
					<ModalText>
						Enter the e-mail associated with your account, and we’ll email you a link to reset your
						password
					</ModalText>
					<SignInModalForm>
						<Input
							{...register("email")}
							autoFocus={true}
							type={"email"}
							placeholder="E-mail"
							maxWidth="396px"
						/>
						<ErrorWrapper>
							<p style={errors?.email && { display: "block" }}>
								{errors?.email && `${errors?.email?.message}`}
							</p>
						</ErrorWrapper>
						<SignInModalFormLine />
						<Button onClick={handleSubmit(onSendResetLink)} width="100%">
							Log In
						</Button>
					</SignInModalForm>
					<SignInModalText style={{ margin: "24px 0 8px" }}>
						Remembered password? Sign in{" "}
						<a onClick={onSignIn} href="#">
							here.
						</a>
					</SignInModalText>
					<SignInModalText>
						Don’t have an account yet? Sign up{" "}
						<a onClick={onSignUp} href="#">
							here.
						</a>
					</SignInModalText>
				</>
			)}
		</SignInModal>
	);
};

const ModalText = styled.div`
	color: #282828;
	margin-bottom: 24px;
	max-width: 396px;
	text-align: center;
	margin-top: -32px;
`;
const ResetText = styled.div`
	margin-top: -18px;
	font-size: 16px;
	color: #282828;
	margin-bottom: 24px;
	max-width: 396px;
	text-align: center;
`;
