import React from "react";
import styled from "styled-components";

interface Props {
	isToggled: boolean;
	setIsToggled: React.Dispatch<React.SetStateAction<boolean>>;
	text: string;
}

export const ToggleSwitch = ({ isToggled, setIsToggled, text }: Props) => {
	const onToggle = () => setIsToggled(!isToggled);
	return (
		<Wrapper>
			<WrapperLabel>
				<Label>
					<Input type="checkbox" checked={isToggled} onChange={onToggle} />
					<SwitchSpan className="switch" />
				</Label>
				<WrapperText>{text}</WrapperText>
			</WrapperLabel>
		</Wrapper>
	);
};
const Wrapper = styled.div`
	display: flex;
	align-items: center;
`;
const WrapperText = styled.div`
	margin-left: 10px;
`;
const Label = styled.label`
	position: relative;
	display: inline-block;
	width: 32px;
	height: 20px;
`;
const WrapperLabel = styled.label`
	display: flex;
	cursor: pointer;
`;
const Input = styled.input`
	display: none;
	&:checked + .switch::before {
		transform: translateX(12px);
		background-color: #fff;
	}
	:checked + .switch {
		background-color: #8562ce;
	}
`;
const SwitchSpan = styled.span`
	position: absolute;
	cursor: pointer;
	background-color: #d7d7d7;
	border-radius: 25px;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: background-color 0.2s ease;
	&::before {
		position: absolute;
		content: "";
		left: 2px;
		top: 2px;
		width: 16px;
		height: 16px;
		background-color: #fff;
		border-radius: 50%;
		transition: transform 0.3s ease;
	}
`;
