import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Button, Checkbox, DeleteTag, ErrorWrapper, Input, RadioBtns } from "../UI";
import { useClickOutside } from "../../hooks/useClickOutside";
import { splitArrayToGalleryFormat } from "../../utils";
import { isSelectedSvg } from "../../static/images";
import { INewGalleryItem } from "../../models/IGallery";
import {
	getPhotographerPhotos,
	getSuggestedTags,
	postPhotos,
	updatePhotographerPhoto,
} from "../../store/reducers/photographerGallery/action";
import { Briefing } from "../../models/IBrifing";
import { suggestPhotos } from "../../store/reducers/photographerJobRequests/action";
import { onPricing, releaseAvailable } from "../../utils/getSmth";
import { LoadingButton } from "../UI/LoadingButton";
import { TimeStamp } from "../../utils/variables";
import { getStripeStatus } from "../../store/reducers/stripe/action";
import { clearUploadedData } from "../../store/reducers/photographerGallery";

interface Props {
	setIsEditImagesInfo: React.Dispatch<React.SetStateAction<boolean>>;
	files?: any[];
	images?: Item[];
	setSelectedImagesToEdit?: React.Dispatch<React.SetStateAction<INewGalleryItem[]>>;
	isEditImages?: boolean;
	imagesToEdit?: INewGalleryItem[];
	isUploadSubmittedImages?: boolean;
	briefing?: Briefing;
	getSuggestions?: () => void;
	setUploadImages?: React.Dispatch<React.SetStateAction<{ id: number; img: string }[]>>;
	setIsArchive?: React.Dispatch<React.SetStateAction<boolean>>;
	onCloseEditImages?: React.Dispatch<React.SetStateAction<boolean>>;
	onSubmit?: () => void;
}
export interface Item {
	img: string;
	id: number;
}

export const EditUploadedImages = ({
	setIsEditImagesInfo,
	setSelectedImagesToEdit,
	images,
	isEditImages = false,
	files,
	imagesToEdit,
	briefing,
	isUploadSubmittedImages = false,
	getSuggestions,
	setUploadImages,
	setIsArchive,
	onCloseEditImages,
	onSubmit,
}: Props) => {
	const [radioInfo, setRadioInfo] = useState("High-Res");
	const [pricing, setPricing] = useState("Upon Request");
	const [modelRelease, setModelRelease] = useState("Possible");
	const [addTagValue, setAddTagValue] = useState("");

	const [isAvailableToUpload, setIsAvailableToUpload] = useState(false);

	const [isStripeConnected, setIsStripeConntected] = useState(false);
	const [isFillBillinForm, setIsFullBillingForm] = useState(false);

	const [currentTags, setCurrentTags] = useState<{ item: string; id: number }[]>([]);

	const [isTagsInputOpen, setIsTagsInputOpen] = useState(false);

	const [selectedImages, setSelectedImages] = useState<Item[]>([]);
	const [selectedToEditImages, setSelectedToEditImages] = useState<INewGalleryItem[]>([]);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { isImagesUploading, ImageTags, isTagsUploading } = useAppSelector(
		(state) => state.gallery
	);

	function extractTagsFromArray(dataArray: INewGalleryItem[] | undefined) {
		if (dataArray?.length === 0) {
			return [];
		}

		const firstItemTags = dataArray?.[0].tags || [];
		const commonTags = [];

		for (const tag of firstItemTags) {
			let a = 0;
			if (dataArray?.every((item) => item.tags.includes(tag))) {
				commonTags.push({ item: tag, id: a });
				a++;
			}
		}

		setCurrentTags(commonTags);
	}

	useEffect(() => {
		if (isEditImages) {
			extractTagsFromArray(imagesToEdit);

			imagesToEdit?.forEach((el) => {
				if (selectedToEditImages.includes(el)) {
					return;
				} else {
					setSelectedToEditImages((prev) => [...prev, el]);
				}
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (ImageTags.length && !currentTags.length) {
			setCurrentTags(ImageTags);
		}
	}, [ImageTags, ImageTags.length, currentTags.length]);

	useEffect(() => {
		if (!isEditImages && !isTagsUploading) {
			const { file } = files?.[0];

			let formData = new FormData();

			formData.append("img", file);

			dispatch(getSuggestedTags(formData));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (radioInfo === "Low-Res") {
			setPricing("Upon Request");
		}
	}, [radioInfo]);

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm();

	const photograpgerMarketPlace = useAppSelector((state) => state.profile.photographerMarketPlace);

	useEffect(() => {
		const getStatus = async () => {
			const { payload } = await dispatch(getStripeStatus());
			setIsStripeConntected(payload.connected);
		};

		getStatus();
		setIsFullBillingForm(
			!!photograpgerMarketPlace.billingAddress &&
				!!photograpgerMarketPlace.billingCity &&
				!!photograpgerMarketPlace.billingCompany &&
				!!photograpgerMarketPlace.billingFirstName &&
				!!photograpgerMarketPlace.billingLastName &&
				!!photograpgerMarketPlace.billingZip &&
				!!photograpgerMarketPlace.countryId
		);
	}, []);

	const onSettingsClick = () => {
		navigate("/creator/profile/settings");
		onCloseEditImages?.(false);
		document.body.style.overflow = "auto";
	};

	useEffect(() => {
		setIsAvailableToUpload((!isFillBillinForm || !isStripeConnected) && pricing !== "Upon Request");
	}, [isFillBillinForm, isStripeConnected, pricing]);

	const onDeleteTag = (el: { item: string; id: number }) => {
		const item = currentTags.find((i) => i.id === el.id);
		setCurrentTags((prev) => prev.filter((el) => el.id !== item?.id));

		setIsTagsInputOpen(false);
	};

	const handleKeyDowm = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key == "Enter") {
			setCurrentTags((prev) => [...prev, { item: addTagValue, id: currentTags.length }]);
			setAddTagValue("");
		}
	};
	const clickRef = useRef<any>();

	const onClickOutsideTags = () => {
		setIsTagsInputOpen(false);
		setAddTagValue("");
	};
	useClickOutside(clickRef, onClickOutsideTags);

	const splitedImages = splitArrayToGalleryFormat(images || imagesToEdit);

	const onImageClick = (el: Item) => {
		const item = selectedImages.find((i) => i.id === el.id);

		if (item) {
			setSelectedImages((prev) => [...prev.filter((el) => el.id !== item.id)]);
		} else {
			setSelectedImages((prev) => [...prev, el]);
		}
	};

	const onImageToEditClick = (el: INewGalleryItem) => {
		const item = selectedToEditImages.find((i) => i.photoId === el.photoId);

		if (item) {
			setSelectedToEditImages((prev) => [...prev.filter((el) => el.photoId !== item.photoId)]);
		} else {
			setSelectedToEditImages((prev) => [...prev, el]);
		}
	};
	const onSelectAll = () => {
		if (isEditImages) {
			imagesToEdit?.forEach((el) => {
				if (selectedToEditImages.includes(el)) {
					return;
				} else {
					setSelectedToEditImages((prev) => [...prev, el]);
				}
			});
		} else {
			images?.forEach((el) => {
				if (selectedImages.includes(el)) {
					return;
				} else {
					setSelectedImages((prev) => [...prev, el]);
				}
			});
		}
	};

	const onDeselectAll = () => {
		if (isEditImages) {
			imagesToEdit?.forEach((el) => {
				if (selectedToEditImages.includes(el)) {
					setSelectedToEditImages((prev) => [
						...prev.filter((item) => item.photoId !== el.photoId),
					]);
				} else {
					return;
				}
			});
		} else {
			images?.forEach((el) => {
				if (selectedImages.includes(el)) {
					setSelectedImages((prev) => [...prev.filter((item) => item.id !== el.id)]);
				} else {
					return;
				}
			});
		}
	};

	const onUploadSelected = async (data: any) => {
		if (isEditImages) {
			for (let i = 0; i < selectedToEditImages.length; i++) {
				const parameters = {
					title: "test",
					tags: currentTags.map((el) => el.item),
					pricing: onPricing(pricing),
					priceOnline: data.online,
					priceEditorial: data.editorial,
					priceExtended: data.extended,
					originalFile: radioInfo === "High-Res",
					releaseAvailable: releaseAvailable(modelRelease),
					pricesFilled: true,
				};
				const formData = new FormData();

				formData.append("photoId", String(selectedToEditImages[i].photoId));
				formData.append("json", JSON.stringify(parameters));

				await dispatch(
					updatePhotographerPhoto({ formData, photoId: selectedToEditImages[i].photoId })
				);
			}
		} else if (isUploadSubmittedImages) {
			const parameters = {
				briefingId: briefing?.id,
				originalFile: radioInfo === "High-Res",
				priceOnline: data.online,
				priceEditorial: data.editorial,
				priceExtended: data.extended,
				// currency: "string",
				// photoId: 0,
				releaseAvailable: releaseAvailable(modelRelease),
				pricing: onPricing(pricing),
				// cameraModel: "string",
				// maxSize: 0,
				// availableFormats: ["string"],
				// incognito: true,
			};
			const formData = new FormData();

			if (files) {
				for (let i = 0; i < files.length; i++) {
					formData.append("file", files[i].file);
					formData.append("json", JSON.stringify(parameters));
				}
			}

			await dispatch(suggestPhotos(formData));
			getSuggestions?.();
		} else {
			const parameters = {
				title: "test",
				tags: currentTags.map((el) => el.item),
				pricing: onPricing(pricing),
				priceOnline: data.online,
				priceEditorial: data.editorial,
				priceExtended: data.extended,
				originalFile: radioInfo === "High-Res",
				releaseAvailable: releaseAvailable(modelRelease),
				pricesFilled: true,
			};

			const formData = new FormData();

			if (files) {
				for (let i = 0; i < files.length; i++) {
					let element = selectedImages.find((el) => el.id === files[i].id);
					if (element) {
						formData.append("attachment", files[i].file);
						formData.append("json", JSON.stringify(parameters));
					}
				}
			}

			await dispatch(postPhotos(formData));

			const filters = `?tab=MY&page=1&pageSize=2000&timestamp=${TimeStamp}&`;
			await dispatch(getPhotographerPhotos(filters));

			let newImages: any = images;
			for (let i = 0; i < selectedImages.length; i++) {
				newImages = newImages.filter((image: any) => image.id !== selectedImages[i].id);
			}
			setUploadImages?.(newImages);
		}

		if (isEditImages) {
			for (let i = 0; i < selectedToEditImages.length; i++) {
				setSelectedImagesToEdit?.((prev) =>
					prev.filter((el) => el.photoId !== selectedToEditImages[i].photoId)
				);
			}

			if (imagesToEdit && imagesToEdit.length - selectedToEditImages.length === 0) {
				setIsEditImagesInfo(false);
			}
			setSelectedToEditImages([]);
			document.body.style.overflow = "auto";
		} else if (isUploadSubmittedImages) {
			for (let i = 0; i < selectedImages.length; i++) {
				setSelectedImagesToEdit?.((prev) =>
					prev.filter((el) => el.photoId !== selectedImages[i].id)
				);
			}

			if (images && images.length - selectedImages.length === 0) {
				setIsEditImagesInfo(false);
			}
			setSelectedImages([]);
			document.body.style.overflow = "auto";
		} else {
			for (let i = 0; i < selectedImages.length; i++) {
				setSelectedImagesToEdit?.((prev) =>
					prev.filter((el) => el.photoId !== selectedImages[i].id)
				);
			}

			if (images && images.length - selectedImages.length === 0) {
				setIsEditImagesInfo(false);
			}

			setSelectedImages([]);
			navigate("/creator/profile/account");
			setIsArchive?.(true);
			document.body.style.overflow = "auto";
		}

		onSubmit?.();
		setCurrentTags([]);
		dispatch(clearUploadedData());
	};

	return (
		<Wrapper>
			<Content>
				<FileInfo>
					<Subtitle>Editing photo information</Subtitle>

					<EditTags>
						<h3>Edit tags</h3>

						<p>Add multiple keywords separated by enter</p>

						{isTagsUploading && <div>Loading suggested tags...</div>}

						<TagsWrapper ref={clickRef} onClick={() => setIsTagsInputOpen(true)}>
							{currentTags.map((el: { item: string; id: number }, i) => (
								<DeleteTag onDelete={() => onDeleteTag(el)} key={i}>
									{el.item}
								</DeleteTag>
							))}

							{isTagsInputOpen && (
								<TagsWrapperInput
									autoFocus
									value={addTagValue}
									onChange={(e) => setAddTagValue(e.target.value)}
									onKeyDown={(e) => handleKeyDowm(e)}
								/>
							)}
						</TagsWrapper>
					</EditTags>

					<RadioWrapper>
						<h3>Image File</h3>
						<RadioBtns
							value={radioInfo}
							setValue={setRadioInfo}
							options={[
								{
									value: "High-Res",
									text: "",
									// toolTip: <EditorialLicence />
								},
								{
									value: "Low-Res",
									text: "",
									// toolTip: <EditorialLicence />
								},
							]}
							name="ChangeImageFile"
						/>
					</RadioWrapper>
					<RadioWrapper>
						<h3>Pricing</h3>
						<RadioBtns
							value={pricing}
							setValue={setPricing}
							disabled={radioInfo === "Low-Res"}
							options={[
								{
									value: "Rights Ready",
									text: "",
									// toolTip: <EditorialLicence />
								},
								{
									value: "Upon Request",
									text: "",
									// toolTip: <EditorialLicence />
								},
								{
									value: "Custom Rights Ready",
									text: "",
									//  toolTip: <EditorialLicence />
								},
							]}
							name="ChangePricing"
						/>
					</RadioWrapper>
					{pricing === "Custom Rights Ready" && (
						<div style={{ marginBottom: "24px" }}>
							<InputsWrapper>
								<Input
									error={!!errors?.online}
									{...register("online", {
										required: "required",
										min: 20,
									})}
									maxWidth="90px"
									labelText="Online"
									type="number"
								/>
								<Input
									error={!!errors?.editorial}
									{...register("editorial", {
										required: "required",
										min: 20,
									})}
									maxWidth="90px"
									labelText="Editorial"
									type="number"
								/>
								<Input
									error={!!errors?.extended}
									{...register("extended", {
										required: "required",
										min: 20,
									})}
									maxWidth="90px"
									labelText="Extended"
									type="number"
								/>
							</InputsWrapper>
							<ErrorWrapper>
								<p
									style={
										(errors?.online || errors?.editorial || errors?.extended) && {
											display: "block",
										}
									}>
									Minimal price - 20$
								</p>
							</ErrorWrapper>
						</div>
					)}
					<RadioWrapper>
						<h3>Model / Property release avialable</h3>
						<RadioBtns
							value={modelRelease}
							setValue={setModelRelease}
							options={[
								{ value: "Possible", text: "" },
								{ value: "Yes", text: "" },
								{ value: "No", text: "" },
							]}
							name="ModelRelease"
						/>
					</RadioWrapper>
					<div style={{ marginBottom: "24px" }}>
						<Checkbox label="Private" />
					</div>
					<Button
						disabled={
							(selectedImages.length === 0 && selectedToEditImages.length === 0) ||
							isAvailableToUpload
						}
						onClick={handleSubmit(onUploadSelected)}
						width="100%">
						{isEditImages ? "Edit Selected" : "Upload Selected"}
					</Button>
				</FileInfo>

				<SelectImages>
					<ButtonWrapper>
						<Button
							isActive={
								(images && images.length === selectedImages.length) ||
								imagesToEdit?.length === selectedToEditImages.length
							}
							onClick={onSelectAll}
							theme={"outline"}>
							Select All
						</Button>
						<Button
							isActive={!(selectedImages.length > 0) && !(selectedToEditImages.length > 0)}
							onClick={onDeselectAll}
							theme={"outline"}>
							Deselect All
						</Button>
					</ButtonWrapper>
					{isAvailableToUpload ? (
						<StripeError>
							<h3>
								In order to sell photos online, you need first to go to account settings and
								complete these steps:
							</h3>
							<p>1. Fill out your payout/billing information in the marketplace section.</p>
							<p>2. Connect stripe to get paid out instantly.</p>
							<div onClick={onSettingsClick}>Account settings</div>
						</StripeError>
					) : images ? (
						<ImagesWrapper>
							{splitedImages.map((arr, index) => {
								return (
									<ImagesColumn key={index}>
										{arr.map((el: Item, i) => {
											return (
												<ImagesItem onClick={() => onImageClick(el)} key={i}>
													<SelectImage isSelected={selectedImages.includes(el)}></SelectImage>

													<img src={el.img} alt="img" />

													{selectedImages.map((image) => {
														if (image.id === el.id && isImagesUploading) {
															return (
																<div key={el.id}>
																	<LoadingButton />
																</div>
															);
														}

														return null;
													})}
												</ImagesItem>
											);
										})}
									</ImagesColumn>
								);
							})}
						</ImagesWrapper>
					) : (
						<ImagesWrapper>
							{splitedImages.map((arr, index) => {
								return (
									<ImagesColumn key={index}>
										{arr.map((el: INewGalleryItem) => {
											return (
												<ImagesItem onClick={() => onImageToEditClick(el)} key={el.photoId}>
													<SelectImage isSelected={selectedToEditImages.includes(el)}></SelectImage>
													<img
														src={`${process.env.REACT_APP_DOMEN}${el.imageDetails.LIGHTBOX.url}`}
														alt="img"
													/>
												</ImagesItem>
											);
										})}
									</ImagesColumn>
								);
							})}
						</ImagesWrapper>
					)}
				</SelectImages>
			</Content>
		</Wrapper>
	);
};
const SelectImages = styled.div`
	width: calc(100% - 340px);
	background: #f7f7f7;
	height: 100vh;
	padding: 24px;
	position: relative;
`;
const SelectImage = styled.div<any>`
	top: 8px;
	left: 8px;
	position: absolute;
	border-radius: 8px;
	width: 24px;
	height: 24px;
	background: #fff;
	transition: 0.2s;
	${({ isSelected }) => {
		if (isSelected) {
			return css`
				background: none;
				transition: 0.2s;
				background-image: url(${isSelectedSvg});
			`;
		}
	}}
`;
const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;
const ImagesWrapper = styled.div`
	display: flex;
	gap: 2%;
	margin-top: 20px;
	height: 90%;
	overflow-y: auto;
`;
const ImagesColumn = styled.div`
	width: 24%;
`;
const ImagesItem = styled.div`
	& > img {
		width: 100%;
	}
	margin-bottom: 1.2vh;
	cursor: pointer;
	position: relative;
`;
const Subtitle = styled.h3`
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 32px;
`;
const Wrapper = styled.div`
	height: 100vh;
	width: 100vw;
`;
const FileInfo = styled.div`
	width: 360px;
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow-y: auto;
	padding: 24px;
`;
const RadioWrapper = styled.div`
	h3 {
		margin-bottom: 16px;
		font-size: 16px;
		line-height: 24px;
		font-weight: 600;
	}
	margin-bottom: 24px;
`;

const Content = styled.div`
	display: flex;
	justify-content: space-between;
	height: 100%;
`;

const TagsWrapper = styled.div`
	background: #f7f7f7;
	border-radius: 8px;
	min-height: 60px;
	padding: 12px;
	margin-bottom: 24px;
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
`;
const TagsWrapperInput = styled.input`
	border: none;
	background: transparent;
	outline: none;
	padding-left: 6px;
`;
const EditTags = styled.div`
	h3 {
		margin-bottom: 4px;
		font-size: 16px;
		line-height: 24px;
		font-weight: 600;
	}
	p {
		margin-bottom: 8px;
	}
`;
const InputsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;
const StripeError = styled.div`
	margin-top: 50px;
	& > h3 {
		font-size: 25px;
		line-height: 34px;
		color: #b40a0a;
		max-width: 800px;
		margin-bottom: 10px;
	}
	& > p {
		font-size: 18px;
		margin-bottom: 10px;
	}
	& > div {
		text-decoration: underline;
		font-size: 45px;
		line-height: 50px;
		margin-top: 10px;
		color: rgb(133, 98, 206);
		cursor: pointer;
		transition: all.2s;
		&:hover {
			color: rgb(135 116 174);
			transition: all.2s;
		}
	}
`;
