import React, { ReactNode } from "react";
import { Tooltip as Tippy } from "react-tippy";
import "react-tippy/dist/tippy.css";

interface Props {
	children: ReactNode;
	html: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
}

export const Tooltip = ({ children, html }: Props) => {
	return (
		// @ts-ignore
		<Tippy arrow={true} trigger="mouseenter" position="bottom" size="small" html={html}>
			{children}
		</Tippy>
	);
};
