import { createSlice } from "@reduxjs/toolkit";
import { INewGalleryItem } from "../../../models/IGallery";
import { getMyPhotos, getPhotographerPhotos, getSuggestedTags, postPhotos } from "./action";

interface GalleryState {
	Sedcards: INewGalleryItem[];
	Archive: INewGalleryItem[];
	CoverImage: INewGalleryItem | undefined;
	ImageTags: { item: string; id: number }[];
	numFound: number;
	isImagesLoading: boolean;
	isImagesUploading: boolean;
	isTagsUploading: boolean;
}

const initialState: GalleryState = {
	Sedcards: [],
	Archive: [],
	CoverImage: undefined,
	numFound: 0,
	isImagesLoading: false,
	ImageTags: [],
	isImagesUploading: false,
	isTagsUploading: false,
};

export const photographerGallery = createSlice({
	name: "user/photographerGallery",
	initialState,
	reducers: {
		clearUploadedData: (state) => {
			state.ImageTags = [];
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getMyPhotos.fulfilled, (state, action) => {
				state.numFound = action.payload.numFound;
				state.Archive = action.payload.photos;
				state.Sedcards = action.payload.photos.filter((el: INewGalleryItem) => {
					return el.sedcard === true;
				});
			})
			.addCase(postPhotos.fulfilled, (state) => {
				state.isImagesUploading = false;
			})
			.addCase(postPhotos.rejected, (state) => {
				state.isImagesUploading = false;
			})
			.addCase(postPhotos.pending, (state) => {
				state.isImagesUploading = true;
			});

		builder
			.addCase(getSuggestedTags.fulfilled, (state, action) => {
				const tags: { item: string; id: number }[] = action.payload.tags.map((tag) => ({
					item: tag,
					id: Math.random(),
				}));

				state.ImageTags = tags;

				state.isTagsUploading = false;
			})
			.addCase(getSuggestedTags.rejected, (state) => {
				state.isTagsUploading = false;
			})
			.addCase(getSuggestedTags.pending, (state) => {
				state.isTagsUploading = true;
			});
	},
});

export const { reducer: photographerGalleryReducer, actions: photographerGalleryActions } =
	photographerGallery;

export const { clearUploadedData } = photographerGalleryActions;
