import React from "react";
import styled from "styled-components";

export const CookiePolicy = () => {
	return (
		<Wrapper>
			<Title> Cookies Policy</Title>
			<div>
				<script
					id="CookieDeclaration"
					src="https://consent.cookiebot.com/b8b49d5b-3934-4b15-9641-92f9a41f5ef8/cd.js"
					type="text/javascript"
					async></script>
			</div>
		</Wrapper>
	);
};
const Wrapper = styled.div`
	padding-top: 88px;
	font-size: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const Title = styled.h3`
	text-align: center;
	margin-bottom: 14px;
	font-weight: 700;
	font-size: 40px;
	line-height: 48px;
`;
