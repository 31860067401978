import ReactDOM from "react-dom/client";

import { store } from "./store";
import { Provider } from "react-redux";

import { ToastContainer } from "react-toastify";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import App from "./components/App";

import "./styles/index.scss";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<BrowserRouter>
		<Provider store={store}>
			<Routes>
				<Route path="/*" element={<App />} />
			</Routes>
			<ToastContainer />
		</Provider>
	</BrowserRouter>
);
