import React from "react";
import styled from "styled-components";
import { useAppDispatch } from "../../hooks/redux";
import { Button } from "../UI";
import {
	deletePhotographerPhoto,
	getPhotographerPhotos,
} from "../../store/reducers/photographerGallery/action";
import { INewGalleryItem } from "../../models/IGallery";
import { TimeStamp } from "../../utils/variables";

interface Props {
	modalOpen: boolean;
	setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setFileInfoOpen: React.Dispatch<React.SetStateAction<boolean>> | undefined;
	fileToDelete: INewGalleryItem;
}

export const DeleteFile = ({ setModalOpen, setFileInfoOpen, fileToDelete }: Props) => {
	const dispatch = useAppDispatch();

	const onDelete = async () => {
		await dispatch(deletePhotographerPhoto(fileToDelete.photoId));

		const filters = `?tab=MY&page=1&pageSize=300&timestamp=${TimeStamp}&`;

		await dispatch(getPhotographerPhotos(filters));

		setModalOpen(false);
		setFileInfoOpen?.(false);
		document.body.style.overflow = "auto";
	};
	const onReset = () => {
		document.body.style.overflow = "auto";
		setModalOpen(false);
	};

	return (
		<div>
			<RequestModalWrapper>
				<RequestModalTitle>Are you sure , you want to delete this file?</RequestModalTitle>
				<ThanksModalText>You will not be able to return this file</ThanksModalText>
				<ModalButtonWrapper>
					<Button theme="red" type="button" onClick={onDelete} width="100%">
						Yes i`m sure, i want to delete this fiile
					</Button>
				</ModalButtonWrapper>
				<ModalButtonWrapper>
					<Button theme="gray" type="button" onClick={onReset} width="100%">
						No, i dont want to delete this file
					</Button>
				</ModalButtonWrapper>
			</RequestModalWrapper>
		</div>
	);
};

const RequestModalTitle = styled.div`
	margin-bottom: 24px;
	text-align: center;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
`;
const ModalButtonWrapper = styled.div`
	text-align: center;
	width: 100%;
	margin: 24px auto 0px;
`;
const RequestModalWrapper = styled.div`
	width: 480px;
	padding: 5px 8px;
`;
const ThanksModalText = styled.div`
	margin-top: -8px;
	text-align: center;
	margin-bottom: 24px;
`;
