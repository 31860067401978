import { INotifications } from "./../../../models/INotifications";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/axiosInstance";

export const getUserNotifications = createAsyncThunk<
	INotifications,
	void,
	{ rejectValue?: unknown }
>("user/notifications", async (_, thunkAPI) => {
	try {
		const { data } = await api({
			method: "GET",
			url: "/photographer/user/notificationSettings",
		});
		return data;
	} catch (e: any) {
		console.log(e);
		return thunkAPI.rejectWithValue(e);
	}
});
export const postUserNotifications = createAsyncThunk<
	any,
	INotifications,
	{ rejectValue?: unknown }
>("user/notifications", async (data, thunkAPI) => {
	try {
		return await api({
			method: "POST",
			url: "/photographer/user/notificationSettings",
			data: {
				...data,
			},
		});
	} catch (e: any) {
		console.log(e);
		return thunkAPI.rejectWithValue(e);
	}
});
export const subscribeMailing = createAsyncThunk<any, any, { rejectValue?: unknown }>(
	"user/subscribeMailing",
	async (data, thunkAPI) => {
		try {
			return await api({
				method: "POST",
				url: "/mailing/subscribe",
				data: data,
			});
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
