import { Showroom } from "./../../../models/IPhoto";
import { createSlice } from "@reduxjs/toolkit";

import { getFilterPhotos, getPhotos, getUserPhotos } from "./action";
import { Item } from "../../../components/Modals/EditUploadedImages";
import { getPhotographerPhotos } from "../photographerGallery/action";

interface PhotoState {
	showroom?: Showroom;
	isLoading?: boolean;
	error?: string;
	uploadPhotos: Item[];
	showAllByAuthor: Showroom;
}

const initialState: PhotoState = {
	showroom: {
		photos: [],
		numFound: 0,
	},
	showAllByAuthor: {
		photos: [],
		numFound: 0,
	},
	isLoading: false,
	error: undefined,
	uploadPhotos: [],
};
let firstImage:any = {}

export const photoSlice = createSlice({
	name: "photographer/photos",
	initialState,
	reducers: {
		uploadNewPhotos: (state, action) => {
			state.uploadPhotos = action.payload;
		},
		removeNewPhotos: (state, action) => {
			for (let i = 0; i < action.payload.length; i++) {
				const element = action.payload[i];
				state.uploadPhotos = state.uploadPhotos.filter((el: any) => el.id !== element.id);
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getPhotos.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getPhotos.fulfilled, (state, action) => {
				state.isLoading = false;
				if(firstImage?.photoId === action.payload.photos[0].photoId ){
					state.showroom = action.payload
				} else {
					state.showroom = {
						// @ts-ignore
						
						photos: [...state.showroom?.photos, ...action.payload.photos],
						numFound: action.payload.numFound
					}
				}
				firstImage = action.payload.photos[0]

			})
			.addCase(getPhotos.rejected, (state, action: any) => {
				state.isLoading = false;
				state.error = action.payload.message as string;
			})
			.addCase(getFilterPhotos.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getFilterPhotos.fulfilled, (state, action) => {
				state.isLoading = false;
				state.showroom = action.payload
			})
			.addCase(getFilterPhotos.rejected, (state, action: any) => {
				state.isLoading = false;
				state.error = action.payload.message as string;
			})
			.addCase(getPhotographerPhotos.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getPhotographerPhotos.fulfilled, (state, action) => {
				state.isLoading = false;
				state.showroom = action.payload;
			})
			.addCase(getPhotographerPhotos.rejected, (state, action: any) => {
				state.isLoading = false;
				state.error = action.payload.message as string;
			})

			.addCase(getUserPhotos.fulfilled, (state, action) => {
				state.showAllByAuthor = action.payload;
			});
	},
});

export const { reducer: photoReducer, actions: photoActions } = photoSlice;

export const { uploadNewPhotos, removeNewPhotos } = photoActions;
