import { IPhotographerTotalSales } from "../../../models/ITotalSales";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/axiosInstance";

export const getTotalSales = createAsyncThunk<
	IPhotographerTotalSales,
	void,
	{ rejectValue?: unknown }
>("user/totalSales", async (_, thunkAPI) => {
	try {
		return await api({
			method: "GET",
			url: "/photographer/user/totalSales?period=MONTH",
		});
	} catch (e: any) {
		console.log(e);
		return thunkAPI.rejectWithValue(e);
	}
});
export const getBuys = createAsyncThunk<any, void, { rejectValue?: unknown }>(
	"artbuyer/buys",
	async (_, thunkAPI) => {
		try {
			return await api({
				method: "GET",
				url: "/artbuyer/buys",
			});
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const getBuyerInvoice = createAsyncThunk<any, any, { rejectValue?: unknown }>(
	"artbuyer/invoice",
	async (orderId, thunkAPI) => {
		try {
			return await api({
				method: "GET",
				url: `/photographer/invoice/download/${orderId}`,
			});
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const getSales = createAsyncThunk<any, void, { rejectValue?: unknown }>(
	"photographer/sales",
	async (_, thunkAPI) => {
		try {
			return await api({
				method: "GET",
				url: "/photographer/sales",
			});
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const getBuysList = createAsyncThunk<any, void, { rejectValue?: unknown }>(
	"artbuyer/buysList",
	async (_, thunkAPI) => {
		try {
			return await api({
				method: "GET",
				url: "/artbuyer/buys-list",
			});
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
