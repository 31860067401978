import styled from "styled-components";
import { Button } from "../UI";
import { useNavigate } from "react-router-dom";

export const PaymentSended = () => {
	const navigate = useNavigate();

	return (
		<Wrapper>
			<Title>Payment Request Sent!</Title>
			<Text>
				A cart has been created for the client to checkout. You will receive an email as soon as
				your image is payed. The amount will be credited to your connected stripe account.
			</Text>
			<Button onClick={() => navigate("/creator/profile/imageRequests")} width="240px">
				Return to Dashboard
			</Button>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 108px);
`;
const Title = styled.div`
	font-weight: 700;
	font-size: 40px;
	line-height: 48px;
	margin-bottom: 16px;
	color: #282828;
	text-align: center;
`;
const Text = styled.div`
	margin-bottom: 40px;
	font-size: 16px;
	line-height: 24px;
	max-width: 750px;
	text-align: center;
`;
