import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/axiosInstance";

export const getJobRequests = createAsyncThunk<any, string, { rejectValue?: any }>(
	"artbuyer/getJobRequests",
	async (parameters, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `/artbuyer/briefings/list${parameters}`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const getArtbuyerBriefing = createAsyncThunk<any, number, { rejectValue?: any }>(
	"artbuyer/getArtbuyerBriefing",
	async (briefingId, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `/artbuyer/briefings/${briefingId}`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const getAllJobRequests = createAsyncThunk<any, string, { rejectValue?: any }>(
	"artbuyer/getAllJobRequests",
	async (parameters, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `/artbuyer/briefings/list${parameters}`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);

export const getArtbuyerSuggestedImage = createAsyncThunk<any, any, { rejectValue?: any }>(
	"artbuyer/getArtbuyerSuggestedImage",
	async (data, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `/artbuyer/briefings/${data.briefingId}/suggestion/${data.photoId}/details`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const deleteJobRequest = createAsyncThunk<any, number, { rejectValue?: any }>(
	"artbuyer/deleteJobRequest",
	async (id, thunkAPI) => {
		try {
			const response = await api({
				method: "DELETE",
				url: `/artbuyer/briefings/${id}/delete`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const deleteJobRequestImage = createAsyncThunk<any, any, { rejectValue?: any }>(
	"artbuyer/deleteJobRequestImage",
	async (data, thunkAPI) => {
		try {
			const response = await api({
				method: "DELETE",
				url: `/artbuyer/briefings/${data.briefingId}/file/${data.fileId}/delete`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);

export const briefingAddFiles = createAsyncThunk<any, any, { rejectValue?: unknown }>(
	"arbuyer/briefingAddFiles",
	async (data, thunkAPI) => {
		try {
			return await fetch(
				`${process.env.REACT_APP_DOMEN}/api/v1/artbuyer/briefings/${data.id}/addFiles`,
				{
					method: "POST",
					body: data.formData,
					headers: {
						"X-AUTH-TOKEN": `${localStorage.getItem("token")}`,
					},
				}
			).then((response) => response.json());
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const postBriefing = createAsyncThunk<any, any, { rejectValue?: unknown }>(
	"arbuyer/postBriefing",
	async (data, thunkAPI) => {
		try {
			return await fetch(`${process.env.REACT_APP_DOMEN}/api/v1/artbuyer/briefings`, {
				method: "POST",
				body: data,
				headers: {
					"X-AUTH-TOKEN": `${localStorage.getItem("token")}`,
				},
			}).then((response) => response.json());
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const updateBriefing = createAsyncThunk<any, string, { rejectValue?: any }>(
	"artbuyer/updateBriefing",
	async (json, thunkAPI) => {
		try {
			const response = await api({
				method: "POST",
				url: `/artbuyer/briefings/update`,
				data: json,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const commentBriefingPhoto = createAsyncThunk<any, string, { rejectValue?: any }>(
	"artbuyer/commentBriefingPhoto",
	async (json, thunkAPI) => {
		try {
			const response = await api({
				method: "POST",
				url: `/artbuyer/briefings/briefingPhoto/comment`,
				data: json,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
