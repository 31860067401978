import styled from "styled-components";
import { SignUpAbout } from "../../components/SignUpAbout";
import { SignupForm } from "../../components/SignUpForm";

import { top2 } from "../../static/images";

export const CreatorSignUp = () => {
	return (
		<Wrapper>
			<SignUpAbout title="Connect with brands and get discovered" img={top2} />
			<SignupForm isCreatorSignUp={true} />
		</Wrapper>
	);
};

const Wrapper = styled.div`
	font-family: var(--font-family);

	height: 100vh;
	width: 100vw;
	display: flex;
`;
