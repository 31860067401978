import React, { ReactNode } from "react";
import styled from "styled-components";
interface Props {
  children: string | ReactNode;
}

export const ErrorWrapper = ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>;
};
const Wrapper = styled.div`
  & > p {
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px;
    padding-left: 5px;
    color: red;
    display: none;
    margin-bottom: -10px;
    width: 100%;
  }
`;
