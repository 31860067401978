import { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { Photo } from "../models/IPhoto";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { splitArrayToGalleryFormat } from "../utils";
import { FilterForm } from "./FilterForm";
import { Post } from "./Post";

import { FileInfo } from "./Modals/FileInfo";
import { Modal } from "./UI";

import { filterSvg, reset } from "../static/images";
import { SearchOnPage } from "./UI/SearchOnPage";
import { getPhotos } from "../store/reducers/photo";
import { getRoles } from "../utils/getSmth";
import PostSkeleton from "./UI/PostSkeleton";
import { MasonryGallery } from "./UI/MasonryGallery";
import { TimeStamp } from "../utils/variables";
import { AuthRoles } from "../models/IProfile";
import { getPhotographerPhotos } from "../store/reducers/photographerGallery/action";
import { getFilterPhotos } from "../store/reducers/photo/action";
import { useSearchParams } from "react-router-dom";

interface FilterByInt {
	id: number;
	name: string;
}

interface SortByProps {
	isActive: boolean;
}

const filterByArray: FilterByInt[] = [
	{ id: 0, name: "All" },
	{ id: 1, name: "Photographer" },
	{ id: 2, name: "Model" },
	{ id: 3, name: "MakeUp Artist" },
	{ id: 4, name: "Stylist" },
	{ id: 5, name: "Illustrator" },
];

type Page = "Main" | "Account";

interface Props {
	setIsLogInOpen?: React.Dispatch<React.SetStateAction<boolean>>;
	isSearchActive?: boolean;
	page?: Page;
	setIsSearchActive?: React.Dispatch<React.SetStateAction<boolean>>;
	searchValue?: string;
}
export const TrendsPosts = ({ setIsLogInOpen, isSearchActive, setIsSearchActive, page }: Props) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [filterActiveItems, setFilterActiveItems] = useState<number[]>([0]);
	const [isFilterOpen, setIsFilterOpen] = useState(false);
	const [isResetFilters, setIsResetFilters] = useState(false);
	const [photosToShow, setPhotosToShow] = useState<Photo[] | undefined>([]);
	let postsCount = 150;
	let postsPage = 1;

	const [fileInfoOpen, setFileInfoOpen] = useState(false);
	const [postId, setPostId] = useState(0);

	const [searchValue, setSearchValue] = useState("");

	const dispatch = useAppDispatch();

	const { error, showroom, isLoading } = useAppSelector((state) => state.photos);
	const { user } = useAppSelector((state) => state.authData);

	const onSortByClick = (id: number) => {
		if (id === 0) {
			setFilterActiveItems([0]);
		} else {
			setFilterActiveItems((prev) => prev.filter((el) => el !== 0));
		}

		const item = filterActiveItems.find((el) => el === id);

		if (item) {
			setFilterActiveItems((prev) => prev.filter((el) => el !== id));
		} else {
			setFilterActiveItems((prev) => [...prev, id]);
			if (filterByArray.length - 2 === filterActiveItems.length) {
				setFilterActiveItems([0]);
			}
		}
	};

	const onGetPhotos = async (count: number) => {
		const additionalSkills = searchParams.get("additionalSkills");
		const additionalService = searchParams.get("additionalService");
		const assets = searchParams.get("assets");
		const skillLevel = searchParams.get("skillLevel");
		const location = searchParams.get("location");

		const filters = `?page=${count}&pageSize=${postsCount}&timestamp=1579388603000${
			typeof assets === "string" ? `&assets=${assets}` : ""
		}${typeof additionalSkills === "string" ? `&skills=${additionalSkills}` : ""}${
			typeof skillLevel === "string" ? `&skillLevel=${skillLevel}` : ""
		}${typeof additionalService === "string" ? `&services=${additionalService}` : ""}${
			searchValue ? `&search=${searchValue}` : ""
		}${typeof location === "string" ? `&location=${location}` : ""}${
			searchValue ? `&search=${searchValue}` : ""
		}${getRoles(filterActiveItems)}&nonIncognito=true`;

		if (user?.role === AuthRoles.Photographer) {
			await dispatch(getPhotographerPhotos(filters));
		} else if (user?.role === AuthRoles.Artbuyer) {
			await dispatch(getPhotos(filters));
		} else if (!user) {
			await dispatch(getPhotos(filters));
		}
	};

	const onGetFilterPhotos = async (count: number) => {
		const additionalSkills = searchParams.get("additionalSkills");
		const additionalService = searchParams.get("additionalService");
		const assets = searchParams.get("assets");
		const skillLevel = searchParams.get("skillLevel");
		const location = searchParams.get("location");

		const filters = `?page=${count}&pageSize=150&timestamp=${TimeStamp}${
			typeof assets === "string" && assets !== "" ? `&assets=${assets}` : ""
		}${
			typeof additionalSkills === "string" && additionalSkills !== ""
				? `&skills=${additionalSkills}`
				: ""
		}${typeof skillLevel === "string" && skillLevel !== "" ? `&skillLevel=${skillLevel}` : ""}${
			typeof additionalService === "string" && additionalService !== ""
				? `&services=${additionalService}`
				: ""
		}${typeof location === "string" ? `&location=${location}` : ""}${
			searchValue ? `&search=${searchValue}` : ""
		}${searchValue ? `&search=${searchValue}` : ""}${getRoles(
			filterActiveItems
		)}&nonIncognito=true`;

		if (user?.role === AuthRoles.Photographer) {
			await dispatch(getFilterPhotos(filters));
		} else if (user?.role === AuthRoles.Artbuyer) {
			await dispatch(getFilterPhotos(filters));
		} else if (!user) {
			await dispatch(getFilterPhotos(filters));
		}
	};

	useEffect(() => {
		onGetFilterPhotos(1);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterActiveItems, dispatch, searchParams, searchValue]);

	const handleScroll = async (e: any) => {
		if (
			window.innerHeight + e.target.documentElement.scrollTop + 1 >=
				e.target.documentElement.scrollHeight &&
			showroom?.numFound &&
			postsCount < showroom?.numFound &&
			!isLoading
		) {
			postsCount += 150;
			postsPage += 1;
			await onGetPhotos(postsPage);
		}
	};

	useEffect(() => {
		const myLoad = new Array(30);
		if (isLoading) {
			setPhotosToShow((prev: any) => [...prev, ...myLoad]);
		} else {
			setPhotosToShow(showroom?.photos);
		}
	}, [isLoading, showroom]);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showroom?.numFound]);

	const splittedPostArray = useMemo(() => splitArrayToGalleryFormat(photosToShow), [photosToShow]);

	const onOpenInfoModal = () => {
		setFileInfoOpen(true);
	};

	// const onSearchBlur = () => {
	// 	setIsSearchActive?.(false);
	// };

	const onResetFilters = () => {
		setIsResetFilters(!isResetFilters);
		setSearchParams({});
	};

	const findIndexOfImage = (array: Photo[] | undefined, image: Photo) => {
		return array?.indexOf(image);
	};

	return (
		<Trends>
			<ShowSearch isSearchActive={!!isSearchActive}>
				<SearchOnPage
					isActive={isSearchActive}
					// onBlur={onSearchBlur}
					value={searchValue}
					onChange={setSearchValue}
					setIsSearchActive={setIsSearchActive}
					placeholder="Search"
					autofocus
				/>
			</ShowSearch>

			<TrendsTitle>Here are some trending Creators</TrendsTitle>

			<Filters>
				<SortBy>
					{filterByArray.map((el: FilterByInt) => (
						<SortByItem
							isActive={filterActiveItems.includes(el.id)}
							onClick={() => onSortByClick(el.id)}
							key={el.id}>
							{el.name}
						</SortByItem>
					))}
				</SortBy>

				<FilterButtons>
					{searchParams.size > 0 && (
						<ResetFilters onClick={onResetFilters}>
							<img src={reset} alt="reset" />
							Reset Filter
						</ResetFilters>
					)}

					<FilterBtn isActive={isFilterOpen} onClick={() => setIsFilterOpen((prev) => !prev)}>
						{searchParams.size > 0 ? (
							<FilterCount>{searchParams.size}</FilterCount>
						) : (
							<FilterBtnIcon style={{ marginLeft: "-2px" }} src={filterSvg} alt="filter" />
						)}
						Filter
					</FilterBtn>
				</FilterButtons>
			</Filters>

			<FilterForm
				isResetFilters={isResetFilters}
				setIsResetFilters={setIsResetFilters}
				setIsFilterOpen={setIsFilterOpen}
				isFilterOpen={isFilterOpen}
			/>

			<Posts>
				{error || splittedPostArray.length === 0 ? (
					error ? (
						<PostsError>{error}</PostsError>
					) : (
						<div style={{ textAlign: "center", width: "100%" }}>
							<TrendsTitle>Nothing was found</TrendsTitle>
							<p style={{ fontSize: "20px", marginTop: "-10px" }}>
								Try to change filters or you can see other trending creators below
							</p>
						</div>
					)
				) : (
					<MasonryGallery>
						{photosToShow?.map((el: Photo, i: number) => {
							if (el === undefined) {
								return <PostSkeleton key={i} />;
							} else {
								return (
									<Post
										key={i}
										onOpenInfoModal={onOpenInfoModal}
										setPostId={setPostId}
										image={el}
										index={findIndexOfImage(showroom?.photos, el) || 0}
									/>
								);
							}
						})}
					</MasonryGallery>
				)}
			</Posts>

			<Modal size="small" noPadding isOpen={fileInfoOpen} onClose={() => setFileInfoOpen(false)}>
				<FileInfo
					setIsLogInOpen={setIsLogInOpen}
					setFileInfoOpen={setFileInfoOpen}
					//@ts-ignore
					images={showroom?.photos}
					page={page}
					currentImage={postId}
					setCurrentImage={setPostId}
				/>
			</Modal>
		</Trends>
	);
};

const Trends = styled.div`
	max-width: 1136px;
	margin: 0 auto;
`;

const TrendsTitle = styled.h2`
	margin-bottom: 40px;
	font-weight: var(--font-bold);
	font-size: 40px;
	line-height: 48px;
	text-align: center;
`;

const Filters = styled.div`
	width: 100%;
	margin-bottom: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const SortBy = styled.div`
	display: flex;
	align-items: center;
`;

const SortByItem = styled.div<SortByProps>`
	border: 1px solid #d7d7d7;
	border-radius: 8px;
	padding: 12px 16px;
	margin-right: 8px;
	cursor: pointer;
	transition: 0.2s;
	&:hover {
		border: 1px solid #000;
	}

	${({ isActive }) => {
		return isActive
			? css`
					background: #000;
					color: #fff;
					border: 1px solid #000;
			  `
			: css`
					background: #fff;
					color: #808080;
			  `;
	}}
`;

const FilterBtn = styled.button<any>`
	width: 93px;
	height: 44px;
	background: #f7f7f7;
	border-radius: 8px;
	display: flex;
	align-items: center;
	border: 1px solid transparent;
	padding: 10px 16px 10px 14px;
	cursor: pointer;
	transition: 0.2s;

	${({ isActive }) => {
		if (isActive) {
			return css`
				background: transparent;
				border: 1px solid #ebebeb;
				transition: 0.2s;
			`;
		}
	}}
`;

const FilterBtnIcon = styled.img`
	margin-right: 10px;
`;

const Posts = styled.div`
	display: flex;
	justify-content: flex-start;
	width: 1136px;
`;

const PostsError = styled.div`
	font-size: 40px;
	line-height: 50px;
	color: red;
	text-align: center;
`;

const FilterCount = styled.div`
	background: #8562ce;
	border-radius: 50px;
	width: 24px;
	height: 24px;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 8px;
`;
const ResetFilters = styled.div`
	margin-right: 16px;
	display: flex;
	align-items: center;
	color: #505050;
	cursor: pointer;

	& > img {
		margin-right: 8px;
	}
`;
const FilterButtons = styled.div`
	display: flex;
`;

const ShowSearch = styled.div<{ isSearchActive: boolean }>`
	height: 0;
	opacity: 0;
	transition: 0.2s;

	${({ isSearchActive }) => {
		if (isSearchActive) {
			return css`
				height: 156px;
				opacity: 1;
				transition: 0.2s;
			`;
		}
	}}
`;
