import { createSlice } from "@reduxjs/toolkit";
import { IImageRequest } from "../../../models/IImageRequest";
import { getAllArtbuyerImageRequests, getArtbuyerImageRequests, postRequest } from "./action";

interface ImageRequests {
	allRequests: IImageRequest[];
	allRequestsCount: number;
	unrepliedRequestsCount: number;
	negotiationRequestsCount: number;
	agreedRequestsCount: number;
	rejectedRequestsCount: number;
	isLoading: boolean;
	sendingPriceRequest: boolean;
}

const initialState: ImageRequests = {
	isLoading: false,
	allRequests: [],
	allRequestsCount: 0,
	sendingPriceRequest: false,
	unrepliedRequestsCount: 0,
	negotiationRequestsCount: 0,
	agreedRequestsCount: 0,
	rejectedRequestsCount: 0,
};

export const artbuyerImageRequest = createSlice({
	name: "user/artbuyerImageRequest",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(postRequest.fulfilled, (state) => {
				state.sendingPriceRequest = false;
			})
			.addCase(postRequest.rejected, (state) => {
				state.sendingPriceRequest = false;
			})
			.addCase(postRequest.pending, (state) => {
				state.sendingPriceRequest = true;
			})
			.addCase(getArtbuyerImageRequests.fulfilled, (state, action) => {
				state.allRequests = action.payload;
				state.isLoading = false;
			})
			.addCase(getArtbuyerImageRequests.rejected, (state, action) => {
				state.isLoading = false;
			})
			.addCase(getArtbuyerImageRequests.pending, (state, action) => {
				state.isLoading = true;
			})
			.addCase(getAllArtbuyerImageRequests.rejected, (state, action) => {
				state.isLoading = false;
			})
			.addCase(getAllArtbuyerImageRequests.pending, (state, action) => {
				state.isLoading = true;
			})
			.addCase(getAllArtbuyerImageRequests.fulfilled, (state, action) => {
				state.allRequestsCount = 0;
				state.unrepliedRequestsCount = 0;
				state.agreedRequestsCount = 0;
				state.negotiationRequestsCount = 0;
				state.rejectedRequestsCount = 0;
				action.payload.forEach((el) => {
					state.allRequestsCount += 1;
					if (el.status === "NEW" || el.status === "COUNTER_OFFER_BUYER") {
						state.unrepliedRequestsCount += 1;
					} else if (el.status === "CONFIRMED" || el.status === "COUNTER_OFFER_ACCEPTED") {
						state.agreedRequestsCount += 1;
					} else if (el.status === "COUNTER_OFFER_PHOTOGRAPHER") {
						state.negotiationRequestsCount += 1;
					} else {
						state.rejectedRequestsCount += 1;
					}
				});
				state.isLoading = false;
			});
	},
});

export const { reducer: artbuyerImageRequestReducer, actions: artbuyerImageRequestActions } =
	artbuyerImageRequest;

export const {} = artbuyerImageRequestActions;
