import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Button, Checkbox, CustomSlider, Modal, RadioBtns, Tag } from "../UI";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { AuthRoles } from "../../models/IProfile";
import { PriceRequest } from "./PriceRequest";
import { JobRequest } from "./JobRequest";
import { useNavigate } from "react-router-dom";
import { EditFileInfo } from "./EditFileInfo";
import { DeleteFile } from "./DeleteFile";
import { IImageInfo, INewGalleryItem } from "../../models/IGallery";
import { getPhotographerPhoto } from "../../store/reducers/photographerGallery/action";
import { getCart, postCart } from "../../store/reducers/cart/action";
import { getArtbuyerPhoto, getUserPhotos } from "../../store/reducers/photo/action";
import { Photoshoot } from "./Photoshoot";
import { getLicence } from "../../utils/getSmth";
import { CornerMessage } from "../UI/CornerMessage";
import { getPhotographerAccount, getUserAccount } from "../../store/reducers/profile/action";
import {
	getAnotherPhotographerSkills,
	getSkillsPhotographer,
} from "../../store/reducers/PhotographerSkills/action";
import { IsLoading } from "../UI/IsLoading";
import { EditorialLicence, ExtendedLicence, OnlineLicence } from "../../utils/Toolltips";
import { ProcessingModal } from "./ProcessingModal";
import { TimeStamp } from "../../utils/variables";

type Page = "Main" | "Account";

interface Props {
	images: INewGalleryItem[] | any[];
	setFileInfoOpen?: React.Dispatch<React.SetStateAction<boolean>>;
	setIsLogInOpen?: React.Dispatch<React.SetStateAction<boolean>>;
	setIsForgotPassword?: React.Dispatch<React.SetStateAction<boolean>>;
	page?: Page;
	currentImage?: number;
	setCurrentImage?: React.Dispatch<React.SetStateAction<number>>;
	isFavoritePage?: boolean;
}

export const FileInfo = ({
	images,
	setFileInfoOpen,
	setIsLogInOpen,
	currentImage,
	page = "Account",
	setCurrentImage,
	isFavoritePage = false,
}: Props) => {
	const [radioInfo, setRadioInfo] = useState("High-Res");
	const [pricing, setPricing] = useState("Rights Ready");
	const [licence, setLicence] = useState("Online Licence");
	const [currentImageInfo, setCurrentImageInfo] = useState<IImageInfo>();
	const [isLoading, setIsLoading] = useState(false);

	const [isPriceRequestOpen, setIsPriceRequestOpen] = useState(false);
	const [isEditFileInfo, setIsEditFileInfo] = useState(false);
	const [isJobRequestOpen, setIsJobRequestOpen] = useState(false);
	const [isDeleteFileOpen, setIsDeleteFileOpen] = useState(false);
	const [isPhotoshootOpen, setIsPhotoshootOpen] = useState(false);
	const [isNeedPhotoOpen, setIsNeedPhotoOpen] = useState(false);
	const [isJobRequestSended, setIsJobRequestSended] = useState(false);
	const [isPriceRequestSended, setIsPriceRequestSended] = useState(false);
	const [isHideUsername, setIsHideUsername] = useState(false);
	const [isEdited, setIsEdited] = useState(false);

	const [isAddedToCart, setIsAddedToCart] = useState(false);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { isAuth, user } = useAppSelector((state) => state.authData);

	const { sendingBriefing } = useAppSelector((state) => state.artbuyerJobRequests);
	const { sendingPriceRequest } = useAppSelector((state) => state.artbuyerImageRequests);
	const getImagesArray = (array: any) => {
		return array.map((el: INewGalleryItem) => el.imageDetails.LIGHTBOX.url);
	};

	useEffect(() => {
		setRadioInfo(currentImageInfo?.originalFile ? "High-Res" : "Low-Res");
		setIsHideUsername(currentImageInfo?.incognito || false);

		setPricing(
			(currentImageInfo?.pricing === "CUSTOM" && "Custom Rights Ready") ||
				(currentImageInfo?.pricing === "NEGOTIATE_LATER" && "Upon Request") ||
				"Rights Ready"
		);

		setIsAddedToCart(false);
	}, [currentImageInfo, currentImage]);

	useEffect(() => {
		const getCurrentImage = async () => {
			setIsLoading(true);
			const { payload } = await dispatch(
				user?.role === AuthRoles.Photographer
					? getPhotographerPhoto(String(images[currentImage || 0].photoId))
					: getArtbuyerPhoto(String(images[currentImage || 0].photoId))
			);
			setIsLoading(false);
			setCurrentImageInfo(payload);
		};

		getCurrentImage();
	}, [currentImage, isEdited]);

	const onPriceRequest = () => {
		if (isAuth) {
			setIsPriceRequestOpen(true);
		} else {
			setIsLogInOpen?.(true);
			setFileInfoOpen?.(false);
		}
	};

	const onJobRequest = () => {
		if (isAuth) {
			setIsJobRequestOpen(true);
		} else {
			setIsLogInOpen?.(true);
			setFileInfoOpen?.(false);
		}
	};
	const onShowAllByThisAuthor = async () => {
		if (isAuth) {
			const parameters = `?page=1&pageSize=2000&timestamp=${TimeStamp}&nonIncognito=true`;
			if (user?.role === AuthRoles.Photographer) {
				await dispatch(getUserAccount(currentImageInfo?.userId));
				await dispatch(getSkillsPhotographer(currentImageInfo?.userId));
			} else {
				await dispatch(getAnotherPhotographerSkills(currentImageInfo?.userId));
				await dispatch(getPhotographerAccount(currentImageInfo?.userId));
			}

			await dispatch(getUserPhotos({ parameters, userId: currentImageInfo?.userId }));
			setFileInfoOpen?.(false);
			navigate("/creator/profile/allByAuthor");
			document.body.style.overflow = "auto";
		} else {
			setFileInfoOpen?.(false);
			setIsLogInOpen?.(true);
		}
	};
	const onEditFile = () => {
		setIsEditFileInfo(true);
	};
	const onDeleteFile = () => {
		if (currentImage || currentImage == 0) {
			setIsDeleteFileOpen(true);
		}
	};

	const onAddToCart = async () => {
		const data = JSON.stringify({
			photoId: currentImageInfo?.photoId,
			licenceType: getLicence(licence),
		});

		await dispatch(postCart(data));
		await dispatch(getCart("PENDING"));

		setIsAddedToCart(true);
	};

	return (
		<Wrapper>
			<Header>
				<Subtitle>File info</Subtitle>
			</Header>
			{isLoading ? (
				<IsLoading />
			) : (
				<Content>
					<ContentAbout>
						{page === "Main" && user?.role === AuthRoles.Photographer && (
							<div>
								<div>
									<Parametrs>
										<ul>
											{currentImageInfo?.exif.cameraModel && (
												<li>Camera: {currentImageInfo?.exif.cameraModel}</li>
											)}
											{currentImageInfo?.exif.focalLength && (
												<li>Focal Length: {currentImageInfo?.exif.focalLength}</li>
											)}
											{currentImageInfo?.exif.aperture && (
												<li>Aperture: {currentImageInfo?.exif.aperture}</li>
											)}
											{currentImageInfo?.exif.isoSpeedRatings && (
												<li>ISO: {currentImageInfo?.exif.isoSpeedRatings}</li>
											)}
											{currentImageInfo?.exif.orientation && (
												<li>Orientation: {currentImageInfo?.exif.orientation}</li>
											)}
										</ul>
									</Parametrs>
									<RadioWrapper>
										<h3>Image File</h3>
										<RadioBtns
											value={radioInfo}
											setValue={setRadioInfo}
											disabled={true}
											options={[
												{ value: "High-Res", text: "" },
												{ value: "Low-Res", text: "" },
											]}
											name="ImageFile"
										/>
									</RadioWrapper>
									<RadioWrapper>
										<h3>Pricing</h3>
										<RadioBtns
											value={pricing}
											setValue={setPricing}
											disabled={true}
											options={[
												{ value: "Rights Ready", text: "" },
												{ value: "Upon Request", text: "" },
												{ value: "Custom Rights Ready", text: "" },
											]}
											name="Pricing"
										/>
									</RadioWrapper>
								</div>
							</div>
						)}

						{((page === "Main" &&
							currentImageInfo?.pricing !== "CUSTOM" &&
							currentImageInfo?.pricing !== "ARTBRIEF_PRICING" &&
							user?.role === AuthRoles.Artbuyer) ||
							!isAuth) && (
							<div>
								<div>
									<Parametrs>
										<ul>
											{currentImageInfo?.exif.cameraModel && (
												<li>Camera: {currentImageInfo?.exif.cameraModel}</li>
											)}
											{currentImageInfo?.exif.focalLength && (
												<li>Focal Length: {currentImageInfo?.exif.focalLength}</li>
											)}
											{currentImageInfo?.exif.aperture && (
												<li>Aperture: {currentImageInfo?.exif.aperture}</li>
											)}
											{currentImageInfo?.exif.isoSpeedRatings && (
												<li>ISO: {currentImageInfo?.exif.isoSpeedRatings}</li>
											)}
											{currentImageInfo?.exif.orientation && (
												<li>Orientation: {currentImageInfo?.exif.orientation}</li>
											)}
										</ul>
									</Parametrs>

									<ButtonWrapper>
										<Button onClick={onPriceRequest} width="100%">
											Ask for Image Price
										</Button>
									</ButtonWrapper>
									<ButtonWrapper style={{ marginBottom: "16px" }}>
										<Button onClick={onJobRequest} width="100%">
											Send your Job Request
										</Button>
									</ButtonWrapper>
									{!currentImageInfo?.incognito && (
										<ButtonWrapper>
											<Button onClick={onShowAllByThisAuthor} theme="secondary" width="100%">
												Show All by This Author
											</Button>
										</ButtonWrapper>
									)}

									<ButtonWrapper>
										{/* <DownloadCompButton
											href={`${process.env.REACT_APP_DOMEN}${currentImageInfo?.imageDetails.WATERMARK.url}`}
											download>
											Download Comp
										</DownloadCompButton> */}
									</ButtonWrapper>
								</div>
							</div>
						)}

						{page === "Main" &&
							currentImageInfo?.pricing === "CUSTOM" &&
							user?.role === AuthRoles.Artbuyer && (
								<div>
									<div>
										<Parametrs>
											<ul>
												{currentImageInfo?.exif.orientation && (
													<li>Orientation: {currentImageInfo?.exif.orientation}</li>
												)}{" "}
											</ul>
										</Parametrs>
										<RadioWrapper>
											<RadioBtns
												value={licence}
												setValue={setLicence}
												options={[
													{
														value: "Online Licence",
														text: `€${currentImageInfo?.priceOnline}.00`,
														// toolTip: <OnlineLicence />,
													},
													{
														value: "Editorial Licence",
														text: `€${currentImageInfo?.priceEditorial}.00`,
														// toolTip: <EditorialLicence />,
													},
													{
														value: "Extended Licence",
														text: `€${currentImageInfo?.priceExtended}.00`,
														// toolTip: <ExtendedLicence />,
													},
												]}
												name="ImageFile"
											/>
										</RadioWrapper>
										<Button onClick={onAddToCart} width="100%">
											Add to Cart
										</Button>
										<SmallText>
											Your project requires specific usage that is not reflected in the pricing
											above?
										</SmallText>
										<ButtonWrapper>
											<Button onClick={onPriceRequest} width="100%">
												Ask for Image Price
											</Button>
										</ButtonWrapper>
										<ButtonWrapper style={{ marginBottom: "16px" }}>
											<Button onClick={onJobRequest} width="100%">
												Send your Job Request
											</Button>
										</ButtonWrapper>
										{!currentImageInfo.incognito && (
											<ButtonWrapper>
												<Button onClick={onShowAllByThisAuthor} theme="secondary" width="100%">
													Show All by This Author
												</Button>
											</ButtonWrapper>
										)}
										{/* <ButtonWrapper>
											<DownloadCompButton
												href={`${process.env.REACT_APP_DOMEN}${currentImageInfo?.imageDetails.WATERMARK.url}`}
												download>
												Download Comp
											</DownloadCompButton>
										</ButtonWrapper> */}
									</div>
								</div>
							)}

						{page === "Main" &&
							currentImageInfo?.pricing === "ARTBRIEF_PRICING" &&
							user?.role === AuthRoles.Artbuyer && (
								<div>
									<div>
										<Parametrs>
											<ul>
												{currentImageInfo?.exif.orientation && (
													<li>Orientation: {currentImageInfo?.exif.orientation}</li>
												)}{" "}
											</ul>
										</Parametrs>
										<RadioWrapper>
											<RadioBtns
												value={licence}
												setValue={setLicence}
												options={[
													{
														value: "Online Licence",
														text: `€${currentImageInfo?.priceOnline}.00`,
														// text: `€20.00`,
														// toolTip: <OnlineLicence />,
													},
													{
														value: "Editorial Licence",
														text: `€${currentImageInfo?.priceEditorial}.00`,
														// text: `€80.00`,
														// toolTip: <EditorialLicence />,
													},
													{
														value: "Extended Licence",
														text: `€${currentImageInfo?.priceExtended}.00`,
														// toolTip: <ExtendedLicence />,
													},
												]}
												name="ImageFile"
											/>
										</RadioWrapper>
										<Button onClick={onAddToCart} width="100%">
											Add to Cart
										</Button>
										<SmallText>
											Your project requires specific usage that is not reflected in the pricing
											above?
										</SmallText>
										<ButtonWrapper>
											<Button onClick={onPriceRequest} width="100%">
												Ask for Image Price
											</Button>
										</ButtonWrapper>
										<ButtonWrapper style={{ marginBottom: "16px" }}>
											<Button onClick={onJobRequest} width="100%">
												Send your Job Request
											</Button>
										</ButtonWrapper>
										{!currentImageInfo.incognito && (
											<ButtonWrapper>
												<Button onClick={onShowAllByThisAuthor} theme="secondary" width="100%">
													Show All by This Author
												</Button>
											</ButtonWrapper>
										)}
										<ButtonWrapper>
											{/* <DownloadCompButton
												href={`${process.env.REACT_APP_DOMEN}${currentImageInfo?.imageDetails.WATERMARK.url}`}
												download>
												Download Comp
											</DownloadCompButton> */}
										</ButtonWrapper>
									</div>
								</div>
							)}

						{page === "Account" && user?.role === AuthRoles.Photographer && (
							<div>
								<div>
									<Parametrs>
										<ul>
											{currentImageInfo?.exif.cameraModel && (
												<li>Camera: {currentImageInfo?.exif.cameraModel}</li>
											)}
											{currentImageInfo?.exif.focalLength && (
												<li>Focal Length: {currentImageInfo?.exif.focalLength}</li>
											)}
											{currentImageInfo?.exif.aperture && (
												<li>Aperture: {currentImageInfo?.exif.aperture}</li>
											)}
											{currentImageInfo?.exif.isoSpeedRatings && (
												<li>ISO: {currentImageInfo?.exif.isoSpeedRatings}</li>
											)}
											{currentImageInfo?.exif.orientation && (
												<li>Orientation: {currentImageInfo?.exif.orientation}</li>
											)}
										</ul>
									</Parametrs>
									<RadioWrapper>
										<h3>Image File</h3>
										<RadioBtns
											value={radioInfo}
											setValue={setRadioInfo}
											disabled={true}
											options={[
												{ value: "High-Res", text: "" },
												{ value: "Low-Res", text: "" },
											]}
											name="ImageFile"
										/>
									</RadioWrapper>
									<RadioWrapper>
										<h3>Pricing</h3>
										<RadioBtns
											value={pricing}
											setValue={setPricing}
											disabled={true}
											options={[
												{ value: "Rights Ready", text: "" },
												{ value: "Upon Request", text: "" },
												{ value: "Custom Rights Ready", text: "" },
											]}
											name="Pricing"
										/>
										<HiddenUserName>
											<Checkbox
												disabled={true}
												checked={isHideUsername}
												onValueChange={setIsHideUsername}
												label="Private"
											/>
										</HiddenUserName>
									</RadioWrapper>
									<ButtonsWrapper>
										<EditBtn onClick={onEditFile}>
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M16.9875 5.17689C16.3041 4.49344 15.196 4.49344 14.5126 5.17689L5.21969 14.4698C5.12106 14.5684 5.0519 14.6926 5.01995 14.8283L4.01995 19.0783C3.96046 19.3311 4.03602 19.5968 4.21968 19.7804C4.40335 19.9641 4.66896 20.0396 4.92179 19.9802L9.17179 18.9802C9.30756 18.9482 9.43172 18.879 9.53034 18.7804L18.8232 9.48754L18.8233 9.48751C19.5066 8.8041 19.5066 7.69609 18.8233 7.01267L18.8232 7.01265L16.9875 5.17689ZM15.5733 6.23751C15.6709 6.1399 15.8292 6.1399 15.9268 6.23751L15.9268 6.23754L17.7626 8.07329C17.8602 8.17093 17.8602 8.32923 17.7626 8.42687L17.7626 8.4269L8.62055 17.5689L5.75757 18.2425L6.43121 15.3796L15.5732 6.23753L15.5733 6.23751ZM13.75 18.5C13.3358 18.5 13 18.8358 13 19.25C13 19.6642 13.3358 20 13.75 20H19.25C19.6642 20 20 19.6642 20 19.25C20 18.8358 19.6642 18.5 19.25 18.5H13.75Z"
													fill="#505050"
												/>
											</svg>
											Edit file info
										</EditBtn>
										<DeleteBtn onClick={onDeleteFile}>
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<path
													d="M6.75 7.75L7.59115 17.4233C7.68102 18.4568 8.54622 19.25 9.58363 19.25H14.4164C15.4538 19.25 16.319 18.4568 16.4088 17.4233L17.25 7.75"
													stroke="#505050"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M10.5 11V16M13.5 11V16"
													stroke="#505050"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M9.75 7.5V6.75C9.75 5.64543 10.6454 4.75 11.75 4.75H12.25C13.3546 4.75 14.25 5.64543 14.25 6.75V7.5"
													stroke="#505050"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M5 7.75H19"
													stroke="#505050"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
											Delete file
										</DeleteBtn>
									</ButtonsWrapper>
								</div>
							</div>
						)}

						<ContentText>
							{currentImageInfo?.originalFile && <p>File dimensions: Original file upon request</p>}
							{currentImageInfo?.size && <p>{currentImageInfo.size}</p>}
							{currentImageInfo?.photoId && <p>ID: #{currentImageInfo.photoId}</p>}
							{currentImageInfo?.releaseAvailable && (
								<p>Release(s) on file: {currentImageInfo.releaseAvailable}</p>
							)}
						</ContentText>
					</ContentAbout>
					<ContentSlider>
						<CustomSlider
							setCurrentItem={setCurrentImage}
							currentItem={currentImage}
							images={getImagesArray(images)}
						/>
						<TagsWrapper>
							{currentImageInfo?.tags.map((el: any, i) => {
								return <Tag key={i}>{el}</Tag>;
							})}
						</TagsWrapper>
					</ContentSlider>
				</Content>
			)}
			<Modal
				hidden={true}
				isOpen={isPriceRequestOpen}
				noPadding
				onClose={() => setIsPriceRequestOpen(false)}>
				<PriceRequest
					isPriceRequestSended={isPriceRequestSended}
					setIsPriceRequestSended={setIsPriceRequestSended}
					photoId={currentImageInfo?.photoId}
					onClose={setIsPriceRequestOpen}
				/>
			</Modal>
			<Modal
				hidden={true}
				isOpen={isJobRequestOpen}
				size="small"
				onClose={() => setIsJobRequestOpen(false)}>
				<JobRequest
					onClose={() => setIsJobRequestOpen(false)}
					onPhotoshootOpen={setIsPhotoshootOpen}
					onOneImageOpen={setIsNeedPhotoOpen}
				/>
			</Modal>
			<Modal
				hidden={true}
				isOpen={isPhotoshootOpen}
				size="small"
				noPadding
				onClose={() => setIsPhotoshootOpen(false)}>
				<Photoshoot
					isJobRequestSended={isJobRequestSended}
					setIsJobRequestSended={setIsJobRequestSended}
					onClose={() => setIsPhotoshootOpen(false)}
					userId={currentImageInfo?.userId || 0}
				/>
			</Modal>
			<Modal
				hidden={true}
				isOpen={isNeedPhotoOpen}
				size="small"
				noPadding
				onClose={() => setIsNeedPhotoOpen(false)}>
				<Photoshoot
					isJobRequestSended={isJobRequestSended}
					setIsJobRequestSended={setIsJobRequestSended}
					onClose={() => setIsNeedPhotoOpen(false)}
					onePhoto
					userId={currentImageInfo?.userId || 0}
				/>
			</Modal>
			<Modal
				hidden={true}
				isCloseSvg={false}
				isOpen={isEditFileInfo}
				size="fullwidth"
				onClose={() => setIsEditFileInfo(false)}>
				<EditFileInfo
					onCloseFileInfo={setFileInfoOpen}
					//@ts-ignore
					image={currentImageInfo}
					onClose={() => setIsEditFileInfo(false)}
					setIsEdited={setIsEdited}
				/>
			</Modal>
			<Modal size="small" isOpen={isDeleteFileOpen} onClose={() => setIsDeleteFileOpen(false)}>
				<DeleteFile
					setFileInfoOpen={setFileInfoOpen}
					fileToDelete={images[currentImage || 0]}
					modalOpen={isDeleteFileOpen}
					setModalOpen={() => setIsDeleteFileOpen(false)}
				/>
			</Modal>
			{(sendingBriefing || sendingPriceRequest) && <ProcessingModal />}

			<CornerMessage
				text={"Item was added to your cart"}
				setIsAddedToCart={setIsAddedToCart}
				isAddedToCart={isAddedToCart}
			/>
			<CornerMessage
				span="Success! "
				text={"Your job request has been sent"}
				setIsAddedToCart={setIsJobRequestSended}
				isAddedToCart={isJobRequestSended}
			/>
			<CornerMessage
				span="Success! "
				text={"Your price request has been sent"}
				setIsAddedToCart={setIsPriceRequestSended}
				isAddedToCart={isPriceRequestSended}
			/>
		</Wrapper>
	);
};

const DownloadCompButton = styled.a`
	padding: 12px 20px;
	cursor: pointer;
	border: 1px solid #000;
	margin: 0px auto;
	width: 100%;
	border-radius: 8px;
	transition: all 0.2s;
	display: inline-block;
	position: relative;
	max-height: 50px;
	background: #fff;
	text-align: center;
	color: #000;
	margin-right: 8px;
	&:hover {
		color: rgb(133, 98, 206);
		border: 1px solid rgb(133, 98, 206);
		transition: 0.2s;
	}
`;

const Wrapper = styled.div`
	text-align: left;
	height: 100%;
	padding: 24px;
	width: 92vw;
	height: 92vh;
`;
const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 22px;
`;
const Subtitle = styled.h3`
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
`;

const Content = styled.div`
	display: flex;
	justify-content: space-between;
	height: 96%;
`;
const ContentAbout = styled.div`
	width: 340px;
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow-y: auto;
	padding-right: 8px;
`;
const Parametrs = styled.div`
	padding: 8px 16px;
	background: #f7f7f7;
	border-radius: 8px;
	width: 100%;
	& > ul {
		& > li {
			margin-bottom: 2px;
		}
	}
	margin-bottom: 24px;
`;
const RadioWrapper = styled.div`
	h3 {
		margin-bottom: 16px;
		font-size: 16px;
		line-height: 24px;
		font-weight: 600;
	}
	margin-bottom: 24px;
`;
const ContentText = styled.div`
	font-size: 14px;
	color: #808080;
	line-height: 20px;
	margin-top: auto;
	margin-bottom: 20px;
	padding-top: 20px;
`;
const ContentSlider = styled.div`
	margin-right: 48px;
	margin-left: 90px;
	max-width: calc(100% - 470px);
	width: 100%;
	position: relative;
`;

const TagsWrapper = styled.div`
	position: absolute;
	left: 0;
	bottom: 2vh;
	right: 0;
	display: flex;
	flex-wrap: wrap;
	max-width: 600px;
	margin: 0 auto;
	justify-content: center;
	max-height: 11vh;
	overflow-y: auto;
`;
const ButtonWrapper = styled.div`
	margin-bottom: 8px;
`;
const SmallText = styled.div`
	margin: 24px 0;
`;

const ButtonsWrapper = styled.div`
	display: flex;
	color: #505050;
	& > div {
		background: #f7f7f7;
		cursor: pointer;
		transition: 0.2s;
		&:hover {
			transition: 0.2s;
		}
		display: flex;
		align-items: center;
		& > svg {
			margin-right: 8px;
			& > path {
				transition: all 0.2s;
			}
		}
	}
`;
const EditBtn = styled.div`
	padding: 16px 19px 16px 28px;

	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;

	&:hover {
		color: #8562ce;
		& > svg {
			& > path {
				fill: #8562ce;
				transition: all 0.2s;
			}
		}
	}
`;
const DeleteBtn = styled.div`
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	padding: 16px 32px 16px 20px;
	&:hover {
		color: #ee4747;
		& > svg {
			& > path {
				stroke: #ee4747;
				transition: all 0.2s;
			}
		}
	}
`;

const ItemWasAdded = styled.div<any>`
	position: absolute;
	bottom: 16px;
	right: 16px;
	padding: 16px;
	display: flex;
	align-items: center;
	background: #8562ce;
	border-radius: 8px;
	transform: translateX(500px);
	transition: 0.2s;
	& > img {
		margin-left: 14px;
		cursor: pointer;
	}
	& > p {
		color: #fff;
	}
	& > div {
		display: flex;
		justify-content: center;
		align-items: center;
		background: #fff;
		border-radius: 50%;
		margin-right: 14px;
		width: 40px;
		height: 40px;
	}
	${({ active }) => {
		if (active) {
			return css`
				transform: translateX(0px);
				transition: 0.2s;
			`;
		}
	}}
`;

const HiddenUserName = styled.div`
	margin-top: 20px;
`;
