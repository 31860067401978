import styled from "styled-components";
import {
	camera,
	favoritesSvg,
	social1,
	social2,
	social3,
	social4,
	social5,
} from "../static/images";

import { Button, Modal } from "./UI";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { AuthRoles } from "../models/IProfile";
import { countries } from "../utils/countries";
import { ProfileGallery } from "./ProfileGallery";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { INewGalleryItem } from "../models/IGallery";
import { EditUploadedImages } from "./Modals/EditUploadedImages";
import { getMyPhotos, getPhotographerPhotos } from "../store/reducers/photographerGallery/action";
import { getPhotographerSkills } from "../store/reducers/PhotographerSkills/action";
import {
	additionalSkillsValues,
	assetsValues,
	servicesValues,
	skillLevels,
} from "../utils/selectValues";
import { addFavourite } from "../store/reducers/artbuyerFavorites/action";
import { TimeStamp } from "../utils/variables";

interface Props {
	isArchive?: boolean;
	setIsArchive?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AboutCreator = ({ isArchive, setIsArchive }: Props) => {
	const [selectedImagesToEdit, setSelectedImagesToEdit] = useState<INewGalleryItem[]>([]);
	const [isEditImagesInfo, setIsEditImagesInfo] = useState(false);
	const [imagesGallerySort, setImagesGallerySort] = useState(0);

	const { user } = useAppSelector((state) => state.authData);

	const { photographerAbout, photographerSocialLinks } = useAppSelector((state) => state.profile);

	const { photographerSkills } = useAppSelector((state) => state.photographerSkills);

	const { Sedcards, Archive, CoverImage } = useAppSelector((state) => state.gallery);
	// const { countries } = useAppSelector((state) => state.countries);

	const dispatch = useAppDispatch();

	useEffect(() => {
		const filters = `&page=1&pageSize=2000&timestamp=${TimeStamp}&`;
		if (user?.role === AuthRoles.Photographer) {
			dispatch(getMyPhotos(filters));
			dispatch(getPhotographerSkills());
		}
	}, []);

	const navigate = useNavigate();

	const getCountry = () => {
		return countries.find((el) => el.value === photographerAbout.countryId)?.label;
	};

	const onEditProfile = () => {
		navigate("/creator/profile/public");
	};
	const getStringSkills = (array: string[]) => {
		let firstString = false;
		return array.map((el) => {
			if (firstString) {
				return `, ${el}`;
			} else {
				firstString = true;
				return el;
			}
		});
	};
	const getSkillsValue = (arr: { value: string; label: string }[], skills: string[]) => {
		return skills.map((el) => {
			let value = "";
			arr.forEach((obj) => {
				if (el === obj.value) {
					value = obj.label;
				}
			});
			return value;
		});
	};
	const getSkillValue = (arr: { value: string; label: string }[], skill: string) => {
		return arr.map((el) => {
			if (el.value === skill) {
				return el.label;
			}
		});
	};

	const onSelectAll = () => {
		Archive.forEach((el) => {
			if (selectedImagesToEdit?.includes(el)) {
				return;
			} else {
				setSelectedImagesToEdit?.((prev) => [...prev, el]);
			}
		});
	};

	const onDeselectAll = () => {
		Archive.forEach((el) => {
			if (selectedImagesToEdit?.includes(el)) {
				setSelectedImagesToEdit?.((prev) => [
					...prev.filter((item) => item.photoId !== el.photoId),
				]);
			} else {
				return;
			}
		});
	};
	const onAddToFavorites = () => {
		const json = {
			photographerIds: [2909],
		};
		dispatch(addFavourite(json));
	};

	return (
		<>
			<About>
				<AvatarBorder>
					<AvatarWrapper>
						<img
							src={
								photographerAbout.avatarURL
									? `${process.env.REACT_APP_DOMEN}${photographerAbout.avatarURL}`
									: camera
							}
							alt="camera"
						/>
					</AvatarWrapper>
				</AvatarBorder>
				<AboutName>
					{photographerAbout.firstName} {photographerAbout.lastName}
				</AboutName>
				<AboutPlace>
					{photographerAbout.city}, {getCountry()}
				</AboutPlace>
				<AboutThemes>{photographerAbout.title}</AboutThemes>
				<AboutLink href={photographerAbout.siteURL} target={"_blank"}>
					{photographerAbout.siteURL}
				</AboutLink>
				<SocialList>
					{photographerSocialLinks.facebookUserId && (
						<li>
							<a href={photographerSocialLinks.facebookUserId} target={"_blank"}>
								<img src={social1} alt="social1" />
							</a>
						</li>
					)}
					{photographerSocialLinks.twitterUserId && (
						<li>
							<a href={photographerSocialLinks.twitterUserId} target={"_blank"}>
								<img src={social2} alt="social2" />
							</a>
						</li>
					)}
					{photographerSocialLinks.instagramUserId && (
						<li>
							<a href={photographerSocialLinks.instagramUserId} target={"_blank"}>
								<img src={social3} alt="social3" />
							</a>
						</li>
					)}
					{photographerSocialLinks.linkedInId && (
						<li>
							<a href={photographerSocialLinks.linkedInId} target={"_blank"}>
								<img src={social4} alt="social4" />
							</a>
						</li>
					)}
					{photographerSocialLinks.vimeoId && (
						<li>
							<a href={photographerSocialLinks.vimeoId} target={"_blank"}>
								<img src={social5} alt="social5" />
							</a>
						</li>
					)}
				</SocialList>
				{(user?.role === AuthRoles.Photographer && (
					<ButtonWrapper onClick={onEditProfile} style={{ marginRight: "-8px" }}>
						<Button theme={"secondary"}>Edit Profile</Button>
					</ButtonWrapper>
				)) ||
					(user?.role === AuthRoles.Artbuyer && (
						<CreatorProfileButtons>
							<ButtonWrapper>
								<Button theme={"secondary"}>Send Job Request</Button>
							</ButtonWrapper>
							<ButtonWrapper>
								<Button theme={"secondary"}>Send Image Request</Button>
							</ButtonWrapper>
							<ButtonWrapper>
								<Button onClick={onAddToFavorites} theme={"icon"}>
									<img style={{ marginTop: "2px" }} src={favoritesSvg} alt="favoritesSvg" />
								</Button>
							</ButtonWrapper>
						</CreatorProfileButtons>
					))}

				<AboutText>{photographerAbout.about}</AboutText>
				<Facts>
					<FactsItem>
						<FactsItemTitle>Skill Level</FactsItemTitle>
						<div>{getSkillValue(skillLevels, photographerSkills.skillLevel) || ""}</div>
					</FactsItem>
					<FactsItem>
						<FactsItemTitle>Assets</FactsItemTitle>
						<div>
							{getStringSkills(getSkillsValue(assetsValues, photographerSkills.assets) || []) || ""}
						</div>
					</FactsItem>
					<FactsItem>
						<FactsItemTitle>Additional Services</FactsItemTitle>
						<div>
							{getStringSkills(
								getSkillsValue(servicesValues, photographerSkills.additionalServices) || []
							) || ""}
						</div>
					</FactsItem>
					<FactsItem>
						<FactsItemTitle>Additional Skills</FactsItemTitle>
						<div>
							{getStringSkills(
								getSkillsValue(additionalSkillsValues, photographerSkills.additionalSkills) || []
							) || ""}
						</div>
					</FactsItem>
				</Facts>
			</About>
			<ProfileGallery
				selectedImages={selectedImagesToEdit}
				setSelectedImages={setSelectedImagesToEdit}
				sedCards={Sedcards}
				coverImage={CoverImage}
				archive={Archive}
				isArchive={isArchive}
				setImagesGallerySort={setImagesGallerySort}
			/>
			{imagesGallerySort === 1 && (
				<ArchiveSelect>
					<ArchiveSelectLeft>
						<p>{selectedImagesToEdit.length} selected</p>
						<Button
							isActive={Archive.length === selectedImagesToEdit.length}
							onClick={onSelectAll}
							padding="8px 12px"
							theme="outline">
							Select All
						</Button>
						<Button
							isActive={!(selectedImagesToEdit.length > 0)}
							onClick={onDeselectAll}
							padding="8px 12px"
							theme="outline">
							Deselect All
						</Button>
					</ArchiveSelectLeft>
					<ArchiveSelectRight>
						<Button
							disabled={selectedImagesToEdit.length === 0}
							onClick={() => setIsEditImagesInfo(true)}
							padding="8px 12px">
							Edit images
						</Button>
					</ArchiveSelectRight>
				</ArchiveSelect>
			)}
			<Modal
				noPadding
				noRadius
				isOpen={isEditImagesInfo}
				onClose={() => setIsEditImagesInfo(false)}>
				<EditUploadedImages
					onCloseEditImages={setIsEditImagesInfo}
					isEditImages={true}
					imagesToEdit={selectedImagesToEdit}
					setSelectedImagesToEdit={setSelectedImagesToEdit}
					setIsEditImagesInfo={setIsEditImagesInfo}
				/>
			</Modal>
		</>
	);
};
const ArchiveSelect = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: 58px;
	padding: 12px 24px;
	background: #f7f7f7;
	z-index: 3;
`;
const ArchiveSelectLeft = styled.div`
	display: flex;
	align-items: center;
	& > p {
		margin-right: 24px;
		color: #808080;
	}
`;
const ArchiveSelectRight = styled.div``;
const About = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 16px;
	line-height: 24px;
`;
const AvatarWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 120px;
	height: 120px;
	background: #000;
	border-radius: 50%;
	transition: 0.2s;
	& > img {
		transition: 0.2s;
		width: 120px;
		height: 120px;
		border-radius: 50%;
	}
`;

const AvatarBorder = styled.div`
	width: 120px;
	margin: 0 auto;
	height: 120px;
	border-radius: 50%;
	border: 1px solid transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 40px;
	transition: 0.2s;
	border: 1px solid #000000;
	& > div {
		width: 108px;
		height: 108px;
		& > img {
			width: 108px;
			object-fit: cover;
			height: 108px;
		}
	}
`;
const AboutName = styled.h2`
	margin-bottom: 4px;
	font-weight: 700;
	font-size: 64px;
	line-height: 80px;
`;
const AboutPlace = styled.div`
	margin-bottom: 15px;
`;
const AboutThemes = styled.div`
	margin-bottom: 8px;
`;
const AboutLink = styled.a`
	margin-bottom: 24px;
	color: #8562ce;
	text-decoration: underline;
`;
const SocialList = styled.ul`
	display: flex;
	margin-bottom: 24px;

	& > li {
		:not(:last-child) {
			margin-right: 8px;
		}

		& > a {
			width: 32px;
			border-radius: 50%;
			display: inline-block;
			height: 32px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}
	}
`;
const CreatorProfileButtons = styled.div`
	display: flex;
`;
const ButtonWrapper = styled.div`
	text-align: center;
	margin-bottom: 24px;
`;
const AboutText = styled.div`
	max-width: 752px;
	text-align: center;
	margin-bottom: 40px;
`;
const Facts = styled.div`
	max-width: 1136px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 16px;
`;
const FactsItem = styled.div`
	width: 560px;
	border-radius: 8px;
	background: #f7f7f7;
	padding: 8px 8px 8px 16px;
`;
const FactsItemTitle = styled.h4`
	margin-bottom: 4px;
	font-weight: 600;
`;
