import { useState } from "react";
import styled, { css } from "styled-components";
import { Button, Modal } from "../UI";
import { useNavigate } from "react-router-dom";
import { INewCartGroup, INewCartItem } from "../../models/ICart";
import { useAppDispatch } from "../../hooks/redux";
import { EditCartItem } from "../Modals/EditCartItem";
import { deleteCartItem, getCart } from "../../store/reducers/cart/action";
import { onAddToCheckout } from "../../store/reducers/cart";

interface Props {
	cartItemsArr: INewCartGroup | null;
	isPurchase?: boolean;
	vatValue?: number;
}

export const ContainerItem = ({ cartItemsArr, vatValue, isPurchase = false }: Props) => {
	const [editOpen, setEditOpen] = useState(false);
	const [currentElement, setCurrentElement] = useState<any>({});
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const onCheckOutClick = () => {
		dispatch(onAddToCheckout(cartItemsArr));
		navigate("/creator/profile/cart/checkout");
	};
	const onRemoveClick = async (el: INewCartItem) => {
		await dispatch(deleteCartItem(el.id));
		await dispatch(getCart("PENDING"));
	};
	const onEditClick = (el: INewCartItem) => {
		setCurrentElement(el);
		setEditOpen(true);
	};

	return (
		<ContainerItemWrapper>
			<ContainerItemTop>
				<ContainerItemAuthor>
					By <span>{cartItemsArr?.photographerName}</span>
				</ContainerItemAuthor>
				<ContainerItemImagesCount>
					{cartItemsArr?.basketListModels.length} images
				</ContainerItemImagesCount>
			</ContainerItemTop>
			<div>
				{cartItemsArr?.basketListModels.map((el, i) => (
					<CartItemWrapper key={i} isPurchase>
						<CartItemImg
							src={`${process.env.REACT_APP_DOMEN}${el.photo?.imageDetails.LIGHTBOX.url}`}
						/>
						<CartItemInfo>
							<CartItemInfoTop>
								<CartItemLicence>{el.licenceType}</CartItemLicence>
								{isPurchase && el.photoPrice ? (
									<CartItemPrice>
										€ {el.photoPrice + (el.photoPrice / 100) * (vatValue || 0)}
									</CartItemPrice>
								) : (
									<CartItemPrice>€ {el.photoPrice}</CartItemPrice>
								)}
							</CartItemInfoTop>
							<CartItemAbout>
								<li>File dimensions: Original file upon request</li>
								<li>{el.photoDetailsModel?.size}</li>
								<li>ID: #{el.id}</li>
								<li>
									Release(s) on file:{" "}
									{el.photoDetailsModel?.releaseAvailable === "YES" ? "Possible" : "Not possible"}
								</li>
							</CartItemAbout>
							{isPurchase ? (
								<div style={{ display: "flex", marginTop: "auto" }}>
									<PurchaseButton style={{ marginRight: "10px" }}>
										<DownloadBtn
											href={`${process.env.REACT_APP_DOMEN}${el.photo?.imageDetails.LIGHTBOX.url}`}
											download>
											Download photo
										</DownloadBtn>
										{/* <DownloadBtn href={`${process.env.REACT_APP_DOMEN}${el?.uploadURL}`} download>
										Download photo
									</DownloadBtn> */}
									</PurchaseButton>

									{el?.releaseUploadURL && (
										<PurchaseButton>
											<DownloadBtn
												href={`${process.env.REACT_APP_DOMEN}${el?.releaseUploadURL}`}
												download>
												Download Release File
											</DownloadBtn>
										</PurchaseButton>
									)}
								</div>
							) : (
								<>
									<CartItemBottom>
										{el.type !== "BY_PAYMENT_REQUEST" && (
											<CartItemBtn onClick={() => onEditClick(el)}>Edit</CartItemBtn>
										)}

										<CartItemBtn onClick={() => onRemoveClick(el)}>Remove</CartItemBtn>
									</CartItemBottom>
								</>
							)}
						</CartItemInfo>
					</CartItemWrapper>
				))}
				{isPurchase || (
					<CartItemsCheckOut>
						<CartItemsTotal>
							<div>Total</div>
							<CartItemsTotalPrice>€ {cartItemsArr?.subTotal}</CartItemsTotalPrice>
						</CartItemsTotal>
						<Button onClick={() => onCheckOutClick()} width="100%">
							Check Out
						</Button>
					</CartItemsCheckOut>
				)}
			</div>
			<Modal padding="40px 32px" isOpen={editOpen} onClose={() => setEditOpen(false)}>
				<EditCartItem onClose={() => setEditOpen(false)} el={currentElement} />
			</Modal>
		</ContainerItemWrapper>
	);
};

const ContainerItemWrapper = styled.div`
	background: #ffffff;
	border-radius: 8px;
	padding: 24px;
	margin-bottom: 16px;
`;
const ContainerItemTop = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 16px;
	border-bottom: 1px solid #ebebeb;
`;
const ContainerItemAuthor = styled.div`
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	& > span {
		color: #8562ce;
	}
`;
const ContainerItemImagesCount = styled.div`
	color: #808080;
`;
const CartItemWrapper = styled.div<any>`
	display: flex;
	padding: 16px 0px;
	border-bottom: 1px solid #ebebeb;
	${({ isPurchase }) => {
		if (isPurchase) {
			return css`
				&:last-child {
					padding-bottom: 0;
					border-bottom: none;
				}
			`;
		}
	}}
`;
const CartItemImg = styled.img`
	width: 256px;
	margin-right: 16px;
`;
const CartItemInfo = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;
const CartItemInfoTop = styled.div`
	margin-bottom: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const CartItemLicence = styled.div`
	line-height: 24px;
`;
const CartItemPrice = styled.div`
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
`;

const CartItemAbout = styled.ul`
	color: #808080;
`;
const CartItemBottom = styled.div`
	margin-top: auto;
	display: flex;
	color: #999999;
`;
const CartItemBtn = styled.div`
	margin-right: 16px;
	cursor: pointer;
`;
const CartItemsCheckOut = styled.div`
	margin-top: 16px;
	width: 248px;
	margin-left: auto;
`;
const CartItemsTotal = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
	line-height: 24px;
`;
const CartItemsTotalPrice = styled.div`
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
`;
const PurchaseButton = styled.div`
	margin-top: auto;
	display: flex;
	color: #999999;
	width: fit-content;
`;
const DownloadBtn = styled.a`
	padding: 8px 12px;
	cursor: pointer;
	border: 1px solid #000;
	border-radius: 8px;
	transition: all 0.2s;
	position: relative;
	max-height: 50px;
	background: #000;
	border: 1px solid#000;
	color: #fff;
	&:hover {
		background: rgb(133, 98, 206);
		border: 1px solid rgb(133, 98, 206);
	}
`;
