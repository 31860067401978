import React from "react";
import styled from "styled-components";
import { Button, Modal } from "../UI";

interface Props {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ThanksModal = ({ modalOpen, setModalOpen }: Props) => {
  const onOkClick = () => {
    setModalOpen(false);
    document.body.style.overflow = "auto";
  };
  return (
    <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
      <RequestModalWrapper>
        <RequestModalTitle>Thank you for the suggestion!</RequestModalTitle>
        <ThanksModalText>
          Our team will review it and will notify you when the requested
          addition will be added
        </ThanksModalText>
        <ModalButtonWrapper>
          <Button type="button" onClick={onOkClick} width="100%">
            Ok
          </Button>
        </ModalButtonWrapper>
      </RequestModalWrapper>
    </Modal>
  );
};
const RequestModalTitle = styled.div`
  margin-bottom: 24px;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;
const ModalButtonWrapper = styled.div`
  text-align: center;
  width: 176px;
  margin: 24px auto 0px;
`;
const RequestModalWrapper = styled.div`
  width: 480px;
  padding: 20px 8px;
`;
const ThanksModalText = styled.div`
  margin-top: -8px;
  text-align: center;
  margin-bottom: 24px;
`;
