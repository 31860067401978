import React, { ReactNode } from "react";
import styled from "styled-components";

interface Props {
	children: string | ReactNode;
	onDelete?: any;
}

export const DeleteTag = ({ children, onDelete }: Props) => {
	return (
		<Wrapper>
			<WrapperChild>
				{children}
				<svg
					onClick={onDelete}
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="#000"
					xmlns="http://www.w3.org/2000/svg">
					<rect rx={"8"} />

					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M5.02022 4.3131C4.82496 4.11784 4.50837 4.11784 4.31311 4.3131C4.11785 4.50837 4.11785 4.82495 4.31311 5.02021L7.29289 7.99999L4.31311 10.9798C4.11785 11.175 4.11785 11.4916 4.31311 11.6869C4.50837 11.8821 4.82496 11.8821 5.02022 11.6869L8 8.7071L10.9798 11.6869C11.175 11.8821 11.4916 11.8821 11.6869 11.6869C11.8821 11.4916 11.8821 11.175 11.6869 10.9798L8.7071 7.99999L11.6869 5.02021C11.8821 4.82495 11.8821 4.50837 11.6869 4.3131C11.4916 4.11784 11.175 4.11784 10.9798 4.3131L8 7.29288L5.02022 4.3131Z"
						fill="black"
					/>
				</svg>
			</WrapperChild>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding: 4px 4px;
	background: #ebebeb;
	border-radius: 8px;
	border-radius: 8px;
	color: #000;
	display: inline-block;
	max-height: 28px;
`;
const WrapperChild = styled.div`
	display: flex;
	align-items: center;
	& > svg {
		margin-left: 4px;
		cursor: pointer;
		& > rect {
			transition: 0.2s;
		}
		&:hover {
			& > rect {
				width: 16px;
				height: 16px;
				fill: #505050;
				transition: 0.2s;
			}
			& > path {
				fill: white;
			}
		}
	}
`;
