import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ContainerItem } from "./ContainerItem";
import { license } from "../../static/images";
import { useAppSelector } from "../../hooks/redux";
import { useNavigate } from "react-router-dom";

export const Purchase = () => {
	const [userCountry, setUserCountry] = useState(0);
	const [vatValue, setVatValue] = useState(0);

	const { checkoutItem, paymentInfo } = useAppSelector((state) => state.cart);
	const { artbuyerProfile } = useAppSelector((state) => state.profile);
	const { countries } = useAppSelector((state) => state.countries);

	const navigate = useNavigate();

	const getVatValue = () => {
		const element = countries.find((el) => el.id === userCountry);
		setVatValue(element?.tax || 0);
	};

	const precentValue = checkoutItem?.subTotal ? (checkoutItem.subTotal / 100) * vatValue : 0;

	useEffect(() => {
		getVatValue();
		setUserCountry(artbuyerProfile.countryId);
	}, [artbuyerProfile, userCountry]);

	useEffect(() => {
		if (!checkoutItem) {
			navigate("/creator/profile/cart");
		}
	}, []);

	return (
		<Wrapper>
			<Title>Thanks for your purchase!</Title>
			<Text>
				Image may take a minute to download. Don’t want to wait? We’ll email you a download link
				shortly.
			</Text>
			<Container>
				<Order>
					<ContainerItem vatValue={vatValue} isPurchase={true} cartItemsArr={paymentInfo[0]} />
				</Order>
				<PurchaseLicense>
					<h4>License information</h4>
					<PurchaseLicenseList>
						<li>
							<a
								target="_blank"
								href={`${process.env.REACT_APP_DOMEN}/assets/themes/theme-ab/pdf/Web.pdf`}>
								Online / Web
							</a>
						</li>
						<li>
							<a
								target="_blank"
								href={`${process.env.REACT_APP_DOMEN}/assets/themes/theme-ab/pdf/Editorial.pdf`}>
								Editorial
							</a>
						</li>
						<li>
							<a
								target="_blank"
								href={`${process.env.REACT_APP_DOMEN}/assets/themes/theme-ab/pdf/Extended.pdf`}>
								Extended
							</a>
						</li>
					</PurchaseLicenseList>
				</PurchaseLicense>
			</Container>
		</Wrapper>
	);
};
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1136px;
	margin: 0 auto;
`;
const Title = styled.h2`
	margin-bottom: 16px;
	font-weight: 700;
	font-size: 40px;
	line-height: 48px;
`;
const Text = styled.div`
	margin-bottom: 40px;
	line-height: 24px;
`;
const Container = styled.div`
	display: flex;
`;
const PurchaseLicense = styled.div`
	padding: 24px;
	background: #fff;
	border-radius: 8px;
	width: 368px;
	height: fit-content;
	& > h4 {
		font-weight: 700;
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 16px;
	}
`;

const PurchaseLicenseList = styled.ul`
	& > li {
		& > a {
			padding-left: 32px;

			position: relative;
			color: #8562ce;
			cursor: pointer;
			&::after {
				position: absolute;
				left: 0;
				top: -4px;
				content: "";
				width: 24px;
				height: 24px;
				background-image: url(${license});
			}
		}
		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}
`;
const Order = styled.div`
	margin-right: 16px;
	width: 100%;
`;
