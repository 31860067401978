import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Modal } from "../UI";
import { trash } from "../../static/images";
import { Briefing } from "../../models/IBrifing";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { updateBriefing } from "../../store/reducers/artbuyerJobRequests/action";
import { DeleteRequestModal } from "./DeleteRequest";
import { PhotoshootEdit } from "./PhotoshootEdit";
import { splitArrayToGalleryFormat } from "../../utils";
import { SuubmittedImage } from "../JobRequests/SuubmittedImage";
import { AuthRoles } from "../../models/IProfile";
import { formatDate } from "../JobRequests";
import { Chat } from "../JobRequests/Chat";
import {
	getJobRequestNotInterested,
	getphotographerBriefing,
} from "../../store/reducers/photographerJobRequests/action";

interface Props {
	element: Briefing | undefined;
	onClose: () => void;
	getRequests: () => void;
	setIsUploadImagesModal?: React.Dispatch<React.SetStateAction<boolean>>;
	getSuggestions: () => void;
	suggestions: [] | never[];
	setIsChangedToDraft: React.Dispatch<React.SetStateAction<boolean>>;
}

export const JobRequestInfo = ({
	element,
	getRequests,
	onClose,
	setIsUploadImagesModal,
	getSuggestions,
	suggestions,
	setIsChangedToDraft,
}: Props) => {
	const [isDeleteRequestOpen, setIsDeleteRequestOpen] = useState(false);
	const [isPhotoshootOpen, setIsPhotoshootOpen] = useState(false);
	const [isChatFocus, setIsChatFocus] = useState(false);
	const [briefingDetails, setBriefingDetails] = useState<any>();

	const { user } = useAppSelector((state) => state.authData);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (user?.role === AuthRoles.Photographer) {
			getSuggestions();
			const getBriefing = async () => {
				const { payload } = await dispatch(getphotographerBriefing(element?.id || 0));
				setBriefingDetails(payload);
			};
			getBriefing();
		}
	}, []);

	const onDeleteRequest = () => {
		setIsDeleteRequestOpen(true);
	};

	const onClosePhotoshoot = () => {
		setIsPhotoshootOpen(false);
		onClose();
	};

	const onEditReqeust = () => {
		setIsPhotoshootOpen(true);
	};

	const onConvertToDraft = async () => {
		const json = { ...element, status: "DRAFT" };

		await dispatch(updateBriefing(JSON.stringify(json)));
		getRequests();
		onClose();
		setIsChangedToDraft(true);
		document.body.style.overflow = "auto";
		setTimeout(() => {
			setIsChangedToDraft(false);
		}, 4000);
	};

	const onSubmitPhotos = () => {
		setIsUploadImagesModal?.(true);
	};

	const onCancelRequest = async () => {
		if (user?.role === AuthRoles.Photographer) {
			await dispatch(getJobRequestNotInterested(element?.id || 0));
			onClose();
			getRequests();
		}
	};

	const splitedArray = splitArrayToGalleryFormat(
		user?.role === AuthRoles.Photographer ? suggestions : element?.photosList
	);

	return (
		<Wrapper>
			<JobRequest>
				{user?.role === AuthRoles.Photographer ? (
					<JobReqeustHeader style={{ display: "flex", justifyContent: "space-between" }}>
						<JobRequestTitle>Job Request</JobRequestTitle>
						<div>
							<Button theme={"outline"} style={{ marginRight: "12px" }} onClick={onCancelRequest}>
								Cancel
							</Button>

							{element?.briefingType === "PHOTO" ? (
								<Button onClick={onSubmitPhotos}>Submit Photos</Button>
							) : (
								<div></div>
								// <Button>Apply for the photoshoot</Button>
							)}
						</div>
					</JobReqeustHeader>
				) : (
					<JobReqeustHeader>
						<JobRequestTitle>Job Request</JobRequestTitle>
						<JobRequestStatus>Published</JobRequestStatus>
						<DeleteRequest onClick={onDeleteRequest}>
							<img src={trash} alt="trash" />
							Delete Request
						</DeleteRequest>
						<Button onClick={onConvertToDraft} theme={"outline"}>
							Convert to Draft
						</Button>
						<Button onClick={onEditReqeust} theme={"outline"}>
							Edit Request
						</Button>
					</JobReqeustHeader>
				)}
				<JobRequestAbout>
					<JobRequestSubtitle>{element?.title}</JobRequestSubtitle>
					<JobRequestAboutDesc>{briefingDetails?.description}</JobRequestAboutDesc>
					<JobRequestInner>
						<JobRequestItem>
							<JobRequestItemTitle>Budget</JobRequestItemTitle>
							<JobRequestItemText>
								{element?.currency === "USD" ? "$" : "€"} {element?.budget} /{" "}
								{element?.budgetTotal ? "For all" : "Per day"}
							</JobRequestItemText>
						</JobRequestItem>
						{element?.briefingType === "PHOTO" || (
							<>
								<JobRequestItem>
									<JobRequestItemTitle>Total Shooting Days</JobRequestItemTitle>
									<JobRequestItemText>
										{element?.totalShootingDays ? element.totalShootingDays : "-"}
									</JobRequestItemText>
								</JobRequestItem>
								<JobRequestItem>
									<JobRequestItemTitle>Approx Shooting Period</JobRequestItemTitle>
									<JobRequestItemText>
										{formatDate(element?.approxShootingStart)} -{" "}
										{formatDate(element?.approxShootingEnd)}
									</JobRequestItemText>
								</JobRequestItem>
							</>
						)}

						<JobRequestItem>
							<JobRequestItemTitle>Briefing Start</JobRequestItemTitle>
							<JobRequestItemText>{formatDate(element?.dateCreate)}</JobRequestItemText>
						</JobRequestItem>
						<JobRequestItem>
							<JobRequestItemTitle>Submission Deadline</JobRequestItemTitle>
							<JobRequestItemText>{formatDate(element?.dateExpire)}</JobRequestItemText>
						</JobRequestItem>
					</JobRequestInner>
				</JobRequestAbout>
				<JobRequestImages>
					<JobRequestSubtitle>Visual References</JobRequestSubtitle>
					<JobRequestImagesInner>
						{element?.examplesList.map((el, index) => {
							return (
								<img
									key={index}
									src={`${process.env.REACT_APP_DOMEN}${el.imageDetails.LIGHTBOX?.url}`}
									alt=""
								/>
							);
						})}
					</JobRequestImagesInner>
				</JobRequestImages>
				{element?.briefingType === "PHOTO" && (
					<SubmittedImagesWrapper>
						<JobRequestSubtitle>Submitted Images</JobRequestSubtitle>
						<SubmittedImages>
							{splitedArray.map((array, i) => (
								<ImagesRequestsColumn key={i}>
									{array.map((el, index) => (
										<SuubmittedImage
											setIsChatFocus={setIsChatFocus}
											getSuggestions={getSuggestions}
											pricing={el.pricing}
											briefingId={element.id}
											key={index}
											el={el}
											release={el.releaseAvailable}
										/>
									))}
								</ImagesRequestsColumn>
							))}
						</SubmittedImages>
					</SubmittedImagesWrapper>
				)}
			</JobRequest>
			<Chat chatFocus={isChatFocus} element={element} />
			<Modal
				size="small"
				isOpen={isDeleteRequestOpen}
				onClose={() => setIsDeleteRequestOpen(false)}>
				<DeleteRequestModal
					getRequests={getRequests}
					setFileInfoOpen={onClose}
					fileToDelete={element?.id}
					modalOpen={isDeleteRequestOpen}
					setModalOpen={() => setIsDeleteRequestOpen(false)}
				/>
			</Modal>
			<Modal
				hidden={true}
				isOpen={isPhotoshootOpen}
				size="small"
				noPadding
				onClose={() => setIsPhotoshootOpen(false)}>
				<PhotoshootEdit
					onClosePhotoshoot={onClosePhotoshoot}
					getRequests={getRequests}
					element={element}
				/>
			</Modal>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	height: 95vh;
	padding-top: 7vh;
	width: 95vw;
`;

const JobRequest = styled.div`
	width: 68vw;
	background: #fff;
	margin-right: 16px;
	padding: 24px;
	overflow-y: auto;
	border-radius: 10px;
`;
const JobReqeustHeader = styled.div`
	display: flex;
	margin-bottom: 24px;
	align-items: center;
`;
const JobRequestTitle = styled.div`
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
`;
const JobRequestStatus = styled.div`
	color: #62c78f;
	margin-right: 12px;
	margin-left: auto;
`;
const DeleteRequest = styled.div`
	padding: 13px 20px 10px;
	color: #505050;
	background: #f7f7f7;
	border-radius: 8px;
	display: flex;
	align-items: center;
	margin-right: 8px;
	cursor: pointer;
	& > img {
		margin-right: 10px;
	}
`;
const JobRequestAbout = styled.div`
	padding-bottom: 24px;
	border-bottom: 1px solid #ebebeb;
`;
const JobRequestSubtitle = styled.div`
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 8px;
`;
const JobRequestAboutDesc = styled.div`
	margin-bottom: 24px;
`;
const JobRequestInner = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 16px;
`;
const JobRequestItem = styled.div``;
const JobRequestItemTitle = styled.div`
	font-weight: 600;
	color: #282828;
	margin-bottom: 8px;
`;
const JobRequestItemText = styled.div``;
const JobRequestImages = styled.div`
	margin-top: 24px;
`;
const JobRequestImagesInner = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	& > img {
		max-height: 132px;
		max-width: 96px;
	}
`;
const SubmittedImagesWrapper = styled.div`
	padding-top: 24px;
	border-top: 1px solid #ebebeb;
	margin-top: 24px;
`;
const SubmittedImages = styled.div`
	margin-top: 24px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 16px;
`;
const ImagesRequestsColumn = styled.div`
	width: 25%;
`;
