import {
	AuthRoles,
	IPhotographerProfileUpdate,
	IPhotographerSocialLinks,
	IPhotographerAbout,
	IPhotographerMarketPlace,
	IBasicInfo,
	buyerProfile,
	IPhotographerProfile,
} from "./../../../models/IProfile";
import { createSlice } from "@reduxjs/toolkit";
import {
	artbuyerProfile,
	getPhotographerAccount,
	getUserAccount,
	photographerProfile,
	updateArbuyerAvatar,
	updateAvatar,
	updatePhotographerProfile,
} from "./action";

interface ProfileState {
	[x: string]: any;
	photographerAbout: IPhotographerAbout;
	photographerMarketPlace: IPhotographerMarketPlace;
	photographerBasicInfo: IBasicInfo;
	photographerSocialLinks: IPhotographerSocialLinks;

	isLoading: boolean;

	profileError: string | undefined;

	photographerProfileUpdate?: IPhotographerProfileUpdate;
	photographerProfileUpdateError?: string;

	userAccountInfo: IPhotographerProfile;

	artbuyerProfile: buyerProfile;
}

const initialState: ProfileState = {
	photographerAbout: {
		firstName: "",
		lastName: "",
		email: "",
		siteURL: "",
		title: "",
		about: "",
		position: { label: AuthRoles.Photographer, value: AuthRoles.Photographer },
		city: "",
		countryId: 0,
		avatarURL: "",
		profession: "",
	},
	photographerMarketPlace: {
		billingFirstName: "",
		billingLastName: "",
		countryId: 0,
		billingAddress: "",
		billingAddress2: "",
		billingCity: "",
		billingZip: "",
		billingCompany: "",
	},
	photographerSocialLinks: {
		twitterUserId: "",
		facebookUserId: "",
		instagramUserId: "",
		vimeoId: "",
		linkedInId: "",
	},

	photographerBasicInfo: {
		email: "",
		phone: "",
		name: "",
		receiveNewsletter: false,
	},

	isLoading: false,

	profileError: undefined,

	photographerProfileUpdate: undefined,
	photographerProfileUpdateError: undefined,

	artbuyerProfile: {
		id: 0,
		login: "",
		name: "",
		firstName: "",
		lastName: "",
		company: "",
		position: "",
		street: "",
		address: "",
		address2: "",
		zip: "",
		city: "",
		countryId: 0,
		email: "",
		siteURL: "",
		about: "",
		vatid: "",
		billingFirstName: "",
		billingLastName: "",
		billingCompany: "",
		billingAddress: "",
		billingAddress2: "",
		billingZip: "",
		billingCity: "",
		avatarURL: "",
		phone: "",
		sex: "",
		receiveNewsletter: true,
		canAddVatId: true,
		landingTour: true,
		createBriefTour: true,
		currency: "",
		status: "",
		facebookUserId: "",
		instagramUserId: "",
		twitterUserId: "",
		vimeoId: "",
		linkedInId: "",
	},
	userAccountInfo: {
		id: 0,
		profession: "",
		login: "",
		name: "",
		firstName: "",
		lastName: "",
		street: "",
		address: "",
		address2: "",
		zip: "",
		city: "",
		countryId: 0,
		title: "",
		email: "",
		siteURL: "",
		about: "",
		vatid: "",
		billingFirstName: "",
		billingLastName: "",
		billingCompany: "",
		billingAddress: "",
		billingAddress2: "",
		billingZip: "",
		billingCity: "",
		avatarURL: "",
		phone: "",
		sex: "",
		likesCount: 0,
		viewsCount: 0,
		photosCount: 0,
		rating: 0,
		photoRequestsCountShowroom: 0,
		photoRequestsCountBrief: 0,
		commentsCount: 0,
		newLikesCount: 0,
		newViewsCount: 0,
		newRating: 0,
		newPhotoRequestsCountShowroom: 0,
		newPhotoRequestsCountBrief: 0,
		commentsCountNew: 0,
		salesCount: 0,
		followers: 0,
		followings: 0,
		participating: 0,
		haveStripeAccount: true,
		activationId: "",
		receiveNewsletter: true,
		canAddVatId: true,
		landingTour: true,
		showSuccessActivationMessage: true,
		currency: "",
		status: "",
		facebookUserId: "",
		instagramUserId: "",
		twitterUserId: "",
		vimeoId: "",
		linkedInId: "",
	},
};

export const profileSlice = createSlice({
	name: "user/profile",
	initialState,
	reducers: {
		photographerAboutUpating: (state, action) => {
			state.photographerAbout = action.payload;
		},
		photographerSocialLinksUpating: (state, action) => {
			state.photographerSocialLinks = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(artbuyerProfile.fulfilled, (state, action) => {
				state.artbuyerProfile = action.payload;
				localStorage.setItem("userEmail", action.payload.email);
				state.isLoading = false;
			})
			.addCase(artbuyerProfile.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(artbuyerProfile.rejected, (state) => {
				state.isLoading = false;
			})
			.addCase(photographerProfile.fulfilled, (state, action) => {
				state.photographerAbout.firstName = action.payload.firstName;
				state.photographerAbout.lastName = action.payload.lastName;
				state.photographerAbout.email = action.payload.email;
				localStorage.setItem("userEmail", action.payload.email);
				state.photographerAbout.siteURL = action.payload.siteURL;
				state.photographerAbout.about = action.payload.about;
				state.photographerAbout.city = action.payload.city;
				state.photographerAbout.avatarURL = action.payload.avatarURL;
				state.photographerAbout.countryId = action.payload.countryId;
				state.photographerAbout.title = action.payload.title;
				state.photographerAbout.profession = action.payload.profession;

				state.photographerMarketPlace.billingAddress = action.payload.billingAddress;
				state.photographerMarketPlace.billingAddress2 = action.payload.billingAddress2;
				state.photographerMarketPlace.billingLastName = action.payload.billingLastName;
				state.photographerMarketPlace.billingFirstName = action.payload.billingFirstName;
				state.photographerMarketPlace.billingZip = action.payload.billingZip;
				state.photographerMarketPlace.billingCity = action.payload.billingCity;
				state.photographerMarketPlace.billingCompany = action.payload.billingCompany;
				state.photographerMarketPlace.countryId = action.payload.countryId;

				state.photographerSocialLinks.facebookUserId = action.payload.facebookUserId;
				state.photographerSocialLinks.twitterUserId = action.payload.twitterUserId;
				state.photographerSocialLinks.instagramUserId = action.payload.instagramUserId;
				state.photographerSocialLinks.vimeoId = action.payload.vimeoId;
				state.photographerSocialLinks.linkedInId = action.payload.linkedInId;

				state.photographerBasicInfo.email = action.payload.email;
				state.photographerBasicInfo.phone = action.payload.phone;
				state.photographerBasicInfo.name = action.payload.name;
				state.photographerBasicInfo.receiveNewsletter = action.payload.receiveNewsletter;

				state.isLoading = false;
			})
			.addCase(photographerProfile.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(photographerProfile.rejected, (state, action) => {
				state.profileError = action.payload as string;
				state.isLoading = false;
			})
			.addCase(updateAvatar.fulfilled, (state, action) => {
				state.photographerAbout.avatarURL = action.payload.avatarURL;
			})
			.addCase(updateArbuyerAvatar.fulfilled, (state, action) => {
				state.artbuyerProfile.avatarURL = action.payload.avatarURL;
			})
			.addCase(updatePhotographerProfile.fulfilled, (state, action) => {
				state.photographerProfileUpdate = action.payload;
				state.photographerProfileUpdateError = undefined;
			})
			.addCase(updatePhotographerProfile.rejected, (state, action) => {
				state.photographerProfileUpdateError = action.payload as string;
			})
			.addCase(getUserAccount.fulfilled, (state, action) => {
				state.userAccountInfo = action.payload.data;
			})
			.addCase(getPhotographerAccount.fulfilled, (state, action) => {
				state.userAccountInfo = action.payload.data;
			});
	},
});

export const { reducer: profileSliceReducer, actions: profileSliceActions } = profileSlice;

export const { photographerAboutUpating, photographerSocialLinksUpating } = profileSliceActions;
