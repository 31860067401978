import { useEffect, useState } from "react";
import styled from "styled-components";
import {
	askForPrice,
	comment,
	deleteImage,
	editImage,
	message,
	putInCart,
	seeMore,
} from "../../static/images";
import { Modal } from "../UI";
import { ChooseImageToCart } from "../Modals/ChooseImageToCart";
import { PriceRequest } from "../Modals/PriceRequest";
import { ImageInfo } from "../Modals/ImageInfo";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { AuthRoles } from "../../models/IProfile";
import { SubmitImageModal } from "../Modals/SubmitImage";
import { DeleteImage } from "../Modals/DeleteImage";
import { ISuggestedImageInfo, PhotosList } from "../../models/IBrifing";
import { CornerMessage } from "../UI/CornerMessage";
import { getArtbuyerSuggestedImage } from "../../store/reducers/artbuyerJobRequests/action";
import { getPricingStatus } from "../../utils/getSmth";

interface Props {
	el: PhotosList;
	briefingId: number;
	getSuggestions: () => void;
	pricing: any;
	release: any;
	setIsChatFocus: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SuubmittedImage = ({
	el,
	briefingId,
	setIsChatFocus,
	release,
	getSuggestions,
	pricing,
}: Props) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const [isPriceRequestOpen, setIsPriceRequestOpen] = useState(false);
	const [isImageInfoOpen, setIsImageInfoOpen] = useState(false);
	const [isSubmitImageOpen, setIsSubmitImageOpen] = useState(false);
	const [isDeleteImage, setIsDeleteImage] = useState(false);
	const [isPriceRequestSended, setIsPriceRequestSended] = useState(false);
	const [currentImageInfo, setCurrentImageInfo] = useState<ISuggestedImageInfo>(
		{} as ISuggestedImageInfo
	);

	const { user } = useAppSelector((state) => state.authData);
	const dispatch = useAppDispatch();

	useEffect(() => {
		const onGetSuggestedImage = async () => {
			if (user?.role === AuthRoles.Photographer) {
			} else {
				const { payload } = await dispatch(
					getArtbuyerSuggestedImage({ briefingId, photoId: el.photoId })
				);
				setCurrentImageInfo(payload);
			}
		};

		onGetSuggestedImage();
	}, []);

	const onSeeMore = () => {
		setIsMenuOpen((prev) => !prev);
	};

	const onPutInCart = () => {
		setEditOpen(true);
	};
	const onSendMessage = () => {
		setIsMenuOpen((prev) => !prev);
		setIsChatFocus((prev) => !prev);
	};
	const onCommentImage = () => {
		setIsImageInfoOpen(true);
	};
	const onAskForPrice = () => {
		setIsPriceRequestOpen(true);
	};
	const onEditImage = () => {
		setIsSubmitImageOpen(true);
	};
	const onDeleteImage = () => {
		setIsDeleteImage(true);
	};

	return (
		<ImagesRequestsItem onMouseLeave={() => setIsMenuOpen(false)}>
			<SeeMore onClick={onSeeMore}>
				<img src={seeMore} alt="seeMore" />
			</SeeMore>
			<img src={`${process.env.REACT_APP_DOMEN}${el.imageDetails.LIGHTBOX.url}`} alt="img" />
			<SubmittedImagesInfo>
				<SubmittedImagesInfoTitle>Release</SubmittedImagesInfoTitle>
				<SubmittedImagesInfoText>
					{release === "POSSIBLE" ? "Possible" : release === "YES" ? "Yes" : "No"}
				</SubmittedImagesInfoText>
			</SubmittedImagesInfo>
			<SubmittedImagesInfo>
				<SubmittedImagesInfoTitle>Price</SubmittedImagesInfoTitle>
				<SubmittedImagesInfoText>
					{getPricingStatus(pricing || currentImageInfo.pricing)}
				</SubmittedImagesInfoText>
			</SubmittedImagesInfo>
			{isMenuOpen && (
				<ProfileMenuOpen className="profileMenu">
					{user?.role === AuthRoles.Photographer ? (
						<ul>
							<li onClick={onEditImage}>
								<img src={editImage} alt="editImage" />
								Edit Details
							</li>
							<li onClick={onDeleteImage}>
								<img src={deleteImage} alt="editImage" />
								Delete Image
							</li>
						</ul>
					) : (
						<ul>
							<li onClick={onSendMessage}>
								<img src={message} alt="message" />
								Send Message
							</li>
							{currentImageInfo.pricing === "CUSTOM" ? (
								<>
									<li onClick={onPutInCart}>
										<img src={putInCart} alt="putInCart" />
										Put Photo in Cart
									</li>
									<li onClick={onCommentImage}>
										<img src={comment} alt="comment" />
										Comment Image
									</li>
								</>
							) : (
								<li onClick={onAskForPrice}>
									<img src={askForPrice} alt="askForPrice" />
									Ask for Price
								</li>
							)}
						</ul>
					)}
				</ProfileMenuOpen>
			)}
			<Modal padding="40px 32px" isOpen={editOpen} onClose={() => setEditOpen(false)}>
				<ChooseImageToCart onClose={() => setEditOpen(false)} el={currentImageInfo} />
			</Modal>
			<Modal
				hidden={true}
				isOpen={isPriceRequestOpen}
				noPadding
				onClose={() => setIsPriceRequestOpen(false)}>
				<PriceRequest
					isPriceRequestSended={isPriceRequestSended}
					setIsPriceRequestSended={setIsPriceRequestSended}
					el={el}
					photoId={currentImageInfo.photoId}
					onClose={setIsPriceRequestOpen}
				/>
			</Modal>
			<Modal
				hidden={true}
				isOpen={isImageInfoOpen}
				noPadding
				onClose={() => setIsImageInfoOpen(false)}>
				<ImageInfo
					onClose={() => setIsImageInfoOpen(false)}
					currentImageInfo={currentImageInfo}
					el={el}
				/>
			</Modal>
			<Modal
				hidden={true}
				isOpen={isSubmitImageOpen}
				size="small"
				noPadding
				onClose={() => setIsSubmitImageOpen(false)}>
				<SubmitImageModal
					el={el}
					briefingId={briefingId}
					getSuggestions={getSuggestions}
					onClose={() => setIsSubmitImageOpen(false)}
				/>
			</Modal>
			<Modal size="small" isOpen={isDeleteImage} onClose={() => setIsDeleteImage(false)}>
				<DeleteImage
					getSuggestions={getSuggestions}
					onClose={() => setIsDeleteImage(false)}
					el={el}
					briefingId={briefingId}
				/>
			</Modal>
			<CornerMessage
				span="Success! "
				text={"Your price request has been sent"}
				setIsAddedToCart={setIsPriceRequestSended}
				isAddedToCart={isPriceRequestSended}
			/>
		</ImagesRequestsItem>
	);
};

const ImagesRequestsItem = styled.div`
	width: 400%;
	position: relative;
	& > img {
		width: 100%;
		margin-bottom: 8px;
	}
	&:hover {
		& > .profileMenu {
			opacity: 1;
		}
		& > div:nth-child(1) {
			opacity: 1;
			transition: all.2s;
		}
		& > div:last-child {
			opacity: 1;
			transition: all.2s;
		}
	}
	margin-bottom: 16px;
`;
const SubmittedImagesInfo = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 4px;
`;
const SubmittedImagesInfoTitle = styled.div`
	font-size: 12px;
	line-height: 16px;
	color: #505050;
`;
const SubmittedImagesInfoText = styled.div`
	font-size: 12px;
	line-height: 16px;
	color: #000000;
`;
const SeeMore = styled.div`
	width: 32px;
	height: 32px;
	border-radius: 50%;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 8px;
	right: 10px;
	opacity: 0;
	transition: all.2s;
	cursor: pointer;
`;
const ProfileMenuOpen = styled.div<any>`
	right: 0;
	opacity: 0;
	top: 48px;
	z-index: 7;
	padding: 16px;
	position: absolute;
	box-shadow: 0px 8px 41px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	background: #fff;
	& > ul {
		& > li {
			cursor: pointer;
			&:not(:last-child) {
				margin-bottom: 16px;
			}
			display: flex;
			align-items: center;
			& > img {
				margin-right: 8px;
			}
			color: #505050;
		}
	}
`;
