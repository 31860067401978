import { useEffect, useRef, useState, ReactNode } from "react";
import styled from "styled-components";
import { arrowScrollRight } from "../../static/images";

export const ScrollMenu = ({ children }: { children: ReactNode }) => {
	const [currentScroll, setCurrentScroll] = useState(0);

	const ref = useRef<any>();

	const onScroll = () => {
		var scrolled = ref.current.scrollLeft;
		setCurrentScroll(scrolled);
	};

	useEffect(() => {
		const el = ref.current;
		if (el) {
			const onWheel = (e: any) => {
				e.preventDefault();
				el.scrollTo({
					left: el.scrollLeft + e.deltaY,
					behavior: "smooth",
				});
			};
			el.addEventListener("wheel", onWheel);

			return () => el.removeEventListener("wheel", onWheel);
		}
	}, []);

	const sliderLeft = () => {
		const el = ref.current;
		el.scrollLeft = el.scrollLeft - 260;
	};
	const sliderRight = () => {
		const el = ref.current;
		el.scrollLeft = el.scrollLeft + 260;
	};

	return (
		<Wrapper>
			{currentScroll > 0 && (
				<>
					<img
						className="leftArrow"
						onClick={(e) => {
							sliderLeft();
							e.stopPropagation();
						}}
						style={{ transform: "rotate(180deg)" }}
						src={arrowScrollRight}
						alt=""
					/>
					<BlurBgLeft></BlurBgLeft>
				</>
			)}

			<TagsMenu onScroll={onScroll} ref={ref}>
				{children}
			</TagsMenu>
			<BlurBg></BlurBg>

			<img
				className="rightArrow"
				onClick={(e) => {
					sliderRight();
					e.stopPropagation();
				}}
				src={arrowScrollRight}
				alt=""
			/>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	width: 336px;
	position: relative;
	margin-bottom: 16px;
	& > img {
		cursor: pointer;
		position: absolute;
		z-index: 5;
	}
	& .leftArrow {
		left: 0;
	}
	& .rightArrow {
		right: 0;
	}
`;
const BlurBg = styled.div`
	background: linear-gradient(270deg, #ffffff 32.5%, rgba(255, 255, 255, 0) 100%);
	position: absolute;
	width: 40px;
	height: 80px;
	top: 0;
	right: 0;
`;
const BlurBgLeft = styled.div`
	background: linear-gradient(270deg, #ffffff 32.5%, rgba(255, 255, 255, 0) 100%);
	position: absolute;
	transform: rotate(180deg);
	z-index: 4;

	width: 40px;
	height: 80px;
	top: 0;
	left: 0;
`;
const TagsMenu = styled.div`
	width: 336px;
	position: relative;
	overflow-x: auto;
	overscroll-behavior-inline: contain;
	scroll-snap-type: inline mandatory;
	scroll-behavior: smooth;
	&::-webkit-scrollbar {
		width: 0;
		height: 0;
		opacity: 0;

		background: transparent;
		transition: 1s;
	}
`;
