import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Button } from "../UI";
import { PaymentInfo } from "./PaymentInfo";
import { PaymentSended } from "./PaymentSended";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
	postPaymentRequest,
	submitRequest,
} from "../../store/reducers/photographerImageRequest/action";
import { useNavigate } from "react-router-dom";

export const SendPaymentRequest = () => {
	const [dragging, setDragging] = useState(false);

	// const [selectedPdf, setSelectedPdf] = useState("");
	// const [selectedPdfFile, setSelectedPdfFile] = useState();

	const [selectedPhoto, setSelectedPhoto] = useState("");
	const [selectedPhotoFile, setSelectedPhotoFile] = useState();

	const [isPaymentReady, setIsPaymentReady] = useState(false);
	const [isPaymentSended, setIsPaymentSended] = useState(false);

	const { fileToPayment } = useAppSelector((state) => state.photographerImageRequests);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (fileToPayment === null) {
			navigate("/creator/profile/imageRequests");
		}
	}, [fileToPayment, navigate]);

	const onSelectPhoto = (e: any) => {
		const files = e.target.files;

		setSelectedPhoto(URL.createObjectURL(files[0]));
		setSelectedPhotoFile(files[0]);
	};

	// const onSelectPdf = (e: any) => {
	// 	const files = e.target.files;

	// setSelectedPdfFile(files[0]);
	// setSelectedPdf(URL.createObjectURL(files[0]));
	// };

	const onDropHandler = (e: any) => {
		e.preventDefault();
		const files = [...e.dataTransfer.files];
		if (files[0].type === "application/pdf") {
			// setSelectedPdfFile(files[0]);
			// setSelectedPdf(URL.createObjectURL(files[0]));
		} else {
			setSelectedPhotoFile(files[0]);
			setSelectedPhoto(URL.createObjectURL(files[0]));
		}

		setDragging(false);
	};

	const onVerifyClick = () => {
		setIsPaymentReady(true);

		const formData = new FormData();

		if (selectedPhotoFile) {
			formData.append("requestId", String(fileToPayment?.id));

			formData.append("photo", selectedPhotoFile);

			// if (selectedPdfFile) {
			// 	formData.append("modelRelease", selectedPdfFile);
			// }

			dispatch(postPaymentRequest({ id: fileToPayment?.id, parameters: formData }));
		}
	};
	const onSendRequestClick = async () => {
		await dispatch(submitRequest({ id: fileToPayment?.id }));
		setIsPaymentSended(true);
	};
	return isPaymentSended ? (
		<PaymentSended />
	) : (
		<Wrapper>
			<Title>Send Payment Request</Title>
			{isPaymentReady ? (
				<PaymentInfo file={fileToPayment} img={selectedPhoto} />
			) : (
				<DragAndDrop
					onDragStart={(e: any) => {
						e.preventDefault();
						setDragging(true);
					}}
					onDragLeave={(e: any) => {
						e.preventDefault();
						setDragging(false);
					}}
					onDragOver={(e: any) => {
						e.preventDefault();
						setDragging(true);
					}}
					onDrop={(e: any) => onDropHandler(e)}
					active={selectedPhoto}
					isDragging={dragging}>
					{dragging ? (
						<DraggingTitle>Now let it go</DraggingTitle>
					) : (
						<>
							{selectedPhoto ? (
								<>
									<SelectedImage src={selectedPhoto} />
									<Button style={{ marginBottom: "25px" }} theme="outline">
										<AvatarEdit>
											<input
												accept="image/*, .png, .jpg,.web"
												onChange={(e) => onSelectPhoto(e)}
												type="file"
											/>
										</AvatarEdit>
										Change Photo
									</Button>
								</>
							) : (
								<>
									<DragAndDropTitle>Upload high-res file in JPG or JPEG format</DragAndDropTitle>
									<div style={{ marginBottom: "24px" }}>
										<Button theme="outline">
											<AvatarEdit>
												<input
													accept="image/*, .png, .jpg,.web"
													onChange={(e) => onSelectPhoto(e)}
													type="file"
												/>
											</AvatarEdit>
											Select Photo
										</Button>
									</div>
								</>
							)}

							{!selectedPhoto ? (
								<DragAndDropText>Or just drag&drop your images here</DragAndDropText>
							) : null}
							{/* {selectedPdf ? (
								<>
									<embed style={{ marginBottom: "24px" }} src={selectedPdf} width="400px" />
									<Button theme="outline">
										{" "}
										<AvatarEdit>
											<input
												accept="application/pdf,application/vnd.ms-excel"
												onChange={(e) => onSelectPdf(e)}
												type="file"
											/>
										</AvatarEdit>
										Change PDF
									</Button>
								</>
							) : (
								<>
									<DragAndDropTitle>Upload Model or Property Release (optional)</DragAndDropTitle>

									<Button theme="outline">
										{" "}
										<AvatarEdit>
											<input
												accept="application/pdf,application/vnd.ms-excel"
												onChange={(e) => onSelectPdf(e)}
												type="file"
											/>
										</AvatarEdit>
										Select PDF
									</Button>
								</>
							)} */}
						</>
					)}
				</DragAndDrop>
			)}

			<ButtonWrapper>
				{isPaymentReady ? (
					<Button onClick={onSendRequestClick} width="240px">
						Send Request
					</Button>
				) : (
					<Button width="240px" disabled={!selectedPhoto} onClick={onVerifyClick}>
						Verify
					</Button>
				)}
			</ButtonWrapper>
		</Wrapper>
	);
};
const ButtonWrapper = styled.div`
	text-align: center;
	margin-top: auto;
	margin-bottom: 50px;
`;
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: calc(100vh - 108px);
`;
const Title = styled.div`
	font-weight: 700;
	font-size: 40px;
	line-height: 48px;
	margin-bottom: 40px;
	color: #282828;
	text-align: center;
`;
const DragAndDrop = styled.div<any>`
	background: #f7f7f7;
	border: 2px dashed #d7d7d7;
	border-radius: 8px;
	margin-bottom: 40px;
	width: 70vw;
	margin: 0 auto;
	height: 55vh;
	margin-bottom: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	${({ active }) => {
		if (active) {
			return css`
				border: 0px transparent;
			`;
		}
	}}
	${({ isDragging }) => {
		if (isDragging) {
			return css`
				border: 2px dashed var(--purple-90, #8562ce);
			`;
		}
	}}
`;
const DragAndDropTitle = styled.div`
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 24px;
`;
const DragAndDropText = styled.div`
	font-size: 16px;
	line-height: 24px;
	color: #505050;
	margin: 0px 0px 40px;
`;
const AvatarEdit = styled.label`
	position: absolute;
	bottom: 0px;
	height: 44px;
	left: 0px;
	right: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	& > input {
		opacity: 0;
		visibility: hidden;
		position: absolute;
	}
`;
const SelectedImage = styled.img`
	width: 180px;
	max-height: 240px;
	margin-bottom: 24px;
`;
// const PdfSelected = styled.div`
// 	margin-bottom: 20px;
// 	font-size: 20px;
// 	font-weight: 600;
// `;

const DraggingTitle = styled.h2`
	font-size: 80px;
	font-weight: 700;
	line-height: 80px;
	color: var(--purple-90, #8562ce);
`;
