import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { getAllJobRequests, getJobRequests } from "../../store/reducers/artbuyerJobRequests/action";
import { AuthRoles } from "../../models/IProfile";
import {
	getAllphotographerJobRequests,
	getSuggestedPhotos,
	getphotographerJobRequests,
} from "../../store/reducers/photographerJobRequests/action";
import styled, { css } from "styled-components";
import { imagesCount, messagesCount } from "../../static/images";
import { Modal } from "../UI";
import { JobRequestInfo } from "../Modals/JobRequestInfo";
import { Briefing } from "../../models/IBrifing";
import { ScrollMenu } from "../UI/ScrollingImages";
import { JobRequestDraft } from "../Modals/JobRequestDraft";
import { getStatus } from "../../utils/getSmth";
import { IsLoading } from "../UI/IsLoading";
import { JobRequestConfirm } from "../Modals/JobRequestConfirm";
import { EditUploadedImages } from "../Modals/EditUploadedImages";
import { UploadImages } from "../Modals/UploadImages";
import { CornerMessage } from "../UI/CornerMessage";
import { TimeStamp } from "../../utils/variables";

export function formatDate(miliseconds: any) {
	const date = new Date(miliseconds);
	const month = date.toLocaleString("default", { month: "short" });
	const day = date.getDate();
	return `${day} ${month}`;
}

export const JobRequests = () => {
	const [requestsType, setRequestsType] = useState("ALL");
	const [jobRequestInfo, setJobRequestInfo] = useState(false);
	const [currentItem, setCurrentItem] = useState<Briefing>();
	const [confirmRequest, setConfirmRequest] = useState(false);
	const [isUploadImageModal, setIsUploadImagesModal] = useState(false);
	const [isEditImagesInfo, setIsEditImagesInfo] = useState(false);
	const [uploadedImages, setUploadImages] = useState<{ id: number; img: string }[]>([]);
	const [filesToUpload, setFilesToUpload] = useState<any[]>([]);
	const [suggestions, setSuggestions] = useState([]);
	const [isChangedToPublished, setIsChangedToPublished] = useState(false);
	const [isChangedToDraft, setIsChangedToDraft] = useState(false);

	const dispatch = useAppDispatch();

	const { user } = useAppSelector((state) => state.authData);

	const {
		jobRequests,
		isLoading,
		isAllLoading,
		allRequestsCount,
		ActiveRequestsCount,
		DraftsRequestsCount,
		ClosedRequestsCount,
	} = useAppSelector((state) =>
		user?.role === AuthRoles.Photographer
			? state.photographerJobRequests
			: state.artbuyerJobRequests
	);

	const getReqeusts = async () => {
		const parameters2 = `?page=1&pageSize=300&timestamp=${TimeStamp}`;
		user?.role === AuthRoles.Photographer
			? dispatch(getAllphotographerJobRequests(parameters2))
			: dispatch(getAllJobRequests(parameters2));

		const parameters = `?page=1&pageSize=300&timestamp=${TimeStamp}${
			requestsType !== "ALL" ? `&status=${requestsType}` : ""
		}`;
		user?.role === AuthRoles.Photographer
			? await dispatch(getphotographerJobRequests(parameters))
			: await dispatch(getJobRequests(parameters));
	};

	useEffect(() => {
		const parameters2 = `?page=1&pageSize=300&timestamp=${TimeStamp}`;
		user?.role === AuthRoles.Photographer
			? dispatch(getAllphotographerJobRequests(parameters2))
			: dispatch(getAllJobRequests(parameters2));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const parameters = `?page=1&pageSize=300&timestamp=${TimeStamp}${
			requestsType !== "ALL" ? `&status=${requestsType}` : ""
		}`;
		user?.role === AuthRoles.Photographer
			? dispatch(getphotographerJobRequests(parameters))
			: dispatch(getJobRequests(parameters));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requestsType]);

	const requestsTypesArtbuyer = [
		{ id: 0, title: "All Requests", parameter: "ALL", count: allRequestsCount },
		{ id: 1, title: "Active", parameter: "PUBLISHED", count: ActiveRequestsCount },
		{ id: 2, title: "Drafts", parameter: "DRAFT", count: DraftsRequestsCount },
		{ id: 3, title: "Closed", parameter: "CLOSED", count: ClosedRequestsCount },
		{ id: 4, title: "Rejected / Cancelled", parameter: "CLOSED", count: 0 },
	];
	const requestsTypesPhotographer = [
		{ id: 0, title: "All Requests", parameter: "ALL", count: allRequestsCount },
		{ id: 1, title: "Active", parameter: "PUBLISHED", count: ActiveRequestsCount },
		{ id: 3, title: "Closed", parameter: "CLOSED", count: ClosedRequestsCount },
		{ id: 4, title: "Rejected / Cancelled", parameter: "CLOSED", count: 0 },
	];

	const onItemClick = (el: Briefing) => {
		setCurrentItem(el);
		if (!el.tracked && user?.role === AuthRoles.Photographer) {
			setConfirmRequest(true);
		} else {
			setJobRequestInfo(true);
		}
	};

	const getSuggestions = async () => {
		const parameters = `?page=1&pageSize=100&timestamp=${TimeStamp}&tab=MY`;

		const { payload } = await dispatch(
			getSuggestedPhotos({ briefingId: currentItem?.id || 0, parameters })
		);
		setSuggestions(payload);
	};

	return (
		<>
			<Wrapper>
				{isLoading || isAllLoading ? (
					<IsLoading />
				) : (
					<>
						<Title>Job Requests</Title>

						<ProfileGallerySortList>
							{[
								...(user?.role === AuthRoles.Photographer
									? requestsTypesPhotographer
									: requestsTypesArtbuyer),
							].map((el) => (
								<ProfileGallerySortItem
									key={el.id}
									active={el.parameter === requestsType ? true : false}
									onClick={() => setRequestsType(el.parameter)}>
									{el.title}

									<ProfileGalleryTypeCount>{el.count}</ProfileGalleryTypeCount>
								</ProfileGallerySortItem>
							))}
						</ProfileGallerySortList>

						<RequestsGallery>
							{jobRequests.map((el, index) => {
								return (
									<RequestsItem
										style={el.tracked === false ? { border: "2px solid #8562ce" } : {}}
										onClick={() => onItemClick(el)}
										key={index}>
										<RequestsItemHeader>
											<RequestsItemHeaderType>
												{el.briefingType === "PHOTO" ? "Image Brief" : "Job Brief"}
											</RequestsItemHeaderType>

											<RequestsItemHeaderStatus
												style={{ color: getStatus(el.status, el.tracked).color }}>
												{getStatus(el.status, el.tracked).value}
											</RequestsItemHeaderStatus>
										</RequestsItemHeader>

										<RequestsItemDesc>{el.title}</RequestsItemDesc>

										<RequestsItemDate>
											{formatDate(el.dateCreate)} - {formatDate(el.dateExpire)}
										</RequestsItemDate>

										{el.examplesList.length > 0 ? (
											<ScrollMenu>
												<RequestsItemImages>
													{el.examplesList.map((el, index) => {
														return (
															<RequestsItemImage
																key={index}
																src={`${process.env.REACT_APP_DOMEN}${el.imageDetails.LIGHTBOX?.url}`}
																alt=""
															/>
														);
													})}
												</RequestsItemImages>
											</ScrollMenu>
										) : (
											<RequestsItemImages>
												<NoImageDetails>No images attached</NoImageDetails>
											</RequestsItemImages>
										)}

										<RequestsItemBottom>
											<RequestsItemPrice>
												{el.currency === "USD" ? "$" : "€"} {el.budget}.00
											</RequestsItemPrice>

											<RequestsItemCountsInner>
												<RequestsItemCounts>
													<img src={imagesCount} alt="messagesCount" />
													{el.examplesList.length}/10
												</RequestsItemCounts>

												<RequestsItemCounts>
													<img src={messagesCount} alt="messagesCount" />0
												</RequestsItemCounts>
											</RequestsItemCountsInner>
										</RequestsItemBottom>
									</RequestsItem>
								);
							})}
						</RequestsGallery>
						{currentItem?.status === "PUBLISHED" ? (
							<Modal
								transparent={true}
								noPadding
								isOpen={jobRequestInfo}
								onClose={() => setJobRequestInfo(false)}>
								<JobRequestInfo
									setIsChangedToDraft={setIsChangedToDraft}
									getSuggestions={getSuggestions}
									suggestions={suggestions}
									setIsUploadImagesModal={setIsUploadImagesModal}
									getRequests={getReqeusts}
									onClose={() => setJobRequestInfo(false)}
									element={currentItem}
								/>
							</Modal>
						) : (
							<Modal
								noPadding
								isOpen={jobRequestInfo}
								changeCloseSvg
								onClose={() => setJobRequestInfo(false)}>
								<JobRequestDraft
									setIsChangedToPublished={setIsChangedToPublished}
									getRequests={getReqeusts}
									onClose={() => setJobRequestInfo(false)}
									element={currentItem}
								/>
							</Modal>
						)}
					</>
				)}
				<Modal noPadding isOpen={confirmRequest} onClose={() => setConfirmRequest(false)}>
					<JobRequestConfirm
						getRequests={getReqeusts}
						onClose={() => setConfirmRequest(false)}
						element={currentItem}
					/>
				</Modal>

				<Modal
					noPadding
					noRadius
					isOpen={isUploadImageModal}
					onClose={() => setIsUploadImagesModal(false)}>
					<UploadImages
						setFilesToUpload={setFilesToUpload}
						setUploadImages={setUploadImages}
						isEditImageOpen={isEditImagesInfo}
						setIsEditImageOpen={setIsEditImagesInfo}
						modalOpen={isUploadImageModal}
						setModalOpen={() => setIsUploadImagesModal(false)}
					/>
				</Modal>

				<Modal
					noPadding
					noRadius
					isOpen={isEditImagesInfo}
					onClose={() => setIsEditImagesInfo(false)}>
					<EditUploadedImages
						onCloseEditImages={setIsEditImagesInfo}
						getSuggestions={getSuggestions}
						briefing={currentItem}
						isUploadSubmittedImages
						files={filesToUpload}
						images={uploadedImages}
						setIsEditImagesInfo={setIsEditImagesInfo}
					/>
				</Modal>
			</Wrapper>

			<CornerMessage
				span="Success! "
				text={"Your request has been saved as public and is now visible in the requests."}
				setIsAddedToCart={setIsChangedToPublished}
				isAddedToCart={isChangedToPublished}
			/>

			<CornerMessage
				span="Success! "
				text={"Your request has been saved as draft and is now visible in the requests."}
				setIsAddedToCart={setIsChangedToDraft}
				isAddedToCart={isChangedToDraft}
			/>
		</>
	);
};

const Wrapper = styled.div`
	width: 1136px;
	margin: 0 auto;
	background: #f7f7f7;
	padding-bottom: 40px;
`;

const Title = styled.h2`
	margin-bottom: 40px;
	font-weight: 700;
	font-size: 64px;
	line-height: 80px;
	text-align: center;
`;
const ProfileGallerySortList = styled.ul`
	display: flex;
	justify-content: center;
	margin-bottom: 40px;
`;
const ProfileGallerySortItem = styled.div<{ active?: boolean }>`
	position: relative;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	color: #999999;
	padding-bottom: 10px;
	cursor: pointer;
	&:not(:last-child) {
		margin-right: 55px;
	}

	${(props) => {
		switch (props.active) {
			case true:
				return css`
					color: #000;
					&::after {
						content: "";
						position: absolute;
						left: 0;
						right: 0;
						bottom: 0;
						border: 2px solid #000;
						border-radius: 8px;
					}
				`;
			default:
				return "";
		}
	}}
`;

const ProfileGalleryTypeCount = styled.div`
	position: absolute;
	right: -25px;
	top: -5px;
	font-size: 12px;
	line-height: 16px;
	color: #808080;
`;
const RequestsGallery = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 16px;
`;
const RequestsItem = styled.div`
	padding: 16px;
	background: #ffffff;
	border-radius: 8px;
	cursor: pointer;
	transition: all.2s;
	border: 1px solid transparent;
	&:hover {
		border: 1px solid #ebebeb;
		transition: all.2s;
	}
`;
const RequestsItemHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
	align-items: center;
`;
const RequestsItemHeaderType = styled.div`
	padding: 4px 8px;
	font-size: 12px;
	line-height: 16px;
	background: #f7f7f7;
	border-radius: 16px;
`;
const RequestsItemHeaderStatus = styled.div`
	color: #62c78f;
	text-transform: capitalize;
`;
const RequestsItemDesc = styled.div`
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 4px;
`;
const RequestsItemDate = styled.div`
	margin-bottom: 24px;
	font-size: 12px;
	line-height: 16px;
`;
const RequestsItemImages = styled.div`
	display: flex;
	max-width: 336px;
	height: 80px;
`;
const RequestsItemImage = styled.img`
	margin-right: 4px;
	&:last-child {
		padding-right: 26px;
	}
`;
const RequestsItemBottom = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const RequestsItemPrice = styled.div`
	font-weight: 600;
`;
const RequestsItemCountsInner = styled.div`
	display: flex;
	gap: 12px;
`;
const RequestsItemCounts = styled.div`
	font-size: 12px;
	line-height: 16px;
	color: #999999;
	display: flex;
	align-items: center;
`;
const NoImageDetails = styled.div`
	color: #c3c3c3;
	margin-top: auto;
`;
