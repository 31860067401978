import React, { useState, useEffect, FormEvent } from "react";
import {
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
	useStripe,
	useElements,
} from "@stripe/react-stripe-js";
import styled from "styled-components";
import { cartProcess } from "../../store/reducers/cart/action";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { cardCvcStyle, cardExpiryStyle, cardNumberStyle } from "../../utils/paymentStyles";
import Stripe from "stripe";

interface Props {
	isValid: boolean;
	clientSecret: string;
	setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
	setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
	basketIds: number[];
	setIsCardComplete: React.Dispatch<React.SetStateAction<boolean>>;
	userCountry: number;
}

// const stripe = require("stripe")("sk_test_WynOTJJDCcjyNShspfp33Xz0");

export function CheckoutForm({
	isValid,
	setIsDisabled,
	setIsValid,
	userCountry,
	basketIds,
	setIsCardComplete,
}: Props): JSX.Element {
	const [succeeded, setSucceeded] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [processing, setProcessing] = useState(false);
	const [disabled, setDisabled] = useState(true);

	const [isNumberComplete, setIsNumberComplete] = useState(false);
	const [isDateComplete, setIsDateComplete] = useState(false);
	const [isCvcComplete, setIsCvcComplete] = useState(false);
	const usestripe = useStripe();
	const elements = useElements();
	const dispatch = useAppDispatch();

	const { artbuyerProfile } = useAppSelector((state) => state.profile);
	const { countries } = useAppSelector((state) => state.countries);
	const { checkoutItem } = useAppSelector((state) => state.cart);

	useEffect(() => {
		isValid && !processing && !disabled && !succeeded && handleSubmit();

		setIsValid(false);
	}, [isValid]);

	useEffect(() => {
		setIsDisabled(processing || disabled || succeeded);
	}, [processing, disabled, succeeded]);

	useEffect(() => {
		setIsCardComplete(isCvcComplete && isDateComplete && isNumberComplete);
	}, [isCvcComplete, isDateComplete, isNumberComplete]);

	const handleCardNumberChange = (event: any): void => {
		setIsNumberComplete(event.complete);
		setDisabled(event.empty);
		setError(event.error ? event.error.message : "");
	};

	const handleCardExpiryChange = (event: any): void => {
		setIsDateComplete(event.complete);

		setDisabled(event.empty);
		setError(event.error ? event.error.message : "");
	};

	const handleCardCvcChange = (event: any): void => {
		setIsCvcComplete(event.complete);
		setDisabled(event.empty);
		setError(event.error ? event.error.message : "");
	};

	const navigate = useNavigate();

	const handleSubmit = async (): Promise<void> => {
		// ev.preventDefault();
		setProcessing(true);

		if (!usestripe || !elements) {
			return;
		}

		const cardElement = elements.getElement(CardNumberElement);

		if (!cardElement) {
			return;
		}

		// const payload = await usestripe.confirmCardPayment(clientSecret, {
		// 	payment_method: {
		// 		card: cardElement,
		// 		billing_details: {
		// 			// Add any billing details if needed (e.g., name, address, etc.)
		// 		},
		// 	},
		// });

		const { token, error } = await usestripe.createToken(cardElement, {
			name: `${artbuyerProfile.billingFirstName} ${artbuyerProfile.billingLastName}`,
			address_line1: artbuyerProfile.billingAddress,
			address_line2: artbuyerProfile.billingAddress2,
			address_city: artbuyerProfile.billingCity,
			address_zip: artbuyerProfile.billingZip,
			address_country: countries.find((el) => el.id === artbuyerProfile.countryId)?.shortName,
			currency: checkoutItem?.currency,
		});

		if (token) {
			const json = {
				token: token?.id,
				// basketItemIds: "385, 386",
				basketItemIds: basketIds,
			};

			const { meta, payload } = await dispatch(cartProcess(JSON.stringify(json)));
			if (meta.requestStatus === "fulfilled") {
				navigate("/creator/profile/cart/purchase");
			}

			if (payload.error) {
				setError(`Payment failed ${payload.error.message}`);
				setProcessing(false);
			} else {
				setError(null);
				setProcessing(false);
				setSucceeded(true);
			}
		} else {
			setProcessing(false);
		}
	};

	return (
		<form id="payment-form">
			<InputsWrapper>
				<label style={{ ...cardNumberStyle.base, ...{ position: "relative" } }}>
					<CustomLabel>Card Number</CustomLabel>
					<CardNumberElement onChange={handleCardNumberChange} />
				</label>

				<label style={{ ...cardExpiryStyle.base, ...{ position: "relative" } }}>
					<CustomLabel>Expiration Date</CustomLabel>
					<CardExpiryElement id="card-expiry" onChange={handleCardExpiryChange} />
				</label>

				<label style={{ ...cardCvcStyle.base, ...{ position: "relative" } }}>
					<CustomLabel>CVC</CustomLabel>
					<CardCvcElement id="card-cvc" onChange={handleCardCvcChange} />
				</label>
			</InputsWrapper>
		</form>
	);
}
const CustomLabel = styled.div`
	font-weight: bold;
	color: #282828;
	position: absolute;
	top: -28px;
	left: 0;
`;
const InputsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
