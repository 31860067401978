import React from "react";
import styled from "styled-components";

export const ProcessingModal = () => {
	return (
		<Wrapper>
			<Content>
				<h2>Please wait. Processing...</h2>
				<ProcessingLine>
					<div></div>
				</ProcessingLine>
			</Content>
		</Wrapper>
	);
};
const Wrapper = styled.div`
	z-index: 5;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
`;
const Content = styled.div`
	width: 480px;
	padding: 40px;
	background: #fff;
	border-radius: 24px;
	& > h2 {
		font-weight: 700;
		font-size: 24px;
		line-height: 32px;
		margin-bottom: 24px;
	}
`;
const ProcessingLine = styled.div`
	width: 100%;
	background: #f1edf7;
	border-radius: 16px;
	height: 10px;

	& > div {
		background: #8562ce;
		height: 10px;
		border-radius: 16px;
		width: 270px;
	}
`;
