import styled from "styled-components";

export const IsLoading = () => {
	return (
		<Wrapper>
			<Loader />
		</Wrapper>
	);
};

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	display: grid;
	place-items: center;
`;
const Loader = styled.div`
	border: 8px solid #e3e3e3;
	border-top: 8px solid #8562ce;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	animation: spin 1s linear infinite;
`;
