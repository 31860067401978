import styled from "styled-components";
import { searchSvg } from "../../static/images";

interface props {
	searchValue: string;
	onChange: React.Dispatch<React.SetStateAction<string>>;
	onBlur?: () => void;
}

export const SearchInput = (props: props) => {
	const { searchValue, onBlur, onChange } = props;

	return (
		<Form>
			<SearchBtnSvg src={searchSvg} alt="searchSvg" />

			<Input
				autoFocus
				value={searchValue}
				onBlur={onBlur}
				onChange={(e) => onChange(e.target.value)}
				type="text"
				placeholder="Search"
			/>
		</Form>
	);
};

const Form = styled.form`
	display: flex;
	align-items: center;
	margin-right: 8px;
	padding: 12px 16px;
	color: #808080;
	width: 400px;
	height: 46px;
	background: #f7f7f7;
	border-radius: 8px;
	margin-right: 8px;
`;
const SearchBtnSvg = styled.img`
	margin-right: 13px;
	width: 14px;
	height: 14px;
`;
const Input = styled.input`
	border: none;
	height: 45px;
	outline: none;
	width: 100%;
	background: transparent;
`;
