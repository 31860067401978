import { createSlice } from "@reduxjs/toolkit";
import { IFavorite } from "../../../models/IFavorites";
import { getFavourites } from "./action";

interface GalleryState {
	favorites: IFavorite[];
}

const initialState: GalleryState = {
	favorites: [],
};

export const artbuyerFavorites = createSlice({
	name: "user/artbuyerFavorites",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getFavourites.fulfilled, (state, action) => {
			state.favorites = action.payload.favourites;
		});
	},
});

export const { reducer: artbuyerFavoritesReducer, actions: artbuyerFavoritesActions } =
	artbuyerFavorites;

export const {} = artbuyerFavoritesActions;
