import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Button, ErrorWrapper, Input, RadioBtns } from "../UI";
import { useAppDispatch } from "../../hooks/redux";
import { useController, useForm } from "react-hook-form";
import { availableFormatValues } from "../../utils/selectValues";
import { MultiSelect } from "../UI/MultiSelect";
import {
	getPricingStatus,
	onPricing,
	releaseAvailable,
	releaseAvailableReverse,
} from "../../utils/getSmth";
import { updateSuggestion } from "../../store/reducers/photographerJobRequests/action";

interface Props {
	onClose: () => void;
	el: any;
	briefingId: number;
	getSuggestions: () => void;
}

export const SubmitImageModal = ({ el, briefingId, onClose, getSuggestions }: Props) => {
	const [dragging, setDragging] = useState(false);

	const [fileType, setFileType] = useState("Preview / Screenshot");
	const [propertyRelease, setPropertyRelease] = useState("Possible");
	const [files, setFiles] = useState<any>([]);
	const [filesId, setFilesId] = useState(0);
	const [pricing, setPricing] = useState("Rights Ready");
	const [imagesToSend, setImagesToSend] = useState<{ id: number; img: string }[]>([]);

	const {
		control,
		formState: { errors },
		handleSubmit,
		register,
		reset,
	} = useForm();

	const { field: availableFormat } = useController({
		name: "availableFormats",
		control,
	});
	const dispatch = useAppDispatch();

	useEffect(() => {
		reset(el);
		setPropertyRelease(releaseAvailableReverse(el.releaseAvailable));
		setPricing(getPricingStatus(el.pricing) || "");
	}, []);

	const uploadImages = (localFiles: any) => {
		const photosArray: { id: number; img: string }[] = [];
		const filesArray: { id: number; file: string }[] = [];

		let countId = filesId;

		for (let i = 0; i < localFiles.length; i++) {
			setFiles((prev: any) => [...prev]);

			filesArray.push({ id: countId, file: localFiles[i] });

			photosArray.push({ id: countId, img: URL.createObjectURL(localFiles[i]) });

			countId++;
		}
		setFilesId(countId);

		setFiles((prev: any) => [...prev, ...filesArray]);
		setImagesToSend((prev: any) => [...prev, ...photosArray]);
	};

	const onUploadImage = async (e: any) => {
		const localFiles = e.target.files;

		uploadImages(localFiles);
	};

	const onDropHandler = (e: any) => {
		e.preventDefault();
		const localFiles = [...e.dataTransfer.files];

		uploadImages(localFiles);
	};

	const onFormSubmit = async (data: any) => {
		const json = {
			briefingId: briefingId,
			originalFile: fileType === "Original file / Mobile",
			priceOnline: data.priceOnline,
			priceEditorial: data.priceEditorial,
			priceExtended: data.priceExtended,
			// currency: "string",
			photoId: el.photoId,
			releaseAvailable: releaseAvailable(propertyRelease),
			pricing: onPricing(pricing),
			cameraModel: data.cameraModel,
			maxSize: data.maxSize,
			availableFormats: data.availableFormats ? data.availableFormats : [""],
			// incognito: true,
		};
		const formData = new FormData();

		if (files && fileType === "Original file / Mobile") {
			formData.append("file", files[0].file);
			formData.append("json", JSON.stringify(json));
		} else {
			formData.append("json", JSON.stringify(json));
		}

		await dispatch(updateSuggestion(formData));
		getSuggestions?.();
		onClose();
	};

	return (
		<Wrapper>
			<Title>Submit Image</Title>
			<Text>Please complete the below form as accurate as possible to place a new submission</Text>
			<FileTypeRadioWrapper>
				<RadioBtns
					value={fileType}
					setValue={setFileType}
					options={[
						{ value: "Original file / Mobile", text: "" },
						{ value: "Preview / Screenshot", text: "" },
					]}
					name="fileType"
					toolTip={false}
					displayFlex={true}
				/>
			</FileTypeRadioWrapper>
			{fileType === "Preview / Screenshot" || (
				<Upload>
					{imagesToSend.length > 0 ? (
						<div>
							<DroppedImage>
								<img src={imagesToSend[0].img} alt="img" />
							</DroppedImage>
						</div>
					) : (
						<DropImages
							onDragStart={(e: any) => {
								e.preventDefault();
								setDragging(true);
							}}
							onDragLeave={(e: any) => {
								e.preventDefault();
								setDragging(false);
							}}
							onDragOver={(e: any) => {
								e.preventDefault();
								setDragging(true);
							}}
							dragging={dragging}
							onDrop={(e: any) => onDropHandler(e)}>
							<AvatarEdit>
								<input
									accept="image/*, .png, .jpg,.web"
									onChange={(e) => onUploadImage(e)}
									type="file"
									multiple
								/>
							</AvatarEdit>
							<DropImagesText>
								Just drop your images here or click to add (PNG or JPG)
							</DropImagesText>
						</DropImages>
					)}
				</Upload>
			)}

			<InputWrapper>
				<Input {...register("cameraModel")} labelText="Camera used" maxWidth="100%" />
			</InputWrapper>
			<FlexInputWrapper>
				<InputWrapper style={{ width: "232px" }}>
					<MultiSelect
						extraButton={false}
						error={!!errors?.availableFormat}
						ref={availableFormat.ref}
						value={availableFormat.value || ["jpg"]}
						onChange={availableFormat.onChange}
						labelText="Available format"
						options={availableFormatValues}
					/>
					<ErrorWrapper>
						<p style={errors?.availableFormat && { display: "block" }}>
							{errors?.availableFormat && `${errors?.availableFormat?.message}`}
						</p>
					</ErrorWrapper>
				</InputWrapper>
				<InputWrapper style={{ width: "232px" }}>
					<Input
						maxWidth="100%"
						type="number"
						{...register("maxSize")}
						labelText="Max available file size, Mb"
					/>
				</InputWrapper>
			</FlexInputWrapper>
			<RadioWrapper>
				<h3>Pricing</h3>
				<RadioBtns
					value={pricing}
					setValue={setPricing}
					options={[
						{ value: "Rights Ready", text: "" },
						{ value: "Upon Request", text: "" },
						{ value: "Custom Rights Ready", text: "" },
					]}
					name="ChangePricing"
					toolTip={false}
				/>
			</RadioWrapper>
			{pricing === "Custom Rights Ready" && (
				<div style={{ marginBottom: "24px" }}>
					<InputsWrapper>
						<Input
							error={!!errors?.priceOnline}
							{...register("priceOnline", {
								required: "required",
								min: 25,
							})}
							maxWidth="152px"
							labelText="Online"
							type="number"
						/>
						<Input
							error={!!errors?.priceEditorial}
							{...register("priceEditorial", {
								required: "required",
								min: 25,
							})}
							maxWidth="152px"
							labelText="Editorial"
							type="number"
						/>
						<Input
							error={!!errors?.priceExtended}
							{...register("priceExtended", {
								required: "required",
								min: 25,
							})}
							maxWidth="152px"
							labelText="Extended"
							type="number"
						/>
					</InputsWrapper>
					<ErrorWrapper>
						<p
							style={
								(errors?.priceOnline || errors?.priceEditorial || errors?.extendedPricing) && {
									display: "block",
								}
							}>
							Minimal price - 25$
						</p>
					</ErrorWrapper>
				</div>
			)}
			<RadioWrapper>
				<h3>Model / Property release</h3>
				<RadioBtns
					value={propertyRelease}
					setValue={setPropertyRelease}
					options={[
						{ value: "Yes", text: "" },
						{ value: "No", text: "" },
						{ value: "Possible", text: "" },
					]}
					name="propertyRelease"
					toolTip={false}
					displayFlex={true}
				/>
			</RadioWrapper>

			<Button type="submit" onClick={handleSubmit(onFormSubmit)} width="100%">
				Submit
			</Button>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding: 40px;
	width: 560px;
	max-height: 90vh;
	overflow-y: auto;
`;
const Title = styled.div`
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	margin-bottom: 8px;
`;
const Text = styled.div`
	margin-bottom: 24px;
`;
const FileTypeRadioWrapper = styled.div`
	width: 100%;
	margin-bottom: 24px;
	padding-right: 55px;
`;

const Upload = styled.div`
	margin-bottom: 24px;
`;

const DropImages = styled.div<any>`
	min-height: 140px;
	background: #f7f7f7;
	border: 2px dashed #d7d7d7;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: relative;
	${({ dragging }) => {
		if (dragging) {
			return css`
				border: 2px dashed #8562ce;
			`;
		}
	}}
`;
const DropImagesText = styled.div`
	max-width: 182px;
	color: #8562ce;
	text-align: center;
`;
const AvatarEdit = styled.label`
	position: absolute;
	bottom: 0px;
	top: 0;
	left: 0px;
	right: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	& > input {
		opacity: 0;
		visibility: hidden;
		position: absolute;
	}
`;
const DroppedImage = styled.div`
	padding: 12px 0px;
	& > img {
		max-width: 140px;
		max-height: 175px;
	}
`;
const InputWrapper = styled.div`
	margin-bottom: 16px;
`;
const FlexInputWrapper = styled.div`
	display: flex;
	gap: 16px;
`;
const RadioWrapper = styled.div`
	h3 {
		margin-bottom: 16px;
		font-size: 16px;
		line-height: 24px;
		font-weight: 600;
	}
	margin-bottom: 24px;
`;
const InputsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;
