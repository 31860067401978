import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { IFavorite } from "../models/IFavorites";
import { favorite } from "../static/images";
import { splitArrayToGalleryFormat } from "../utils";
import { getFavourites, removeFavourite } from "../store/reducers/artbuyerFavorites/action";
import { useNavigate } from "react-router-dom";
import { AuthRoles } from "../models/IProfile";
import { getPhotographerAccount, getUserAccount } from "../store/reducers/profile/action";
import {
	getAnotherPhotographerSkills,
	getSkillsPhotographer,
} from "../store/reducers/PhotographerSkills/action";
import { getUserPhotos } from "../store/reducers/photo/action";
import { TimeStamp } from "../utils/variables";

export const Favorites = () => {
	const { favorites } = useAppSelector((state) => state.favorites);
	const { user } = useAppSelector((state) => state.authData);

	const splitedImagesArray = splitArrayToGalleryFormat(favorites);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const getFavoritesList = () => {
		const parameters = `?page=1&pageSize=300&timestamp=${TimeStamp}`;

		dispatch(getFavourites(parameters));
	};

	useEffect(() => {
		getFavoritesList();
	}, []);

	const onGalleryImgClick = async (el: IFavorite) => {
		const parameters = `?page=1&pageSize=500&timestamp=${TimeStamp}&nonIncognito=true`;
		await dispatch(getUserPhotos({ parameters, userId: el.photographerId }));
		if (user?.role === AuthRoles.Photographer) {
			await dispatch(getUserAccount(el.photographerId));
			await dispatch(getSkillsPhotographer(el.photographerId));
		} else {
			await dispatch(getAnotherPhotographerSkills(el.photographerId));
			await dispatch(getPhotographerAccount(el.photographerId));
		}
		navigate("/creator/profile/userAccount");
	};
	const onRemoveFromFavorites = async (el: IFavorite) => {
		const json = {
			photographerIds: [el.photographerId],
		};
		await dispatch(removeFavourite(json));
		getFavoritesList();
	};

	return (
		<Wrapper>
			<TrendsTitle>Favorites</TrendsTitle>
			<ProfileGalleryImages>
				{splitedImagesArray.map((arr, index) => {
					return (
						<ProfileGalleryColumn key={index}>
							{arr.map((el: IFavorite, i) => {
								return (
									<ProfileGalleryItem onClick={() => onGalleryImgClick(el)} key={i}>
										<ProfileGalleryCover
											onClick={(e) => {
												onRemoveFromFavorites(el);
												e.stopPropagation();
											}}>
											<img src={favorite} alt="favorite" />
										</ProfileGalleryCover>
										<ProfileGalleryImg
											src={`${process.env.REACT_APP_DOMEN}${el?.cover?.imageDetails.LIGHTBOX.url}`}
											alt="img"
										/>
										<AboutImage>
											<AboutImageName>{el.name}</AboutImageName>
											<AboutImageRole style={{ textTransform: "capitalize" }}>
												{el.role}
											</AboutImageRole>
										</AboutImage>
									</ProfileGalleryItem>
								);
							})}
						</ProfileGalleryColumn>
					);
				})}
			</ProfileGalleryImages>
		</Wrapper>
	);
};
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1136px;
	margin: 0 auto;
`;
const TrendsTitle = styled.h2`
	margin-bottom: 40px;
	font-weight: var(--font-bold);
	font-size: 40px;
	line-height: 48px;
	text-align: center;
`;
const AboutImage = styled.div`
	margin-top: 16px;
	margin-bottom: 20px;
	text-align: center;
`;
const AboutImageName = styled.div`
	margin-bottom: 8px;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
`;
const AboutImageRole = styled.div`
	color: #505050;
`;
const ProfileGalleryImages = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 16px;
`;
const ProfileGalleryColumn = styled.div`
	width: 272px;
`;
const ProfileGalleryImg = styled.img`
	width: 272px;
	position: relative;
	z-index: 0;
`;
const ProfileGalleryItem = styled.div`
	margin-bottom: 12px;
	position: relative;
	cursor: pointer;
`;
const ProfileGalleryCover = styled.div`
	position: absolute;
	z-index: 2;
	top: 8px;
	right: 8px;
	padding: 5px 4px 1px 6px;
	font-weight: 600;
	background: #8562ce;
	border-radius: 8px;
	opacity: 1;
	transition: 0.2s;
	&:hover {
		background: #7557b5;
		transition: 0.2s;
	}
`;
