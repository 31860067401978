import { ButtonHTMLAttributes, ReactNode } from "react";
import styled, { css } from "styled-components";

export const Button = ({
	theme = "primary",
	icon,
	isLoading = false,
	activeIcon = "",
	children,
	width,
	isActive = false,
	padding,
	disabled = false,
	smallSize = false,
	margin,
	customColor = "",
	...other
}: Props) => {
	return (
		<Wrapper
			className={"button-loading"}
			width={width}
			isLoading={isLoading}
			theme={theme}
			icon={icon}
			activeIcon={activeIcon}
			isActive={isActive}
			padding={padding}
			disabled={disabled}
			smallSize={smallSize}
			margin={margin}
			customColor={customColor}
			{...other}>
			<div className="button-text">{children}</div>
		</Wrapper>
	);
};

const Wrapper = styled.button<Props>`
	padding: ${({ padding }) => padding || "12px 20px"};
	cursor: pointer;
	border: 1px solid #000;
	margin: ${({ margin }) => margin || "0 auto"};
	border-radius: 8px;
	transition: all 0.2s;
	position: relative;
	max-height: 50px;
	width: ${({ width }) => width || "auto"};

	${({ smallSize }) => {
		if (smallSize) {
			return css`
				font-size: 12px;
				line-height: 16px;
			`;
		}
	}}

	&:hover {
		transition: 0.2s;
	}

	${({ isLoading }) => {
		switch (isLoading) {
			case true:
				return css`
					& > span {
						visibility: hidden;
					}

					&.button-loading::after {
						content: "";
						position: absolute;
						width: 16px;
						height: 16px;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						margin: auto;
						border: 4px solid transparent;
						border-top-color: #ffffff;
						border-radius: 50%;
						animation: button-loading-spinner 1s linear infinite;
					}
				`;
		}
	}}

	${({ theme, disabled, isActive, icon, activeIcon, customColor }) => {
		switch (theme) {
			case "primary":
				return css`
					background: ${customColor ? customColor : disabled ? "#C3C3C3" : "#000"};
					border: 1px solid ${customColor ? customColor : disabled ? "#C3C3C3" : "#000"};
					cursor: ${disabled && "auto"};
					color: #fff;
					&:hover {
						background: ${disabled ? "" : "rgb(133, 98, 206)"};
						border: 1px solid ${disabled ? "#C3C3C" : "rgb(133, 98, 206)"};
					}
				`;
			case "outline":
				return css`
					margin: 0 8px 0 0;
					background: #fff;
					border: 1px solid ${isActive ? "#c3c3c3" : "#000"};
					font-weight: 400;
					color: ${isActive ? "#c3c3c3" : "#000"};
					cursor: ${isActive ? "auto" : "pointer"};
					&:hover {
						color: #c3c3c3;
						border: 1px solid #c3c3c3;
					}
				`;
			case "secondary":
				return css`
					background: #fff;
					color: #000;
					margin-right: 8px;
					font-size: 14px;
					line-height: 20px;
					&:hover {
						color: rgb(133, 98, 206);
						border: 1px solid rgb(133, 98, 206);
					}
				`;
			case "noline":
				return css`
					background: #f7f7f7;
					color: #000;
					margin-right: 8px;
					border: transparent;
					&:hover {
						color: rgb(133, 98, 206);
					}
				`;
			case "icon":
				return css`
					background: #fff;
					padding: 4px 4px 0px 4px;
					color: #000;
					margin-right: 8px;
					&:hover {
						color: rgb(133, 98, 206);
						border: 1px solid rgb(133, 98, 206);
					}
				`;
			case "danger":
				return css`
					background: #d53434;
					color: #fff;
					border: none;
					&:hover {
						background: #ee4747;
					}
				`;
			case "withIcon":
				return css`
					background: #000;
					padding: 12px 20px 12px 44px;
					color: #fff;
					&::after {
						content: "";
						position: absolute;
						left: 10px;
						top: 10px;
						width: 24px;
						height: 24px;
						background-image: url(${icon});
					}
					&:hover {
						background: rgb(133, 98, 206);
						border: 1px solid rgb(133, 98, 206);
					}
				`;
			case "secondaryWithIcon":
				if (isActive) {
					return css`
						&::after {
							content: "";
							position: absolute;
							left: 10px;
							top: 10px;
							width: 24px;
							height: 24px;
							background-image: url(${activeIcon});
						}
						border: 1px solid rgb(133, 98, 206);
						background: #fff;
						padding: 12px 20px 12px 44px;
						color: rgb(133, 98, 206);
					`;
				} else {
					return css`
						background: #fff;
						padding: 12px 20px 12px 44px;
						color: #000;
						&::after {
							content: "";
							position: absolute;
							left: 10px;
							top: 10px;
							width: 24px;
							height: 24px;
							background-image: url(${icon});
						}
						&:hover {
							color: rgb(133, 98, 206);
							border: 1px solid rgb(133, 98, 206);
							&::after {
								background-image: url(${activeIcon});
							}
						}
					`;
				}

			case "green":
				return css`
					background: green;
					border: 1px solid green;
					color: #fff;
					&:hover {
						background: rgb(133, 98, 206);
						border: 1px solid rgb(133, 98, 206);
					}
				`;
			case "gray":
				return css`
					background: gray;
					border: 1px solid gray;
					color: #fff;
					&:hover {
						background: rgb(133, 98, 206);
						border: 1px solid rgb(133, 98, 206);
					}
				`;
			case "red":
				return css`
					background: red;
					border: 1px solid red;
					color: #fff;
					&:hover {
						background: #ee4747;
						border: 1px solid #ee4747;
					}
				`;
		}
	}}
`;

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	children: string | ReactNode;
	theme?: Theme;
	icon?: string;
	isLoading?: boolean;
	width?: string;
	activeIcon?: string;
	isActive?: boolean;
	padding?: string;
	disabled?: boolean;
	smallSize?: boolean;
	margin?: string;
	customColor?: string
}

type Theme =
	| "primary"
	| "danger"
	| "secondary"
	| "outline"
	| "withIcon"
	| "secondaryWithIcon"
	| "icon"
	| "green"
	| "gray"
	| "red"
	| "noline";
