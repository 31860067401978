import { forwardRef, InputHTMLAttributes, RefObject } from "react";
import styled, { css } from "styled-components";

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  maxWidth?: string;
  autofocus?: boolean;
  labelText?: string;
  type?: string;
  error?: boolean;
}

type Ref =
  | ((instance: HTMLTextAreaElement | null) => void)
  | RefObject<HTMLTextAreaElement>
  | null
  | undefined;

export const TextArea = forwardRef((props: Props, ref: Ref) => {
  const { maxWidth, labelText, error, autoFocus, ...other } = props;

  return (
    <Label>
      {labelText && <LabelText>{labelText}</LabelText>}
      <Wrapper
        error={error}
        autoFocus={autoFocus}
        ref={ref}
        maxWidth={maxWidth}
        {...other}
      />
    </Label>
  );
});

const Wrapper = styled.textarea<Props>`
  padding: 12px 8px 12px 16px;
  max-width: ${({ maxWidth }) => maxWidth};
  width: 100%;
  height: 84px;
  background: #f7f7f7;
  border-radius: 8px;
  border: 1px solid transparent;
  color: #000;
  outline: none;
  resize: none;
  &:focus {
    background: #fff;
    border: 1px solid #ebebeb;
  }
  ${(props) => {
    return (
      props.error &&
      css`
        border: 1px solid red;
        background: #fff;
      `
    );
  }}
`;

const LabelText = styled.div`
  margin-bottom: 8px;
  font-weight: bold;
`;

const Label = styled.label`
  width: 100%;
`;
