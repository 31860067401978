import styled from "styled-components";
import { useForm, useController } from "react-hook-form";
import { useAppDispatch } from "../hooks/redux";
import { AuthRoles } from "../models/IProfile";

import { Button, Checkbox, CustomSelect, ErrorWrapper, Modal, PasswordInput } from "./UI";
import { Input } from "./UI/Input";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { signUp } from "../store/reducers/authData";
import { countries } from "../utils/countries";
import { SignIn } from "./Modals/SignInModal";
import { ResetPasswordForm } from "./ResetPasswordForm";

interface Props {
	isCreatorSignUp?: boolean;
	isBuyerSignUp?: boolean;
}

const getPorfolioValidation = (isCreatorSignUp: boolean, err: string) => {
	return isCreatorSignUp ? Yup.string().required(err) : Yup.string();
};

export const SignupForm = ({ isCreatorSignUp = false, isBuyerSignUp = false }: Props) => {
	const err = "The filed is required";

	const signUpValidationSchema = Yup.object().shape({
		firstName: Yup.string().required(err),
		lastName: Yup.string().required(err),
		userName: Yup.string().required(err),
		email: Yup.string().email().required(err),
		company: Yup.string(),
		countryId: Yup.number().required(err),
		portfolio: getPorfolioValidation(isCreatorSignUp, err),
		password: Yup.string().required(err).min(6, "Password must be at least 6 characters"),
		repeatPassword: Yup.string()
			.required(err)
			.oneOf([Yup.ref("password")], "Passwords must match"),
	});

	const formOptions = { resolver: yupResolver(signUpValidationSchema) };

	const {
		register,
		formState: { errors },
		reset,
		control,
		handleSubmit,
	} = useForm({ ...formOptions });

	const [modalOpen, setModalOpen] = useState(false);
	const [switchForm, setSwitchForm] = useState(true);
	const [isResetPassword, setIsResetPassword] = useState(false);

	const { isLoading } = useSelector((state: RootState) => state.authData);
	const { selectCountries } = useSelector((state: RootState) => state.countries);
	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const onSubmit = async (data: any) => {
		const profileData = {
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			siteURL: data.portfolio,
			role: isCreatorSignUp ? AuthRoles.Photographer : AuthRoles.Artbuyer,
			company: data.company,
			receiveNewsletter: data.subscribe,
			countryId: data.countryId,
			login: data.userName,
			password: data.password,
		};

		const response = await dispatch(signUp(profileData));

		// if (response.meta.requestStatus === "fulfilled") {
		// 	// @ts-ignore
		// 	response.payload.role === AuthRoles.Photographer
		// 		? navigate("/creator/profile")
		setModalOpen(true);

		reset();

		// toast.success("Succeeded signUp", {
		// 	position: "top-right",
		// 	autoClose: 5000,
		// 	hideProgressBar: false,
		// 	closeOnClick: true,
		// 	pauseOnHover: true,
		// 	draggable: true,
		// 	progress: undefined,
		// 	theme: "light",
		// });
		// }
	};

	const { field: countryField } = useController({
		name: "countryId",
		control,
		rules: { required: true },
	});

	const onModalBtnClick = () => {
		navigate("/");
		document.body.style.overflow = "auto";
	};
	return (
		<SignUpContent>
			{switchForm ? (
				<SignUpLogin>
					Already have an account?{" "}
					<a
						onClick={(e) => {
							e.preventDefault();
							setSwitchForm(false);
						}}
						href="">
						Log in.
					</a>
				</SignUpLogin>
			) : (
				<SignUpLogin>
					Don’t have an account yet?{" "}
					<a
						onClick={(e) => {
							setSwitchForm(true);
							e.preventDefault();
						}}
						href="">
						Sign up here.
					</a>
				</SignUpLogin>
			)}
			{switchForm ? (
				<SignUpFormWrapper>
					<SignUpFormTitle>Sign Up</SignUpFormTitle>
					<SignUpForm>
						<InputWrapper
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginBottom: "16px",
							}}>
							<div>
								<Input
									error={!!errors?.firstName}
									{...register("firstName")}
									autoFocus={true}
									placeholder={"Name"}
									type={"text"}
									maxWidth={"194px"}
								/>
								<ErrorWrapper>
									<p style={errors?.firstName && { display: "block" }}>
										{errors?.firstName && `${errors?.firstName?.message}`}
									</p>
								</ErrorWrapper>
							</div>
							<div>
								<Input
									error={!!errors?.lastName}
									{...register("lastName")}
									placeholder={"Surname"}
									type={"text"}
									maxWidth={"194px"}
								/>
								<ErrorWrapper>
									<p style={errors?.lastName && { display: "block" }}>
										{errors?.lastName && `${errors?.lastName?.message}`}
									</p>
								</ErrorWrapper>
							</div>
						</InputWrapper>

						<InputWrapper>
							<Input
								error={!!errors?.userName}
								{...register("userName")}
								placeholder={"Username"}
								type={"text"}
								maxWidth={"394px"}
							/>
							<ErrorWrapper>
								<p style={errors?.userName && { display: "block" }}>
									{errors?.userName && `${errors?.userName?.message}`}
								</p>
							</ErrorWrapper>
						</InputWrapper>

						<InputWrapper>
							<Input
								error={!!errors?.email}
								{...register("email")}
								placeholder={"E-mail"}
								type={"email"}
								maxWidth={"394px"}
							/>
							<ErrorWrapper>
								<p style={errors?.email && { display: "block" }}>
									{errors?.email && `${errors?.email?.message}`}
								</p>
							</ErrorWrapper>
						</InputWrapper>


						{isCreatorSignUp ? (
							<InputWrapper>
								<Input
									error={!!errors?.portfolio}
									{...register("portfolio")}
									placeholder={"Your portfolio / website"}
									type={"text"}
									maxWidth={"394px"}
								/>
								<ErrorWrapper>
									<p style={errors?.portfolio && { display: "block" }}>
										{errors?.portfolio && `${errors?.portfolio?.message}`}
									</p>
								</ErrorWrapper>
							</InputWrapper>
						) : (
							<InputWrapper>
							<Input
								error={!!errors?.company}
								{...register("company")}
								placeholder={"Company"}
								type={"text"}
								maxWidth={"394px"}
							/>
							<ErrorWrapper>
								<p style={errors?.company && { display: "block" }}>
									{errors?.company && `${errors?.company?.message}`}
								</p>
							</ErrorWrapper>
						</InputWrapper>

						)}

						<InputWrapper>
							<CustomSelect
								error={!!errors?.countryId}
								ref={countryField.ref}
								placeholder="Country"
								value={countryField.value}
								onChange={countryField.onChange}
								options={selectCountries}
							/>

							<ErrorWrapper>
								<p style={errors?.countryId && { display: "block" }}>
									{errors?.countryId && `${errors?.countryId?.message}`}
								</p>
							</ErrorWrapper>
						</InputWrapper>

						<InputWrapper>
							<PasswordInput
								error={!!errors?.password}
								{...register("password")}
								placeholder={"Password"}
								maxWidth={"394px"}
							/>
							<ErrorWrapper>
								<p style={errors?.password && { display: "block" }}>
									{errors?.password && `${errors?.password?.message}`}
								</p>
							</ErrorWrapper>
						</InputWrapper>

						<InputWrapper>
							<PasswordInput
								error={!!errors?.repeatPassword}
								{...register("repeatPassword")}
								placeholder={"Repeat password"}
								maxWidth={"394px"}
							/>
							<ErrorWrapper>
								<p style={errors?.repeatPassword && { display: "block" }}>
									{errors?.repeatPassword && `${errors?.repeatPassword?.message}`}
								</p>
							</ErrorWrapper>
						</InputWrapper>

						<CheckboxWrapper>
							<Checkbox {...register("subscribe")} label="Subscribe for Newsletter" />
						</CheckboxWrapper>

						<Button
							isLoading={isLoading}
							width="100%"
							onClick={handleSubmit(onSubmit)}
							type="button">
							{isCreatorSignUp ? "Sign up as creator" : "Sign up as a brand"}
						</Button>
						<SignUpPolicy>
							By signing up, you accept our <a href="#">terms of use</a> and{" "}
							<a href="#">privacy policy</a>
						</SignUpPolicy>
					</SignUpForm>
				</SignUpFormWrapper>
			) : isResetPassword ? (
				<LoginForm>
					<ResetPasswordForm setIsResetPassword={setIsResetPassword} />
				</LoginForm>
			) : (
				<LoginForm>
					<SignIn setIsResetPassword={setIsResetPassword} loginFromPage />
				</LoginForm>
			)}

			<Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
				<RequestModalWrapper>
					<RequestModalTitle>Registration request sent</RequestModalTitle>
					<ThanksModalText>Please wait for a response</ThanksModalText>
					<ModalButtonWrapper>
						<Button type="button" onClick={onModalBtnClick} width="100%">
							Ok
						</Button>
					</ModalButtonWrapper>
				</RequestModalWrapper>
			</Modal>
		</SignUpContent>
	);
};

const SignUpLogin = styled.div`
	text-align: right;
	color: #282828;
	& > a {
		color: #8562ce;
		cursor: pointer;
	}
`;
const SignUpContent = styled.div`
	width: 64vw;
	padding: 25px;
`;

const SignUpFormWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	flex-direction: column;
`;

const SignUpFormTitle = styled.div`
	margin-bottom: 24px;
	font-size: 40px;
	font-weight: 700;
	line-height: 48px;
`;

const SignUpForm = styled.form`
	width: 396px;
`;

const InputWrapper = styled.div`
	margin-bottom: 16px;
	width: 100%;
`;

const CheckboxWrapper = styled.div`
	margin: 26px 0px;
`;

const SignUpPolicy = styled.div`
	margin-top: 24px;
	font-size: 12px;
	line-height: 16px;
	& > a {
		color: #8562ce;
	}
`;
const RequestModalTitle = styled.div`
	margin-bottom: 24px;
	text-align: center;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
`;
const ModalButtonWrapper = styled.div`
	text-align: center;
	width: 176px;
	margin: 24px auto 0px;
`;
const RequestModalWrapper = styled.div`
	width: 480px;
	padding: 10px 8px;
`;
const ThanksModalText = styled.div`
	margin-top: -8px;
	text-align: center;
	margin-bottom: 24px;
`;
const LoginForm = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	flex-direction: column;
`;
