import { createSlice } from "@reduxjs/toolkit";
import { IImageRequest } from "../../../models/IImageRequest";
import { getAllImageRequests, getImageRequests } from "./action";

interface ImageRequests {
	allRequests: IImageRequest[];
	allRequestsCount: number;
	unrepliedRequestsCount: number;
	negotiationRequestsCount: number;
	agreedRequestsCount: number;
	rejectedRequestsCount: number;
	fileToPayment: IImageRequest | null;
	isLoading: boolean;
}

const initialState: ImageRequests = {
	isLoading: false,
	allRequests: [],
	allRequestsCount: 0,
	unrepliedRequestsCount: 0,
	negotiationRequestsCount: 0,
	agreedRequestsCount: 0,
	rejectedRequestsCount: 0,
	fileToPayment: null,
};

export const photographerImageRequest = createSlice({
	name: "user/artbuyerImageRequest",
	initialState,
	reducers: {
		onPaymentRequest: (state, action) => {
			state.fileToPayment = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getImageRequests.fulfilled, (state, action) => {
				state.allRequests = action.payload;
				state.isLoading = false;
			})
			.addCase(getImageRequests.rejected, (state, action) => {
				state.isLoading = false;
			})
			.addCase(getImageRequests.pending, (state, action) => {
				state.isLoading = true;
			})
			.addCase(getAllImageRequests.rejected, (state, action) => {
				state.isLoading = false;
			})
			.addCase(getAllImageRequests.pending, (state, action) => {
				state.isLoading = true;
			})
			.addCase(getAllImageRequests.fulfilled, (state, action) => {
				state.allRequestsCount = 0;
				state.unrepliedRequestsCount = 0;
				state.agreedRequestsCount = 0;
				state.negotiationRequestsCount = 0;
				state.rejectedRequestsCount = 0;
				action.payload.forEach((el) => {
					state.allRequestsCount += 1;
					if (el.status === "NEW" || el.status === "COUNTER_OFFER_BUYER") {
						state.unrepliedRequestsCount += 1;
					} else if (el.status === "CONFIRMED" || el.status === "COUNTER_OFFER_ACCEPTED") {
						state.agreedRequestsCount += 1;
					} else if (el.status === "COUNTER_OFFER_PHOTOGRAPHER") {
						state.negotiationRequestsCount += 1;
					} else {
						state.rejectedRequestsCount += 1;
					}
				});
				state.isLoading = false;
			});
	},
});

export const {
	reducer: photographerImageRequestReducer,
	actions: photographerImageRequestActions,
} = photographerImageRequest;

export const { onPaymentRequest } = photographerImageRequestActions;
