import { useEffect, useState } from "react";
import styled from "styled-components";
import { CheckoutBillingAdress } from "./CheckoutBillingForm";
import { payments } from "../../static/images";
import { Button, Checkbox, ErrorWrapper, Input } from "../UI";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useForm } from "react-hook-form";
import { cartProcess } from "../../store/reducers/cart/action";
import FormWrapper from "./FormWrapper";
import { artbuyerProfile, updateArtbuyerProfile } from "../../store/reducers/profile/action";

const stripe = require("stripe")("sk_test_WynOTJJDCcjyNShspfp33Xz0");

export const Checkout = () => {
	const [userCountry, setUserCountry] = useState(0);
	const [vatValue, setVatValue] = useState(0);
	const [isValidToPay, setIsValidToPay] = useState(false);
	const [isAgree, setIsAgree] = useState(false);
	const [isRead, setIsRead] = useState(false);
	const [isDisabled, setIsDisabled] = useState(true);
	const [clientSecret, setClientSecret] = useState<string>("");
	const [isCardComplete, setIsCardComplete] = useState(false);

	const { checkoutItem } = useAppSelector((state) => state.cart);
	const { countries } = useAppSelector((state) => state.countries);

	const precentValue = checkoutItem?.subTotal ? (checkoutItem.subTotal / 100) * vatValue : 0;
	// const totalValue = checkoutItem?.subTotal ? checkoutItem.subTotal + precentValue : 0;

	const {
		register,
		control,
		formState: { errors, isValid },
		reset,
		handleSubmit,
	} = useForm();

	const navigate = useNavigate();

	// const createPaymentIntent = async () => {
	// 	const paymentIntent = await stripe.paymentIntents.create({
	// 		amount: totalValue * 100,
	// 		currency: "usd",
	// 		payment_method: "pm_card_visa",
	// 	});

	// 	setClientSecret(paymentIntent.client_secret);
	// };

	useEffect(() => {
		if (checkoutItem === null) {
			navigate("/creator/profile/cart");
		} else {
			// createPaymentIntent();
		}
	}, []);

	useEffect(() => {
		getVatValue();
	}, [userCountry]);

	const getBasketItemsIds = () => {
		const arr: number[] = [];
		if (checkoutItem)
			for (let i = 0; i < checkoutItem.basketListModels.length; i++) {
				const item = checkoutItem.basketListModels[i];
				if (item.id) arr.push(item.id);
			}
		return arr;
	};

	const getVatValue = () => {
		const element = countries.find((el) => el.id === userCountry);
		setVatValue(element?.tax || 0);
	};

	const dispatch = useAppDispatch();

	const onBuyNowClick = async (data: any) => {
		await dispatch(updateArtbuyerProfile({ ...data, countryId: userCountry }));
		await dispatch(artbuyerProfile());

		setIsValidToPay(true);
		// const json = {
		// 	token: "pi_3NYTsqE1BTvbTbDz0NNzFKf8_secret_YqZ5FyetgrLo9P5E8LGUbLvNm",
		// 	basketItemIds: getBasketItemsIds(),
		// };

		// const { meta } = await dispatch(cartProcess(JSON.stringify(json)));
		// if (meta.requestStatus === "fulfilled") {
		// 	navigate("/creator/profile/cart/purchase");
		// }
	};

	
	return (
		<Wrapper>
			<Title>Checkout</Title>
			<Container>
				<ContainerContent>
					<BillingAdress>
						<CheckoutBillingAdress
							userCountry={userCountry}
							setUserCountry={setUserCountry}
							reset={reset}
							register={register}
							errors={errors}
							control={control}
						/>
					</BillingAdress>

					<Payment>
						<PaymentTop>
							<PaymentTitle>Payment</PaymentTitle>
							<PaymentSytems src={payments} />
						</PaymentTop>
						<FormWrapper
							setIsCardComplete={setIsCardComplete}
							basketIds={getBasketItemsIds()}
							setIsDisabled={setIsDisabled}
							clientSecret={clientSecret}
							setIsValid={setIsValidToPay}
							isValid={isValidToPay}
							userCountry={userCountry}
						/>
					</Payment>
				</ContainerContent>
				<OrderInfo>
					<OrderInfoTop>
						<h4>Your order</h4>
						<p>
							{checkoutItem?.basketListModels.length +
								` ${checkoutItem?.basketListModels.length === 1 ? "image" : "images"}`}
						</p>
					</OrderInfoTop>
					<OrderPriceCalculation>
						<OrderPriceRow>
							<p>Sub-total</p>
							<h4>€ {checkoutItem?.subTotal}.00</h4>
						</OrderPriceRow>
						<OrderPriceRow style={{ color: "#808080" }}>
							<p>VAT ({vatValue}%)</p>
							<h4>
								€ {checkoutItem?.subTotal ? precentValue.toFixed(2) : 0}
								{checkoutItem?.subTotal && Number.isInteger(precentValue) && 0.0}
							</h4>
						</OrderPriceRow>
						<OrderPriceText>
							The amount is charged in the original currency of the photographer. The actual on your
							bank statement in your currency may differ according to your bank fees and currency
							conversion rates.
						</OrderPriceText>
					</OrderPriceCalculation>
					<OrderInfoTotalPrice>
						<div>Total</div>
						<div>
							€ {checkoutItem?.subTotal ? precentValue + checkoutItem.subTotal : 0}
							{checkoutItem?.subTotal &&
								Number.isInteger(precentValue + checkoutItem.total) &&
								".00"}
						</div>
					</OrderInfoTotalPrice>
					<CheckboxWrapper>
						<Checkbox checked={isRead} onValueChange={setIsRead} />
						<p>
							I have read andd understand the VSBLE.space{" "}
							<a target="_blank" href={`${process.env.REACT_APP_DOMEN}/terms`}>
								Terms of Service
							</a>{" "}
							and{" "}
							<a target="_blank" href={`${process.env.REACT_APP_DOMEN}/privacy`}>
								Privacy Policy
							</a>
						</p>
					</CheckboxWrapper>
					<CheckboxWrapper>
						<Checkbox checked={isAgree} onValueChange={setIsAgree} />
						<p>
							I expressly agree that you will begin with the execution of the contrast before the
							expiry of the withdrawal period. I am aware thet by giving this consent I lose my
							right of withdrawal at the beginning of the execution of the contrast.
						</p>
					</CheckboxWrapper>
					<Button
						disabled={isDisabled  || !isAgree || !isRead || !isCardComplete}
						onClick={handleSubmit(onBuyNowClick)}
						width="100%">
						Buy Now
					</Button>
				</OrderInfo>
			</Container>
		</Wrapper>
	);
};
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1136px;
	margin: 0 auto;
	padding-bottom: 84px;
`;
const Title = styled.h2`
	margin-bottom: 40px;
	font-weight: 700;
	font-size: 40px;
	line-height: 48px;
`;
const Container = styled.div`
	display: flex;
`;
const ContainerContent = styled.div`
	width: 736px;
	margin-right: 32px;
`;
const BillingAdress = styled.div`
	width: 100%;
	background: #ffffff;
	border-radius: 8px;
	padding: 24px;
	margin-bottom: 16px;
`;
const OrderInfo = styled.div`
	width: 368px;

	padding: 24px;
	background: #ffffff;
	border-radius: 8px;
	max-height: 521px;
`;
const Payment = styled.div`
	padding: 24px;
	background: #ffffff;
	border-radius: 8px;
`;
const PaymentTop = styled.div`
	margin-bottom: 48px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const PaymentTitle = styled.div`
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
`;
const PaymentSytems = styled.img``;

const OrderInfoTop = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 16px;
	border-bottom: 1px solid #ebebeb;
	& > h4 {
		font-weight: 700;
		font-size: 18px;
		line-height: 24px;
	}
	& > p {
		color: #808080;
	}
`;
const OrderPriceCalculation = styled.div`
	padding: 16px 0;
	border-bottom: 1px solid #ebebeb;
`;

const OrderPriceRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
	& > p {
		line-height: 24px;
	}
	& > h4 {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
	}
`;
const OrderPriceText = styled.div`
	font-size: 12px;
	line-height: 16px;
	color: #3c3c3c;
	margin-top: 16px;
`;

const OrderInfoTotalPrice = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 18px;
	line-height: 24px;
	font-weight: 700;
	padding: 16px 0px;
	border-bottom: 1px solid #ebebeb;
	margin-bottom: 16px;
`;
const CheckboxWrapper = styled.div`
	margin-bottom: 16px;
	display: flex;
	align-items: flex-start;
	& > p {
		font-size: 12px;
		line-height: 16px;
		& > a {
			color: #8562ce;
		}
	}
`;
const PaymentForm = styled.div`
	display: grid;
	grid-template-columns: 328px 1fr 1fr;
	gap: 16px;
`;
