import { createAsyncThunk } from "@reduxjs/toolkit";
import { Showroom } from "../../../models/IPhoto";
import { api } from "../../../api/axiosInstance";

export const postPhotos = createAsyncThunk<any, any, { rejectValue?: unknown }>(
	"user/postPhotos",
	async (data, thunkAPI) => {
		try {
			return await fetch(
				`${process.env.REACT_APP_DOMEN}/api/v1/photographer/showroom/uploadPhoto`,
				{
					method: "POST",
					body: data,
					headers: {
						"X-AUTH-TOKEN": `${localStorage.getItem("token")}`,
					},
				}
			).then((response) => response.json());
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const updatePhotographerPhoto = createAsyncThunk<any, any, { rejectValue?: unknown }>(
	"photographer/updatePhotographerPhoto",
	async (data, thunkAPI) => {
		try {
			return await fetch(
				`${process.env.REACT_APP_DOMEN}/api/v1/photographer/showroom/updatePhoto/${data.photoId}`,
				{
					method: "POST",
					body: data.formData,
					headers: {
						"X-AUTH-TOKEN": `${localStorage.getItem("token")}`,
					},
				}
			).then((response) => response.json());
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);

export const getPhotographerPhotos = createAsyncThunk<any, string, { rejectValue?: any }>(
	"photographer/getPhotos",
	async (filters, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `/photographer/showroom/photos${filters}`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);

export const getMyPhotos = createAsyncThunk<any, string, { rejectValue?: any }>(
	"photographer/myPhotos",
	async (filters, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `/photographer/showroom/photos?tab=MY${filters}`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);

export const getSuggestedTags = createAsyncThunk<
	{ duplicateFound: boolean; duplicates: any; tags: string[] },
	any,
	{ rejectValue?: any }
>("photographer/suggestedTags", async (data, thunkAPI) => {
	try {
		return await fetch(`${process.env.REACT_APP_DOMEN}/api/v1/photos/recognition`, {
			method: "POST",
			body: data,
			headers: {
				"X-AUTH-TOKEN": `${localStorage.getItem("token")}`,
			},
		}).then((response) => response.json());
	} catch (e: any) {
		console.log(e);
		return thunkAPI.rejectWithValue(e);
	}
});

export const deletePhotographerPhoto = createAsyncThunk<any, number, { rejectValue?: any }>(
	"photographer/deletePhoto",
	async (id, thunkAPI) => {
		try {
			const response = await api({
				method: "DELETE",
				url: `/photographer/showroom/deletePhoto/${id}`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const getPhotographerPhoto = createAsyncThunk<any, string, { rejectValue?: any }>(
	"photographer/getPhotoById",
	async (id, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `/photographer/showroom/photo/${id}`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const changeToSedcard = createAsyncThunk<any, string, { rejectValue?: any }>(
	"photographer/changeToSedcard",
	async (json, thunkAPI) => {
		try {
			const response = await api({
				method: "POST",
				url: `/photographer/showroom/setSedcard`,
				data: json,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const setPhotoCover = createAsyncThunk<any, string, { rejectValue?: any }>(
	"photographer/changeToSedcard",
	async (json, thunkAPI) => {
		try {
			const response = await api({
				method: "POST",
				url: `/photographer/showroom/setCover`,
				data: json,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const removeFromSedcard = createAsyncThunk<any, string, { rejectValue?: any }>(
	"photographer/removeFromSedcard",
	async (json, thunkAPI) => {
		try {
			const response = await api({
				method: "POST",
				url: `/photographer/showroom/removeSedcard`,
				data: json,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
