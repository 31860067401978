import { forwardRef, InputHTMLAttributes, RefObject, useState } from "react";
import styled, { css } from "styled-components";
import { closeEye, openEye } from "../../static/images";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	maxWidth?: string;
	placeholder?: string;
	labelText?: string;
	error?: boolean;
}
interface FormProps {
	maxWidth?: string;
	error?: boolean;
}

export const PasswordInput = forwardRef(
	(
		props: Props,
		ref:
			| ((instance: HTMLInputElement | null) => void)
			| RefObject<HTMLInputElement>
			| null
			| undefined
	) => {
		const { maxWidth, placeholder, error, labelText, ...other } = props;

		const [showPassword, setShowPassword] = useState(false);

		return (
			<Label>
				{labelText && <LabelText>{labelText}</LabelText>}

				<Form error={error} maxWidth={maxWidth}>
					<Input
						ref={ref}
						type={showPassword ? "text" : "password"}
						placeholder={placeholder}
						{...other}
					/>
					<InputImg
						onClick={() => setShowPassword(!showPassword)}
						src={showPassword ? openEye : closeEye}
						alt="eye"
					/>
				</Form>
			</Label>
		);
	}
);

const Form = styled.div<FormProps>`
	margin-right: 8px;
	max-width: ${({ maxWidth }) => maxWidth};
	width: 100%;
	height: 46px;

	border: none;
	color: #000;
	position: relative;
	${(props) => {
		return (
			props.error &&
			css`
				& > input {
					border: 1px solid red;
					background: #fff;
				}
			`
		);
	}}
`;
const Input = styled.input`
	border: 1px solid transparent;
	height: 47px;
	outline: none;
	width: 100%;
	padding: 12px 16px;
	position: absolute;
	background: #f7f7f7;
	top: 0;
	left: 0;
	border-radius: 8px;
	bottom: 0;
	right: 0;
	color: #808080;
	&:focus {
		background: #fff;
		border: 1px solid #ebebeb;
	}
`;
const InputImg = styled.img`
	width: 24px;
	height: 24px;
	cursor: pointer;
	position: absolute;
	right: 16px;
	top: 10px;
`;

const LabelText = styled.div`
	margin-bottom: 8px;
	font-weight: bold;
	color: #282828;
`;

const Label = styled.label`
	width: 100%;
`;
