export const getStatus = (status: string, tracked?: boolean) => {
	if (status === "PUBLISHED" && tracked === false) {
		return {
			value: "New",
			color: "#62C78F",
		};
	}
	if (status === "DRAFT") {
		return {
			value: "Draft",
			color: "#FFAA06",
		};
	} else if (status === "PUBLISHED") {
		return {
			value: "Published",
			color: "#62C78F",
		};
	} else {
		return {
			value: "Closed",
			color: "#C3C3C3",
		};
	}
};
export const getColor = (status: string) => {
	switch (status) {
		case "CONFIRMED":
		case "COUNTER_OFFER_ACCEPTED":
			return "#62C78F";

		case "REJECTED":
		case "CANCELLED_BY_BUYER":
		case "COUNTER_OFFER_REJECTED":
			return "#EE4747";

		default:
			return "#FFAA06";
	}
};
export const getCardPhotographerStatus = (status: string) => {
	switch (status) {
		case "NEW":
			return "pending";
		case "REJECTED":
			return "Declined by you";
		case "CONFIRMED":
			return "Accepted";
		case "COUNTER_OFFER_PHOTOGRAPHER":
			return "Your counter offer";
		case "COUNTER_OFFER_BUYER":
			return "Awaiting feedback";
		case "COUNTER_OFFER_ACCEPTED":
			return "Accepted";
		case "COUNTER_OFFER_REJECTED":
			return "Declined by buyer";
		case "CANCELLED_BY_BUYER":
			return "Cancelled";
	}
};
export const getCardBuyerStatus = (status: string) => {
	console.log(status);

	switch (status) {
		case "NEW":
			return "pending";
		case "REJECTED":
			return "Declined by photographer";
		case "CONFIRMED":
			return "Accepted";
		case "COUNTER_OFFER_PHOTOGRAPHER":
			return "Awaiting feedback";
		case "COUNTER_OFFER_BUYER":
			return "Your counter offer";
		case "COUNTER_OFFER_ACCEPTED":
			return "Accepted";
		case "COUNTER_OFFER_REJECTED":
			return "Declined by you";
		case "CANCELLED_BY_BUYER":
			return "Cancelled";
	}
};

export const releaseAvailable = (modelRelease: string) => {
	if (modelRelease === "Yes") {
		return "YES";
	} else if (modelRelease === "No") {
		return "NO";
	} else {
		return "POSSIBLE";
	}
};
export const releaseAvailableReverse = (modelRelease: string) => {
	if (modelRelease === "YES") {
		return "Yes";
	} else if (modelRelease === "NO") {
		return "No";
	} else {
		return "Possible";
	}
};
export const onPricing = (pricing: string) => {
	if (pricing === "Custom Rights Ready") {
		return "CUSTOM";
	} else if (pricing === "Upon Request") {
		return "NEGOTIATE_LATER";
	} else {
		return "ARTBRIEF_PRICING";
	}
};
export const getLicence = (licence: string) => {
	if (licence === "Online Licence") {
		return "ONLINE";
	} else if (licence === "Editorial Licence") {
		return "EDITORIAL";
	} else {
		return "EXTENDED";
	}
};
export const getLicenceReverse = (licence: string) => {
	if (licence === "ONLINE") {
		return "Online Licence";
	} else if (licence === "EDITORIAL") {
		return "Editorial Licence";
	} else {
		return "Extended Licence";
	}
};
export const getRoles = (roles: number[]) => {
	let string = "";

	roles.forEach((el) => {
		if (el === 1) {
			string += "&roles=PHOTOGRAPHER";
		} else if (el === 2) {
			string += "&roles=MODEL";
		} else if (el === 3) {
			string += "&roles=MAKEUP_ARTIST";
		} else if (el === 4) {
			string += "&roles=STYLIST";
		} else if (el === 5) {
			string += "&roles=ILLUSTRATOR";
		}
	});
	return string;
};

export const getPhotographerColor = (status: string) => {
	switch (status) {
		case "CONFIRMED":
		case "COUNTER_OFFER_ACCEPTED":
			return "#62C78F";

		case "REJECTED":
		case "CANCELLED_BY_BUYER":
		case "COUNTER_OFFER_REJECTED":
			return "#EE4747";

		default:
			return "#FFAA06";
	}
};

export const getArtbuyerColor = (status: string) => {
	switch (status) {
		case "CONFIRMED":
		case "COUNTER_OFFER_ACCEPTED":
			return "#62C78F";

		case "REJECTED":
		case "CANCELLED_BY_BUYER":
		case "COUNTER_OFFER_BUYER":
		case "COUNTER_OFFER_REJECTED":
			return "#EE4747";

		default:
			return "#FFAA06";
	}
};

export const getBuyerStatus = (status: string) => {
	switch (status) {
		case "NEW":
			return "pending";
		case "REJECTED":
			return "Declined by you";
		case "CONFIRMED":
			return "Accepted";
		case "COUNTER_OFFER_PHOTOGRAPHER":
			return "Declined by you";
		case "COUNTER_OFFER_BUYER":
			return "Awaiting feedback";
		case "COUNTER_OFFER_ACCEPTED":
			return "Accepted";
		case "COUNTER_OFFER_REJECTED":
			return "Declined by you";
		case "CANCELLED_BY_BUYER":
			return "Declined by you";
	}
};
export const getPhotographerStatus = (status: string) => {
	switch (status) {
		case "NEW":
			return "pending";
		case "REJECTED":
			return "Declined by you";
		case "CONFIRMED":
			return "Accepted";
		case "COUNTER_OFFER_PHOTOGRAPHER":
			return "Awaiting feedback";
		case "COUNTER_OFFER_BUYER":
			return "Declined by buyer";
		case "COUNTER_OFFER_ACCEPTED":
			return "Accepted";
		case "COUNTER_OFFER_REJECTED":
			return "Declined by buyer";
		case "CANCELLED_BY_BUYER ":
			return "Cancelled by buyer";
	}
};

export const getPricingStatus = (status: string) => {
	if (status === "ARTBRIEF_PRICING") {
		return "Rights Ready";
	} else if (status === "CUSTOM") {
		return "Custom Rights Ready";
	} else if (status === "NEGOTIATE_LATER") {
		return "Upon Request";
	} else if (status === "MOBILE_RIGHTS_READY") {
		return "Mobile Rights Ready";
	}
};
