import React from "react";
import { addedSvg, closeWSvg } from "../../static/images";
import styled, { css } from "styled-components";

interface Props {
	isAddedToCart: boolean;
	setIsAddedToCart: (value: React.SetStateAction<boolean>) => void;
	text: string;
	span?: string;
}

export const CornerMessage = ({ text, span, isAddedToCart, setIsAddedToCart }: Props) => {
	return (
		<ItemWasAdded active={isAddedToCart}>
			<div>
				<img src={addedSvg} alt="addedSvg" />
			</div>
			<p>
				{span && <span>{span}</span>}
				{text}
			</p>
			<img onClick={() => setIsAddedToCart(false)} src={closeWSvg} alt="closeWSvg" />
		</ItemWasAdded>
	);
};

const ItemWasAdded = styled.div<any>`
	position: fixed;
	bottom: 16px;
	right: 16px;
	padding: 16px;
	display: flex;
	align-items: center;
	background: #8562ce;
	border-radius: 8px;
	transform: translateX(800px);
	display: none;
	transition: 0.2s;
	z-index: 10;
	& > img {
		margin-left: 14px;
		cursor: pointer;
	}
	& > p {
		max-width: 374px;
		color: #fff;
		& > span {
			font-weight: 700;
		}
	}
	& > div {
		display: flex;
		justify-content: center;
		align-items: center;
		background: #fff;
		border-radius: 50%;
		margin-right: 14px;
		width: 40px;
		height: 40px;
	}
	${({ active }) => {
		if (active) {
			return css`
				transform: translateX(0px);
				display: flex;
				transition: 0.2s;
			`;
		}
	}}
`;
