import React, { useEffect, useState } from "react";
import { messagesArray } from "../utils/messages";

export const TextSwitcher = () => {
	const [currentImage, setCurrentImage] = useState(0);

	let messageInterval: any;

	useEffect(() => {
		messageInterval = setInterval(() => {
			setCurrentImage((prev) => {
				if (messagesArray.length - 1 === prev) {
					return 0;
				} else {
					return prev + 1;
				}
			});
		}, 15000);
	}, []);

	return <div>{messagesArray[currentImage]}</div>;
};
