import React from "react";
import styled from "styled-components";

export const LoadingButton = () => {
	return (
		<Button>
			<span>Loading...</span>
		</Button>
	);
};

const Button = styled.button`
	border: none;
	width: 100%;
	height: 20px;
	outline: none;
	position: relative;
	background-color: var(--first-color);
	color: #fff;
	font-size: 12px;
	border-radius: 0.5rem;
	/* box-shadow: 0 18px 40px hsla(231, 56%, 56%, 0.3); */
	overflow: hidden;
	cursor: wait;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 150%;
		height: 100%;
		background: repeating-linear-gradient(
			60deg,
			transparent,
			transparent 0.75rem,
			var(--first-color-alt) 0.75rem,
			var(--first-color-alt) 1.5rem
		);
		animation: load 1s infinite linear;
	}
	& > span {
		position: relative;
	}
`;
