import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
	getAllArtbuyerImageRequests,
	getArtbuyerImageRequests,
} from "../../store/reducers/imageRequest/action";
import { splitArrayToGalleryFormat } from "../../utils";
import { IImageRequest } from "../../models/IImageRequest";
import { Modal } from "../UI";
import { PriceRequestAbout } from "../Modals/PriceRequestAbout";
import { AuthRoles } from "../../models/IProfile";
import { PriceRequestOffer } from "../Modals/PriceRequestOffer";
import {
	getAllImageRequests,
	getImageRequests,
} from "../../store/reducers/photographerImageRequest/action";
import { PriceRequestPhotographer } from "../Modals/PriceRequestPhotographer";
import {
	getBuyerStatus,
	getCardBuyerStatus,
	getCardPhotographerStatus,
	getColor,
	getPhotographerStatus,
} from "../../utils/getSmth";
import { IsLoading } from "../UI/IsLoading";
import { TimeStamp } from "../../utils/variables";

export const ImageRequests = () => {
	const { user } = useAppSelector((state) => state.authData);

	const dispatch = useAppDispatch();
	const [requestsType, setRequestsType] = useState("ALL");
	const [currentArray, setCurrentArray] = useState<any[][]>([]);
	const [currentItem, setCurrentItem] = useState<any>({});

	const [priceRequestModal, setPriceRequestModal] = useState(false);
	const [priceRequestOffer, setPriceRequestOffer] = useState(false);

	const {
		allRequests,
		allRequestsCount,
		unrepliedRequestsCount,
		negotiationRequestsCount,
		agreedRequestsCount,
		rejectedRequestsCount,
		isLoading,
	} = useAppSelector((state) =>
		user?.role === AuthRoles.Photographer
			? state.photographerImageRequests
			: state.artbuyerImageRequests
	);

	const requestsTypes = [
		{ id: 0, title: "All Requests", parameter: "ALL", count: allRequestsCount },
		{ id: 1, title: "Unreplied", parameter: "UNREPLIED", count: unrepliedRequestsCount },
		{ id: 2, title: "In Negotiation", parameter: "NEGOTIATION", count: negotiationRequestsCount },
		{ id: 3, title: "Agreed", parameter: "AGREED", count: agreedRequestsCount },
		{ id: 4, title: "Rejected / Cancelled", parameter: "REJECTED", count: rejectedRequestsCount },
	];

	const onImageRequestItemClick = (el: IImageRequest) => {
		if (user?.role === AuthRoles.Artbuyer) {
			setPriceRequestModal(true);
		} else {
			setPriceRequestOffer(true);
		}
		setCurrentItem(el);
	};

	const getAllRequests = async () => {
		const parameters = `?page=1&pageSize=300&timestamp=${TimeStamp}`;
		user?.role === AuthRoles.Photographer
			? await dispatch(getAllImageRequests(parameters))
			: await dispatch(getAllArtbuyerImageRequests(parameters));
	};

	const getRequests = async () => {
		const parameters = `?page=1&pageSize=300&timestamp=${TimeStamp}&filter=${requestsType}`;

		user?.role === AuthRoles.Photographer
			? await dispatch(getImageRequests(parameters))
			: await dispatch(getArtbuyerImageRequests(parameters));

		getAllRequests();
	};

	useEffect(() => {
		getRequests();
	}, [requestsType]);

	useEffect(() => {
		const imagesRequestsToGalleryFormat = splitArrayToGalleryFormat(allRequests);
		setCurrentArray(imagesRequestsToGalleryFormat);
	}, [allRequests, requestsType]);

	useEffect(() => {
		getAllRequests();
	}, []);

	const onCloseRequestModal = () => {
		setPriceRequestModal(false);
	};

	const onCloseRequestOffer = () => {
		setPriceRequestOffer(false);
	};

	return (
		<Wrapper>
			{isLoading ? (
				<IsLoading />
			) : (
				<>
					<Title>Price Requests</Title>

					<ProfileGallerySortList>
						{requestsTypes.map((el) => (
							<ProfileGallerySortItem
								key={el.id}
								active={el.parameter === requestsType ? true : false}
								onClick={() => setRequestsType(el.parameter)}>
								{el.title}

								<ProfileGalleryTypeCount>{el.count}</ProfileGalleryTypeCount>
							</ProfileGallerySortItem>
						))}
					</ProfileGallerySortList>

					<ImagesRequestsImages>
						{currentArray.map((array, i) => (
							<ImagesRequestsColumn key={i}>
								{array.map((el: IImageRequest) => (
									<ImagesRequestsItem
										newrequest={el.status === "NEW" && user?.role === AuthRoles.Photographer}
										onClick={() => onImageRequestItemClick(el)}
										key={el.id}>
										<img
											src={`${process.env.REACT_APP_DOMEN}${el.photo.imageDetails.LIGHTBOX.url}`}
											alt="reqImg"
										/>

										{user?.role === AuthRoles.Artbuyer ? (
											<>
												<ImagesRequestsItemInfo>
													<p>Your budget</p>
													<h5>
														{el.currency === "USD" ? "$" : ""} {el.initialPrice}
													</h5>
												</ImagesRequestsItemInfo>

												<ImagesRequestsItemInfo color={getColor(el.status)}>
													<p>
														<span>{el.status === "NEW" && "Reply "} </span>
														{getCardBuyerStatus(el.status)}
													</p>

													{el.status !== "NEW" &&
														el.status !== "REJECTED" &&
														el.status !== "CANCELLED_BY_BUYER" &&
														el.status !== "COUNTER_OFFER_REJECTED" && (
															<h5>
																{el.price &&
																	`${el.currency === "USD" ? "$" : "€"} ${
																		el.status === "COUNTER_OFFER_BUYER"
																			? el.priceBuyer
																			: el.pricePhotographer
																			? el.pricePhotographer
																			: el.price
																	}`}
															</h5>
														)}
												</ImagesRequestsItemInfo>
											</>
										) : (
											<ImagesRequestsItemInfo color={getColor(el.status)}>
												<p>
													<span>{el.status === "NEW" ? "Reply " : ""} </span>
													{getCardPhotographerStatus(el.status)}
												</p>

												<h5>
													{el.price &&
														`${el.currency === "USD" ? "$" : "€"} ${
															el.status === "COUNTER_OFFER_BUYER"
																? el.priceBuyer
																: el.pricePhotographer
																? el.pricePhotographer
																: el.price
														}`}
												</h5>
											</ImagesRequestsItemInfo>
										)}
									</ImagesRequestsItem>
								))}
							</ImagesRequestsColumn>
						))}
					</ImagesRequestsImages>

					<Modal noPadding isOpen={priceRequestModal} onClose={onCloseRequestModal}>
						<PriceRequestAbout
							getRequests={getRequests}
							onClose={onCloseRequestModal}
							element={currentItem}
						/>
					</Modal>

					{currentItem.status === "NEW" ? (
						<Modal noPadding isOpen={priceRequestOffer} onClose={onCloseRequestOffer}>
							<PriceRequestPhotographer
								getRequests={getRequests}
								getAllRequests={getAllRequests}
								element={currentItem}
								onClose={onCloseRequestOffer}
							/>
						</Modal>
					) : (
						<Modal noPadding isOpen={priceRequestOffer} onClose={onCloseRequestOffer}>
							<PriceRequestOffer
								getRequests={getRequests}
								getAllRequests={getAllRequests}
								element={currentItem}
								onClose={onCloseRequestOffer}
							/>
						</Modal>
					)}
				</>
			)}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	width: 1136px;
	margin: 0 auto;
`;

const Title = styled.h2`
	margin-bottom: 40px;
	font-weight: 700;
	font-size: 64px;
	line-height: 80px;
	text-align: center;
`;
const ProfileGallerySortList = styled.ul`
	display: flex;
	justify-content: center;
`;
const ProfileGallerySortItem = styled.div<{ active?: boolean }>`
	position: relative;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	color: #999999;
	padding-bottom: 10px;
	cursor: pointer;
	&:not(:last-child) {
		margin-right: 55px;
	}

	${(props) => {
		switch (props.active) {
			case true:
				return css`
					color: #000;
					&::after {
						content: "";
						position: absolute;
						left: 0;
						right: 0;
						bottom: 0;
						border: 2px solid #000;
						border-radius: 8px;
					}
				`;
			default:
				return "";
		}
	}}
`;
const ProfileGalleryTypeCount = styled.div`
	position: absolute;
	right: -25px;
	top: -5px;
	font-size: 12px;
	line-height: 16px;
	color: #808080;
`;

const ImagesRequestsImages = styled.div`
	margin-top: 24px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 16px;
`;
const ImagesRequestsColumn = styled.div`
	width: 272px;
`;
const ImagesRequestsItem = styled.div<any>`
	& > img {
		width: 240px;
		margin-bottom: 16px;
	}
	margin-bottom: 16px;
	padding: 16px;
	background: #ffffff;
	border-radius: 8px;
	cursor: pointer;
	transition: all.2s;
	border: 1px solid transparent;

	&:hover {
		border: 1px solid #ebebeb;
		transition: all.2s;
	}
	${({ newrequest }) => {
		if (newrequest) {
			return css`
				border: 2px solid rgb(133, 98, 206);
				&:hover {
					border: 2px solid rgb(172 150 216);
				}
			`;
		}
	}}
`;
const ImagesRequestsItemInfo = styled.div<any>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: ${({ color }) => color};
	margin-bottom: 4px;
	& > p > {
		span {
			color: #000;
		}
	}
	& > h5 {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
	}
`;
