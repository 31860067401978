import React from "react";
import styled from "styled-components";

export const Privacy = () => {
	return (
		<Wrapper>
			<Title>Privacy Policy</Title>
			<Block>
				<Text>
					With this privacy policy we would like to inform you about how we process personal data
					and inform you about your rights. We are aware of the importance of processing of personal
					data for you as affected users and thus fulfill all relevant legal requirements.
					Protecting your privacy is paramount to us. The processing of your personal data by us
					takes place in compliance with the General Data Protection Regulation and the national
					data protection regulations.
				</Text>
			</Block>
			<Block>
				<Subtitle>
					Name and contact details of the responsible person and his representative
				</Subtitle>
				<Subtitle>ArtBrief UG</Subtitle>
				<List>
					<li> Methfesselstr. 16A</li>
					<li>20257 Hamburg, Germany</li>
					<li>vertretungsberechtigte Geschäftsführer: Wladimir Alexi</li>
					<li>info@artbrief.com</li>
				</List>
			</Block>
			<Block>
				<Subtitle>Definitions</Subtitle>
				<Text>
					This Privacy Policy uses the terms of the General Data Protection Regulation (DSGVO).
				</Text>
			</Block>
			<Block>
				<Subtitle>Definitions</Subtitle>
				<Text>
					"Personal data" means any information relating to an identified or identifiable natural
					person (hereinafter the "data subject"); a natural person is regarded as identifiable,
					which can be identified directly or indirectly, in particular by association with an
					identifier such as a name, an identification number, location data, an online identifier
					or one or more special features, the expression of the physical , physiological, genetic,
					mental, economic, cultural or social identity of this natural person.
				</Text>
				<Text>
					"Processing" means any process performed, with or without the aid of automated procedures,
					or any such process associated with personal data, such as collecting, collecting,
					organizing, organizing, storing, adapting or modifying, reading out, and querying Use,
					disclosure by transmission, dissemination or other form of provision, matching or linking,
					restriction, erasure or destruction.
				</Text>
				<Text>
					"Restriction of processing" is the marking of personal data stored with the aim of
					limiting its future processing.
				</Text>
				<Text>
					"Profiling" means any kind of automated processing of personal data which consists in
					using that personal data to evaluate certain personal aspects relating to a natural
					person, in particular aspects relating to job performance, economic situation, health, to
					analyze or predict personal preferences, interests, reliability, behavior, location or
					change of location of that natural person.
				</Text>
				<Text>
					{" "}
					"Pseudonymisation" means the processing of personal data in such a way that the personal
					data can no longer be assigned to a specific data subject without additional information
					being provided, provided that such additional information is kept separate and subject to
					technical and organizational measures to ensure that the personal data Data should not be
					assigned to an identified or identifiable natural person.
				</Text>
				<Text>
					"File system" means any structured collection of personal data accessible by specific
					criteria, whether that collection is centralized, decentralized or organized according to
					functional or geographical considerations.
				</Text>
				<Text>
					'Controller' means the natural or legal person, public authority, body or body which,
					alone or in concert with others, decides on the purposes and means of processing personal
					data; where the purposes and means of such processing are determined by Union law or the
					law of the Member States, the controller or the specific criteria for his appointment may
					be provided for under Union or national law.
				</Text>
				<Text>
					"Processor" means a natural or legal person, public authority, body or body that processes
					personal data on behalf of the controller.
				</Text>
				<Text>
					{" "}
					"Recipient" means a natural or legal person, public authority, agency or other entity to
					whom personal data are disclosed, whether or not it is a third party. However, authorities
					which may receive personal data under Union or national law in connection with a
					particular mission are not considered to be recipients; the processing of such data by the
					said authorities shall be in accordance with the applicable data protection rules in
					accordance with the purposes of the processing.
				</Text>
				<Text>
					"The third party" means a natural or legal person, public authority, body or body other
					than the data subject, the controller, the processor and the persons authorized under the
					direct responsibility of the controller or processor to process the personal data.
				</Text>
				<Text>
					"Consent" means the expression of the individuals will voluntarily in a particular case,
					in an informed and unambiguous manner, in the form of a statement or other unambiguous
					confirmatory act that indicates to the data subject that they are processing personal data
					relating to them Data agrees.
				</Text>
			</Block>
			<Block>
				<Subtitle>We collect and process the following personal information about you:</Subtitle>
				<ListDots>
					<li>
						{" "}
						Contact and address information, provided you have provided us with your contact
						information or registered on our site.
					</li>
					<li>
						Online identifiers (such as your IP address, browser type and browser version, the
						related operating system, referrer URL, IP address, file name, access status, amount of
						data transferred, date and time of the server request).
					</li>
					<li>Payment information, if you have given us the same.</li>
					<li>Social media identifiers.</li>
					<li>Processing purposes</li>
				</ListDots>
			</Block>
			<Block>
				<Subtitle>We process your data for the following purposes:</Subtitle>
				<ListDots>
					<li> for the desired contact,</li>
					<li>
						for the execution of contracts with you, in particular, the processing of orders and the
						creation of invoices and the administration of your customer account,
					</li>
					<li>
						to update our offer to you (e.g., information about new services or special promotions),
					</li>
					<li>for advertising purposes,</li>
					<li>to send the e-mail newsletter, if you have registered for it</li>
					<li>for quality assurance and</li>
					<li>for our statistics.</li>
				</ListDots>
			</Block>
			<Block>
				<Subtitle>Legal basis of data processing</Subtitle>
				<Text>The processing of your data is done on the following legal bases:</Text>
				<ListDots>
					<li> Your consent in accordance with Art. 6 para. 1 lit. a) GDPR,</li>
					<li>to carry out a contract with you in accordance with Art. 6 para. 1 lit. b) GDPR</li>
					<li>to fulfill legal obligations according to Art. 6 para. 1 lit. c) GDPR or</li>
					<li>from a legitimate interest according to Art. 6 para. 1 lit. f) GDPR.</li>
				</ListDots>
			</Block>
			<Block>
				<Subtitle> Legitimate interests</Subtitle>
				<Text>
					Insofar as the processing of your personal data is based on legitimate interests.Art. 6
					para. 1 lit. f) support DS-GMO are such
				</Text>
				<ListDots>
					<li>the improvement of our offer,</li>
					<li>the protection against abuse and</li>
					<li>the management of our statistics.</li>
				</ListDots>
			</Block>
			<Block>
				<Subtitle>Data sources</Subtitle>
				<Text>
					We receive the data from you (including the equipment you use). If we do not collect the
					personal data directly from you, we will also inform you of the source of the personal
					data and, if applicable, whether they originate from publicly available sources.
				</Text>
				<Text>
					Recipients or categories of recipients of personal data <br />
					When processing your data, we work with the following service providers who have access to
					your data:
				</Text>
				<ListDots>
					<li>Providers of web analytics tools.</li>
					<li>Payment service</li>
				</ListDots>
				<Text>
					Es findet eine Datenübertragung in Drittländer außerhalb der Europäischen Union statt.
					Dies erfolgt auf der Grundlage gesetzlich vorgesehener vertraglicher Regelungen, die einen
					angemessenen Schutz Ihrer Daten sicherstellen sollen und die Sie auf Anfrage einsehen
					können.
				</Text>
				<Text>
					There is a transfer of data to third countries outside the European Union. This is done on
					the basis of statutory contractual provisions that are intended to ensure adequate
					protection of your data and that you can consult upon request.
				</Text>
			</Block>
			<Block>
				<Subtitle>Storage time</Subtitle>
				<Text>
					We store your personal data only as long as it is necessary for the achievement of the
					processing purpose or is subject to the storage of a legal retention period. We store your
					data,
				</Text>
				<ListDots>
					<li>
						{" "}
						if you have consented to the processing at the latest until you revoke your consent,
					</li>
					<li>
						if we need the data to carry out a contract at most as long as the contractual
						relationship with you or statutory retention periods are in effect,
					</li>
					<li>
						if we use the data on the basis of a legitimate interest at most as long as your
						interest in deletion or anonymization does not prevail.
					</li>
				</ListDots>
			</Block>
			<Block>
				<Subtitle>Your rights</Subtitle>
				<Text>They have the right - sometimes under certain conditions - to</Text>
				<ListDots>
					<li>
						{" "}
						Request information about the processing of your data free of charge as well as the
						receipt of a copy of your personal data. Information you can u.a. require the purposes
						of the processing, the categories of personal data being processed, the recipients of
						the data (if disclosed), the duration of the storage or the criteria for determining the
						duration;
					</li>
					<li>
						Correct your data. If your personal data is incomplete, you have the right, under
						consideration of the processing purposes, to complete the data;
					</li>
					<li>
						Clear or block your data. Reasons for the existence of a cancellation / blocking claim
						can i.a. the revocation of the consent on which the processing is based, the data
						subject objects to the processing, the personal data were processed unlawfully;
					</li>
					<li>Limit the processing;</li>
					<li>Object to the processing of your data;</li>
					<li>Revoke your consent to the processing of your data for the future; and</li>
					<li>Complain to the competent authority about inadmissible data processing.</li>
				</ListDots>
			</Block>
			<Block>
				<Subtitle>Requirement or obligation to provide data</Subtitle>
				<Text>
					Unless specifically stated in the survey, the provision of data is not required or
					required. Such an obligation may arise from statutory provisions or contractual
					arrangements.
				</Text>
			</Block>
			<Block>
				<Subtitle>Further information on data protection</Subtitle>
				<Subtitle style={{ marginBottom: "0px" }}>Contact form / customer support</Subtitle>
				<Text>
					The contact form on our website is an easy way to get in contact with us quickly. In order
					to make contact possible, some fields are marked as mandatory fields. By filling out the
					fields and selecting "Submit", you agree that your data will be sent to us by email with
					the message. The data is not stored on the web server. As part of customer support, you
					have the opportunity to contact us via our website. In doing so, you provide us with your
					contact information voluntarily. Your personal data transmitted to us will be stored
					automatically, the processing being solely for the purpose of processing your request to
					us. A passing on to third does not take place.
				</Text>
			</Block>
			<Block>
				<Subtitle>E-mail Newsletter</Subtitle>
				<Text>
					If you subscribe to our newsletter, we will use the information required or separately
					provided by you to send you regular e-mail newsletters. The deregistration from the
					newsletter is possible at any time and can be done either by a message to the contact
					option described above or via the designated unsubscribe link in the newsletter.
				</Text>
			</Block>
			<Block>
				<Subtitle>MailChimp</Subtitle>
				<Text>
					This website uses the services of MailChimp for sending newsletters. Provider is the
					Rocket Science Group LLC, 675 Ponce De Leon Ave. NE, Suite 5000, Atlanta, GA 30308, USA.
				</Text>
				<Text>
					MailChimp is a service that helps u.a. the dispatch of newsletters can be organized and
					analyzed. If you enter dates for the purposes of newsletter subscription (e.g., e-mail
					address), they will be stored on MailChimp's servers in the United States. MailChimp is
					certified under the EU-US Privacy Shield. The Privacy Shield is an agreement between the
					European Union (EU) and the US to ensure compliance with European privacy standards in the
					United States.
				</Text>
				<Text>
					{" "}
					With the help of MailChimp we can analyze our newsletter campaigns. When you open an
					e-mail sent by MailChimp, a file included in the e-mail (called web beacon) connects to
					MailChimp's servers in the United States. This way you can determine if a newsletter
					message has been opened and which links have been clicked on. In addition, technical
					information is collected (e.g., time of retrieval, IP address, browser type and operating
					system). This information can not be assigned to the respective newsletter recipient. They
					are used exclusively for the statistical analysis of newsletter campaigns. The results of
					these analyzes can be used to better tailor future newsletters to the interests of the
					recipient.
				</Text>
				<Text>
					If you do not want analysis by MailChimp, unsubscribe from the newsletter. For this
					purpose, we provide a link in every newsletter message. Furthermore, you can unsubscribe
					from the newsletter directly on the website. Data processing is based on your consent
					(Article 6 (1) (a) GDPR). You can revoke this consent at any time by unsubscribing from
					the newsletter. The legality of the already completed data processing operations remains
					unaffected by the revocation.
				</Text>
				<Text>
					The data deposited with us for the purpose of obtaining the newsletter will be stored by
					us from the newsletter until it has been removed from the newsletter and, after
					unsubscribing from the newsletter, deleted both from our servers and from the servers of
					MailChimp. Data that has been stored for other purposes with us (such as e-mail addresses
					for the members area) remain unaffected.
				</Text>
			</Block>
			<Block>
				<Subtitle>Data storage</Subtitle>
				<Text>
					We have taken extensive technical and organizational measures to safeguard your
					information against possible risks, such as unauthorized access or access, unauthorized
					access, modification or dissemination, loss, destruction or misuse.
					<br />
					In order to protect your personal data from unauthorized access by third parties, we
					secure the data transmissions. using SSL encryption. It is a standardized encryption
					method for online services, especially for the Web.
				</Text>
			</Block>
			<Block>
				<Subtitle>Logfiles</Subtitle>
				<Text>
					Every time you access our Internet pages, usage data is transmitted through the respective
					Internet browser and stored in log files, the so-called server log files. The data records
					stored in this case contain the following data: domain from which the user accesses the
					website, date and time of retrieval IP address of the accessing computer website (s)
					visited by the user in the context of the offer, amount of data transmitted, browser type
					and version, operating system used, name of the Internet service provider, message whether
					the retrieval was successful. These logfile records are evaluated anonymously to improve
					the offering and make it more user-friendly, to find and fix bugs, and to control server
					load.
				</Text>
			</Block>
			<Block>
				<Subtitle>Cookies</Subtitle>
				<Text>
					This website uses so-called cookies. A cookie is a text file with an identification number
					that is transmitted to the user's computer when the website is used together with the
					other data actually requested. The file is kept there for later access and is used to
					authenticate the user. Since cookies are just simple files and not executable programs,
					they pose no threat to your computer. These cookies are automatically accepted according
					to the user's setting of the Internet browser. However, this setting can be changed and
					the storage of cookies disabled or set so that the user is notified when a cookie is set.
					In the case of a deactivation of the use of cookies, however, some functions of the
					website may not be available or only to a limited extent. You can prevent the setting of
					cookies through our website at any time by means of an appropriate setting of the Internet
					browser used and thus permanently contradict the setting of cookies. Already active
					cookies can be deleted at any time via an internet browser or other software programs.{" "}
					<br />
					We may work with advertising partners to help us make our web site more interesting to
					you. For this purpose, in this case, when you visit our website, cookies from partner
					companies are stored on your hard disk (third-party cookies). If we cooperate with
					aforementioned advertising partners, you will be informed individually and separately
					about the use of such cookies and the scope of the information collected in the following
					paragraphs.
					<br />
					Please note that you can set your browser so that you are informed about the setting of
					cookies and individually decide on their acceptance or can exclude the acceptance of
					cookies for specific cases or in general. Each browser differs in the way it manages the
					cookie settings. This is described in the Help menu of each browser, which explains how to
					change your cookie settings. These can be found for the respective browser under the
					following links:
				</Text>
				<Paragraph>
					Internet Explorer:{" "}
					<a href="https://support.microsoft.com/uk-ua/windows/%D0%B2%D0%B8%D0%B4%D0%B0%D0%BB%D0%B5%D0%BD%D0%BD%D1%8F-%D1%84%D0%B0%D0%B9%D0%BB%D1%96%D0%B2-cookie-%D1%82%D0%B0-%D0%BA%D0%B5%D1%80%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F-%D0%BD%D0%B8%D0%BC%D0%B8-168dab11-0753-043d-7c16-ede5947fc64d">
						http://windows.microsoft.com/de-DE/windows-vista/B...
					</a>
				</Paragraph>
				<Paragraph>
					Firefox:
					<a href="https://support.mozilla.org/de/kb/verbesserter-schutz-aktivitatenverfolgung-desktop?redirectslug=cookies-erlauben-und-ablehnen&redirectlocale=de">
						https://support.mozilla.org/de/kb/cookies-erlauben...
					</a>
				</Paragraph>
				<Paragraph>
					Chrome:
					<a href="https://support.google.com/chrome/answer/95647?hl=de&hlrm=en">
						http://support.google.com/chrome/bin/answer.py?hl=...
					</a>
				</Paragraph>
				<Paragraph>
					Safari:
					<a href="https://support.apple.com/kb/ph21411?locale=de_DE">
						{" "}
						https://support.apple.com/kb/ph21411?locale=de_DE
					</a>
				</Paragraph>
				<Paragraph>
					Opera:
					<a href="https://help.opera.com/en/latest/web-preferences/#cookies">
						{" "}
						http://help.opera.com/Windows/10.20/de/cookies.htm...
					</a>
				</Paragraph>
				<Text style={{ marginTop: "26px" }}>
					Please note that if you do not accept cookies, the functionality of our website may be
					limited.
				</Text>
			</Block>
			<Block>
				<Subtitle>Using the analytics tool Google Analytics</Subtitle>
				<Text>
					We use the service Google Analytics of Google Inc. This service allows an analysis of the
					use of our web pages and uses cookies. For this purpose, the information generated by the
					cookie, such as your anonymized IP address, is transmitted on our behalf to a Google Inc.
					server in the USA, stored and evaluated there. For on this website Google Analytics has
					been extended by the code "gat._anonymizeIp ();". This ensures an anonymous collection of
					IP addresses. The anonymization of your IP address is usually done by shortening your IP
					address by Google Inc. within the European Union or in other contracting states of the
					European Economic Area (EEA). In exceptional cases, your IP address will be transmitted to
					a Google Inc. server in the US and will be anonymised there. Your IP address will not be
					merged with other data provided by Google Inc. The Google Analytics Advertising feature
					uses remarketing and demographics and interest reporting. These procedures have the
					purpose of using the information about the user behavior to direct the advertising
					measures more closely to the interests of the respective users. As part of the remarketing
					can be based on the surfing behavior of the user on this website personalized advertising
					on other websites. The advertising media may contain products that the user has previously
					viewed on the website. If you've consented to Google linking your web and app browsing
					history to your Google Account and using information from your Google Account to
					personalize your ads, Google will use that information for cross-device remarketing. You
					may object to the collection of your data through Google Analytics at any time. The
					following options are available: Most browsers accept cookies automatically. However, you
					can prevent the use of cookies by setting your browser; In this case, however, not all
					functions of the website may be used. The settings need to be adjusted eventually
					separately by you for each browser .You may also prevent the collection and processing by
					Google Inc. of this data by downloading and installing the browser ad-on available from
					the following link:
				</Text>
				<Paragraph style={{ marginBottom: "30px" }}>
					<a href="https://tools.google.com/dlpage/gaoptout?hl=de">
						https://tools.google.com/dlpage/gaoptout?hl=de
					</a>
				</Paragraph>
				<Text>
					As an alternative to the browser plug-in or within browsers on mobile devices, please
					click on the following link to set an opt-out cookie that will prevent Google Analytics
					from entering this website in the future (this opt-out cookie only works in this browser
					and only for this domain, delete your cookies in this browser, you must click this link
					again):
				</Text>
				<Paragraph style={{ marginBottom: "30px" }}>
					<a href="Disable Google Analytics">Disable Google Analytics</a>
				</Paragraph>
				<Text>
					An opt-out cookie is stored on your device for our web pages with effect for your
					currently used browser. If you delete your cookies in this browser, you must click on this
					link again. For more information about Terms of Use and Privacy, please visit
				</Text>
				<Paragraph style={{ marginBottom: "30px" }}>
					<a href="http://www.google.com/analytics/terms/de.html">
						http://www.google.com/analytics/terms/de.html
					</a>{" "}
					or{" "}
					<a href="http://www.google.com/analytics/terms/de.html">
						http://www.google.com/analytics/terms/de.html
					</a>
				</Paragraph>
			</Block>
			<Block>
				<Subtitle>Webflow</Subtitle>
				<Text style={{ marginBottom: "0px" }}>
					This website is hosted on Webflow's Content Delivery Network (CDN). This is a service
					provided by Webflow Inc,398 11th Street, 2nd Floor, San Francisco, California, 94103. The
					Webflow CDN makes duplicates of a website's data available on various Webflow servers
					located around the world. This provides faster website load times, increased resilience,
					protection against brute force attacks, and increased protection against data loss. A
					large part of the elements and the source code of this website are retrieved from the
					Webflow CDN when the page is called up. Through this retrieval, your IP address is
					transferred anonymously to Webflow's servers in other EU countries and stored there for 24
					hours. This anonymized storage for 24 hours serves as protection against brute force
					attacks. Tracking or other further processing of this data does not take place. The use of
					the Webflow CDN is in the interest of a higher reliability of the website, increased
					protection against data loss, protection against brute force attacks and a better loading
					speed of this website. This represents a legitimate interest within the meaning of Art. 6
					para. 1 lit. f DSGVO. Webflow Inc. with headquarters in the USA is certified for the
					us-European data protection agreement "Privacy Shield", which ensures compliance with the
					level of data protection applicable in the EU. The current privacy policy of Webflow can
					be found at:
				</Text>
				<Paragraph style={{ marginBottom: "30px" }}>
					<a href="https://webflow.com/legal/eu-privacy-policy">
						https://webflow.com/legal/eu-privacy-policy
					</a>
				</Paragraph>
			</Block>
			<Block>
				<Subtitle>Use of the Marketing & Support service provider "Hey Oliver"</Subtitle>
				<Text style={{ marginBottom: "0px" }}>
					Our website provides you with the opportunity to contact our Suppport via Hey Oliver Chat.
					Hey Oliver is provided by Hey Oliver Limited, 8, Michael Karaoli Street, 1095, Nicosia,
					Cyprus, registered in the Department of Registrar of Companies and Official Receiver
					maintained by the Republic of Cyprus Your use of Hey Oliver is deemed to be your consent
					to Hey Oliver's privacy policy, which is published at:
				</Text>
				<Paragraph style={{ marginBottom: "30px" }}>
					<a href="https://www.heyoliver.com/privacy-policy">
						https://www.heyoliver.com/privacy-policy.
					</a>
				</Paragraph>
				<Text>
					Please read it carefully before using the service. Any and all information and data will
					be processed by Hey oliver and stored on servers in the USA (among others). Hey Oliver
					Limited complies with the EU-U.S. Privacy Shield Framework regarding the collection, use,
					and retention of personal information transferred from the European Union to the United
					States.
				</Text>
				<Text>Hey Oliver collects the following data:</Text>
				<ListNoMargin>
					<li> duration of your visit</li>
					<li>pages visited</li>
					<li>your location (determined based on your IP address)</li>
					<li>referrer URL</li>
					<li>operating system</li>
					<li>browser version</li>
				</ListNoMargin>
				<Text>
					{" "}
					When you contact us via Hey Oliver, the following additional data may be collected in case
					you enter them:
				</Text>
				<ListNoMargin>
					<li>contact data</li>
					<li>last and first name</li>
					<li>e-mail address</li>
					<li>telephone number</li>
					<li>chat logs and the files exchanged between you and our support team</li>
					<li>
						For more information on how Hey Oliver processes these data, please click on the
						following link:
						<a href=" https://www.heyoliver.com/privacy-policy">
							https://www.heyoliver.com/privacy-policy
						</a>
					</li>
				</ListNoMargin>
				<Text>
					Vsble is not making use of these messages or data other than to follow up on users’
					registered issues or inquiries. Your personal data will be processed and transmitted in
					accordance with the General Data Protection Regulation (GDPR).
				</Text>
				<Text>
					Data processing for the purpose of your contacting us via chat is subject to Article 6,
					para. 1, clause 1, lit. a of the GDPR based on your voluntarily given consent, which shall
					be deemed given if you use the chat function. If you do not agree to this process, please
					do not use our chat function but contact us via e-mail or telephone.
				</Text>
			</Block>
			<Block>
				<Text>
					Data processing for the purpose of your contacting us via chat is subject to Article 6,
					para. 1, clause 1, lit. a of the GDPR based on your voluntarily given consent, which shall
					be deemed given if you use the chat function. If you do not agree to this process, please
					do not use our chat function but contact us via e-mail or telephone.
				</Text>
				<Text>
					We inform you regarding the processing of personal data on behalf of Exact Visitor Inc
					(“Exact Visitor”).
				</Text>
				<Text>
					Information collected from cookies set in your device that qualify as personal data will
					be processed by Exact Visitor, a company offering lead identification and ad targeting
					services with offices in London and Marbella. Please see below for full contact details.
				</Text>
				<Text>
					<b>The purpose</b> for the processing of the personal data is that it enables Exact
					Visitor to improve a service rendered to us and our website (e.g “Lead Generation”
					service), by adding data to their database about companies. The Exact Visitor database
					will in addition to “Lead Generation” be used for targeted advertising purposes towards
					companies and for this purpose data will be transferred to third party data service
					providers. For the purpose of clarity, targeted advertising regards companies, not towards
					individuals.
				</Text>
				<Text>
					<b> The data that is collected and used</b> by Exact Visitor to achieve this purpose is
					information about the IP-address from which you visited our website, and technical
					information that enables Exact Visitor to tell apart different visitors from the same
					IP-address. Exact Visitor stores the domain from form input in order to correlate the
					IP-address with your employer.
				</Text>
				<Text>
					<b>For the full information</b> about our processing of personal data, please see Exact
					Visitior's Privacy Policy.
				</Text>
				<ListDots>
					<li> Exact Visitor Inc</li>
					<li>548 Market Street</li>
					<li>94104, San Francisco, CA</li>
					<li>United States</li>
				</ListDots>
			</Block>
			<Block>
				<Subtitle>Paypal</Subtitle>
				<Text>
					The PayPal service is provided by PayPal (Europe) S.à r.l. et Cie, S.C.A., 22-24 Boulevard
					Royal, L-2449 Luxembourg. When paying by PayPal, you will be redirected to PayPal's
					website. For the use of this service, PayPal collects, stores and processes your personal
					data such as your name, address, telephone number and e-mail address as well as your
					credit card or bank account details. PayPal alone is responsible for the protection and
					handling of data collected by PayPal. In this respect, the PayPal Terms of Use apply,
					which you can access at www.PayPal.com. For more information about how to handle your
					information, please refer to the PayPal Privacy Policy available at the following link:
					<Paragraph>
						https://www.paypal.com/de/webapps/mpp/ua/privacy-f...
						<a href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full?locale.x=de_DE"></a>
					</Paragraph>
				</Text>
			</Block>
			<Block>
				<Subtitle>Credit Card Payment / Stripe</Subtitle>
				<Text>
					If you choose to use a payment method from the payment service provider Stripe, the
					payment will be processed via the payment service provider Stripe Payments Europe Ltd,
					Block 4, Harcourt Center, Harcourt Road, Dublin 2, Ireland, to which we will provide your
					information communicated during the ordering process and the information about Your order
					(name, address, account number, bank code, possibly credit card number, invoice amount,
					currency and transaction number) in accordance with Art. 6 para. 1 lit. b Pass on DSGVO.
					The transfer of your data is exclusively for the purpose of payment processing with the
					payment service provider Stripe Payments Europe Ltd. and only insofar as it is necessary
					for this. For more information about Stripe's privacy, please visit{" "}
					<a href="https://stripe.com/de/terms">https://stripe.com/de/terms</a> <br />
					In particular, Stripe has published the following information on the nature, scope and
					purpose of the data processing:{" "}
					<a href="https://stripe.com/en-ch/privacy"> Datenschutzerklärung</a> ,{" "}
					<a href="https://www.privacyshield.gov/participant?id=a2zt0000000TQOUAA4">
						Privacy Shield-List
					</a>
					entry.
				</Text>
			</Block>
			<Block>
				<Subtitle>Social Media Plugins (Facebook, Instagram)</Subtitle>
				<Text>
					On our website are plugins from Facebook Inc., 1601 S. California Ave, Palo Alto, CA
					94304, USA ("Facebook") and Instagram LLC. (Led by Facebook Inc., 1601 S. Califor-nia Ave,
					Palo Alto, CA 94304, USA). When you visit a page of our website that contains such a
					plugin, your browser connects directly to the Facebook server. The content of the plugin
					is transmitted from Facebook or Instagram directly to your browser and integrated into the
					page. Through this integration, Face-book receives the information that your browser has
					opened the corresponding page of our website, even if you do not have a Facebook /
					Instagram account or are currently not logged in to Facebook / Instagram. This information
					(including your IP address) is transmitted from your browser directly to a Facebook server
					in the USA and stored there. If you are logged in to Facebook, Facebook can immediately
					assign your visit to our website to your Facebook account. If you interact with the
					plugins (such as pressing the start button of a video), this information is also
					transmitted directly to a Facebook server and stored there. The purpose and scope of the
					data collection and the further processing and use of data by Facebook, as well as your
					rights in this regard and setting options for the protection of your privacy, please refer
					to the privacy policy:
				</Text>
				<Text>
					<a href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0">
						https://www.facebook.com/about/privacy/,
					</a>
				</Text>
				<Text>
					<a href="https://privacycenter.instagram.com/policy/?entry_point=ig_help_center_data_policy_redirect">
						https://instagram.com/about/legal/privacy/
					</a>
				</Text>
			</Block>
			<Block>
				<Subtitle>Status of this Privacy Policy</Subtitle>
				<Text>April 2021</Text>
				<Text>
					We reserve the right to change this Privacy Policy at any time with future effect.
				</Text>
			</Block>
		</Wrapper>
	);
};
const Wrapper = styled.div`
	padding-top: 88px;
	font-size: 40px;
	max-width: 752px;
	margin: 0 auto;
`;
const Title = styled.h2`
	font-weight: 700;
	font-size: 64px;
	line-height: 80px;
	margin-bottom: 40px;
	text-align: center;
`;
const Paragraph = styled.p`
	font-size: 16px;
	line-height: 24px;
	color: #282828;
	& > a {
		color: inherit;
		text-decoration: underline;
	}
`;
const Block = styled.div``;
const Text = styled.div`
	margin-bottom: 24px;
	font-size: 16px;
	line-height: 24px;
	color: #282828;
	& > a {
		color: inherit;
		text-decoration: underline;
	}
`;

const Subtitle = styled.div`
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 16px;
`;
const List = styled.ul`
	& > li {
		color: #282828;
		margin-bottom: 14px;
		font-size: 16px;
		line-height: 24px;
	}
	margin-bottom: 24px;
`;
const ListNoMargin = styled.ul`
	& > li {
		color: #282828;
		font-size: 16px;
		line-height: 24px;
		& > a {
			color: inherit;
			text-decoration: underline;
		}
	}
	margin-bottom: 24px;
`;
const ListDots = styled.ul`
	list-style: square;
	padding-left: 20px;
	& > li {
		color: #282828;
		font-size: 16px;
		line-height: 24px;
	}
	margin-bottom: 24px;
`;
