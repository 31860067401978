import AppRouter from "../routes/AppRouter";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { artbuyerProfile, photographerProfile } from "../store/reducers/profile/action";
import { authDataActions } from "../store/reducers/authData";
import { AuthRoles } from "../models/IProfile";
import { getPhotographerPhotos } from "../store/reducers/photographerGallery/action";
import { LoadingToast } from "./UI/LoadingToast";
import { toast } from "react-toastify";
import { TimeStamp } from "../utils/variables";
import { getCountriesList } from "../store/reducers/countries/action";

const App = () => {
	// const [isLoaded, setIsLoaded] = useState(false);
	const dispatch = useAppDispatch();
	const { user, isAuth } = useAppSelector((state) => state.authData);
	const { isImagesLoading } = useAppSelector((state) => state.gallery);

	// const requestInterval = useRef<any>(null);

	const text = useRef<any>();

	const isLocked = process.env.REACT_APP_LOCKED;

	useEffect(() => {
		const isLockedStorage = JSON.parse(sessionStorage.getItem("isLocked") || "false");

		console.log(isLockedStorage);

		if (isLockedStorage) {
			return;
		} else if (isLocked === "true") {
			while (text.current !== process.env.REACT_APP_PASSWORD) {
				text.current = prompt("Enter the password");
			}
			sessionStorage.setItem("isLocked", "true");
		}
	}, [isLocked]);

	useEffect(() => {
		const userFromLocalStorage = JSON.parse(localStorage.getItem("user") || "{}");

		if (!user) {
			dispatch(authDataActions.setUser(userFromLocalStorage));
		}

		if (localStorage.getItem("token")) {
			dispatch(authDataActions.setAuth(true));
		}
	}, [dispatch, user]);

	useEffect(() => {
		if (isAuth && user?.role === AuthRoles.Photographer) {
			dispatch(photographerProfile());
		} else if (isAuth && user?.role === AuthRoles.Artbuyer) {
			dispatch(artbuyerProfile());
		}
	}, [isAuth]);

	useEffect(() => {
		dispatch(getCountriesList(4));
	}, []);
	return (
		<>
			<LoadingToast showToast={isImagesLoading} />
			<AppRouter />
		</>
	);
};

export default App;
