import { useState } from "react";
import styled from "styled-components";
import { Button, RadioBtns } from "../UI";
import { useAppDispatch } from "../../hooks/redux";
import { postCart } from "../../store/reducers/cart/action";
import { ISuggestedImageInfo } from "../../models/IBrifing";
import { getLicence } from "../../utils/getSmth";

interface IEditModal {
	el: ISuggestedImageInfo;
	onClose: () => void;
	onCloseModal?: () => void;
}

export const ChooseImageToCart = ({ el, onClose, onCloseModal }: IEditModal) => {
	const [licence, setLicence] = useState("Online Licence");
	const dispatch = useAppDispatch();

	const currency = el.currency === "USD" ? "$" : "€";

	const onUpdateLicence = async () => {
		const data = JSON.stringify({
			photoId: el.photoId,
			licenceType: getLicence(licence),
		});
		await dispatch(postCart(data));

		onCloseModal?.();
		onClose();
	};

	return (
		<Wrapper>
			<Title>License type</Title>
			<Content>
				<img src={`${process.env.REACT_APP_DOMEN}${el?.imageDetails.LIGHTBOX.url}`} alt="img" />
				<RadioBtnsWrapper>
					<RadioBtns
						value={licence}
						setValue={setLicence}
						options={[
							{ value: "Online Licence", text: `${currency}${String(el.priceOnline)}.00` },
							{ value: "Editorial Licence", text: `${currency}${String(el.priceEditorial)}.00` },
							{ value: "Extended Licence", text: `${currency}${String(el.priceExtended)}.00` },
						]}
						name="ImageFile"
					/>
				</RadioBtnsWrapper>
			</Content>
			<Button onClick={onUpdateLicence} width="100%">
				Add to Cart
			</Button>
		</Wrapper>
	);
};
const Wrapper = styled.div`
	width: 500px;
`;
const Title = styled.div`
	margin-bottom: 24px;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	margin-top: -13px;
`;
const Content = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	& > img {
		margin-right: 24px;
		width: 144px;
	}
	margin-bottom: 24px;
`;
const RadioBtnsWrapper = styled.div`
	width: 100%;
`;
