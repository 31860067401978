import styled from "styled-components";

import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { Button } from "../../components/UI";

import { top1, top2 } from "../../static/images";
import { NavLink, Route, Routes } from "react-router-dom";
import { TrendsPosts } from "../../components/TrendsPosts";
import { useState } from "react";
import { Imprint } from "../../components/FooterPages/Imprint";
import { Privacy } from "../../components/FooterPages/Privacy";
import { Terms } from "../../components/FooterPages/Terms";
import { CookiePolicy } from "../../components/FooterPages/CookiePolicy";
import { NotFoundPage } from "../NotFound";

interface Props {
	searchValue: string;
	setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}

export const Home = ({ searchValue, setSearchValue }: Props) => {
	const [isSignUp, setIsSignUp] = useState(false);
	const [isLogInOpen, setIsLogInOpen] = useState(false);
	const [isForgorPassword, setIsForgotPassword] = useState(false);
	const [isSearchActive, setIsSearchActive] = useState(false);
	const onBrandSignUp = () => {};

	const onCreatorSignUp = () => {};

	const onStartBtnClick = () => {
		setIsSignUp(true);
	};

	return (
		<Wrapper>
			<Container>
				<Header
					searchValue={searchValue}
					setSearchValue={setSearchValue}
					isForgorPassword={isForgorPassword}
					setIsForgotPassword={setIsForgotPassword}
					isLogInOpen={isLogInOpen}
					setIsLogInOpen={setIsLogInOpen}
					isSignUp={isSignUp}
					setIsSignUp={setIsSignUp}
					setIsSearchActive={setIsSearchActive}
					isSearchActive={isSearchActive}
				/>

				<Content>
					<Routes>
						<Route
							path="/"
							element={
								<>
									<Top>
										<TopItem bg={top1}>
											<TopItemTitle>Find Selected Creators Worldwide</TopItemTitle>

											<NavLink to="buyer/signup">
												<Button customColor="#1fb40f" onClick={onBrandSignUp} theme="primary">
													Sign Up as a Brand
												</Button>
											</NavLink>
											{/* <SignUpCopy>Copyright © Photograph rights reserved</SignUpCopy> */}
											<SignUpCopy>© Owen Reynolds</SignUpCopy>
											
										</TopItem>

										<TopItem bg={top2}>
											<TopItemTitle>Showcase Your Work & Win Clients</TopItemTitle>

											<NavLink to="creator/signup">
												<Button customColor="#1fb40f" onClick={onCreatorSignUp} theme="primary">
													Sign Up as a Creator
												</Button>
											</NavLink>
											<SignUpCopy>© Richard Ramos</SignUpCopy>
											{/* <SignUpCopy>Copyright © Photograph rights reserved</SignUpCopy> */}
										</TopItem>
									</Top>

									<TrendsPosts
										setIsSearchActive={setIsSearchActive}
										page="Main"
										searchValue={searchValue}
										setIsLogInOpen={setIsLogInOpen}
									/>

									<Start>
										<StartTitle>Get started, It's Free</StartTitle>
										<ButtonWrapper>
											<Button  theme="primary" onClick={onStartBtnClick}>
												Sign Up For Free
											</Button>
										</ButtonWrapper>
									</Start>
								</>
							}
						/>
						<Route path="/imprint" element={<Imprint />} />
						<Route path="/privacy" element={<Privacy />} />
						<Route path="/terms" element={<Terms />} />
						<Route path="/cookiePolicy" element={<CookiePolicy />} />
						<Route path="*" element={<NotFoundPage />} />
					</Routes>
				</Content>

				<Footer />
			</Container>
		</Wrapper>
	);
};

export const Wrapper = styled.div`
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-family: var(--font-family);

	background-color: var(--color-white);
`;

export const Container = styled.div`
	max-width: 1412px;
	padding: 0 10px;
	margin: 0 auto;
`;
const Content = styled.div`
	min-height: 70vh;
`;

const Top = styled.div`
	display: flex;
	max-width: 1136px;
	justify-content: center;
	margin: 0 auto 80px;
	padding-top: 88px;
`;

const TopItem = styled.div<TopItemProps>`
	max-width: 568px;
	/* width: 100%; */
	height: 700px;
	position: relative;
	background-image: url(${(props) => props.bg});
	background-size: cover;
	background-position: center;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: end;
	padding-bottom: 96px;
`;
const TopItemTitle = styled.h3`
	font-weight: var(--font-bold);
	font-size: 40px;
	line-height: 48px;
	color: var(--color-white);
	max-width: 490px;
	margin: 0 auto 24px;
`;

interface TopItemProps {
	bg: string;
}

const Start = styled.div`
	margin-top: 20px;
`;
const StartTitle = styled.div`
	margin-bottom: 36px;
	font-weight: var(--font-bold);
	font-size: 64px;
	line-height: 80px;
	text-align: center;
`;
const ButtonWrapper = styled.div`
	text-align: center;
`;

const SignUpCopy = styled.div`
	max-width: 150px;
	font-size: 12px;
	line-height: 16px;
	color: #fff;
	position: absolute;
	bottom: 15px;
	text-align: left;
	left: 15px;
`;
