import React from "react";
import {
	SignInModal,
	SignInModalForm,
	SignInModalFormLine,
	SignInModalText,
	SignUpTitle,
} from "../components/Header";
import { useForm } from "react-hook-form";
import { Button, ErrorWrapper, Input } from "./UI";
import { useAppDispatch } from "../hooks/redux";
import { resetPassword } from "../store/reducers/authData/action";
import styled from "styled-components";

interface Props {
	setIsResetPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ResetPasswordForm = ({ setIsResetPassword }: Props) => {
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm();

	const dispatch = useAppDispatch();

	const onSendResetLink = (data: any) => {
		dispatch(resetPassword(data));
	};

	return (
		<SignInModal>
			<SignUpTitle>Reset password</SignUpTitle>
			<ModalText>
				Enter the e-mail associated with your account, and we’ll email you a link to reset your
				password
			</ModalText>
			<SignInModalForm>
				<Input
					{...register("email")}
					autoFocus={true}
					type={"email"}
					placeholder="E-mail"
					maxWidth="396px"
				/>
				<ErrorWrapper>
					<p style={errors?.email && { display: "block" }}>
						{errors?.email && `${errors?.email?.message}`}
					</p>
				</ErrorWrapper>
				<SignInModalFormLine />
				<Button onClick={handleSubmit(onSendResetLink)} width="100%">
					Reset
				</Button>
			</SignInModalForm>
			<SignInModalText style={{ margin: "24px 0 8px" }}>
				Remembered password? Sign in{" "}
				<a onClick={() => setIsResetPassword(false)} href="#">
					here.
				</a>
			</SignInModalText>
		</SignInModal>
	);
};
const ModalText = styled.div`
	color: #282828;
	margin-bottom: 24px;
	max-width: 396px;
	text-align: center;
	margin-top: -32px;
`;
