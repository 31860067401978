import {
	IPhotographerProfile,
	IPhotographerProfileUpdate,
	IUserProfile,
	buyerProfile,
} from "./../../../models/IProfile";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/axiosInstance";

export const userProfile = createAsyncThunk<IUserProfile, void, { rejectValue?: unknown }>(
	"user/profile",
	async (_, thunkAPI) => {
		try {
			return await api({
				method: "GET",
				url: "/photographer/user/profile",
			});
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const getUserAccount = createAsyncThunk<any, any, { rejectValue?: unknown }>(
	"user/getUserAccount",
	async (id, thunkAPI) => {
		try {
			return await api({
				method: "GET",
				url: `/photographer/user/${id}/profile`,
			});
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const getPhotographerAccount = createAsyncThunk<any, any, { rejectValue?: unknown }>(
	"user/getPhotographerAccount",
	async (id, thunkAPI) => {
		try {
			return await api({
				method: "GET",
				url: `/artbuyer/user/${id}/profile`,
			});
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);

export const photographerProfile = createAsyncThunk<
	IPhotographerProfile,
	void,
	{ rejectValue?: any }
>("user/myProfile", async (_, thunkAPI) => {
	try {
		const response = await api({
			method: "GET",
			url: "/photographer/user/myProfile",
		});
		return response.data;
	} catch (e: any) {
		console.log(e);
		return thunkAPI.rejectWithValue(e);
	}
});
export const artbuyerProfile = createAsyncThunk<buyerProfile, void, { rejectValue?: any }>(
	"user/artbuherProfile",
	async (_, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: "/artbuyer/user/myProfile",
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);

export const updateAvatar = createAsyncThunk<any, any, { rejectValue?: unknown }>(
	"user/updateAvatar",
	async (data, thunkAPI) => {
		try {
			return await fetch(`${process.env.REACT_APP_DOMEN}/api/v1/photographer/user/updateAvatar`, {
				method: "POST",
				body: data,
				headers: {
					"X-AUTH-TOKEN": `${localStorage.getItem("token")}`,
				},
			}).then((response) => response.json());
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const updateArbuyerAvatar = createAsyncThunk<any, any, { rejectValue?: unknown }>(
	"user/updateArtbuyerAvatar",
	async (data, thunkAPI) => {
		try {
			return await fetch(`${process.env.REACT_APP_DOMEN}/api/v1/artbuyer/user/updateAvatar`, {
				method: "POST",
				body: data,
				headers: {
					"X-AUTH-TOKEN": `${localStorage.getItem("token")}`,
				},
			}).then((response) => response.json());
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);

export const updatePhotographerProfile = createAsyncThunk<
	any,
	IPhotographerProfileUpdate,
	{ rejectValue?: unknown }
>("user/updatePhotographerProfile", async (data, thunkAPI) => {
	try {
		return await api({
			method: "POST",
			url: "/photographer/user/updateProfile",
			data: {
				...data,
			},
		});
	} catch (e: any) {
		console.log(e);
		return thunkAPI.rejectWithValue(e);
	}
});

export const updateArtbuyerProfile = createAsyncThunk<
	any,
	IPhotographerProfileUpdate,
	{ rejectValue?: unknown }
>("user/updateArtbuyerProfile", async (data, thunkAPI) => {
	try {
		return await api({
			method: "POST",
			url: "/artbuyer/user/updateProfile",
			data: {
				...data,
			},
		});
	} catch (e: any) {
		console.log(e);
		return thunkAPI.rejectWithValue(e);
	}
});
