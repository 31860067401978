import { createSlice } from "@reduxjs/toolkit";
import { deletePhotographer } from "./action";

interface ProfileState {
	isLoading: boolean
	error: undefined | string
}

const initialState: ProfileState = {
	isLoading: false,
	error: undefined
};

export const deletePhotographerSlice = createSlice({
	name: "user/deletePhotographer",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(deletePhotographer.fulfilled, (state, action) =>{
			state.isLoading = false
		})
		builder.addCase(deletePhotographer.pending, (state) =>{
			state.isLoading = true
		})
		builder.addCase(deletePhotographer.rejected, (state, action) =>{
			state.isLoading = false
			state.error = action.payload as string
		})
	},
});

export const { reducer: deletePhotographerReducer, actions: deletePhotographerActions } = deletePhotographerSlice;
