import { useEffect, useState } from "react";
import styled from "styled-components";
import { SubTitle } from ".";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { postUserNotifications } from "../../store/reducers/notifications/action";
import { Button, ToggleSwitch } from "../UI";

interface Props {
	onUpdateInfo: () => void;
}

export const NotificationSettings = ({ onUpdateInfo }: Props) => {
	const dispatch = useAppDispatch();
	const { notifications } = useAppSelector((state) => state.notification);

	const [isJobRequest, setIsJobRequest] = useState(notifications.briefings);
	const [isImageRequest, setIsImageRequest] = useState(notifications.showrooms);

	useEffect(() => {
		setIsJobRequest(notifications.briefings);
		setIsImageRequest(notifications.showrooms);
	}, [notifications]);

	const onSubmit = async () => {
		const { meta } = await dispatch(
			postUserNotifications({
				briefings: isJobRequest,
				showrooms: isImageRequest,
			})
		);
		if (meta.requestStatus === "fulfilled") {
			onUpdateInfo();
		}
	};

	return (
		<NotificationSettingsWrapper id="notifications">
			<SubTitle style={{ marginBottom: "24px" }}>Notification Settings</SubTitle>
			<SwitcherWrapper>
				<ToggleSwitch text="Job Requests" isToggled={isJobRequest} setIsToggled={setIsJobRequest} />
			</SwitcherWrapper>
			<SwitcherWrapper>
				<ToggleSwitch
					text="Image Requests"
					isToggled={isImageRequest}
					setIsToggled={setIsImageRequest}
				/>
			</SwitcherWrapper>
			<Button onClick={onSubmit}>Confirm</Button>
		</NotificationSettingsWrapper>
	);
};

const NotificationSettingsWrapper = styled.div`
	margin-bottom: 48px;
`;
const SwitcherWrapper = styled.div`
	margin-bottom: 24px;
`;
