import React, { useState } from "react";
import styled from "styled-components";
import { Header } from "../../components/Header";

import { ProfiileUpdated } from "../../components/UI";
import { Route, Routes, useLocation } from "react-router-dom";
import { TrendsPosts } from "../../components/TrendsPosts";
import { PublicInfo } from "../../components/PublicInfo";
import { ProfileSettings } from "../../components/ProfileSettings";
import { AboutCreator } from "../../components/AboutCreator";
import { ShowAllByAuthor } from "../../components/ShowAllByAuthor";
import { Favorites } from "../../components/Favorites";
import { Cart } from "../../components/Cart/Cart";
import { Checkout } from "../../components/Cart/Checkout";
import { Purchase } from "../../components/Cart/Purchase";
import { ImageRequests } from "../../components/ImageRequests";
import { SendPaymentRequest } from "../../components/ImageRequests/SendPaymentRequest";
import { useAppSelector } from "../../hooks/redux";
import { AuthRoles } from "../../models/IProfile";
import { ArtbuyerProfileSettings } from "../../components/ProfileSettings/artbuyerPage";
import { JobRequests } from "../../components/JobRequests";
import { UserAccount } from "../../components/UserAccount";
import { Imprint } from "../../components/FooterPages/Imprint";
import { Privacy } from "../../components/FooterPages/Privacy";
import { Terms } from "../../components/FooterPages/Terms";
import { CookiePolicy } from "../../components/FooterPages/CookiePolicy";

interface Props {
	searchValue?: string;
	setSearchValue?: React.Dispatch<React.SetStateAction<string>>;
}

export const CreatorProfile = ({ searchValue, setSearchValue }: Props) => {
	const [isPropfileUpdated, setIsProfileUpdated] = useState(false);
	const [isSearchActive, setIsSearchActive] = useState(false);
	const [isArchive, setIsArchive] = useState(false);

	const location = useLocation();

	const { user } = useAppSelector((state) => state.authData);

	const onUpdateInfo = () => {
		setIsProfileUpdated(true);
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		setTimeout(() => {
			setIsProfileUpdated(false);
		}, 5000);
	};

	return (
		<Wrapper
			darker={
				location.pathname.includes("/creator/profile/cart") ||
				location.pathname === "/creator/profile/imageRequests" ||
				location.pathname === "/creator/profile/jobRequests" ||
				location.pathname.includes("/creator/profile/sendPayment")
			}>
			<Container>
				<Header
					searchValue={searchValue || ""}
					//@ts-ignore
					setSearchValue={setSearchValue}
					setIsArchive={setIsArchive}
					isForgorPassword={false}
					setIsForgotPassword={() => console.log()}
					isLogInOpen={false}
					setIsLogInOpen={() => console.log()}
					isSignUp={false}
					setIsSignUp={() => console.log()}
					setIsSearchActive={setIsSearchActive}
					isSearchActive={isSearchActive}
				/>

				<Content>
					<ProfiileUpdated isUpdated={isPropfileUpdated} />
					<Routes>
						<Route
							path="/*"
							element={
								<TrendsPosts
									setIsSearchActive={setIsSearchActive}
									page={"Main"}
									isSearchActive={isSearchActive}
								/>
							}
						/>
						<Route path="/imageRequests" element={<ImageRequests />} />
						<Route path="/jobRequests" element={<JobRequests />} />
						<Route path="/sendPayment" element={<SendPaymentRequest />} />
						<Route path="/allByAuthor" element={<ShowAllByAuthor />} />
						<Route path="/userAccount" element={<UserAccount />} />
						<Route path="/imprint" element={<Imprint />} />
						<Route path="/privacy" element={<Privacy />} />
						<Route path="/terms" element={<Terms />} />
						<Route path="/cookiePolicy" element={<CookiePolicy />} />
						{user?.role === AuthRoles.Photographer ? (
							<>
								<Route
									path="/account"
									element={<AboutCreator isArchive={isArchive} setIsArchive={setIsArchive} />}
								/>
								<Route path="/settings" element={<ProfileSettings onUpdateInfo={onUpdateInfo} />} />
								<Route path="/public" element={<PublicInfo onUpdateInfo={onUpdateInfo} />} />
							</>
						) : (
							<>
								<Route path="/favorites" element={<Favorites />} />
								<Route
									path="/settings"
									element={<ArtbuyerProfileSettings onUpdateInfo={onUpdateInfo} />}
								/>
								<Route path="/cart" element={<Cart />} />
								<Route path="/cart/checkout" element={<Checkout />} />
								<Route path="/cart/purchase" element={<Purchase />} />
							</>
						)}
					</Routes>
				</Content>
			</Container>
		</Wrapper>
	);
};

const Content = styled.div`
	padding-top: 108px;
`;
export const Wrapper = styled.div<any>`
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-family: var(--font-family);
	min-height: 100vh;
	background-color: ${(props) => (props.darker ? "#F7F7F7" : `var(--color-white)`)};
`;

export const Container = styled.div`
	max-width: 1412px;
	padding: 0 10px;
	margin: 0 auto;
`;
