import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { INewGalleryItem } from "../models/IGallery";
import { AuthRoles } from "../models/IProfile";
import { addToSedcardImg, cover, isInSedcardSvg, isSelectedSvg } from "../static/images";
import { splitArrayToGalleryFormat } from "../utils";
import { FileInfo } from "./Modals/FileInfo";
import { Modal } from "./UI";
import {
	changeToSedcard,
	getMyPhotos,
	getPhotographerPhotos,
	removeFromSedcard,
	setPhotoCover,
} from "../store/reducers/photographerGallery/action";
import { Photo } from "../models/IPhoto";
import { toast } from "react-toastify";
import { TimeStamp } from "../utils/variables";

interface Props {
	sedCards: INewGalleryItem[];
	archive: INewGalleryItem[];
	showAllByAuthorSedcard?: Photo[];
	showAllByAuthorArchive?: Photo[];
	coverImage?: INewGalleryItem;
	selectedImages?: INewGalleryItem[];
	setSelectedImages?: React.Dispatch<React.SetStateAction<any[]>>;
	setImagesGallerySort?: React.Dispatch<React.SetStateAction<number>>;
	userAccount?: boolean;
	isArchive?: boolean;
	setIsArchive?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProfileGallery = ({
	sedCards,
	archive,
	selectedImages,
	setSelectedImages,
	showAllByAuthorSedcard,
	showAllByAuthorArchive,
	userAccount = false,
	isArchive,
	setImagesGallerySort,
	setIsArchive,
}: Props) => {
	const [imagesTypesSort, setImagesTypesSort] = useState(0);

	const [currentImage, setCurrentImage] = useState<number>(0);

	const [fileInfoOpen, setFileInfoOpen] = useState(false);
	const [currentGallery, setCurrentGallery] = useState<any[]>([]);

	const dispatch = useAppDispatch();

	const { user } = useAppSelector((state) => state.authData);

	let splitedSedCardsArray: any[] = [];
	let splitedArchiveArray: any[] = [];
	if (showAllByAuthorSedcard || showAllByAuthorArchive) {
		splitedSedCardsArray = splitArrayToGalleryFormat(showAllByAuthorSedcard);
		splitedArchiveArray = splitArrayToGalleryFormat(showAllByAuthorArchive);
	} else {
		splitedSedCardsArray = splitArrayToGalleryFormat(sedCards);
		splitedArchiveArray = splitArrayToGalleryFormat(archive);
	}

	useEffect(() => {
		setImagesGallerySort?.(imagesTypesSort);
	}, [imagesTypesSort]);

	useEffect(() => {
		if (isArchive) {
			setImagesTypesSort(1);
			setIsArchive?.(false);
		}
	}, [isArchive]);

	const imagesTypes = [
		{
			id: 0,
			title: " Sedcard",
			count: showAllByAuthorSedcard ? showAllByAuthorSedcard.length : sedCards.length,
		},
		{
			id: 1,
			title: " Archive",
			count: showAllByAuthorArchive ? showAllByAuthorArchive.length : archive.length,
		},
		{ id: 2, title: " Video", count: 0 },
	];

	const onGetPhotographerPhotos = async () => {
		const filters = `&page=1&pageSize=2000&timestamp=${TimeStamp}`;

		await dispatch(getMyPhotos(filters));
	};

	const onSedCardsImgClick = (el: INewGalleryItem | any) => {
		let index = 0;
		if (showAllByAuthorSedcard) {
			index = showAllByAuthorSedcard.indexOf(el);
			setCurrentGallery(showAllByAuthorSedcard);
		} else {
			setCurrentGallery(sedCards);
			index = sedCards.indexOf(el);
		}

		setCurrentImage(index);
		setFileInfoOpen(true);
	};

	const onArchiveImgClick = (el: INewGalleryItem | any) => {
		let index = 0;
		if (showAllByAuthorArchive) {
			index = showAllByAuthorArchive.indexOf(el);
			setCurrentGallery(showAllByAuthorArchive);
		} else {
			index = archive.indexOf(el);
			setCurrentGallery(archive);
		}

		setCurrentImage(index);
		setFileInfoOpen(true);
	};
	const onRemoveFromSedcard = async (el: INewGalleryItem) => {
		if (el.cover) {
			toast.error("You can not remove cover image from sedcards", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
		} else {
			const json = {
				photoIds: [el.photoId],
			};

			await dispatch(removeFromSedcard(JSON.stringify(json)));

			onGetPhotographerPhotos();

			setSelectedImages?.([]);
		}
	};
	const onAddToSedcard = async (el: INewGalleryItem) => {
		const json = {
			photoIds: [el.photoId],
		};

		await dispatch(changeToSedcard(JSON.stringify(json)));

		onGetPhotographerPhotos();

		setSelectedImages?.([]);
	};
	const onImageCoverClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();
	};
	const onMakeCover = async (el: INewGalleryItem) => {
		await dispatch(
			setPhotoCover(
				JSON.stringify({
					photoId: el.photoId,
				})
			)
		);
		onGetPhotographerPhotos();
	};
	const onSelectImage = (el: INewGalleryItem) => {
		const item = selectedImages?.find((i) => i.photoId === el.photoId);

		if (item) {
			setSelectedImages?.((prev) => [...prev.filter((el) => el.photoId !== item.photoId)]);
		} else {
			setSelectedImages?.((prev) => [...prev, el]);
		}
	};

	return (
		<ProfileGalleryWrapper>
			<ProfileGallerySort>
				<ProfileGallerySortList>
					{imagesTypes.map((el) => {
						if (el.id === 2) {
							return (
								<ProfileGallerySortItem
									active={el.id === imagesTypesSort}
									// onClick={() => setImagesTypesSort(el.id)}
									key={el.id}
									disabled>
									{el.title}
									<ProfileGalleryTypeCount>{el.count}</ProfileGalleryTypeCount>
								</ProfileGallerySortItem>
							);
						} else {
							return (
								<ProfileGallerySortItem
									active={el.id === imagesTypesSort}
									onClick={() => setImagesTypesSort(el.id)}
									key={el.id}>
									{el.title}
									<ProfileGalleryTypeCount>{el.count}</ProfileGalleryTypeCount>
								</ProfileGallerySortItem>
							);
						}
					})}
				</ProfileGallerySortList>
			</ProfileGallerySort>

			{(imagesTypesSort === 0 && (
				<>
					{user?.role === AuthRoles.Photographer && !userAccount && (
						<ProfileGalleryText>You can choose images for Sedcard from Archive</ProfileGalleryText>
					)}
					<ProfileGalleryImages>
						{splitedSedCardsArray.map((arr, index) => {
							return (
								<ProfileGalleryColumn key={index}>
									{arr.map((el: INewGalleryItem, i: number) => {
										const isCoverImage = el.cover;
										return (
											<ProfileGalleryItem key={el.photoId} onClick={() => onSedCardsImgClick(el)}>
												<ProfileGalleryBackgroung></ProfileGalleryBackgroung>
												{user?.role === AuthRoles.Photographer && !userAccount && (
													<>
														<ProfileGalleryItemBtn
															src={cover}
															alt="coverSvg"
															onClick={(e) => {
																e.stopPropagation();
																onRemoveFromSedcard(el);
															}}
														/>

														{isCoverImage ? (
															<ProfileGalleryCover
																style={{ right: "8px" }}
																onClick={(e) => onImageCoverClick(e)}>
																Cover Image
															</ProfileGalleryCover>
														) : (
															!el.incognito && (
																<MakeCover
																	onClick={(e) => {
																		e.stopPropagation();
																		onMakeCover(el);
																	}}>
																	Make Cover
																</MakeCover>
															)
														)}
													</>
												)}
												{el?.incognito && <IsIncognito>Private</IsIncognito>}

												<ProfileGalleryImg
													src={`${process.env.REACT_APP_DOMEN}${el.imageDetails.LIGHTBOX.url}`}
													alt="img"
												/>
											</ProfileGalleryItem>
										);
									})}
								</ProfileGalleryColumn>
							);
						})}
					</ProfileGalleryImages>
				</>
			)) ||
				(imagesTypesSort === 1 && (
					<>
						{user?.role === AuthRoles.Photographer && !userAccount && (
							<ProfileGalleryText>
								You can choose images for Sedcard from Archive
							</ProfileGalleryText>
						)}
						<ProfileGalleryImages style={{ paddingBottom: "58px" }}>
							{splitedArchiveArray.map((arr, index) => {
								return (
									<ProfileGalleryColumn key={index}>
										{arr.map((el: INewGalleryItem, i: number) => {
											const isSedCard = sedCards.find((item) => item.photoId === el.photoId);
											const isCoverImage = el.cover;

											return (
												<ProfileGalleryItem key={el.photoId} onClick={() => onArchiveImgClick(el)}>
													<ProfileGalleryBackgroung></ProfileGalleryBackgroung>

													{user?.role === AuthRoles.Photographer && !userAccount && (
														<>
															{isSedCard ? (
																<ProfileGalleryItemBtn
																	src={cover}
																	alt="coverSvg"
																	onClick={(e) => {
																		e.stopPropagation();
																		onRemoveFromSedcard(el);
																	}}
																/>
															) : (
																<ProfileGalleryItemBtnSedCard
																	src={addToSedcardImg}
																	alt="coverSvg"
																	onClick={(e) => {
																		e.stopPropagation();
																		onAddToSedcard(el);
																	}}
																/>
															)}

															{el?.incognito && <IsIncognito>Private</IsIncognito>}
															{isSedCard && <IsInSedcard>Sedcard</IsInSedcard>}
															{isCoverImage && (
																<ProfileGalleryCover
																	style={{ padding: "5px 12px" }}
																	onClick={(e) => onImageCoverClick(e)}>
																	Cover Image
																</ProfileGalleryCover>
															)}
															<SelectImage
																onClick={(e: any) => {
																	e.stopPropagation();
																	onSelectImage(el);
																}}
																isSelected={selectedImages?.includes(el)}></SelectImage>
														</>
													)}

													<ProfileGalleryImg
														src={`${process.env.REACT_APP_DOMEN}${el.imageDetails.LIGHTBOX.url}`}
														alt="img"
													/>
												</ProfileGalleryItem>
											);
										})}
									</ProfileGalleryColumn>
								);
							})}
						</ProfileGalleryImages>
					</>
				)) ||
				(imagesTypesSort === 2 && (
					<NftBlock>
						<NftTitle>Display your NFTs on your profile</NftTitle>
						<NftText>
							Connect your wallet with your VSBLE.match account, then add your NFTs to your profile.
						</NftText>
						<InstructionItem>
							<InstructionNumberWrapper>
								<InstructionNumber>1</InstructionNumber>
							</InstructionNumberWrapper>
							<InstructionText>
								<a href="#"> Add your wallet</a> to your VSBLE.match Account
							</InstructionText>
						</InstructionItem>
						<InstructionItem>
							<InstructionNumberWrapper>
								<InstructionNumber>2</InstructionNumber>
							</InstructionNumberWrapper>
							<InstructionText>Display your NFTs on your profile</InstructionText>
						</InstructionItem>
					</NftBlock>
				))}

			<Modal size="small" noPadding isOpen={fileInfoOpen} onClose={() => setFileInfoOpen(false)}>
				<FileInfo
					setCurrentImage={setCurrentImage}
					currentImage={currentImage}
					setFileInfoOpen={setFileInfoOpen}
					images={currentGallery}
					page={showAllByAuthorArchive || userAccount ? "Main" : "Account"}
				/>
			</Modal>
		</ProfileGalleryWrapper>
	);
};

const ProfileGalleryWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1136px;
	margin: 40px auto 0;
`;
const ProfileGallerySort = styled.div`
	display: flex;
	justify-content: center;
`;
const ProfileGallerySortList = styled.ul`
	display: flex;
`;
const ProfileGallerySortItem = styled.div<{ active?: boolean; disabled?: boolean }>`
	position: relative;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	color: #999999;
	padding-bottom: 12px;
	cursor: pointer;
	&:not(:last-child) {
		margin-right: 55px;
	}

	${(props) => {
		switch (props.active) {
			case true:
				return css`
					color: #000;
					&::after {
						content: "";
						position: absolute;
						left: 0;
						right: 0;
						bottom: 0;
						border: 2px solid #000;
						border-radius: 8px;
					}
				`;
			default:
				return "";
		}
	}}
	${(props) => {
		if (props.disabled) {
			return css`
				cursor: auto;
				color: #d6cfcf;
			`;
		}
	}}
`;

const ProfileGalleryTypeCount = styled.div`
	position: absolute;
	right: -25px;
	top: -5px;
	font-size: 12px;
	line-height: 16px;
	color: #808080;
`;
const ProfileGalleryText = styled.div`
	font-size: 16px;
	text-align: center;
	line-height: 24px;
	margin-top: 24px;
`;
const ProfileGalleryImages = styled.div`
	margin-top: 24px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 16px;
`;
const ProfileGalleryColumn = styled.div`
	width: 272px;
`;
const ProfileGalleryImg = styled.img`
	height: 100%;
	position: relative;
	z-index: 0;
	width: 272px;
`;
const ProfileGalleryItem = styled.div`
	margin-bottom: 12px;
	position: relative;
	cursor: pointer;
	&:hover {
		& > :nth-child(1) {
			opacity: 1;
		}
		& > :nth-child(2) {
			opacity: 1;
		}
		& > :nth-child(3) {
			opacity: 1;
		}
		& > :nth-child(4) {
			opacity: 1;
		}
	}
`;
const ProfileGalleryCover = styled.div`
	position: absolute;
	z-index: 2;
	top: 8px;
	right: 116px;
	padding: 6px 12px;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #fff;
	background: #8562ce;
	border-radius: 8px;
	opacity: 1;
	transition: 0.2s;
`;
const ProfileGalleryBackgroung = styled.div`
	position: absolute;
	top: 0;
	bottom: 5px;
	right: 0;
	z-index: 1;

	left: 0;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
	opacity: 0;
	transition: 0.2s;
`;
const ProfileGalleryItemBtn = styled.img`
	background: #8562ce;
	border-radius: 8px;
	padding: 5px;
	position: absolute;
	bottom: 13px;
	right: 8px;
	z-index: 2;

	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: 0.2s;
	&:hover {
		transition: 0.2s;
		background: #7557b5;
	}
`;
const ProfileGalleryItemBtnSedCard = styled.img`
	border-radius: 8px;
	padding: 4px;
	position: absolute;
	bottom: 13px;
	right: 8px;
	z-index: 2;

	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: 0.2s;
	background: #fff;
	border: 1px solid #000000;
	&:hover {
		transition: 0.2s;
		border: 1px solid #8562ce;
	}
`;
const NftBlock = styled.div`
	padding: 40px;
	text-align: center;
	background: #f7f7f7;
	border-radius: 8px;
	margin-top: 24px;
`;
const NftTitle = styled.h3`
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	margin-bottom: 14px;
`;
const NftText = styled.div`
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 32px;
`;
const InstructionItem = styled.div`
	margin-bottom: 32px;
	line-height: 24px;
`;
const InstructionNumber = styled.div`
	width: 40px;
	height: 40px;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #000;
	font-weight: 700;
	font-size: 18px;
	margin-bottom: 24px;
`;
const InstructionText = styled.div`
	font-size: 16px;
	font-weight: 600;
	& > a {
		color: #8562ce;
		border-bottom: 1px solid #8562ce;
	}
`;

const InstructionNumberWrapper = styled.div`
	display: flex;
	justify-content: center;
`;
const IsInSedcard = styled.div`
	padding: 5px 12px 5px 30px;
	cursor: default;
	position: absolute;
	font-weight: 600;
	color: #fff;
	background: #000;
	border-radius: 8px;
	max-width: 113px;
	z-index: 3;
	right: 9px;
	top: 8px;

	&::after {
		width: 24px;
		height: 24px;
		content: "";
		top: 3px;
		left: 5px;
		position: absolute;
		background-image: url(${isInSedcardSvg});
	}
`;
const IsIncognito = styled.div`
	padding: 5px 12px 5px 30px;
	cursor: default;
	position: absolute;
	font-weight: 600;
	color: #fff;
	background: #000;
	border-radius: 8px;
	max-width: 113px;
	z-index: 3;
	top: 8px;
	right: 10px;

	&::after {
		width: 24px;
		height: 24px;
		content: "";
		top: 3px;
		left: 5px;
		position: absolute;
		background-image: url(${isInSedcardSvg});
	}
`;
const MakeCover = styled.div`
	padding: 8px 12px;
	color: #000;
	background: #fff;
	border: 1px solid #000000;
	border-radius: 8px;
	position: absolute;
	bottom: 14px;
	left: 10px;
	z-index: 2;
	opacity: 0;
	transition: 0.2s;
	font-size: 12px;
	line-height: 16px;
	&:hover {
		border: 1px solid #8562ce;
		color: #8562ce;
		transition: 0.2s;
	}
`;
const SelectImage = styled.div<any>`
	top: 8px;
	left: 8px;
	position: absolute;
	border-radius: 8px;
	width: 24px;
	height: 24px;
	background: #fff;
	transition: 0.2s;
	z-index: 2;
	${({ isSelected }) => {
		if (isSelected) {
			return css`
				background: none;
				transition: 0.2s;
				background-image: url(${isSelectedSvg});
			`;
		}
	}}
`;
