import React, { ReactNode } from "react";
import Masonry from "react-responsive-masonry";

interface Props {
	children: ReactNode;
}

export const MasonryGallery = ({ children }: Props) => {
	return <Masonry columnsCount={4}>{children}</Masonry>;
};
