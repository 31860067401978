import { IPhotographerSkills } from "../../../models/IProfile";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/axiosInstance";

export const getBriefingsMessages = createAsyncThunk<any, any, { rejectValue?: unknown }>(
	"user/getBriefingsMessages",
	async (parameters, thunkAPI) => {
		try {
			const { data } = await api({
				method: "GET",
				url: `/briefings/messages?briefingId=${parameters.briefingId}&photographerId=${parameters.photographerId}&page=1&pageSize=300`,
			});
			return data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);

export const postBriefingsMessages = createAsyncThunk<any, any, { rejectValue?: unknown }>(
	"user/postBriefingsMessages",
	async (json, thunkAPI) => {
		try {
			return await api({
				method: "POST",
				url: `/briefings/messages`,
				data: json,
			});
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
