import styled from "styled-components";
import { SignUpAbout } from "../../components/SignUpAbout";
import { SignupForm } from "../../components/SignUpForm";

import { top1 } from "../../static/images";

export const BuyerSignUp = () => {
	return (
		<Wrapper>
			<SignUpAbout blackColor title="Discover selected professionals worldwide" img={top1} />
			<SignupForm isBuyerSignUp={true} />
		</Wrapper>
	);
};
const Wrapper = styled.div`
	font-family: var(--font-family);

	height: 100vh;
	width: 100vw;
	display: flex;
`;
