import { useEffect, useState } from "react";
import { useController, useForm } from "react-hook-form";
import styled from "styled-components";
import { SubTitle } from ".";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
	updateArtbuyerProfile,
	updatePhotographerProfile,
} from "../../store/reducers/profile/action";
import { countries } from "../../utils/countries";
import { InputWrapper } from "../PublicInfo";
import { Button, CustomSelect, ErrorWrapper, Input } from "../UI";
import { AuthRoles } from "../../models/IProfile";

interface Props {
	onUpdateInfo: () => void;
}

export const MarketPlaceForm = ({ onUpdateInfo }: Props) => {
	const photograpgerMarketPlace = useAppSelector((state) => state.profile.photographerMarketPlace);
	const { artbuyerProfile } = useAppSelector((state) => state.profile);
	const { selectCountries } = useAppSelector((state) => state.countries);

	const dispatch = useAppDispatch();

	const { user } = useAppSelector((state) => state.authData);

	const {
		register,
		control,
		formState: { errors },
		reset,
		handleSubmit,
	} = useForm();

	const { field } = useController({
		name: "countryId",
		control,
		rules: { required: true },
	});

	useEffect(() => {
		user?.role === AuthRoles.Photographer
			? reset({ ...photograpgerMarketPlace })
			: reset({ ...artbuyerProfile });
	}, [photograpgerMarketPlace, artbuyerProfile]);

	const onSubmit = async (data: any) => {
		const { meta } = await dispatch(
			user?.role === AuthRoles.Photographer
				? updatePhotographerProfile(data)
				: updateArtbuyerProfile(data)
		);

		if (meta.requestStatus === "fulfilled") {
			onUpdateInfo();
		}
	};

	const err = "The filed is required";

	return (
		<MarketPlace id="marketPlace">
			<SubTitle>Marketplace</SubTitle>
			<p>
				Selling with VSBLE.space pricing model. If you offer any image with VSBLE.space Pricing
				model for immediate purchase you must provide full details of your invoice address. This is
				necessary so we can issue proper sales statemetns for your accounting.
			</p>
			<p>
				All other image offered on request can not be purchased online. The buyer will contact you
				for pricing.
			</p>
			<p>To sell images online the below information must be filled out.</p>
			<InputWrapper style={{ display: "flex", justifyContent: "space-between" }}>
				<div style={{ width: "100%" }}>
					<Input
						error={!!errors?.billingFirstName}
						maxWidth="272px"
						{...register("billingFirstName", {
							required: err,
						})}
						labelText="First Name"
					/>
					<ErrorWrapper>
						<p style={errors?.billingFirstName && { display: "block" }}>
							{errors?.billingFirstName && `${errors?.billingFirstName?.message}`}
						</p>
					</ErrorWrapper>
				</div>
				<div style={{ paddingLeft: "10px", width: "100%" }}>
					{" "}
					<Input
						error={!!errors?.billingLastName}
						maxWidth="272px"
						{...register("billingLastName", {
							required: err,
						})}
						labelText="Surname"
					/>
					<ErrorWrapper>
						<p style={errors?.billingLastName && { display: "block" }}>
							{errors?.billingLastName && `${errors?.billingLastName?.message}`}
						</p>
					</ErrorWrapper>
				</div>
			</InputWrapper>
			<h5>Billing Address</h5>
			<InputWrapper>
				<CustomSelect
					error={!!errors?.countryId}
					ref={field.ref}
					placeholder="Select Country"
					value={field.value}
					onChange={field.onChange}
					labelText="Country"
					options={selectCountries}
				/>

				<ErrorWrapper>
					<p style={errors?.countryId && { display: "block" }}>
						{errors?.countryId && `${errors?.countryId?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper>
				<Input
					error={!!errors?.billingAddress}
					{...register("billingAddress", {
						required: err,
					})}
					labelText="Street Line 1"
				/>
				<ErrorWrapper>
					<p style={errors?.billingAddress && { display: "block" }}>
						{errors?.billingAddress && `${errors?.billingAddress?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper>
				<Input
					error={!!errors?.billingAddress2}
					{...register("billingAddress2")}
					labelText="Street Line 2"
				/>
				<ErrorWrapper>
					<p style={errors?.billingAddress2 && { display: "block" }}>
						{errors?.billingAddress2 && `${errors?.billingAddress2?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper style={{ display: "flex", justifyContent: "space-between" }}>
				<div style={{ width: "100%" }}>
					<Input
						error={!!errors?.billingZip}
						maxWidth="272px"
						{...register("billingZip", {
							required: err,
						})}
						labelText="Zip"
					/>
					<ErrorWrapper>
						<p style={errors?.billingZip && { display: "block" }}>
							{errors?.billingZip && `${errors?.billingZip?.message}`}
						</p>
					</ErrorWrapper>
				</div>
				<div style={{ paddingLeft: "10px", width: "100%" }}>
					{" "}
					<Input
						error={!!errors?.billingCity}
						maxWidth="272px"
						{...register("billingCity", {
							required: err,
						})}
						labelText="City"
					/>
					<ErrorWrapper>
						<p style={errors?.billingCity && { display: "block" }}>
							{errors?.billingCity && `${errors?.billingCity?.message}`}
						</p>
					</ErrorWrapper>
				</div>
			</InputWrapper>
			<InputWrapper>
				<Input
					error={!!errors?.billingCompany}
					{...register("billingCompany", {
						required: err,
					})}
					labelText="Company"
				/>
				<ErrorWrapper>
					<p style={errors?.billingCompany && { display: "block" }}>
						{errors?.billingCompany && `${errors?.billingCompany?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<Button onClick={handleSubmit(onSubmit)}>Udpate</Button>
		</MarketPlace>
	);
};

const MarketPlace = styled.div`
	margin-bottom: 48px;
	& > p {
		font-size: 14px;
		line-height: 20px;
		color: #282828;
		margin-bottom: 18px;
	}
	& > h5 {
		margin-bottom: 16px;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
	}
`;
