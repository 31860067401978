import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/axiosInstance";

export const getCart = createAsyncThunk<any, string, { rejectValue?: any }>(
	"artBuyer/getCart",
	async (tab, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `/artbuyer/basket/items?tab=${tab}`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const postCart = createAsyncThunk<any, any, { rejectValue?: any }>(
	"artbuyer/postCart",
	async (data, thunkAPI) => {
		try {
			const response = await api({
				method: "POST",
				url: `/artbuyer/basket/add`,
				data: data,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const cartProcess = createAsyncThunk<any, any, { rejectValue?: any }>(
	"artbuyer/cartProcess",
	async (data, thunkAPI) => {
		try {
			const response = await api({
				method: "POST",
				url: `/artbuyer/basket/process`,
				data: data,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const changeCartItem = createAsyncThunk<any, any, { rejectValue?: any }>(
	"artbuyer/changeCartItem",
	async (data, thunkAPI) => {
		try {
			const response = await api({
				method: "PUT",
				url: `/artbuyer/basket/${data.id}`,
				data: data.json,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const deleteCartItem = createAsyncThunk<any, any, { rejectValue?: any }>(
	"artbuyer/eleteCartItem",
	async (id, thunkAPI) => {
		try {
			const response = await api({
				method: "DELETE",
				url: `/artbuyer/basket/remove/${id}`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
