import axios, { AxiosError } from "axios";
// import { toast } from "react-toastify";

import { store } from "../store";
import { authDataActions } from "../store/reducers/authData";

export const api = axios.create({
	baseURL: `${process.env.REACT_APP_DOMEN}/api/v1`,
	headers: { "Content-Type": "application/json" },
});

api.interceptors.request.use((config) => {
	config.headers["X-AUTH-TOKEN"] = `${localStorage.getItem("token")}`;

	return config;
});

api.interceptors.response.use(
	(response) => response,
	(error: AxiosError) => {
		const isAuth = store?.getState()?.authData?.isAuth;

		if (error?.response?.status === 401 && isAuth) {
			store?.dispatch(authDataActions?.setLogout());
		}

		errorHandler(error);

		throw error;
	}
);

const errorHandler = (error: AxiosError) => {
	// if (error.response?.status === 500) {
	// 	toast.error(`${error.response?.data || "Something wrong. Please try again later."}`, {
	// 		position: "top-right",
	// 		autoClose: 5000,
	// 		hideProgressBar: false,
	// 		closeOnClick: true,
	// 		pauseOnHover: true,
	// 		draggable: true,
	// 		progress: undefined,
	// 		theme: "light",
	// 	});
	// } else {
	// 	toast.error("Something wrong. Please try again later.", {
	// 		position: "top-right",
	// 		autoClose: 5000,
	// 		hideProgressBar: false,
	// 		closeOnClick: true,
	// 		pauseOnHover: true,
	// 		draggable: true,
	// 		progress: undefined,
	// 		theme: "light",
	// 	});
	// }

	return Promise.reject({ ...error });
};
