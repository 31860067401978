export function getTimeAgo(milliseconds: number) {
	const now = new Date().getTime();
	const difference = now - milliseconds;

	const minutes = Math.floor(difference / (1000 * 60));
	const hours = Math.floor(difference / (1000 * 60 * 60));
	const days = Math.floor(difference / (1000 * 60 * 60 * 24));

	if (minutes < 1) {
		return "now";
	} else if (minutes < 60) {
		return minutes + (minutes === 1 ? " minute ago" : " minutes ago");
	} else if (hours < 24) {
		return hours + (hours === 1 ? " hour ago" : " hours ago");
	} else {
		return days + (days === 1 ? " day ago" : " days ago");
	}
}
