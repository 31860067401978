import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../hooks/redux";
import { ILogin } from "../../models/AuthData";
import { AuthRoles } from "../../models/IProfile";
import { RootState } from "../../store";
import {
	SignInModal,
	SignInModalForm,
	SignInModalFormLine,
	SignInModalText,
	SignUpTitle,
} from "../Header";
import { Button, ErrorWrapper, Input, PasswordInput } from "../UI";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { artbuyerProfile, photographerProfile } from "../../store/reducers/profile/action";
import { login } from "../../store/reducers/authData";

interface Props {
	forgotPassword?: React.Dispatch<React.SetStateAction<boolean>>;
	setIsLogIn?: React.Dispatch<React.SetStateAction<boolean>>;
	setIsSignUp?: React.Dispatch<React.SetStateAction<boolean>>;
	loginFromPage?: boolean;
	setIsResetPassword?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SignIn = ({
	forgotPassword,
	setIsLogIn,
	setIsSignUp,
	loginFromPage = false,
	setIsResetPassword,
}: Props) => {
	const err = "The filed is required";

	const validationSchema = Yup.object().shape({
		login: Yup.string().email().required(err),
		password: Yup.string().required(err),
	});

	const formOptions = { resolver: yupResolver(validationSchema) };
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm<ILogin>(formOptions);

	const dispatch = useAppDispatch();

	const { loginIsLoading } = useSelector((state: RootState) => state.authData);

	const navigate = useNavigate();

	const onSubmit = async (data: ILogin) => {
		const response = await dispatch(login(data));
		document.body.style.overflow = "auto";

		if (response.meta.requestStatus === "fulfilled") {
			navigate(
				// @ts-ignore
				response.payload.role === AuthRoles.Photographer ? "/creator/profile" : "/"
			);
			// toast.success("Succeeded login!", {
			// 	position: "top-right",
			// 	autoClose: 5000,
			// 	hideProgressBar: false,
			// 	closeOnClick: true,
			// 	pauseOnHover: true,
			// 	draggable: true,
			// 	progress: undefined,
			// 	theme: "light",
			// });
			setIsLogIn?.(false);
			//@ts-ignore
			if (response.payload.role === AuthRoles.Artbuyer) {
				dispatch(artbuyerProfile());
			} else {
				dispatch(photographerProfile());
			}
		}
	};

	const onPasswordForgot = () => {
		if (loginFromPage) {
			setIsResetPassword?.(true);
		} else {
			setIsLogIn?.(false);
			forgotPassword?.(true);
		}
	};

	const onSignUp = () => {
		setIsSignUp?.(true);
		setIsLogIn?.(false);
		forgotPassword?.(false);
	};

	return (
		<SignInModal>
			<SignUpTitle style={loginFromPage ? { fontSize: "40px", lineHeight: "48px" } : {}}>
				{loginFromPage ? "Login" : "Log in to your account"}
			</SignUpTitle>
			<SignInModalForm>
				<Input
					error={!!errors?.login}
					{...register("login", {
						required: err,
					})}
					autoFocus={true}
					type={"email"}
					placeholder="E-mail"
					maxWidth="396px"
				/>
				<ErrorWrapper>
					<p style={errors?.login && { display: "block" }}>
						{errors?.login && `${errors?.login?.message}`}
					</p>
				</ErrorWrapper>
				<SignInModalFormLine />
				<PasswordInput
					error={!!errors?.password}
					{...register("password", {
						required: err,
					})}
					placeholder="Password"
					maxWidth="396px"
				/>
				<ErrorWrapper>
					<p style={errors?.password && { display: "block" }}>
						{errors?.password && `${errors?.password?.message}`}
					</p>
				</ErrorWrapper>
				<SignInModalFormLine />
				<Button isLoading={loginIsLoading} onClick={handleSubmit(onSubmit)} width="100%">
					Log In
				</Button>
			</SignInModalForm>
			<SignInModalText style={{ margin: "24px 0 8px" }}>
				Forgot your password? Reset it{" "}
				<a onClick={onPasswordForgot} href="#">
					here.
				</a>
			</SignInModalText>
			{loginFromPage || (
				<SignInModalText>
					Don’t have an account yet? Sign up{" "}
					<a onClick={onSignUp} href="#">
						here.
					</a>
				</SignInModalText>
			)}
		</SignInModal>
	);
};
