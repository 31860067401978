import React from "react";
import ContentLoader from "react-content-loader";

const PostSkeleton = (props: any) => (
	<ContentLoader
		style={{ marginRight: "16px" }}
		speed={2}
		width={270}
		height={400}
		viewBox="0 0 270 400"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		{...props}>
		<rect x="176" y="222" rx="0" ry="0" width="26" height="0" />
		<rect x="0" y="0" rx="39" ry="39" width="270" height="300" />
		<rect x="0" y="360" rx="10" ry="10" width="270" height="30" />
		<rect x="0" y="315" rx="10" ry="10" width="270" height="30" />
	</ContentLoader>
);

export default PostSkeleton;
