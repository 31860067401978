import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { login, logout, signUp, userProfile } from "./action";

import { IUser } from "../../../models/AuthData";
import { AuthRoles } from "../../../models/IProfile";

export interface AuthData {
	isAuth?: boolean;
	user?: IUser;
	isLoading?: boolean;
	signUpError?: string;
	signUpIsLoading?: boolean;
	loginError?: string;
	loginIsLoading?: boolean;
}

const initialState: AuthData = {
	isAuth: false,
	user: undefined,
	isLoading: false,
	signUpError: undefined,
	signUpIsLoading: false,
	loginError: undefined,
	loginIsLoading: false,
};

const authData = createSlice({
	name: "auth/authData",
	initialState,
	reducers: {
		setAuth: (state, action: PayloadAction<boolean>) => {
			state.isAuth = action.payload;
		},
		setLogout: (state) => {
			localStorage.removeItem("token");
			localStorage.removeItem("user");
			state.isAuth = false;
			state.user = {} as IUser;
		},
		setUser: (state, action) => {
			state.user = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(signUp.fulfilled, (state, action) => {
				if (action.payload.role === AuthRoles.Photographer) {
					state.signUpIsLoading = false;
					state.signUpError = undefined;
					// localStorage.setItem("token", action.payload.token);
					// state.isAuth = true;
					// state.isLoading = false;
					// const { userId, role, token } = action.payload;
					// localStorage.setItem("user", JSON.stringify({ userId, role, token }));
					// state.user = { userId, role, token };
				}
				if (action.payload.role === AuthRoles.Artbuyer) {
					state.isLoading = false;
				}
			})
			.addCase(signUp.pending, (state) => {
				state.signUpIsLoading = true;
				state.signUpError = undefined;
				state.isLoading = true;
			})
			.addCase(signUp.rejected, (state, action) => {
				state.signUpIsLoading = false;
				state.signUpError = action.payload as string;
				state.isLoading = false;
			})
			.addCase(login.fulfilled, (state, action) => {
				state.loginIsLoading = false;
				state.loginError = undefined;
				localStorage.setItem("token", action.payload.token);
				state.isAuth = true;
				const { userId, role, token } = action.payload;
				console.log(action.payload);
				localStorage.setItem("user", JSON.stringify({ userId, role, token }));
				state.user = { userId, role, token };
			})
			.addCase(login.pending, (state) => {
				state.loginIsLoading = true;
				state.loginError = undefined;
			})
			.addCase(login.rejected, (state, action) => {
				state.loginIsLoading = false;
				state.loginError = action.payload as string;
			})
			.addCase(logout.fulfilled, (state, action) => {
				localStorage.removeItem("token");
				localStorage.removeItem("user");
				state.isAuth = false;
				state.user = {} as IUser;
			})
			.addCase(logout.rejected, (state, action) => {
				localStorage.removeItem("token");
				localStorage.removeItem("user");
				localStorage.removeItem("userEmail");
				state.isAuth = false;
				state.user = {} as IUser;
			});
	},
});

export const { reducer: authDataReducer, actions: authDataActions } = authData;
