import {
	Control,
	FieldErrors,
	FieldValues,
	UseFormRegister,
	UseFormReset,
	useController,
} from "react-hook-form";
import styled from "styled-components";
import { countries } from "../../utils/countries";
import { InputWrapper } from "../PublicInfo";
import { CustomSelect, ErrorWrapper, Input } from "../UI";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks/redux";

interface Props {
	errors: FieldErrors<FieldValues>;
	control: Control<FieldValues, any>;
	register: UseFormRegister<FieldValues>;
	reset: UseFormReset<FieldValues>;
	setUserCountry: React.Dispatch<React.SetStateAction<number>>;
	userCountry: number;
}

// const stripe = require("stripe")("sk_test_WynOTJJDCcjyNShspfp33Xz0");

export const CheckoutBillingAdress = ({
	userCountry,
	setUserCountry,
	errors,
	reset,
	control,
	register,
}: Props) => {
	// const [userCountry, setUserCountry] = useState(0);
	const { artbuyerProfile } = useAppSelector((state) => state.profile);

	const { selectCountries } = useAppSelector((state) => state.countries);

	// const { field } = useController({
	// 	name: "countryId",
	// 	control,
	// 	rules: { required: true },
	// });

	useEffect(() => {
		reset({ ...artbuyerProfile });
		setUserCountry(artbuyerProfile.countryId);
	}, [artbuyerProfile]);

	// useEffect(() => {}, [userCountry]);

	// const calculateVat = async () => {
	// 	const calculation = await stripe.tax.calculations.create({
	// 		currency: "usd",
	// 		line_items: [
	// 			{
	// 				amount: 1000,
	// 				reference: "L1",
	// 			},
	// 		],
	// 		customer_details: {
	// 			address: {
	// 				line1: "920 5th Ave",
	// 				city: "London",
	// 				postal_code: "98104",
	// 				country: "US",
	// 			},
	// 			address_source: "shipping",
	// 		},
	// 		expand: ["line_items.data.tax_breakdown"],
	// 	});

	// };

	// useEffect(() => {
	// calculateVat();
	// }, []);

	const err = "The field is required";

	return (
		<MarketPlace id="marketPlace">
			<h2>Your billing address</h2>
			<p>Following details will appear on your invoice</p>
			<h5>Invoice Address</h5>
			<InputWrapper style={{ display: "flex", justifyContent: "space-between" }}>
				<div style={{ width: "100%" }}>
					<Input
						error={!!errors?.billingFirstName}
						maxWidth="336px"
						{...register("billingFirstName", {
							required: err,
						})}
						labelText="First Name"
					/>
					<ErrorWrapper>
						<p style={errors?.billingFirstName && { display: "block" }}>
							{errors?.billingFirstName && `${errors?.billingFirstName?.message}`}
						</p>
					</ErrorWrapper>
				</div>
				<div style={{ paddingLeft: "10px", width: "100%" }}>
					{" "}
					<Input
						error={!!errors?.billingLastName}
						maxWidth="336px"
						{...register("billingLastName", {
							required: err,
						})}
						labelText="Surname"
					/>
					<ErrorWrapper>
						<p style={errors?.billingLastName && { display: "block" }}>
							{errors?.billingLastName && `${errors?.billingLastName?.message}`}
						</p>
					</ErrorWrapper>
				</div>
			</InputWrapper>
			<InputWrapper>
				<Input
					error={!!errors?.billingCompany}
					{...register("billingCompany", {
						required: err,
					})}
					labelText="Company"
				/>
				<ErrorWrapper>
					<p style={errors?.billingCompany && { display: "block" }}>
						{errors?.billingCompany && `${errors?.billingCompany?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper>
				<CustomSelect
					error={!!errors?.countryId}
					// ref={field.ref}
					placeholder="Select Country"
					value={userCountry}
					onChange={setUserCountry}
					labelText="Country"
					options={selectCountries}
				/>
				{/* <CustomSelect
					error={!!errors?.countryId}
					ref={field.ref}
					placeholder="Select Country"
					value={field.value}
					onChange={field.onChange}
					labelText="Country"
					options={selectCountries}
				/> */}

				<ErrorWrapper>
					<p style={errors?.countryId && { display: "block" }}>
						{errors?.countryId && `${errors?.countryId?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper>
				<Input
					error={!!errors?.billingAddress}
					{...register("billingAddress", {
						required: err,
					})}
					labelText="Street Line 1"
				/>
				<ErrorWrapper>
					<p style={errors?.billingAddress && { display: "block" }}>
						{errors?.billingAddress && `${errors?.billingAddress?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper>
				<Input
					error={!!errors?.billingAddress2}
					{...register("billingAddress2")}
					labelText="Street Line 2"
				/>
				<ErrorWrapper>
					<p style={errors?.billingAddress2 && { display: "block" }}>
						{errors?.billingAddress2 && `${errors?.billingAddress2?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper>
				<Input
					error={!!errors?.billingZip}
					{...register("billingZip", {
						required: err,
					})}
					labelText="Zip"
				/>
				<ErrorWrapper>
					<p style={errors?.billingZip && { display: "block" }}>
						{errors?.billingZip && `${errors?.billingZip?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper>
				<Input
					error={!!errors?.billingCity}
					{...register("billingCity", {
						required: err,
					})}
					labelText="City"
				/>
				<ErrorWrapper>
					<p style={errors?.billingCity && { display: "block" }}>
						{errors?.billingCity && `${errors?.billingCity?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
		</MarketPlace>
	);
};

const MarketPlace = styled.div`
	& > p {
		font-size: 14px;
		line-height: 20px;
		color: #282828;
		margin-bottom: 24px;
	}
	& > h2 {
		font-weight: 700;
		font-size: 24px;
		line-height: 32px;
		margin-bottom: 8px;
	}
	& > h5 {
		margin-bottom: 16px;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
	}
`;
