import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Briefing } from "../../models/IBrifing";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { AuthRoles } from "../../models/IProfile";
import { getBriefingsMessages, postBriefingsMessages } from "../../store/reducers/Messages/action";
import { getTimeAgo } from "../../utils/getTime";
import { MessageInput } from "../UI/MessageInput";
import { getphotographerBriefing } from "../../store/reducers/photographerJobRequests/action";
import { getArtbuyerBriefing } from "../../store/reducers/artbuyerJobRequests/action";
import { Button } from "../UI";

interface Props {
	element: Briefing | undefined;
	chatFocus?: boolean;
}

export const Chat = ({ element, chatFocus }: Props) => {
	const [value, setValue] = useState("");
	const [isMessageSended, setIsMessageSended] = useState(false);
	const [userName, setUserName] = useState("");
	const { user } = useAppSelector((state) => state.authData);

	const { messages } = useAppSelector((state) => state.briefingMessages);

	const chatRef = React.createRef<any>();
	const chatInput = React.createRef<any>();

	useEffect(() => {
		if (messages.length > 0) {
			chatRef.current.scrollTo({
				top: 10000,
				left: 0,
				behavior: "smooth",
			});
		}
	}, [isMessageSended]);

	useEffect(() => {
		if (chatInput) {
			chatInput.current.focus();
		}
	}, [chatFocus]);

	useEffect(() => {
		const getBrief = async () => {
			if (user?.role === AuthRoles.Artbuyer) {
				const { payload } = await dispatch(getArtbuyerBriefing(element?.id || 0));
				setUserName(payload.photographerName);
			} else {
				const { payload } = await dispatch(getphotographerBriefing(element?.id || 0));
				setUserName(payload.buyerName);
			}
		};
		getBrief();
	}, []);

	const dispatch = useAppDispatch();

	const onSendMessage = async () => {
		if (value.length > 0) {
			const json = {
				briefingId: element?.id,
				byBuyer: user?.role === AuthRoles.Artbuyer,
				photographerId: element?.photographerId,
				message: value,
				createDate: new Date().getTime(),
			};
			await dispatch(postBriefingsMessages(json));
			setValue("");
		}
		await dispatch(
			getBriefingsMessages({ briefingId: element?.id, photographerId: element?.photographerId })
		);
		setIsMessageSended(!isMessageSended);
	};
	useEffect(() => {
		dispatch(
			getBriefingsMessages({ briefingId: element?.id, photographerId: element?.photographerId })
		);
	}, []);

	return (
		<ChatWrapper>
			<ChatTitle>Messaging</ChatTitle>
			<ChatName>{userName}</ChatName>
			<ChatInner>
				{messages.length > 0 ? (
					<ChatInnerMessages ref={chatRef}>
						{user?.role === AuthRoles.Photographer
							? messages.map((el, index) =>
									el.byBuyer ? (
										<MessageInner key={index}>
											<Message>{el.message}</Message>
											<MessageTime>{getTimeAgo(el.createDate)}</MessageTime>
										</MessageInner>
									) : (
										<MyMessageInner key={index}>
											<MyMessage>{el.message}</MyMessage>
											<MessageTime>{getTimeAgo(el.createDate)}</MessageTime>
										</MyMessageInner>
									)
							  )
							: messages.map((el, index) =>
									!el.byBuyer ? (
										<MessageInner key={index}>
											<Message>{el.message}</Message>
											<MessageTime>{getTimeAgo(el.createDate)}</MessageTime>
										</MessageInner>
									) : (
										<MyMessageInner key={index}>
											<MyMessage>{el.message}</MyMessage>
											<MessageTime>{getTimeAgo(el.createDate)}</MessageTime>
										</MyMessageInner>
									)
							  )}
					</ChatInnerMessages>
				) : (
					<EmptyChat>
						<svg
							width="48"
							height="48"
							viewBox="0 0 48 48"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M38.1427 38.1397C32.0304 44.2527 22.9795 45.5734 15.5728 42.148C14.4794 41.7078 13.583 41.352 12.7307 41.352C10.357 41.3661 7.40233 43.6677 5.86672 42.134C4.33111 40.5982 6.63453 37.6412 6.63453 35.2531C6.63453 34.4008 6.29283 33.5203 5.85265 32.4248C2.42566 25.0192 3.74822 15.9654 9.86052 9.85441C17.6632 2.04886 30.34 2.04886 38.1427 9.8524C45.9595 17.67 45.9454 30.3362 38.1427 38.1397Z"
								fill="#F1EDF7"
								stroke="#F1EDF7"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M31.8788 24.8262H31.8968"
								stroke="#8562CE"
								strokeWidth="4"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M23.8608 24.8262H23.8788"
								stroke="#8562CE"
								strokeWidth="4"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M15.8428 24.8262H15.8608"
								stroke="#8562CE"
								strokeWidth="4"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<h4>No message here yet</h4>
						<p>You can submit photos, a link to your portfolio or your questions.</p>
					</EmptyChat>
				)}
				<Container
					onSubmit={(e) => {
						e.preventDefault();
						onSendMessage();
					}}>
					<Input
						ref={chatInput}
						isOpen={value.length > 0}
						value={value}
						onChange={(e: any) => setValue(e.target.value)}
						placeholder="Type your message here"
					/>
					{value.length > 0 && (
						<SubmitButton>
							<Button type="submit" padding="8px 12px">
								Send
							</Button>
						</SubmitButton>
					)}
				</Container>
				{/* <MessageInput
					ref={chatInput}
					onSendMessage={onSendMessage}
					value={value}
					setValue={setValue}
				/> */}
			</ChatInner>
		</ChatWrapper>
	);
};

const ChatWrapper = styled.div`
	background: #fff;
	width: 32vw;
	padding: 24px 0px;
	border-radius: 10px;
`;
const EmptyChat = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 73vh;
	& > h4 {
		font-weight: 600;
		color: #282828;
		margin-top: 16px;
	}
	& > p {
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		width: 260px;
		margin: 8px auto 0px;
		text-align: center;
		color: var(--gray-50, #999);
	}
`;
const ChatTitle = styled.div`
	margin-bottom: 16px;
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	margin-left: 24px;
`;
const ChatName = styled.div`
	padding: 18px 24px;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #282828;
	border-bottom: 1px solid #ebebeb;
	border-top: 1px solid #ebebeb;
`;
const ChatInner = styled.div`
	padding: 16px 24px;
	display: flex;
	flex-direction: column;
	height: 73vh;
`;
const MessageInner = styled.div`
	margin-bottom: 16px;
	margin-right: auto;
	width: fit-content;
`;
const Message = styled.div`
	padding: 16px;
	background: #f7f7f7;
	border-radius: 16px;
	margin-bottom: 8px;
`;
const MessageTime = styled.div`
	font-size: 12px;
	line-height: 16px;
	color: #c3c3c3;
`;
const MyMessageInner = styled.div`
	margin-left: auto;
	margin-bottom: 16px;

	width: fit-content;
`;
const MyMessage = styled.div`
	padding: 16px;
	background: #8562ce;
	border-radius: 16px;
	margin-bottom: 8px;
	color: #fff;
`;
const ChatInnerMessages = styled.div`
	overflow-y: auto;
`;

const Container = styled.form`
	position: relative;
	margin-top: auto;
`;

const Input = styled.textarea<any>`
	margin-top: 10px;
	width: 100%;
	padding: 12px 16px;
	color: #000;
	background: #f7f7f7;
	border-radius: 8px;
	border: 1px solid transparent;
	outline: none;
	resize: none;
	height: 44px;
	&::placeholder {
		color: #808080;
	}

	${({ isOpen }) => {
		if (isOpen) {
			return css`
				padding-bottom: 60px;
				height: 124px;
			`;
		}
	}}
`;

const SubmitButton = styled.div`
	position: absolute;
	bottom: 16px;
	left: 16px;
`;
