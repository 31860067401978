import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/axiosInstance";
import { IUserProfile } from "../../../models/IProfile";
import axios from "axios";

export const connectStripeAccount = createAsyncThunk<any, any, { rejectValue?: any }>(
	"photographer/connectStripeAccount",
	async (json, thunkAPI) => {
		try {
			const response = await api({
				method: "POST",
				url: `/photographer/user/connectStripeAccount`,
				data: json,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
// export const connectStripeAccount = createAsyncThunk<any, any, { rejectValue?: any }>(
// 	"photographer/connectStripeAccount",
// 	async (data, thunkAPI) => {
// 		try {
// 			return await fetch(
// 				`${process.env.REACT_APP_DOMEN}/api/v1/photographer/user/connectStripeAccount`,
// 				{
// 					method: "POST",
// 					body: data,
// 					headers: {
// 						"X-AUTH-TOKEN": `${localStorage.getItem("token")}`,
// 					},
// 				}
// 			).then((response) => response.json());
// 		} catch (e: any) {
// 			console.log(e);
// 			return thunkAPI.rejectWithValue(e);
// 		}
// 	}
// );

export const detachStripeAccount = createAsyncThunk<any, any, { rejectValue?: any }>(
	"photographer/detachStripeAccount",
	async (_, thunkAPI) => {
		try {
			const response = await api({
				method: "POST",
				url: `/photographer/user/detachStripeAccount`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);

export const getStripeStatus = createAsyncThunk<any, void, { rejectValue?: unknown }>(
	"user/stripeStatus",
	async (_, thunkAPI) => {
		try {
			const { data } = await api({
				method: "GET",
				url: "/photographer/user/stripe/status",
			});
			return data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const connectStripe = createAsyncThunk<any, void, { rejectValue?: unknown }>(
	"user/stripeStatus",
	async (_, thunkAPI) => {
		try {
			const { data } = await axios({
				method: "GET",
				url: `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_O4Q7KNuW5hoaIHe6hgySGY7IeBgrDVVV&scope=read_write`,
			});
			return data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
