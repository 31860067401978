import { MouseEvent } from "react";
import styled from "styled-components";

import { Photo } from "../models/IPhoto";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { useNavigate } from "react-router-dom";
import { getPhotographerAccount, getUserAccount } from "../store/reducers/profile/action";
import { getUserPhotos } from "../store/reducers/photo/action";
import {
	getAnotherPhotographerSkills,
	getSkillsPhotographer,
} from "../store/reducers/PhotographerSkills/action";
import { AuthRoles } from "../models/IProfile";
import { TimeStamp } from "../utils/variables";

interface Props {
	image: Photo;
	onOpenInfoModal: () => void;
	setPostId: React.Dispatch<React.SetStateAction<number>>;
	index: number;
}

export const Post = ({ image, setPostId, onOpenInfoModal, index }: Props) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { isAuth, user } = useAppSelector((state) => state.authData);

	const onPostClick = (e: any) => {
		e.stopPropagation();

		setPostId?.(index);
		onOpenInfoModal?.();
	};

	const onTitleClick = async (e: MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		if (isAuth) {
			const parameters = `?page=1&pageSize=2000&timestamp=${TimeStamp}&nonIncognito=true`;
			await dispatch(getUserPhotos({ parameters, userId: image.photographer.id }));
			if (user?.role === AuthRoles.Photographer) {
				await dispatch(getUserAccount(image.photographer.id));
				await dispatch(getSkillsPhotographer(image.photographer.id));
			} else {
				await dispatch(getAnotherPhotographerSkills(image.photographer.id));
				await dispatch(getPhotographerAccount(image.photographer.id));
			}

			navigate("/creator/profile/userAccount");
		} else {
			setPostId?.(index);
			onOpenInfoModal?.();
		}
	};

	return (
		<Wrapper onClick={onTitleClick}>
			<ImageWrapper onClick={onPostClick}>
				<PostImage
					src={`${process.env.REACT_APP_DOMEN}${image.imageDetails.LIGHTBOX.url}`}
					alt="postImg"
				/>
				<PostBackground></PostBackground>
			</ImageWrapper>

			<PostTitle>{image.photographer?.name}</PostTitle>

			<PostUser>Photographer</PostUser>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	cursor: pointer;
	text-align: center;
	height: fit-content;
	width: 272px;
	margin-bottom: 40px;
	margin-right: 16px;
`;
const ImageWrapper = styled.div`
	border: 1px solid #fff;
	transition: border 0.2s;
	margin-bottom: 16px;
	position: relative;
	&:hover {
		& > div {
			background: rgba(0, 0, 0, 0.2);
			transition: all.3s;
		}
	}
`;
const PostImage = styled.img`
	display: block;
	width: 100%;
	height: 100%;
	transition: scale 0.4s;
`;
const PostTitle = styled.h4`
	margin-bottom: 8px;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
`;

const PostUser = styled.div`
	color: var(--color-gray);
	margin-bottom: 8px;
`;
const PostBackground = styled.div`
	background: rgba(0, 0, 0, 0);
	transition: all.3s;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;
