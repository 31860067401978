import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import { headerLogo } from "./../static/images";

interface Props {
	title?: string;
	img: string;
	blackColor?: boolean;
}

export const SignUpAbout = ({ title, img, blackColor = false }: Props) => {
	return (
		<SignUpAboutWrapper blackColor={blackColor} img={img}>
			<NavLink to="/">
				<SignUpLogo src={headerLogo} alt="logo" />
			</NavLink>
			<SignUpTitle>{title}</SignUpTitle>
			<SignUpCopy>Copyright © Photograph rights reserved</SignUpCopy>
		</SignUpAboutWrapper>
	);
};
const SignUpAboutWrapper = styled.div<Props>`
	padding: 25px;
	width: 36vw;
	height: 100vh;
	background-image: url(${({ img }) => img});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	display: flex;
	flex-direction: column;

	${({ blackColor }) => {
		if (blackColor) {
			return css`
				& > h3 {
					color: #000;
					margin-top: auto;
					margin-bottom: 24px;
				}
				& > div {
					margin-top: 0;
				}
			`;
		} else {
			return css`
				& > h3 {
					color: #fff;
				}
			`;
		}
	}}
`;
const SignUpLogo = styled.img`
	margin-bottom: 38px;
	width: 112px;
	cursor: pointer;
`;
const SignUpTitle = styled.h3`
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	max-width: 350px;
`;

const SignUpCopy = styled.div`
	margin-top: auto;
	max-width: 150px;
	font-size: 12px;
	line-height: 16px;
	color: #000;
`;
