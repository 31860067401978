import { forwardRef, InputHTMLAttributes, RefObject } from "react";
import styled, { css } from "styled-components";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	maxWidth?: string;
	autofocus?: boolean;
	labelText?: string;
	type?: string;
	error?: boolean;
	padding?: string;
	labelMaxWidth?: string;
	withCurrency?: boolean;
}

type Ref =
	| ((instance: HTMLInputElement | null) => void)
	| RefObject<HTMLInputElement>
	| null
	| undefined;

export const Input = forwardRef((props: Props, ref: Ref) => {
	const {
		maxWidth,
		labelText,
		labelMaxWidth,
		error,
		padding = "12px 8px 12px 16px",
		autoFocus,
		withCurrency = false,
		...other
	} = props;

	return (
		<Label style={labelMaxWidth ? { maxWidth: labelMaxWidth } : {}}>
			{labelText && <LabelText>{labelText}</LabelText>}

			<Wrapper
				withCurrency={withCurrency}
				error={error}
				autoFocus={autoFocus}
				ref={ref}
				maxWidth={maxWidth}
				padding={padding}
				{...other}
			/>
		</Label>
	);
});

const Wrapper = styled.input<Props>`
	padding: ${({ padding }) => padding};
	max-width: ${({ maxWidth }) => maxWidth};
	width: 100%;
	height: 47px;
	background: #f7f7f7;
	border-radius: 8px;
	border: 1px solid transparent;
	color: #000;
	outline: none;

	&:focus {
		background: #fff;
		border: 1px solid #ebebeb;
	}
	${(props) => {
		return (
			props.error &&
			css`
				border: 1px solid red;
				background: #fff;
			`
		);
	}}
	${({ withCurrency }) => {
		if (withCurrency) {
			return css`
				padding-left: 34px;
			`;
		}
	}}
`;

const LabelText = styled.div`
	margin-bottom: 8px;
	font-weight: bold;
	color: #282828;
`;

const Label = styled.label`
	width: 100%;
	position: relative;
`;
