import React from "react";
import styled from "styled-components";
import { useAppDispatch } from "../../hooks/redux";
import { deletePhotographer } from "../../store/reducers/deletePhotographer/action";
import { Button } from "../UI";

interface Props {
	modalOpen: boolean;
	setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	password: {};
	setIsDeleteAccount: React.Dispatch<React.SetStateAction<boolean>>;
	setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DeleteAccountConfirm = ({
	setModalOpen,
	password,
	setIsDeleteAccount,
	setIsOpenModal,
}: Props) => {
	const dispatch = useAppDispatch();

	const onDelete = async () => {
		const { meta } = await dispatch(deletePhotographer(password));
		if (meta.requestStatus === "fulfilled") {
			setIsOpenModal(true);
			setIsDeleteAccount(true);
		}
		setModalOpen(false);
		document.body.style.overflow = "auto";
	};
	const onReset = () => {
		setModalOpen(false);
	};

	return (
		<div>
			<RequestModalWrapper>
				<RequestModalTitle>Are you sure , you want to delete your account?</RequestModalTitle>
				<ThanksModalText>
					Your account will be deleted, and and you won't be able to access it in the future
				</ThanksModalText>
				<ModalButtonWrapper>
					<Button theme={"outline"} type="button" onClick={onReset} width="232px">
						Cancel
					</Button>
					<Button theme="red" type="button" onClick={onDelete} width="232px">
						Yes, delete my account
					</Button>
				</ModalButtonWrapper>
			</RequestModalWrapper>
		</div>
	);
};

const RequestModalTitle = styled.div`
	margin-bottom: 24px;
	text-align: center;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
`;
const ModalButtonWrapper = styled.div`
	text-align: center;
	width: 100%;
	margin: 24px auto 0px;
`;
const RequestModalWrapper = styled.div`
	width: 480px;
	padding: 5px 8px;
`;
const ThanksModalText = styled.div`
	margin-top: -8px;
	text-align: center;
	margin-bottom: 24px;
`;
