import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/axiosInstance";

export const getCountriesList = createAsyncThunk<any, any, { rejectValue?: any }>(
	"getCountriesList",
	async (_, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `/countries`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
