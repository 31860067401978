import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SignUpTitle } from "../Header";
import { Button } from "../UI";

export const SignUpModalComponent = () => {
	const navigate = useNavigate();

	const onBuyerSignUp = () => {
		document.body.style.overflow = "auto";
		navigate("/buyer/signup");
	};
	const onCreatorSignUp = () => {
		document.body.style.overflow = "auto";
		navigate("/creator/signup");
	};
	return (
		<SignUpModal>
			<SignUpTitle>Create your VSBLE.Space account</SignUpTitle>
			<SignUpWrapper>
				<SignUpModalItem>
					<SignUpSubTitle>Find selected creators worldwide</SignUpSubTitle>
					<Button onClick={onBuyerSignUp}>Sign up as brand</Button>
				</SignUpModalItem>
				<SignUpModalLine></SignUpModalLine>
				<SignUpModalItem>
					<SignUpSubTitle>Showcase your work and win clients</SignUpSubTitle>
					<Button onClick={onCreatorSignUp}>Sign up as creator</Button>
				</SignUpModalItem>
			</SignUpWrapper>
		</SignUpModal>
	);
};

const SignUpWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const SignUpSubTitle = styled.p`
	color: #282828;
	line-height: 24px;
	margin-bottom: 24px;
`;
const SignUpModalLine = styled.div`
	width: 1px;
	background: #000;
	height: 92px;
`;
const SignUpModal = styled.div`
	width: 660px;
`;
const SignUpModalItem = styled.div`
	text-align: center;
	width: 360px;
`;
