import { useState } from "react";
import styled from "styled-components";
import { INewCartItem } from "../../models/ICart";
import { Button, RadioBtns } from "../UI";
import { useAppDispatch } from "../../hooks/redux";
import { changeCartItem, getCart } from "../../store/reducers/cart/action";
import { getLicence, getLicenceReverse } from "../../utils/getSmth";

interface IEditModal {
	el: INewCartItem;
	onClose: () => void;
}

export const EditCartItem = ({ el, onClose }: IEditModal) => {
	const [licence, setLicence] = useState(getLicenceReverse(el.licenceType || ""));
	const dispatch = useAppDispatch();

	const onUpdateLicence = async () => {
		const json = {
			licenceType: getLicence(licence),
		};
		await dispatch(changeCartItem({ id: el.id, json: JSON.stringify(json) }));
		onClose();
		document.body.style.overflow = "auto";
		await dispatch(getCart("PENDING"));
	};

	return (
		<Wrapper>
			<Title>License type</Title>
			<Content>
				<img
					src={`${process.env.REACT_APP_DOMEN}${el.photo?.imageDetails.LIGHTBOX.url}`}
					alt="img"
				/>
				<RadioBtnsWrapper>
					<RadioBtns
						value={licence}
						setValue={setLicence}
						options={[
							{ value: "Online Licence", text: `€${el?.photoPriceOnline}.00` },
							{
								value: "Editorial Licence",
								text: `€${el?.photoPriceEditorial}.00`,
							},
							{ value: "Extended Licence", text: `€${el?.photoPriceExtended}.00` },
						]}
						name="ImageFile"
					/>
				</RadioBtnsWrapper>
			</Content>
			<Button onClick={onUpdateLicence} width="100%">
				Update license type
			</Button>
		</Wrapper>
	);
};
const Wrapper = styled.div`
	width: 500px;
`;
const Title = styled.div`
	margin-bottom: 24px;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	margin-top: -13px;
`;
const Content = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	& > img {
		margin-right: 24px;
		width: 144px;
	}
	margin-bottom: 24px;
`;
const RadioBtnsWrapper = styled.div`
	width: 100%;
`;
