import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../hooks/redux";
import { connectStripeAccount } from "../store/reducers/stripe/action";
import styled from "styled-components";

export const ConnectStripe = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const someQueryParam = searchParams.get("code");

	const onConnectStripe = async () => {
		if (someQueryParam) {
			await dispatch(connectStripeAccount({ code: someQueryParam }));
			navigate("/creator/profile/settings");
		}
	};

	useEffect(() => {
		onConnectStripe();
	}, [someQueryParam]);

	return <Wrapper>Connecting...</Wrapper>;
};

const Wrapper = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 50px;
	line-height: 60px;
`;
