import { useController, useForm } from "react-hook-form";
import styled from "styled-components";
import { Button, CustomSelect, ErrorWrapper, Input } from "./UI";
import {
	additionalSkillsValues,
	assetsValues,
	servicesValues,
	skillLevels,
} from "../utils/selectValues";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

interface Props {
	isFilterOpen: boolean;
	setFilterCount?: React.Dispatch<React.SetStateAction<number>>;
	setIsFilterOpen?: React.Dispatch<React.SetStateAction<boolean>>;
	isResetFilters?: boolean;
	setIsResetFilters?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FilterForm = (props: Props) => {
	const { isResetFilters, isFilterOpen, setIsFilterOpen } = props;

	const [searchParams, setSearchParams] = useSearchParams();

	const {
		register,
		formState: { errors },
		control,
		reset,
		handleSubmit,
	} = useForm({
		defaultValues: {
			additionalService: searchParams.get("additionalService") || "",
			additionalSkills: searchParams.get("additionalSkills") || "",
			assets: searchParams.get("assets") || "",
			location: searchParams.get("location") || "",
			skillLevel: searchParams.get("skillLevel") || "",
			videography: "",
		},
	});

	console.log(searchParams.get("location"));

	useEffect(() => {
		reset({
			additionalService: "",
			additionalSkills: "",
			assets: "",
			skillLevel: "",
			location: "",
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isResetFilters]);

	const onSubmit = (data: any) => {
		setIsFilterOpen?.(false);

		let filteredData = {};

		for (const key in data) {
			const element = data[key];

			if (!!element) {
				// @ts-ignore
				filteredData[key] = element;
			}
		}

		setSearchParams(filteredData);
	};

	const { field: skillLevel } = useController({
		name: "skillLevel",
		control,
	});
	const { field: assets } = useController({
		name: "assets",
		control,
	});
	const { field: additionalService } = useController({
		name: "additionalService",
		control,
	});
	const { field: additionalSkills } = useController({
		name: "additionalSkills",
		control,
	});

	return (
		<Wrapper isFilterOpen={isFilterOpen}>
			<WrapperColumn>
				<Label>
					<LabelText>Location</LabelText>

					<Input {...register("location")} maxWidth="272px" placeholder={"Where"} type={"text"} />
				</Label>

				<Label>
					<LabelText>Skill level</LabelText>

					<CustomSelect
						ref={skillLevel.ref}
						placeholder="Skill level"
						value={skillLevel.value}
						onChange={skillLevel.onChange}
						options={skillLevels}
					/>

					<ErrorWrapper>
						<p style={errors?.skillLevel && { display: "block" }}>
							{errors?.skillLevel && `${errors?.skillLevel?.message}`}
						</p>
					</ErrorWrapper>
				</Label>

				<Label>
					<LabelText>Assets</LabelText>
					<CustomSelect
						ref={assets.ref}
						placeholder="Assets"
						value={assets.value}
						onChange={assets.onChange}
						options={assetsValues}
					/>
					<ErrorWrapper>
						<p style={errors?.assets && { display: "block" }}>
							{errors?.assets && `${errors?.assets?.message}`}
						</p>
					</ErrorWrapper>
				</Label>

				<Label>
					<LabelText>Additional service</LabelText>
					<CustomSelect
						ref={additionalService.ref}
						placeholder="Additional service"
						value={additionalService.value}
						onChange={additionalService.onChange}
						options={servicesValues}
					/>
					<ErrorWrapper>
						<p style={errors?.additionalService && { display: "block" }}>
							{errors?.additionalService && `${errors?.additionalService?.message}`}
						</p>
					</ErrorWrapper>
				</Label>

				<Label style={{ marginRight: "0px" }}>
					<LabelText>Additional skills </LabelText>
					<CustomSelect
						ref={additionalSkills.ref}
						placeholder="Additional skills"
						value={additionalSkills.value}
						onChange={additionalSkills.onChange}
						options={additionalSkillsValues}
					/>
					<ErrorWrapper>
						<p style={errors?.videography && { display: "block" }}>
							{errors?.videography && `${errors?.videography?.message}`}
						</p>
					</ErrorWrapper>
				</Label>
			</WrapperColumn>

			<WrapperColumn>
				<ButtonWrapper>
					<Button onClick={handleSubmit(onSubmit)} type="submit" theme="primary">
						Apply
					</Button>
				</ButtonWrapper>
			</WrapperColumn>
		</Wrapper>
	);
};

const Wrapper = styled.form<Props>`
	height: ${({ isFilterOpen }) => (isFilterOpen ? "188px" : "0px")};
	transition: 0.2s;
	opacity: ${({ isFilterOpen }) => (isFilterOpen ? "1" : "0")};
	margin-bottom: ${({ isFilterOpen }) => (isFilterOpen ? "20px" : "0px")};
`;
const WrapperColumn = styled.div`
	display: flex;
`;

const LabelText = styled.div`
	margin-bottom: 8px;
	font-weight: bold;
`;

const Label = styled.label`
	display: block;
	max-width: 272px;
	width: 100%;
	margin-right: 16px;
	margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
	padding-top: 28px;
	margin-left: auto;
`;
