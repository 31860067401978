import { IPhotographerSkills, ISkills } from "../../../models/IProfile";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/axiosInstance";

export const getPhotographerSkills = createAsyncThunk<
	IPhotographerSkills,
	void,
	{ rejectValue?: unknown }
>("user/getPhotographerSkills", async (_, thunkAPI) => {
	try {
		const { data } = await api({
			method: "GET",
			url: "/photographer/user/skills",
		});
		return data;
	} catch (e: any) {
		console.log(e);
		return thunkAPI.rejectWithValue(e);
	}
});
export const getAnotherPhotographerSkills = createAsyncThunk<
	IPhotographerSkills,
	any,
	{ rejectValue?: unknown }
>("user/getAnotherPhotographerSkills", async (id, thunkAPI) => {
	try {
		const { data } = await api({
			method: "GET",
			url: `/artbuyer/user/skills/${id}`,
		});
		return data;
	} catch (e: any) {
		console.log(e);
		return thunkAPI.rejectWithValue(e);
	}
});
export const getSkillsPhotographer = createAsyncThunk<
	IPhotographerSkills,
	any,
	{ rejectValue?: unknown }
>("user/getSkillsPhotographer", async (id, thunkAPI) => {
	try {
		const { data } = await api({
			method: "GET",
			url: `/photographer/user/${id}/skills`,
		});
		return data;
	} catch (e: any) {
		console.log(e);
		return thunkAPI.rejectWithValue(e);
	}
});

export const updatePhotographerSkills = createAsyncThunk<
	any,
	IPhotographerSkills,
	{ rejectValue?: unknown }
>("user/updatePhotographerSkills", async (data, thunkAPI) => {
	try {
		return await api({
			method: "POST",
			url: "/photographer/user/skills",
			data: {
				...data,
			},
		});
	} catch (e: any) {
		console.log(e);
		return thunkAPI.rejectWithValue(e);
	}
});
