import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-scroll";

import { AnchorsLinks, AnchorsLinksList } from "../PublicInfo";
import { arrDown, arrUp, camera } from "../../static/images";
import { PasswordsForm } from "./PasswordsForm";
import { MarketPlaceForm } from "./MarketPlace";
import { DeleteAccountForm } from "./DeleteAccount";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { RootState } from "../../store";
import { updateArbuyerAvatar, updateAvatar } from "../../store/reducers/profile/action";
import { IsLoading } from "../UI/IsLoading";
import { AuthRoles } from "../../models/IProfile";
import { ArtbuyerBasicInfo } from "./artbuyerBasicInfo";
import { getBuyerInvoice, getBuys } from "../../store/reducers/totalSales/action";
import { Sale } from "../../models/ITotalSales";

interface Props {
	onUpdateInfo: () => void;
}

export const ArtbuyerProfileSettings = ({ onUpdateInfo }: Props) => {
	const [isSalesOpen, setIsSalesOpen] = useState(false);

	const { artbuyerTotalBuys } = useAppSelector((state: RootState) => state.totalSales);

	const { artbuyerProfile, isLoading } = useAppSelector((state) => state.profile);
	const { user } = useAppSelector((state) => state.authData);
	const dispatch = useAppDispatch();

	const onEditAvatar = async (e: any) => {
		const file = e.target.files[0];

		const formData = new FormData();
		formData.append("file", file);
		if (user?.role === AuthRoles.Photographer) {
			await dispatch(updateAvatar(formData));
		} else {
			await dispatch(updateArbuyerAvatar(formData));
		}
	};

	useEffect(() => {
		dispatch(getBuys());
	}, []);

	return isLoading ? (
		<IsLoading />
	) : (
		<Wrapper>
			<Title>Settings</Title>

			<AvatarBorder>
				<AvatarWrapper>
					<img
						src={
							artbuyerProfile.avatarURL
								? `${process.env.REACT_APP_DOMEN}${artbuyerProfile.avatarURL}`
								: camera
						}
						alt="camera"
					/>
					<AvatarEdit>
						<input
							accept="image/*, .png, .jpg,.web"
							onChange={(e) => onEditAvatar(e)}
							type="file"
						/>
						Edit
					</AvatarEdit>
				</AvatarWrapper>
			</AvatarBorder>

			<ArtbuyerBasicInfo onUpdateInfo={onUpdateInfo} />

			<PasswordsForm onUpdateInfo={onUpdateInfo} />

			<MarketPlaceForm onUpdateInfo={onUpdateInfo} />

			<SalesStatements id="salesStatement">
				<SubTitle>Purchase History</SubTitle>
				<Text>For questions, inquries and special corporate plans, please contact our support</Text>
				{artbuyerTotalBuys.length ? (
					artbuyerTotalBuys.map((byYear, index) => {
						return (
							<SalesStatementsWrapper key={index} isOpen={isSalesOpen}>
								<SalesStatementsItem
									isOpen={isSalesOpen}
									onClick={() => setIsSalesOpen(!isSalesOpen)}>
									<SalesStatementsArrow src={isSalesOpen ? arrUp : arrDown} />
									<SalesStatementsYear>{byYear.year}</SalesStatementsYear>
									<SalesStatementsCount>{byYear.sales.length} sales</SalesStatementsCount>
								</SalesStatementsItem>
								<SalesStatementsList isOpen={isSalesOpen}>
									{byYear.sales.map((el: Sale, i: number) => {
										return (
											<SalesStatementsListItem key={i}>
												<SalesStatementsListDate>{el.date}</SalesStatementsListDate>
												<SalesStatementsListPrice>€{el.amount}</SalesStatementsListPrice>
												<SalesStatementsListDownload href={el.invoiceLink}>
													Download Invoice
												</SalesStatementsListDownload>
											</SalesStatementsListItem>
										);
									})}
								</SalesStatementsList>
							</SalesStatementsWrapper>
						);
					})
				) : (
					<Text>Sorry, you don't have Purchases yet</Text>
				)}
			</SalesStatements>

			<DeleteAccountForm />

			<AnchorsLinks>
				<AnchorsLinksList>
					<li>
						<Link
							to="basicInfo"
							spy={true}
							offset={-90}
							duration={500}
							smooth={true}
							activeClass="active">
							Basic Information
						</Link>
					</li>
					<li>
						<Link
							to="password"
							spy={true}
							offset={-90}
							duration={500}
							smooth={true}
							activeClass="active">
							Password
						</Link>
					</li>

					<li>
						<Link
							to="marketPlace"
							activeClass="active"
							spy={true}
							offset={-90}
							duration={500}
							smooth={true}>
							Marketplace
						</Link>
					</li>

					<li>
						<Link
							to="salesStatement"
							activeClass="active"
							spy={true}
							offset={-90}
							duration={500}
							smooth={true}>
							Purchase Statements
						</Link>
					</li>
				</AnchorsLinksList>
			</AnchorsLinks>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	max-width: 560px;
	margin: 0 auto;
`;
const Title = styled.h2`
	font-weight: 700;
	font-size: 64px;
	line-height: 80px;
	margin-bottom: 40px;
	text-align: center;
`;
export const SubTitle = styled.h4`
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	margin-bottom: 8px;
`;
export const Text = styled.div`
	color: #282828;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 24px;
`;

const SalesStatementsWrapper = styled.div<SalesProps>`
	border: 1px solid transparent;
	transition: 0.2s;
	&:hover {
		&:hover {
			${({ isOpen }) => {
				if (isOpen) {
					return css`
						border: 1px solid #ebebeb;
						border-radius: 18px;
						transition: 0.2s;
					`;
				} else {
					return css`
						border: 1px solid transparent;
					`;
				}
			}};
		}
	}
`;
const SalesStatements = styled.div`
	margin-bottom: 48px;
`;
const SalesStatementsItem = styled.div<SalesProps>`
	display: flex;
	align-items: center;
	padding: 16px 16px 16px 8px;
	background: #f7f7f7;
	border-radius: 16px;
	cursor: pointer;
	border: 1px solid #f7f7f7;
	transition: 0.2s;
	&:hover {
		${({ isOpen }) => {
			if (isOpen) {
				return css`
					border: 1px solid transparent;
				`;
			} else {
				return css`
					border: 1px solid #ebebeb;
				`;
			}
		}};
	}
`;
const SalesStatementsList = styled.div<SalesProps>`
	${({ isOpen }) => {
		if (isOpen) {
			return css`
				height: auto;
				opacity: 1;
				& > div {
					height: auto;
					&:not(:last-child) {
						margin-bottom: 16px;
					}
				}
			`;
		} else {
			return css`
				height: 0px;
				opacity: 0;
				padding: 0px !important;
				z-index: -5;
				& > div {
					height: 0px;
					&:not(:last-child) {
						margin-bottom: 0px;
					}
				}
			`;
		}
	}};
	transition: 0.1s;
	padding: 16px 16px 16px 42px;
	background: #f7f7f7;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
	margin-top: -12px;
`;
const SalesStatementsListItem = styled.div`
	display: flex;
	align-items: center;
`;
const SalesStatementsListDate = styled.div`
	color: #282828;
	font-weight: 600px;
`;
const SalesStatementsListPrice = styled.div`
	color: #282828;
	font-weight: 600px;

	margin-left: auto;
`;
const SalesStatementsListDownload = styled.a`
	margin-left: 56px;
	color: #8562ce;
	cursor: pointer;
	transition: 0.2s;

	&:hover {
		color: #7557b5;
	}
`;
const SalesStatementsArrow = styled.img`
	margin-right: 10px;
`;
const SalesStatementsCount = styled.div`
	color: #505050;
	margin-left: auto;
`;
const SalesStatementsYear = styled.div`
	color: #282828;
	font-weight: 600;
`;

interface SalesProps {
	isOpen?: boolean;
}
const AvatarBorder = styled.div`
	width: 120px;
	margin: 0 auto;
	height: 120px;
	border-radius: 50%;
	border: 1px solid transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 40px;
	transition: 0.2s;
	&:hover {
		border: 1px solid #000000;
		& > div {
			width: 108px;
			height: 108px;
			& > label {
				opacity: 0.7;
			}
			& > img {
				width: 108px;
				height: 108px;
			}
		}
	}
`;
const AvatarEdit = styled.label`
	position: absolute;
	bottom: 0px;
	height: 32px;
	left: 6px;
	right: 6px;
	background: #000;
	opacity: 0;
	transition: 0.2s;
	color: #fff;
	border-bottom-left-radius: 40px;
	border-bottom-right-radius: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	& > input {
		opacity: 0;
		visibility: hidden;
		position: absolute;
	}
`;
const AvatarWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 120px;
	height: 120px;
	background: #000;
	border-radius: 50%;
	transition: 0.2s;
	position: relative;
	overflow: hidden;
	& > img {
		transition: 0.2s;
		width: 120px;
		height: 120px;
		object-fit: cover;
	}
`;
