export enum AuthRoles {
	Anonymous = "ANONYMOUS",
	Photographer = "PHOTOGRAPHER",
	Artbuyer = "ARTBUYER",
	Registration = "REGISTRATION",
	Admin = "ADMIN",
}

export interface IProfile {
	firstName: string;
	lastName: string;
	email: string;
	siteURL: string;
	role: AuthRoles;
	receiveNewsletter: boolean;
	countryId: number;
	login: string;
	password: string;
	company?: string;
}
export interface IPhotographerAbout {
	firstName: string;
	lastName: string;
	email: string;
	siteURL: string;
	title: string;
	about: string;
	position: { label: AuthRoles; value: AuthRoles };
	city: string;
	countryId: number;
	avatarURL: string;
	profession: string;
}
export interface IPhotographerMarketPlace {
	billingFirstName: string;
	billingLastName: string;
	countryId: number;
	billingAddress: string;
	billingAddress2: string;
	billingCity: string;
	billingZip: string;
	billingCompany: string;
}
export interface IPhotographerSocialLinks {
	twitterUserId: string;
	facebookUserId: string;
	instagramUserId: string;
	vimeoId: string;
	linkedInId: string;
}
export interface IBasicInfo {
	email: string;
	phone: string;
	name: string;
	receiveNewsletter: boolean;
}

export interface IUserProfile {
	id: number;
	login: string;
	email: string;
	name: string;
	role: AuthRoles;
	avatarURL: string;
}

export interface IPhotographerSkills {
	skillLevel: string;
	assets: string[];
	additionalServices: string[];
	additionalSkills: string[];
}

export interface ISocialLinks {
	photographerId: number;
	typeSocialLink: string;
	url: string;
}

export interface ISkills {
	photographerId: number;
	skillNote: string;
}
export interface IUpdateAvatar {
	avatarURL: string;
}

export interface IPhotographerProfile {
	id: number;
	login: string;
	name: string;
	firstName: string;
	lastName: string;
	street: string;
	address: string;
	address2: string;
	profession: string;
	zip: string;
	city: string;
	countryId: number;
	title: string;
	email: string;
	siteURL: string;
	about: string;
	vatid: string;
	billingFirstName: string;
	billingLastName: string;
	billingCompany: string;
	billingAddress: string;
	billingAddress2: string;
	billingZip: string;
	billingCity: string;
	avatarURL: string;
	phone: string;
	sex: string;
	likesCount: number;
	viewsCount: number;
	photosCount: number;
	rating: number;
	photoRequestsCountShowroom: number;
	photoRequestsCountBrief: number;
	commentsCount: number;
	newLikesCount: number;
	newViewsCount: number;
	newRating: number;
	newPhotoRequestsCountShowroom: number;
	newPhotoRequestsCountBrief: number;
	commentsCountNew: number;
	salesCount: number;
	followers: number;
	followings: number;
	participating: number;
	haveStripeAccount: boolean;
	activationId: string;
	receiveNewsletter: boolean;
	canAddVatId: boolean;
	landingTour: boolean;
	showSuccessActivationMessage: boolean;
	currency: string;
	status: string;
	facebookUserId: string;
	instagramUserId: string;
	twitterUserId: string;
	vimeoId: string;
	linkedInId: string;
}

export interface IPhotographerProfileUpdate {
	name?: string;
	street?: string;
	address?: string;
	address2?: string;
	zip?: string;
	city?: string;
	countryId?: number;
	siteURL?: string;
	about?: string;
	firstName?: string;
	lastName?: string;
	vatid?: string;
	title?: string;
	billingFirstName?: string;
	billingLastName?: string;
	billingCompany?: string;
	billingAddress?: string;
	billingAddress2?: string;
	billingZip?: string;
	billingCity?: string;
	phone?: string;
	sex?: string;
	oldPassword?: string;
	newPassword?: string;
	receiveNewsletter?: boolean;
	landingTour?: boolean;
	showSuccessActivationMessage?: boolean;
	facebookUserId?: string;
	instagramUserId?: string;
	twitterUserId?: string;
	vimeoId?: string;
	linkedInId?: string;
}
export interface buyerProfile {
	id: number;
	login: string;
	name: string;
	firstName: string;
	lastName: string;
	company: string;
	position: string;
	street: string;
	address: string;
	address2: string;
	zip: string;
	city: string;
	countryId: number;
	email: string;
	siteURL: string;
	about: string;
	vatid: string;
	billingFirstName: string;
	billingLastName: string;
	billingCompany: string;
	billingAddress: string;
	billingAddress2: string;
	billingZip: string;
	billingCity: string;
	avatarURL: string;
	phone: string;
	sex: string;
	receiveNewsletter: boolean;
	canAddVatId: boolean;
	landingTour: boolean;
	createBriefTour: boolean;
	currency: string;
	status: string;
	facebookUserId: string;
	instagramUserId: string;
	twitterUserId: string;
	vimeoId: string;
	linkedInId: string;
}
