import { useState } from "react";
import styled, { css } from "styled-components";
import { Button, CustomSelect, ErrorWrapper, Input, RadioBtns, TextArea } from "../UI";
import { useController, useForm } from "react-hook-form";
import { splitArrayToGalleryFormat } from "../../utils";
import { DatePicker } from "../UI/DatePicker";
import { DateRangePickerUi } from "../UI/DateRangePicker";
import { useAppDispatch } from "../../hooks/redux";
import { postBriefing } from "../../store/reducers/artbuyerJobRequests/action";
import { trashRed } from "../../static/images";

interface Props {
	userId: number;
	onePhoto?: boolean;
	onClose: () => void;
	isJobRequestSended: boolean;
	setIsJobRequestSended: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CurrencyArray = [
	{ value: "USD", label: "$" },
	{ value: "EUR", label: "€" },
];

export const Photoshoot = ({ userId, onClose, setIsJobRequestSended, onePhoto = false }: Props) => {
	const [dragging, setDragging] = useState(false);

	const [priceForCount, setPriceForCount] = useState("Per day");
	const [currencyValue, setCurrencyValue] = useState("USD");

	const [files, setFiles] = useState<any>([]);
	const [filesId, setFilesId] = useState(0);

	const [dateStart, setDateStart] = useState(new Date());
	const [dateDeadline, setDateDeadline] = useState(new Date());

	// Date-range
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	//
	const [imagesToSend, setImagesToSend] = useState<{ id: number; img: string }[]>([]);

	const {
		control,
		formState: { errors },
		handleSubmit,
		register,
	} = useForm();

	const dispatch = useAppDispatch();

	const err = "The filed is required";

	const { field: currencyField } = useController({
		name: "Currency",
		control,
	});

	const uploadImages = (localFiles: any) => {
		const photosArray: { id: number; img: string }[] = [];
		const filesArray: { id: number; file: string }[] = [];
		let countId = filesId;
		for (let i = 0; i < localFiles.length; i++) {
			setFiles((prev: any) => [...prev]);

			filesArray.push({ id: countId, file: localFiles[i] });

			photosArray.push({ id: countId, img: URL.createObjectURL(localFiles[i]) });

			countId++;
		}
		setFilesId(countId);

		setFiles((prev: any) => [...prev, ...filesArray]);
		setImagesToSend((prev: any) => [...prev, ...photosArray]);
	};

	const onUploadImage = async (e: any) => {
		const localFiles = e.target.files;

		uploadImages(localFiles);
	};

	const onDropHandler = (e: any) => {
		setDragging(false);
		e.preventDefault();
		const localFiles = [...e.dataTransfer.files];

		uploadImages(localFiles);
	};

	const onRemoveImage = (id: number) => {
		const newImagesToSend = imagesToSend.filter((el) => el.id !== id);

		setImagesToSend(newImagesToSend);
		const newFiles = files.filter((el: any) => el.id !== id);

		setFiles(newFiles);
	};

	const onPublish = async (data: any) => {
		const json = {
			title: data.title,
			description: data.description,
			dateCreate: dateStart.getTime(),
			dateExpire: dateDeadline.getTime(),
			status: "PUBLISHED",
			budget: data.budget,
			currency: currencyValue,
			hasFiles: files.length > 0,
			briefingType: onePhoto ? "PHOTO" : "JOB",
			budgetTotal: priceForCount === "Total",
			approxShootingStart: startDate.getTime(),
			approxShootingEnd: endDate.getTime(),
			totalShootingDays: data.totalDays,
			photographerId: userId,
		};

		const formData = new FormData();

		if (files) {
			for (let i = 0; i < files.length; i++) {
				formData.append("files", files[i].file);
			}
			formData.append("json", JSON.stringify(json));

			onClose();
			await dispatch(postBriefing(formData));
		}
		document.body.style.overflow = "auto";
		setIsJobRequestSended(true);
		setTimeout(() => {
			setIsJobRequestSended(false);
		}, 4000);
	};

	const onDraft = async (data: any) => {
		const json = {
			title: data.title,
			description: data.description,
			dateCreate: dateStart.getTime(),
			dateExpire: dateDeadline.getTime(),
			status: "DRAFT",
			budget: data.budget,
			currency: currencyValue,
			hasFiles: files.length > 0,
			briefingType: onePhoto ? "PHOTO" : "JOB",
			budgetTotal: priceForCount === "Total",
			approxShootingStart: startDate.getTime(),
			approxShootingEnd: endDate.getTime(),
			totalShootingDays: data.totalDays,
			photographerId: userId,
		};

		const formData = new FormData();

		if (files) {
			for (let i = 0; i < files.length; i++) {
				formData.append("files", files[i].file);
			}
			formData.append("json", JSON.stringify(json));

			onClose();
			await dispatch(postBriefing(formData));
		}
		document.body.style.overflow = "auto";
		setIsJobRequestSended(true);
		setTimeout(() => {
			setIsJobRequestSended(false);
		}, 4000);
	};

	const splitedImages = splitArrayToGalleryFormat(imagesToSend);

	return (
		<Wrapper>
			<Title>Job Request</Title>
			<Description>
				<Subtitle>Description</Subtitle>
				<InputWrapper>
					<Input
						{...register("title", {
							required: err,
						})}
						labelText="Title"
						maxWidth="100%"
					/>
					<ErrorWrapper>
						<p style={errors?.title && { display: "block" }}>
							{errors?.title && `${errors?.title?.message}`}
						</p>
					</ErrorWrapper>
				</InputWrapper>
				<InputWrapper>
					<TextArea
						{...register("description", {
							required: err,
						})}
						labelText="Description"
						maxWidth="100%"
					/>
					<ErrorWrapper>
						<p style={errors?.description && { display: "block" }}>
							{errors?.description && `${errors?.description?.message}`}
						</p>
					</ErrorWrapper>
				</InputWrapper>
				<LabelTitle>Budget</LabelTitle>
				<InputWrapper>
					<InputWrapper style={{ display: "flex", alignItems: "center", marginBottom: "4px" }}>
						<Input
							{...register("budget", {
								required: err,
							})}
							type={"number"}
							labelMaxWidth="162px"
							maxWidth="162px"
						/>
						<SelectWrapper>
							<CustomSelect
								options={CurrencyArray}
								value={currencyValue}
								onChange={setCurrencyValue}
								isSearchable={false}
								maxWidth="90px"
								labelMaxWidth="90px"
								ref={currencyField.ref}
								smallSize={true}
							/>
						</SelectWrapper>
						<RadioWrapper>
							<RadioBtns
								value={priceForCount}
								setValue={setPriceForCount}
								options={[
									{ value: "Per day", text: "" },
									{ value: "Total", text: "" },
								]}
								name="ImageFile"
								toolTip={false}
								displayFlex={true}
							/>
						</RadioWrapper>
					</InputWrapper>
					<ErrorWrapper>
						<p style={errors?.budget && { display: "block" }}>
							{errors?.budget && `${errors?.budget?.message}`}
						</p>
					</ErrorWrapper>
				</InputWrapper>
				<LabelText>Leave ‘0’ to receive price suggestion </LabelText>
				{onePhoto || (
					<InputWrapper style={{ display: "flex", gap: "16px" }}>
						<InputWrapper style={{ width: "232px" }}>
							<Input
								{...register("totalDays", {
									required: err,
								})}
								type="number"
								labelMaxWidth="232px"
								maxWidth="232px"
								labelText="Total Shooting Days"
							/>
							<ErrorWrapper>
								<p style={errors?.totalDays && { display: "block" }}>
									{errors?.totalDays && `${errors?.totalDays?.message}`}
								</p>
							</ErrorWrapper>
						</InputWrapper>
						<DateRangePickerUi
							setEndDate={setEndDate}
							endDate={endDate}
							startDate={startDate}
							setStartDate={setStartDate}
							label="Approx Shooting Period"
						/>
					</InputWrapper>
				)}
				<InputWrapper style={{ display: "flex", gap: "16px" }}>
					<DatePicker date={dateStart} setDate={setDateStart} byLeftSide label="Briefing Start" />
					<DatePicker date={dateDeadline} setDate={setDateDeadline} label="Submission Deadline" />
				</InputWrapper>
			</Description>
			<Upload>
				<Subtitle>Upload Visual References (JPG)</Subtitle>
				{imagesToSend && (
					<DroppedImages>
						{splitedImages.map((arr, index) => {
							return (
								<ImagesColumn key={index}>
									{arr.map((el) => {
										return (
											<ImagesItem key={el.id}>
												<ImagesItemDelete onClick={() => onRemoveImage(el.id)}>
													<img src={trashRed} alt="trash" />
												</ImagesItemDelete>
												<img src={el.img} alt="img" />
											</ImagesItem>
										);
									})}
								</ImagesColumn>
							);
						})}
					</DroppedImages>
				)}
				<DropImages
					onDragStart={(e: any) => {
						e.preventDefault();
						setDragging(true);
					}}
					onDragLeave={(e: any) => {
						e.preventDefault();
						setDragging(false);
					}}
					onDragOver={(e: any) => {
						e.preventDefault();
						setDragging(true);
					}}
					dragging={dragging}
					onDrop={(e: any) => onDropHandler(e)}>
					<AvatarEdit>
						<input
							accept="image/*, .png, .jpg,.web"
							onChange={(e) => onUploadImage(e)}
							type="file"
							multiple
						/>
					</AvatarEdit>
					<DropImagesText>Just drop your images here or click to add (JPG or PNG)</DropImagesText>
				</DropImages>
			</Upload>
			<ButtonsWrapper>
				<Button onClick={handleSubmit(onDraft)} width="236px" theme={"secondary"}>
					Save as Draft
				</Button>
				<Button type="submit" onClick={handleSubmit(onPublish)} width="236px">
					Save & Publish
				</Button>
			</ButtonsWrapper>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding: 40px;
	width: 560px;
	overflow-y: auto;
	max-height: 90vh;
`;
const Title = styled.div`
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	margin-bottom: 24px;
`;
const Subtitle = styled.div`
	margin-bottom: 16px;
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
`;
const Description = styled.div`
	margin-bottom: 48px;
`;
const InputWrapper = styled.div`
	margin-bottom: 16px;
`;
const LabelTitle = styled.div`
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #282828;
	margin-bottom: 8px;
`;
const LabelText = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #808080;
	margin-top: -8px;
	margin-bottom: 16px;
`;
const SelectWrapper = styled.div`
	margin-left: 4px;
	margin-right: 50px;
`;
const RadioWrapper = styled.div`
	width: 100%;
`;
const Upload = styled.div`
	margin-bottom: 24px;
`;

const DropImages = styled.div<any>`
	min-height: 140px;
	background: #f7f7f7;
	border: 2px dashed #d7d7d7;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: relative;
	${({ dragging }) => {
		if (dragging) {
			return css`
				border: 2px dashed #8562ce;
			`;
		}
	}}
`;
const DropImagesText = styled.div`
	max-width: 182px;
	color: #8562ce;
	text-align: center;
`;
const AvatarEdit = styled.label`
	position: absolute;
	bottom: 0px;
	top: 0;
	left: 0px;
	right: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	& > input {
		opacity: 0;
		visibility: hidden;
		position: absolute;
	}
`;
const DroppedImages = styled.div`
	display: flex;
	gap: 8px;
	margin-top: 20px;
	height: 90%;
	overflow-y: auto;
	margin-bottom: 10px;
`;
const ImagesColumn = styled.div`
	width: 24%;
`;
const ImagesItem = styled.div`
	position: relative;
	& > img {
		width: 100%;
		min-height: 42px;
	}
	&:hover {
		& > div {
			opacity: 1;
			transition: all.2s;
		}
	}
	position: relative;
`;
const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;
const ImagesItemDelete = styled.div`
	width: 32px;
	height: 32px;
	border-radius: 50%;
	background: #fff;
	top: 4px;
	position: absolute;
	right: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	opacity: 0;
	transition: all.2s;
`;
