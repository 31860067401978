import React from "react";
import styled from "styled-components";
import { Button } from "../UI";

interface Props {
	modalOpen: boolean;
	setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DeleteAccountReq = ({ setModalOpen }: Props) => {
	const onBtnClick = () => {
		setModalOpen(false);
		document.body.style.overflow = "auto";
	};

	return (
		<div>
			<RequestModalWrapper>
				<RequestModalTitle>Thank you, your request has been received</RequestModalTitle>
				<ThanksModalText>
					Your account is scheduled for deletion, you will be notified once its deleted.
				</ThanksModalText>
				<ModalButtonWrapper>
					<Button type="button" onClick={onBtnClick} width="100%">
						Ok
					</Button>
				</ModalButtonWrapper>
			</RequestModalWrapper>
		</div>
	);
};

const RequestModalTitle = styled.div`
	margin-bottom: 24px;
	text-align: center;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
`;
const ModalButtonWrapper = styled.div`
	text-align: center;
	width: 176px;
	margin: 24px auto 0px;
`;
const RequestModalWrapper = styled.div`
	width: 480px;
	padding: 20px 8px;
`;
const ThanksModalText = styled.div`
	margin-top: -8px;
	text-align: center;
	margin-bottom: 24px;
`;
