import styled from "styled-components";
import { useAppDispatch } from "../../hooks/redux";
import { Button } from "../UI";
import { PhotosList } from "../../models/IBrifing";
import { removeSuggestion } from "../../store/reducers/photographerJobRequests/action";

interface Props {
	el: PhotosList;
	briefingId: number;
	onClose: () => void;
	getSuggestions: () => void;
}

export const DeleteImage = ({ el, briefingId, onClose, getSuggestions }: Props) => {
	const dispatch = useAppDispatch();

	const onDelete = async () => {
		await dispatch(removeSuggestion({ photoId: el.photoId, briefingId }));
		onClose();
		getSuggestions();
	};
	const onReset = () => {
		onClose();
	};

	return (
		<div>
			<RequestModalWrapper>
				<RequestModalTitle>Are you sure you want to delete this image?</RequestModalTitle>
				<ThanksModalText>You will not be able to restore the image after deletion!</ThanksModalText>
				<ModalButtonWrapper>
					<Button theme={"outline"} type="button" onClick={onReset} width="232px">
						Cancel
					</Button>
					<Button theme={"red"} type="button" onClick={onDelete} width="232px">
						Yes, delete this image
					</Button>
				</ModalButtonWrapper>
			</RequestModalWrapper>
		</div>
	);
};

const RequestModalTitle = styled.div`
	margin-bottom: 24px;
	text-align: center;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
`;
const ModalButtonWrapper = styled.div`
	display: flex;
`;
const RequestModalWrapper = styled.div`
	width: 480px;
	padding: 5px 8px;
`;
const ThanksModalText = styled.div`
	margin-top: -8px;
	text-align: center;
	margin-bottom: 24px;
`;
