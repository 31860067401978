import { useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { updateArtbuyerProfile } from "../../store/reducers/profile/action";
import { Button, Checkbox, ErrorWrapper, Input } from "../UI";
import { photographerAboutUpating } from "../../store/reducers/profile";

interface Props {
	onUpdateInfo: () => void;
}

export const ArtbuyerBasicInfo = ({ onUpdateInfo }: Props) => {
	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm();

	const { artbuyerProfile } = useAppSelector((state) => state.profile);

	useEffect(() => {
		reset({ ...artbuyerProfile });
	}, [artbuyerProfile]);

	const dispatch = useAppDispatch();

	const onAboutMeSubmit = async (data: any) => {
		const { meta } = await dispatch(updateArtbuyerProfile(data));
		if (meta.requestStatus === "fulfilled") {
			onUpdateInfo();
		}
	};

	const err = "The filed is required";

	return (
		<AboutMeForm id="basicInfo" onSubmit={handleSubmit(onAboutMeSubmit)}>
			<AboutMeFormTitle>Basic Information</AboutMeFormTitle>
			<Text>
				Your e-mail is used for all notifications concerning purchase request. It is also the
				address where the password reset e-mail is sent if you forget your password.
			</Text>
			<InputWrapper>
				<Input
					error={!!errors?.email}
					{...register("email", {
						required: err,
					})}
					labelText="E-mail"
				/>
				<ErrorWrapper>
					<p style={errors?.email && { display: "block" }}>
						{errors?.email && `${errors?.email?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper>
				<Input
					error={!!errors?.name}
					{...register("name", {
						required: err,
					})}
					labelText="Username"
				/>
				<ErrorWrapper>
					<p style={errors?.name && { display: "block" }}>
						{errors?.name && `${errors?.name?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper>
				<Input
					error={!!errors?.phone}
					{...register("phone", {
						required: err,
					})}
					labelText="Phone"
				/>
				<ErrorWrapper>
					<p style={errors?.phone && { display: "block" }}>
						{errors?.phone && `${errors?.phone?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper style={{ display: "block" }}>
				<Input
					error={!!errors?.firstName}
					{...register("firstName", {
						required: err,
					})}
					labelText="Name"
				/>
				<ErrorWrapper>
					<p style={errors?.firstName && { display: "block" }}>
						{errors?.firstName && `${errors?.firstName?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper style={{ display: "block" }}>
				<Input
					error={!!errors?.lastName}
					{...register("lastName", {
						required: err,
					})}
					labelText="Surname"
				/>
				<ErrorWrapper>
					<p style={errors?.lastName && { display: "block" }}>
						{errors?.lastName && `${errors?.lastName?.message}`}
					</p>
				</ErrorWrapper>
			</InputWrapper>
			<InputWrapper
				style={{
					display: "flex",
					justifyContent: "space-between",
					marginBottom: "0px",
					width: "560px",
				}}>
				<InputWrapper style={{ display: "block", width: "276px" }}>
					<Input
						error={!!errors?.company}
						{...register("position", {
							required: err,
						})}
						labelText="Position"
						maxWidth={"276px"}
					/>
					<ErrorWrapper>
						<p style={errors?.company && { display: "block" }}>
							{errors?.company && `${errors?.company?.message}`}
						</p>
					</ErrorWrapper>
				</InputWrapper>
				<InputWrapper style={{ display: "block", width: "276px" }}>
					<Input
						error={!!errors?.login}
						{...register("company", {
							required: err,
						})}
						maxWidth={"276px"}
						labelText="Company"
					/>
					<ErrorWrapper>
						<p style={errors?.login && { display: "block" }}>
							{errors?.login && `${errors?.login?.message}`}
						</p>
					</ErrorWrapper>
				</InputWrapper>
			</InputWrapper>
			<InputWrapper>
				<Checkbox {...register("receiveNewsletter")} label="Receive Newsletter" />
			</InputWrapper>
			<ButtonWrapper>
				<Button type="submit">Update</Button>
			</ButtonWrapper>
		</AboutMeForm>
	);
};
const AboutMeForm = styled.form`
	text-align: left;
	margin-bottom: 48px;
`;
export const AboutMeFormTitle = styled.h5`
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	margin-bottom: 8px;
`;
export const InputWrapper = styled.div`
	margin-bottom: 16px;
	position: relative;
`;
export const ButtonWrapper = styled.div`
	margin-top: 24px;
`;

export const AnchorsLinks = styled.div`
	position: fixed;
	top: 232px;
	left: 24px;
	text-align: left;
`;
export const AnchorsLinksList = styled.ul`
	& > li {
		font-weight: 700;
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 8px;
		cursor: pointer;
		transition: 0.3s;

		& > a {
			color: #808080;
			&:hover {
				color: #000;
				transition: 0.3s;
			}
		}
		& .active {
			color: #000;
		}
	}
`;
export const Text = styled.div`
	color: #282828;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 24px;
`;
