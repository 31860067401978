import React, { useEffect } from "react";
import { Button, Input } from "../UI";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { AboutMeFormTitle, ButtonWrapper, InputWrapper } from ".";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
	updateArtbuyerProfile,
	updatePhotographerProfile,
} from "../../store/reducers/profile/action";
import { AuthRoles } from "../../models/IProfile";

interface Props {
	onUpdateInfo: () => void;
}

export const SocialLinksForm = ({ onUpdateInfo }: Props) => {
	const photographerSocialLinks = useAppSelector((state) => state.profile.photographerSocialLinks);

	const { user } = useAppSelector((state) => state.authData);

	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm();

	useEffect(() => {
		reset({ ...photographerSocialLinks });
	}, [photographerSocialLinks]);

	const dispatch = useAppDispatch();

	const onSocialSubmit = async (data: any) => {
		const { meta } = await dispatch(
			user?.role === AuthRoles.Photographer
				? updatePhotographerProfile(data)
				: updateArtbuyerProfile(data)
		);

		if (meta.requestStatus === "fulfilled") {
			onUpdateInfo();
		}
	};

	return (
		<SocialForm id="socialLinks" onSubmit={handleSubmit(onSocialSubmit)}>
			<AboutMeFormTitle>Social Links</AboutMeFormTitle>
			<InputWrapper>
				<Input
					error={!!errors?.facebookUserId}
					{...register("facebookUserId")}
					placeholder="Account Link"
					labelText="Facebook"
				/>
			</InputWrapper>
			<InputWrapper>
				<Input
					error={!!errors?.instagramUserId}
					{...register("instagramUserId")}
					placeholder="Account Link"
					labelText="Instagram"
				/>
			</InputWrapper>
			<InputWrapper>
				<Input
					error={!!errors?.twitterUserId}
					{...register("twitterUserId")}
					placeholder="Account Link"
					labelText="Twitter"
				/>
			</InputWrapper>
			<InputWrapper>
				<Input
					error={!!errors?.linkedInId}
					{...register("linkedInId")}
					placeholder="Account Link"
					labelText="Linked In"
				/>
			</InputWrapper>
			<InputWrapper>
				<Input
					error={!!errors?.vimeoId}
					{...register("vimeoId")}
					placeholder="Account Link"
					labelText="Vimeo"
				/>
			</InputWrapper>
			<ButtonWrapper>
				<Button type="submit">Update</Button>
			</ButtonWrapper>
		</SocialForm>
	);
};

const SocialForm = styled.form`
	text-align: left;
	margin-bottom: 48px;
`;
