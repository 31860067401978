import { ReactNode } from "react";
import styled from "styled-components";

interface Props {
	children: string | ReactNode;
}

export const Tag = ({ children }: Props) => {
	return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
	cursor: pointer;
	padding: 4px 8px;
	border: 1px solid #d7d7d7;
	border-radius: 8px;
	color: #808080;
	margin-right: 4px;
	margin-bottom: 4px;
	display: inline-block;
	transition: all.2s;
	&:hover {
		border: 1px solid #000;
		color: #000;
	}
`;
