import React, { useState } from "react";
import styled from "styled-components";
import { Button, Input, Modal } from "../UI";
interface Props {
	modalOpen: boolean;
	setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setIsThanksModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RequestModal = ({ modalOpen, setModalOpen, setIsThanksModalOpen }: Props) => {
	const [suggestion, setSuggestion] = useState("");

	const onRequest = () => {
		setSuggestion("");
		setModalOpen(false);
		setIsThanksModalOpen(true);
		document.body.style.overflow = "auto";
	};

	return (
		<Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
			<RequestModalWrapper>
				<RequestModalTitle>Request Addition</RequestModalTitle>
				<Input
					value={suggestion}
					onChange={(e) => setSuggestion(e.target.value)}
					autoFocus={true}
					labelText={"Your Suggestion"}
				/>
				<ModalButtonWrapper>
					<Button type="button" onClick={onRequest} width="100%">
						Submit
					</Button>
				</ModalButtonWrapper>
			</RequestModalWrapper>
		</Modal>
	);
};
const RequestModalTitle = styled.div`
	margin-bottom: 24px;
	text-align: center;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
`;
const ModalButtonWrapper = styled.div`
	text-align: center;
	width: 176px;
	margin: 24px auto 0px;
`;
const RequestModalWrapper = styled.div`
	width: 480px;
	padding: 20px 8px;
`;
