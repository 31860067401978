import { useState } from "react";
import styled from "styled-components";
import { social1, social2, social3, headerLogo, checkCircle } from "../static/images";

import { Button, Input, Modal } from "./UI";
import { useAppDispatch } from "../hooks/redux";
import { subscribeMailing } from "../store/reducers/notifications/action";
import { NavLink, useNavigate } from "react-router-dom";

export const Footer = () => {
	const [inputValue, setInputValue] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const onFooterFormClick = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (inputValue.length > 0) {
			await dispatch(subscribeMailing({ email: inputValue }));
			setIsModalOpen(true);
			setInputValue("");
		}
	};
	const onModalBtnClick = () => {
		document.body.style.overflow = "auto";

		setIsModalOpen(false);
	};
	const onBrandsAccess = () => {
		navigate("/buyer/signup");
	};
	const onCreatorAccess = () => {
		navigate("/creator/signup");
	};
	const onFooterListClick = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	return (
		<MainFooter>
			<FooterLeft>
				<FooterLogo src={headerLogo} alt="logo" />

				<FooterCopy>Copyright &copy; Vsble.space All rights reserved</FooterCopy>
			</FooterLeft>

			<FooterList>
				<li onClick={onFooterListClick}>
					<NavLink to="/imprint">Imprint</NavLink>
				</li>
				<li onClick={onFooterListClick}>
					<NavLink to="/privacy">Privacy</NavLink>
				</li>
				<li onClick={onFooterListClick}>
					<NavLink to="/terms">Terms</NavLink>
				</li>
				<li onClick={onFooterListClick}>
					<NavLink to="/cookiePolicy">Cookie Policy</NavLink>
				</li>
			</FooterList>

			<FooterAccess>
				<li onClick={onBrandsAccess}>Brands Access</li>
				<li onClick={onCreatorAccess}>Creator Access</li>
			</FooterAccess>

			<div>
				<FooterContactsTitle>Contact</FooterContactsTitle>

				<FooterContactsMail href="/">Write Us</FooterContactsMail>
				{/* <FooterContactsMail href="mailto:support@artbrief.com">
					support@artbrief.com
				</FooterContactsMail> */}

				<FooterContactsList>
					<li>
						<a target="_blank" href="https://www.facebook.com/artbriefcom/">
							<img src={social1} alt="social1" />
						</a>
					</li>
					<li>
						<a target="_blank" href="https://twitter.com/artbrief_com">
							<img src={social2} alt="social2" />
						</a>
					</li>
					<li>
						<a target="_blank" href="https://www.instagram.com/artbrief/">
							<img src={social3} alt="social3" />
						</a>
					</li>
				</FooterContactsList>
			</div>

			<div>
				<FooterRightTitle>Let’s be in touch</FooterRightTitle>

				<form onSubmit={(e) => onFooterFormClick(e)} style={{ display: "flex" }}>
					<InputWrapper>
						<Input
							value={inputValue}
							onChange={(e) => setInputValue(e.target.value)}
							maxWidth="216px"
							type="email"
							placeholder="E-mail"
						/>
					</InputWrapper>

					<Button type="submit" theme="primary">
						Send
					</Button>
				</form>
			</div>

			<Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
				<SubscribePopup>
					<img src={checkCircle} alt="checkCircle" />
					<SubscribePopupTitle>Thank you for subscribing to updates!</SubscribePopupTitle>
					<SubscribePopupText>
						We will send the latest news and updates directly in your inbox.
					</SubscribePopupText>
					<SubscribePopupBtn onClick={onModalBtnClick}>Ok</SubscribePopupBtn>
				</SubscribePopup>
			</Modal>
		</MainFooter>
	);
};

const MainFooter = styled.div`
	display: flex;
	justify-content: space-between;
	max-width: 1392px;
	margin: 0 auto;
	padding-top: 36px;
	padding-bottom: 67px;
	border-top: 1px solid var(--color-gray);
	margin-top: 70px;
`;

const FooterLeft = styled.div`
	display: block;
`;
const FooterLogo = styled.img`
	margin-bottom: 21px;
`;
const FooterCopy = styled.div`
	max-width: 150px;
	font-size: 12px;
	line-height: 16px;
	color: var(--color-gray);
`;
const FooterList = styled.ul`
	& > li {
		& > a {
			cursor: pointer;
			color: var(--color-gray);
			line-height: var(--line-height-m);
		}
		margin-bottom: 8px;
	}
`;

const FooterAccess = styled.ul`
	& > li {
		cursor: pointer;
		margin-bottom: 8px;
		color: var(--color-gray);
		line-height: var(--line-height-m);
	}
`;
const FooterContactsTitle = styled.h5`
	margin-bottom: 16px;
	color: #282828;
	font-weight: 600;
`;
const FooterContactsMail = styled.a`
	color: #8562ce;
	margin-bottom: 24px;
	display: inline-block;
`;
const FooterContactsList = styled.ul`
	display: flex;

	& > li {
		:not(:last-child) {
			margin-right: 8px;
		}

		& > a {
			width: 32px;
			border-radius: 50%;
			display: inline-block;
			height: 32px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}
	}
`;

const FooterRightTitle = styled.h5`
	margin-bottom: 8px;
	color: #282828;
	font-weight: 600;
`;
const InputWrapper = styled.div`
	margin-right: 8px;
`;
const SubscribePopup = styled.div`
	text-align: center;
`;
const SubscribePopupTitle = styled.h3`
	margin-top: 24px;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	margin-bottom: 16px;
`;
const SubscribePopupText = styled.p`
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 24px;
	color: #282828;
`;
const SubscribePopupBtn = styled.button`
	padding: 12px;
	width: 176px;
	border-radius: 8px;
	background: #000;
	border: none;
	color: #fff;
	cursor: pointer;
`;
