import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/axiosInstance";
import { IImageRequest } from "../../../models/IImageRequest";

export const getArtbuyerImageRequests = createAsyncThunk<
	IImageRequest[],
	string,
	{ rejectValue?: any }
>("artbuyer/getImageRequests", async (parameters, thunkAPI) => {
	try {
		const response = await api({
			method: "GET",
			url: `artbuyer/showroom/requests${parameters}`,
		});
		return response.data;
	} catch (e: any) {
		console.log(e);
		return thunkAPI.rejectWithValue(e);
	}
});
export const getAllArtbuyerImageRequests = createAsyncThunk<
	IImageRequest[],
	string,
	{ rejectValue?: any }
>("artbuyer/getAllArtbuyerImageRequests", async (parameters, thunkAPI) => {
	try {
		const response = await api({
			method: "GET",
			url: `artbuyer/showroom/requests${parameters}`,
		});
		return response.data;
	} catch (e: any) {
		console.log(e);
		return thunkAPI.rejectWithValue(e);
	}
});

export const postRequest = createAsyncThunk<IImageRequest, any, { rejectValue?: any }>(
	"artbuyer/postRequest",
	async (jsonData, thunkAPI) => {
		try {
			const response = await api({
				method: "POST",
				url: `artbuyer/showroom/request`,
				data: jsonData,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
export const postCounterOffer = createAsyncThunk<IImageRequest, any, { rejectValue?: any }>(
	"artbuyer/postCounterOffer",
	async (jsonData, thunkAPI) => {
		try {
			const response = await api({
				method: "POST",
				url: `artbuyer/showroom/requests/counterOffer`,
				data: jsonData,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);

export const artbuyerConfirmCounterOffer = createAsyncThunk<
	IImageRequest[],
	number,
	{ rejectValue?: any }
>("artbuyer/confirmCounterOffer", async (id, thunkAPI) => {
	try {
		const response = await api({
			method: "GET",
			url: `artbuyer/showroom/requests/${id}/confirmCounterOffer`,
		});
		return response.data;
	} catch (e: any) {
		console.log(e);
		return thunkAPI.rejectWithValue(e);
	}
});
export const artbuyerRejectCounterOffer = createAsyncThunk<
	IImageRequest[],
	number,
	{ rejectValue?: any }
>("artbuyer/rejectCounterOffer", async (id, thunkAPI) => {
	try {
		const response = await api({
			method: "GET",
			url: `artbuyer/showroom/requests/${id}/rejectCounterOffer`,
		});
		return response.data;
	} catch (e: any) {
		console.log(e);
		return thunkAPI.rejectWithValue(e);
	}
});

export const artbuyerCancelRequest = createAsyncThunk<
	IImageRequest[],
	number,
	{ rejectValue?: any }
>("artbuyer/cancelRequest", async (id, thunkAPI) => {
	try {
		const response = await api({
			method: "PUT",
			url: `artbuyer/showroom/request/${id}/cancel`,
		});
		return response.data;
	} catch (e: any) {
		console.log(e);
		return thunkAPI.rejectWithValue(e);
	}
});
export const getRequestDetails = createAsyncThunk<IImageRequest[], number, { rejectValue?: any }>(
	"artbuyer/getRequestDetails",
	async (id, thunkAPI) => {
		try {
			const response = await api({
				method: "GET",
				url: `artbuyer/showroom/request/${id}`,
			});
			return response.data;
		} catch (e: any) {
			console.log(e);
			return thunkAPI.rejectWithValue(e);
		}
	}
);
