import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../hooks/redux";
import { AuthRoles } from "../models/IProfile";
import { CreatorProfile } from "../pages";

interface RequireAuthProps {
  allowedRoles?: AuthRoles[];
  anonymous?: boolean;
}

export const RequireAuth = (props: RequireAuthProps) => {
  const { allowedRoles, anonymous } = props;

  const location = useLocation();

  const { user, isAuth } = useAppSelector((state) => state.authData);

  const userRoles = [user?.role];

  const renderPages = () => {
    if (anonymous) {
      return !isAuth ? <Outlet /> : <CreatorProfile />;
    }

    return userRoles?.find((role) =>
      allowedRoles?.includes(role as AuthRoles)
    ) ? (
      <Outlet />
    ) : user ? (
      <Navigate to="/signIn" state={{ from: location }} replace />
    ) : null;
  };

  return renderPages();
};
