import { forwardRef } from "react";
import Select from "react-select";
import styled, { css } from "styled-components";

export interface IOption {
	value: string | number;
	label: string;
}

interface Props {
	options: IOption[] | any;
	placeholder?: string;
	value: number | string | undefined;
	onChange: (...event: any[]) => void;
	smallSize?: boolean;
	labelText?: string;
	maxWidth?: string;
	error?: boolean;
	isSearchable?: boolean;
	labelMaxWidth?: string;
}
interface WrapperProps {
	maxWidth?: string;
	error?: boolean;
	smallSize?: boolean;
}

export const CustomSelect = forwardRef(
	(
		{
			options,
			maxWidth,
			onChange,
			isSearchable = true,
			value,
			labelText,
			smallSize = false,
			placeholder,
			labelMaxWidth,
			error,
			...other
		}: Props,
		ref: any
	) => {
		const onChangeValue = (newValue: any) => {
			onChange(newValue.value);
		};

		const getElement = () => {
			if (value === "") {
				return placeholder;
			} else {
				return options.find((el: IOption) => el.value === value);
			}
		};

		return (
			<Label style={labelMaxWidth ? { maxWidth: labelMaxWidth } : {}}>
				{labelText && <LabelText>{labelText}</LabelText>}
				<Wrapper smallSize={smallSize} error={error} ref={ref} maxWidth={maxWidth}>
					<Select
						classNamePrefix="custom-select"
						isSearchable={isSearchable}
						value={getElement()}
						onChange={onChangeValue}
						options={options}
						placeholder={placeholder}
						{...other}
					/>
				</Wrapper>
			</Label>
		);
	}
);

const Wrapper = styled.div<WrapperProps>`
	max-width: ${({ maxWidth }) => maxWidth};
	& .custom-select {
		&__control {
			min-width: 80px;
			padding: 0px 10px 0px 8px !important;
			height: 47px !important;
			background-color: #f7f7f7 !important;
			border-radius: 8px !important;
			border: 1px solid transparent !important;
			color: #000 !important;
			outline: none !important;
			box-shadow: none;
			${(props) => {
				return (
					props.error &&
					css`
						border: 1px solid red !important;
						background: #fff !important;
					`
				);
			}}
			&--is-focused {
				background: #fff !important;
				border: 1px solid #ebebeb !important;
			}
		}

		&__indicator {
			${({ smallSize }) => {
				if (smallSize) {
					return css`
						padding: 0;
					`;
				}
			}}
		}
		&__indicator-separator {
			display: none;
		}
		&__value-container {
			cursor: text;
		}
		&__indicators {
			cursor: pointer;
		}
		&__menu {
			border-radius: 12px;
			border: none;
			padding: 8px;
			box-shadow: 0px 8px 41px rgba(0, 0, 0, 0.1);
		}
		&__option {
			&:nth-child(1) {
				color: #c6b8b8;
			}
			color: #000;
			font-size: 14px;
			cursor: pointer;
			&--is-focused {
				background: transparent;
				color: #8562ce;
			}
			&--is-selected {
				background: transparent;
				font-weight: 700;
			}
		}
		&__single-value {
			color: #000;
		}
	}
`;

const LabelText = styled.div`
	margin-bottom: 8px;
	color: #282828;

	font-weight: bold;
`;

const Label = styled.label`
	width: 100%;
`;
