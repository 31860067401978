import { AuthRoles, IUserProfile } from "../../../models/IProfile";
import { createSlice } from "@reduxjs/toolkit";
import { getStripeStatus } from "./action";

interface StripeState {
	connected: boolean;
	connectUrl: string;
	accountId: number;
}

const initialState: StripeState = {
	connected: false,
	connectUrl: "",
	accountId: 0,
};

export const stripeSlice = createSlice({
	name: "user/profile",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getStripeStatus.fulfilled, (state, action) => {
			state.connectUrl = action.payload.connectUrl;
			state.connected = action.payload.connected;
			state.accountId = action.payload.accountId;
		});
	},
});

export const { reducer: stripeSliceReducer, actions: stripeSliceActions } = stripeSlice;
