import { ReactNode, useEffect } from "react";
import styled, { css } from "styled-components";
import { closeSvg, closeWSvg } from "../../static/images";

interface Props {
	children: ReactNode;
	isOpen: boolean;
	size?: size;
	onClose: () => void;
	hidden?: boolean;
	isCloseSvg?: boolean;
	noPadding?: boolean;
	padding?: string;
	transparent?: boolean;
	noRadius?: boolean;
	changeCloseSvg?: boolean;
}

interface PopupProps {
	size?: size;
	noPadding?: boolean;
	padding?: string;
	transparent?: boolean;
	noRadius?: boolean;
	changeCloseSvg?: boolean;
}

export const Modal = (props: Props) => {
	const {
		children,
		isOpen,
		onClose,
		hidden,
		noPadding = false,
		noRadius = false,
		isCloseSvg = true,
		transparent = false,
		changeCloseSvg = false,
		padding = "80px 40px",
		size = "big",
	} = props;

	const handleClose = () => {
		document.body.style.overflow = hidden ? "hidden" : "auto";

		onClose();
	};
	if (!hidden) {
		document.body.style.overflow = isOpen ? "hidden" : "none";
	}
	return (
		<>
			{isOpen && (
				<Popup>
					<PopupBg onClick={handleClose} />
					<PopupInner
						noRadius={noRadius}
						transparent={transparent}
						padding={padding}
						noPadding={noPadding}
						changeCloseSvg={changeCloseSvg}
						size={size}>
						{children}
						{isCloseSvg && (
							<PopupBtn
								changeCloseSvg={changeCloseSvg}
								transparent={transparent}
								onClick={handleClose}>
								<img src={changeCloseSvg ? closeWSvg : closeSvg} alt="close" />
							</PopupBtn>
						)}
					</PopupInner>
				</Popup>
			)}
		</>
	);
};

const Popup = styled.div<PopupProps>`
	z-index: 8;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	overflow-y: auto;
	width: 100%;
	min-height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
`;

const PopupInner = styled.div<PopupProps>`
	overflow: hidden;
	${({ changeCloseSvg }) => {
		if (changeCloseSvg) {
			return css`
				position: initial;
			`;
		} else {
			return css`
				position: relative;
			`;
		}
	}}
	z-index: 8;
	background: ${({ transparent }) => {
		if (transparent) {
			return "transparent";
		} else {
			return "#fff";
		}
	}};
	border-radius: ${({ transparent, noRadius }) => {
		if (transparent || noRadius) {
			return "0px";
		} else {
			return "24px";
		}
	}};
	padding: ${({ padding }) => padding};
	${({ size }) => {
		switch (size) {
			case "fullwidth":
				return css`
					padding: 21px 24px;
					position: absolute;
					top: 30px;
					bottom: 30px;
					left: 100px;
					right: 100px;
				`;
			case "small":
				return css`
					padding: 60px 40px 40px;
				`;
		}
	}}
	${({ noPadding }) => {
		if (noPadding) {
			return css`
				padding: 0;
			`;
		}
	}}
`;

const PopupBtn = styled.button<any>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 24px;
	background: #f7f7f7;
	position: absolute;
	transition: all.2s;
	&:hover {
		transition: all.2s;
		background: #ebebeb;
	}
	${({ transparent }) => {
		if (transparent) {
			return css`
				top: 0;
				right: 0;
			`;
		} else {
			return css`
				top: 24px;
				right: 24px;
			`;
		}
	}}
	${({ changeCloseSvg }) => {
		if (changeCloseSvg) {
			return css`
				background: transparent;
				&:hover {
					background: transparent;
				}
			`;
		}
	}}
	border: none;
	cursor: pointer;
`;

const PopupBg = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 4;
`;

type size = "small" | "big" | "fullwidth";
