import React from "react";
import styled from "styled-components";

export const Terms = () => {
	return (
		<Wrapper>
			<Title>Terms of service</Title>
			<Text>
				The following conditions apply if you use the platform (community and marketplace) at the
				Internet domain www.artbrief.com or with the associated mobile app (iOS and Android)
				(hereinafter refered to as “ArtBrief”), and purchase or provide content or services through
				these. Artbrief is operated by ArtBrief UG (limited liability), Grosse Elbstrasse 277 A,
				22767 Hamburg, Germany (hereinafter referred to as “Provider”).
			</Text>
			<Block>
				<Subtitle>§ 1 – ArtBrief Services</Subtitle>
				<Text>
					(1) The Provider operates an online platform that provides a community (“Showroom”) as
					well as a marketplace (“Briefing”) for digital images, particularly photographs. As part
					of the community, registered users have the opportunity to exhibit their digital images
					and make contact with other registered users. As part of the marketplace, registered users
					have the opportunity to offer licenses for their digital images or to purchase such
					licenses.(2) The content provider in the community is not ArtBrief, but rather the
					respective user. ArtBrief merely reproduces the content that the user has provided to
					ArtBrief.(3) ArtBrief may make changes to the ArtBrief design and formatting of the
					content submitted by the user, but does not check whether the content uploaded to ArtBrief
					or submissions made by users are lawful, truthful or complete. The content uploaded by
					users does not represent the views of ArtBrief.(4) ArtBrief is not a platform for the
					storing and management of content. The user shall ensure that he or she transfers only
					copies of works to ArtBrief, and that the user retains possession of the original
					works.(5) Use does not include a claim to registration, participation in the marketplace,
					a certain upload capacity or the fault-free or uninterrupted availability of content on
					our website or mobile app.
				</Text>
			</Block>
			<Block>
				<Subtitle>§ 2 – Access to the Platform, Registration as User</Subtitle>
				<Text>
					(1) Anyone may visit the platform and search through the provided content without logging
					on.(2) The exploitation, provision and purchasing of licenses requires the prior
					registration as a user of the platform, which is done through the opening of a user
					account.(3) User accounts may only be opened by natural persons of legal age. The opening
					of a user account by a legal entity or private company may only take place through a
					natural person who is an authorized representative. A user is not authorized to register
					several user accounts. User accounts are not transferrable.(4) When opening a user
					account, the user must provide a complete address along with an email address. All
					information must be true and complete. ArtBrief stores the information supplied by the
					user for as long as the user account exists. Further details can be found in ArtBrief’s
					Data Privacy Statement. The user is required to inform the Provider, without request and
					immediately, of all future changes to the information that was provided as part of the
					registration and use of ArtBrief.(5) Registration may be carried out online at the
					ArtBrief website or on the mobile app, provided that the user submits the user’s data with
					a click on the “Register” button on the registration page, thereby submitting an offer to
					ArtBrief to conclude a user agreement. Until submission of the user’s offer, the user
					still has the opportunity to correct the information on the registration page. Acceptance
					by ArtBrief takes place via email, in which ArtBrief confirms receipt of the registration
					request and accepts such request by sending the personal access information that is
					required for the use of the marketplace.(6) Alternatively, the user has the option of
					registering through third-party ‘connect’ features (Facebook Connect, Instagram Connect).
					Here, selected information from the user’s profile will be applied to the user’s account
					by the Provider. By registering using a ‘connect’ feature, the user accepts the conditions
					of the third party and allows the selected information from the third-party profile to be
					passed on to the Provider.(7) The user is not authorized to share the user’s login
					information, especially the user’s password, with third parties. Should third parties
					nevertheless obtain access to the user’s account or the user believes that the user’s
					account has been misused, the user must immediately inform ArtBrief of this and change the
					respective login information.(8) ArtBrief allows for the keeping of a user account free of
					charge. There is no entitlement to registration and participation in the community and
					marketplace.(9) The user may correct submission errors at any time before saving them.
					After registration is completed, the information in the user account may be viewed,
					edited, corrected or deleted. The user may demand information concerning personal data
					stored by ArtBrief at any time.(10) The user has no claim to the account being available
					permanently and without interruption. ArtBrief reserves the right to expand the scope of
					features available to user accounts, to change or limit features, or to remove user
					accounts from the marketplace.
				</Text>
			</Block>
			<Block>
				<Subtitle>
					§ 3 – Uploading of Content; Granting of Rights for the ArtBrief Showroom
				</Subtitle>
				<Text>
					(1) The user may supplement the user’s account with additional personal information and
					content (such as a profile picture, etc.). With the uploading of content the user
					renounces all rights to the publication and exploitation of the uploaded content. The user
					releases ArtBrief from all damages and claims that may be asserted against ArtBrief due to
					the violation of third-party rights through the use of the content uploaded by the user to
					the user’s account. This also includes reasonable costs for legal defense. (2) By
					uploading content to the user account, the user grants ArtBrief the non-exclusive right to
					use this content worldwide and free of charge for the ArtBrief community, that is, to
					integrate this content into ArtBrief’s website and mobile applications, and make it
					publicly visible, searchable and retrievable. This includes, the right to store,
					technically reproduce, include information (metadata, hashtags, keywords, etc.),
					distribute, make publicly accessible, edit (e.g. by altering the picture resolution) and
					combine the uploaded content with other content. This right of use is non-exclusive, that
					is, the user is also free to share the user’s content on other platforms and offer
					licensing arrangements. (3) ArtBrief also strives to introduce users and their content in
					social networks in order to promote the content and the community as well. By uploading
					content, the user grants ArtBrief the right to use the uploaded content on ArtBrief’s
					websites and on other social networks (e.g. Twitter, Facebook, Pinterest, Instagram and
					Google+), that is, to present, comment, reproduce, share, and rate said content. (4)
					ArtBrief always uses the username given by the user in the user’s account along with the
					image transmitted by the user. However, ArtBrief shall not be liable if an image is
					published for ArtBrief self-promotion or in a non-commercial editorial article that
					reports on ArtBrief without the mention of the username or copyright notice. (5) Such
					granting of rights as stipulated in Section 3 Paragraphs 1 - 4 may be deleted by the user
					at any time by deleting the content from the user account. Content that ArtBrief has
					already distributed on social networks according to (3) cannot be automatically deleted.
				</Text>
			</Block>
			<Block>
				<Subtitle>§ 4 – ArtBriefings</Subtitle>
				<Text>
					If ArtBrief should hold contests or sweepstakes, the relevant conditions of participation
					shall apply.
				</Text>
			</Block>
			<Block>
				<Subtitle>
					§ 5 – Granting of Rights for Offers as part of the ArtBrief Marketplace (“Briefings”)
				</Subtitle>
				<Text>
					(1) The user may release individual content, especially image material, and sell licenses
					for said content by selecting a license model in the user account for the respective
					content, marking this content as “for sale” or offering individual prices for a license.
					(2) An account with PayPal is necessary for the payment of any license fees, as these
					cannot otherwise be paid out by the Provider. (3) If the user, within the framework of the
					user’s account, does sell licenses for images uploaded by the user, the following ArtBrief
					Marketplace Conditions apply: (4) Transfer of rights(4.1) By transmitting and releasing
					the image material in the ArtBrief Marketplace, the user transfers to ArtBrief all rights
					of use to the image material for any kind of commercial and/or non-commercial use and
					exploitation. The transfer also includes the right to ancillary and subsequent rights.
					(4.2) The user transfers, in particular, the following rights, non-exclusively and
					unlimited in terms of content, time and place, whereby it should be expressly noted that
					the following list is not exhaustive:a) The right to transmit, copy, and/or otherwise
					reproduce and/or distribute the image, as well as place it on online networks (such as the
					Internet) or make it accessible to online users, on to all visual, audiovisual and other
					multimedia data carrier formats that are known at the time of the conclusion of the
					contract. b) The right to make available on demand, that is, the right to make images
					available on electronic databases and through digital and other transmission technology to
					a multitude of users for a fee, so that those users can receive the material for
					individual retrieval by means of a television, mobile communications device, computer
					and/or other device designed for interactive use (e.g. television “on demand”, video “on
					demand”, online, etc.) and, if applicable, may present these publicly. c) The right to
					use, reproduce and/or distribute and/or have distributed images for the design and/or
					presentation of audio, audiovisual or other multimedia and/or digital data carriers (e.g.
					CD-ROM, Blue- ray disc, laser disc, optical disc, video, photo CDs, etc.). d) The right to
					use, reproduce and/or distribute the images for sale, advertising, promotion and marketing
					purposes in print media (e.g. magazines, newspapers, books), for the designing of
					marketing and/or other posters, postcards, advertisements, advertising brochures,
					pamphlets, programs, catalogues, press kits, biographies, commercials, image brochures
					and/or the firm’s own advertising, etc., in any edition or re-issue. e) The right to use
					the images in other forms from which they were submitted, namely to edit and/or alter the
					images under safeguarding of any moral rights, to have the images edited or altered,
					especially in whole or in part, to change the images using, for example, photo-technical
					disassociations, montages and/or through the scanning into a computer and then using
					computer- technical disassociations (e.g. coloring, printing color in black and white,
					retouching, etc.), to imitate, exploit and/or distribute the images in the edited form in
					parts or as a whole together with other works.(4.4) ArtBrief is authorized to transfer the
					rights transferred to ArtBrief, in whole or in part, to third parties in and outside of
					Germany. (4.5) ArtBrief makes the images available at no cost for self-promotion and for
					private online databases (www.artbrief.com and others). The user grants ArtBrief the right
					to use the names of the authors/creators of the images for the marketing of images (such
					as on the website of a stock photo agency). (4.6) ArtBrief also provides images for
					commercial end consumers in the area of advertising. The parties agree that mention of the
					user’s name with the use of images is uncommon in this area. In this respect, the user
					officially declares to ArtBrief that user waives the right to be named as the author,
					creator, etc. This waiver is also recognized with the author’s remuneration for the image.
					(4.7) The user grants ArtBrief the right to assert user’s claim to recognition of
					authorship (according to § 13 of the German Copyright Law) and to enforce any
					indemnification claims. No other obligation exists. The targeted revenues are calculated
					according to Paragraph 5.(5) Remuneration / licensing fees and their accounting
				</Text>
				<Text>
					(5.1) For the compensation of the transfer of all rights owed according to these Terms of
					Use, the user receives a performance-based remuneration in the amount of 85% of the net
					earnings achieved through exploitation and sale to end consumers. Net earnings are all
					payments received by ArtBrief for the exploitation of images, after taxes and other fees,
					reductions for reimbursements to customers through rescinded transactions from an end
					consumer’s cancellation or reduction. (5.2) The accounting of remuneration is carried out
					through ArtBrief. After the amount of remuneration has been determined, ArtBrief shall
					issue the user a credit note that can be retrieved in the user account. The user hereby
					agrees to receive the credit note exclusively in electronic form via email. The credit
					amount shall be paid to the user exclusively through the payment services provider PayPal.
					(5.3) All amounts are understood to be net amounts plus VAT, if the pay recipient and the
					type of pay are subject to tax. If the user is based in another EU country and if the user
					has provided the user’s valid VAT ID number before billing, the credit will be in a net
					amount. The same shall apply for users based outside the EU.
				</Text>
				<Text>(6) Assurance and guarantee of legal ownership and indemnification of ArtBrief</Text>
				<Text>
					(6.1) The user ensures that the images delivered are devoid of third-party rights,
					particularly that the user may freely use the images and their existing user rights. (6.2)
					The user ensures, within the scope of the user’s artistic sphere of influence, that the
					rights of third parties are not and cannot be violated, particularly personal rights, both
					in the creation of the image as well as in its unaltered use by ArtBrief. (6.3) The user
					guarantees, and shall prove, that depicted persons and/or the owners of the rights
					pertaining to them, and/or the owners of the rights to depicted works of visual or applied
					art (paintings, buildings) have communicated their necessary consent, including consent to
					later use of images through ArtBrief and/or third parties. If images of persons or works,
					for which such consent is not given or is given only in limited scope, are brought to
					ArtBrief, these are to be clearly labeled as such by the user. For damages incurred by
					ArtBrief through lack of or insufficient labeling, the user is liable to the fullest
					extent. (6.4) Furthermore, the user guarantees that the images supplied by the user do not
					violate copyright laws, competition laws and/or trademark laws. (6.5) The user shall, upon
					first request, indemnify and hold harmless ArtBrief from all third-party claims (including
					co-authors such as photo models), particularly if such claims of copyright and personal
					right violations raised against ArtBrief are in connection with the exercising of
					contractual rights. Any impairments to contractual rights that become known to the user
					must be immediately reported to ArtBrief. ArtBrief is authorized to take measures to
					protect ArtBrief from third-party claims or exercise its own rights. Any measures taken by
					the user have ArtBrief’s prior consent. This also includes the compensation of costs
					incurred by ArtBrief for legal action/legal defense.
				</Text>
			</Block>
			<Block>
				<Subtitle>§ 6 – Purchase of Image Licenses in the ArtBrief Marketplace</Subtitle>
				<Text>
					(1) Logged-in, active users may, as part of Briefings, purchase licenses with their
					relevant user rights for the images exhibited there. (2) The scope of licenses and details
					on the purchase of licenses through users are to be found in the individual license
					agreements, which can be reviewed in the order process before the conclusion of each
					contract and are sent to the user per email after the conclusion of a contract. (3) A user
					who purchases a license as a consumer may withdraw from the contract pursuant to statutory
					provisions. A consumer is any natural person who conducts a legal transaction for purposes
					that can be largely attributed neither to commercial nor independent professional
					activity. An existing consumer right to withdraw expires as soon as the licensor has begun
					to execute the contract, provided that the user has expressly agreed that the licensor may
					begin the execution of the contract before expiry of the withdrawal period, and that the
					user has confirmed that the right to withdraw has been lost through this consent at
					commencement of the execution of the contract.
					<br />
					License information:{" "}
					<a href="https://artbrief.com/assets/themes/theme-ab/pdf/Web.pdf">Online/Web</a> ,{" "}
					<a href="https://artbrief.com/assets/themes/theme-ab/pdf/Editorial.pdf">Editorial</a> ,{" "}
					<a href="https://artbrief.com/assets/themes/theme-ab/pdf/Extended.pdf">Extended</a>
				</Text>
			</Block>
			<Block>
				<Subtitle>§ 7 – Other User Obligations</Subtitle>
				<Text>
					(1) The user shall adhere to the applicable rules when using the community and
					marketplace; in particular,- criminal laws, - rules on the protection of minors, - data
					privacy, - copyrights, brand and trademark rights, patent rights, name rights of third
					parties, and - personal rights and other third-party rights may not be infringed. (2)
					Furthermore, the use shall:
				</Text>
				<Text>
					- treat other users and the marketplace with respect; - provide only content and services
					that are available to the user and that the user can also deliver or perform within the
					given time; - place content provided only in the suitable category; - only provide ratings
					if the user purchased the content or service to be rated; - make no biased ratings; - make
					no disguised (e.g. as a report) and/or obvious advertisement for goods, services and/or
					businesses; - not collect the personal data of other users or their information, no matter
					kind, or use or publish without the consent of the user in question; - use no harmful
					technologies that can harm or annoy the community and the marketplace, users, or third
					parties (viruses, bots, spiders, scrapers, crawlers, hacking, brute force attacks, etc.);
					and - not use, in product descriptions or ratings, URLs that do not lead to further
					information about the relevant product. The content of the linked sites must comply with
					applicable law. Links to partner programs, referral URLs, URLs to fee-based subscription
					sites and online shops, etc. are forbidden.
				</Text>
			</Block>
			<Block>
				<Subtitle>§ 8 – Sanctions and Other Measures Taken in case of Violations</Subtitle>
				<Text>
					(1) As soon as ArtBrief is given specific indications of a user’s violation of statutory
					provisions, the rights of third parties, these Terms of Use, or other legitimate interests
					(hereinafter referred to as “Suspicion of Abuse”), ArtBrief may take measures against said
					user. When deciding on what measures should be taken, ArtBrief factors in the operational
					requirements of the community and the marketplace, its own liability risks, and the
					legitimate interests of potential claimants as well as of the user. Until the complete
					removal of the Suspicion of Abuse, ArtBrief may:- warn the user to immediately cease
					committing any such violations; - alter or delete the user’s content or ratings without
					replacement; - temporarily restrict or limit user accounts; - temporarily block user
					accounts; and/or - ban a user completely and permanently.ArtBrief may take further
					measures as well. Legal claims from ArtBrief due to abuse remain unaffected by these
					measures. (2) After being made aware of any Suspicion of Abuse, ArtBrief shall generally
					inform the member of this suspicion, and set an appropriate period for a response that
					corresponds to the particular case.
				</Text>
			</Block>
			<Block>
				<Subtitle>§ 9 – Assessments, Complaints, Contact to Providers, Data Forwarding</Subtitle>
				<Text>
					(1) ArtBrief provides its users in the marketplace, where available, with the opportunity
					to rate the services of other users concerning the performance of services rendered (e.g.
					through comments or giving stars). ArtBrief may make these ratings visible in the
					marketplace to all users of the website, or delete them, either independently or in
					consultation with the provider in question. (2) ArtBrief can, as contact partner for the
					user, receive and edit complaints concerning the performance of services rendered by other
					users and forward these complaints to the users concerned. ArtBrief is however not
					obligated to do so. In that case, ArtBrief shall refer the user to the other user or
					users, in order to facilitate an immediate clarification of the matter and a faster and
					easier solution between the parties. Should a clarification of the matter not lead to a
					solution within eight working days, ArtBrief itself can step in and assist the parties in
					reaching a solution. (3) If provided by ArtBrief, the marketplace allows the user to
					communicate with other users free of charge. ArtBrief then sends the data submitted by the
					user, particularly user contact information, per email to the email address of the other
					user. Further communication – particularly concerning the conditions and purchase of the
					particular service offered – then takes place directly between the users. ArtBrief has no
					influence on user responses.
				</Text>
			</Block>
			<Block>
				<Subtitle>§ 10 – Limitation of Liability</Subtitle>
				<Text>
					(1) The following provisions concerning the limited liability of ArtBrief apply for all
					claims for damages and cases of liability, regardless on which legal basis they rest (e.g.
					warranty, default, impediment or impossibility of performance, breach of duty, tortious
					act, etc.). Excluded are claims by users due to damages from injury to life, body and
					health, rights and claims of the user regarding fraudulent concealment of a defect by
					ArtBrief or due to the absence of a characteristic or quality for which ArtBrief has
					guaranteed, and claims and rights of the user that are based on willful or gross negligent
					conduct by ArtBrief, its legal representatives or vicarious agents, as well as claims of
					the user in accordance with product liability law. Statutory provisions apply in the case
					of the aforementioned exceptions. (2) ArtBrief is liable for damages arising from slight
					negligence only in cases of infringement of material contractual obligations, i.e.
					obligations, the fulfillment of which is necessary for the purpose of the agreement or the
					adherence of which the contractual partner may rely. With the infringement of material
					contractual obligations, ArtBrief’s liability is limited to the compensation of damages
					that are typical and foreseeable for ArtBrief at the time of the signing of the contract.
					Apart from that, ArtBrief is not liable for damages caused by slight or simple negligence.
					(3) There is no liability for non-availability of the marketplace or its database online.
					In particular, no claims exist against ArtBrief if the non-availability of ArtBrief’s
					services affects an agreement between users, e.g. because content cannot be loaded or if a
					possible license purchase fails to be realized. (4) ArtBrief is not liable for the
					execution of agreements between users. (5) ArtBrief is not obligated to monitor
					transferred or stored information or to search for content that is potentially unlawful.
					(6) ArtBrief is not liable for the loss of and/or damages to a user’s content (e.g.
					images) because it has been agreed upon that ArtBrief will only have copies and not the
					originals.
				</Text>
			</Block>
			<Block>
				<Subtitle>§ 11 – Release from Liability</Subtitle>
				<Text>
					The user shall indemnify and hold harmless ArtBrief from all claims that third parties may
					assert against ArtBrief due to an infringement of rights or a breach of duty by the user,
					unless the user is not responsible for the breach of duty. This includes, in particular,
					the use of content (digital photos), services or assessments that are illegal or contrary
					to the terms of the agreement. Reasonable costs of legal defense (especially attorney
					fees) that ArtBrief incurred through the proven misconduct of a user are also to be
					reimbursed.
				</Text>
			</Block>
			<Block>
				<Subtitle>§ 12 – Termination</Subtitle>
				<Text>
					(1) The user can close said user’s account at any time and without notice. ArtBrief may
					close a user account at any time subject to a notice of four weeks. Termination must be in
					written form. The user’s data and content shall be deleted when the termination becomes
					effective. Termination for cause and without notice is not be affected by this. (2)
					Licenses that the user granted to ArtBrief or other users as part of the marketplace are
					not affected by the termination. User rights granted respective to the license terms
					remain with ArtBrief or the other users. ArtBrief shall continue to store the content for
					the term of the license in question until full completion and processing of the license
					agreement and only delete the content from its own database with the expiry of the
					license.
				</Text>
			</Block>
			<Block>
				<Subtitle>§ 13 – Exchange of Information</Subtitle>
				<Text>
					(1) For the purpose of the reciprocal fulfillment of a contract, the Provider of the
					platform discloses the relevant personal data (name, address, email address), by
					electronic means, to both parties of an agreement concluded over the platform. (2) For the
					deposit, payment and disbursement of the purchase money, the personal data of the parties
					to the purchase agreement (name, address, email address) shall also be forwarded
					electronically to PayPal or Stripe Payments Europe, Ltd. (3) The user of the platform
					grants the Provider the right to disclose the user’s name, address and email address, if a
					third party approaches the platform with reasonable suspicion that an item sold or
					purchased by the user violates the property rights of the third party, and especially if
					there is suspicion that the item originates from a criminal violation (for example, theft,
					embezzlement, robbery, or receiving stolen goods).
				</Text>
			</Block>
			<Block>
				<Subtitle>§ 14 – Transfer of Contract</Subtitle>
				<Text>
					ArtBrief is authorized, with a notice period of four weeks, to transfer all rights and
					obligations arising from the user relationship, in whole or in part, to a third party. In
					this case the user is authorized to end the user relationship within two weeks after
					receipt of notification, with a time limit of 5 working days.
				</Text>
			</Block>
			<Block>
				<Subtitle>§ 15 – Changes to the Terms of Use</Subtitle>
				<Text>
					(1) It may be necessary to amend or change these Terms of Use in the future in order to,
					for example, take into account the platform’s new offers or features. (2) A change to the
					terms and conditions of business is announced by email subject to a term of at least six
					weeks. (3) The announced changes come into effect if the user does not file an objection
					in writing within four weeks from notification. The legal consequences are especially
					emphasized in the notification. (4) If the user objects, ArtBrief is entitled to a special
					right of termination that becomes effective on the planned date of the implementation of
					the changes; the special right of termination must be exercised within one week of the
					user’s objection.
				</Text>
			</Block>
			<Block>
				<Subtitle>§ 16 – Consumer Dispute Resolution</Subtitle>
				<Text>
					(1) The general consumer arbitration board of the Centre for Arbitration e. V.,
					Strassburger Str. 8, 77694 Kehl (www.verbraucher-schlichter.de /
					mail@@verbraucher-schlichter.de) is responsible for the extrajudicial settling of disputes
					between consumers and the Provider. (2) The Provider notes that it is neither obligated to
					participate in extrajudicial dispute arbitration proceedings, nor is it prepared to do so
					on a voluntary basis. The information concerning the arbitration body in Section 16
					Paragraph 1 was provided due to a legal obligation to inform consumers. (3) Independent of
					the arbitration body named in Section 16 Paragraph 1, consumers may also file complaints
					to the EU Online Dispute Resolution Platform, which can be found at
					ec.europa.eu/consumers/odr.
				</Text>
			</Block>
			<Block>
				<Subtitle>§ 17 – Applicable Law, Jurisdiction, Severability Clause</Subtitle>
				<Text>
					(1) The law of the Federal Republic of Germany applies to the exclusion of private
					international law. (2) ArtBrief’s Data Privacy Statement applies. (3) If the user is a
					merchant, a corporation enterprise under public law, or a special fund under public law,
					the parties agree that the place of fulfillment and exclusive jurisdiction are both in
					Hamburg, Germany, the place of ArtBrief’s head office. (4) Should any individual
					provisions of these Terms of Use be or become invalid or unenforceable, this shall not
					affect the validity of the remaining provisions.
				</Text>
			</Block>
		</Wrapper>
	);
};
const Wrapper = styled.div`
	padding-top: 88px;
	font-size: 40px;
	max-width: 752px;
	margin: 0 auto 120px;
`;
const Block = styled.div``;

const Title = styled.h2`
	font-weight: 700;
	font-size: 64px;
	line-height: 80px;
	margin-bottom: 40px;
	text-align: center;
`;
const Subtitle = styled.h2`
	font-weight: 600;
	font-size: 40px;
	line-height: 50px;
	margin-bottom: 20px;
`;
const Text = styled.div`
	margin-bottom: 24px;
	font-size: 16px;
	line-height: 24px;
	color: #282828;
	& > a {
		color: inherit;
		text-decoration: underline;
	}
`;
