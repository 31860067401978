import { useEffect, useState } from "react";
import { Button, CustomSelect, ErrorWrapper } from "../UI";
import { useController, useForm } from "react-hook-form";
import styled from "styled-components";
import { AboutMeFormTitle, ButtonWrapper } from ".";
import { MultiSelect } from "../UI/MultiSelect";
import { RequestModal } from "../Modals/RequestModal";
import { ThanksModal } from "../Modals/ThanksModal";
import {
	getPhotographerSkills,
	updatePhotographerSkills,
} from "../../store/reducers/PhotographerSkills/action";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
	additionalSkillsValues,
	assetsValues,
	servicesValues,
	skillLevels,
} from "../../utils/selectValues";
import { AuthRoles } from "../../models/IProfile";

interface Props {
	onUpdateInfo: () => void;
}

export const SkillsForm = ({ onUpdateInfo }: Props) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [isThanksModalOpen, setIsThanksModalOpen] = useState(false);

	const { photographerSkills } = useAppSelector((state) => state.photographerSkills);
	const { user } = useAppSelector((state) => state.authData);

	const dispatch = useAppDispatch();

	const {
		control,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm({ defaultValues: photographerSkills });
	const err = "The filed is required";

	const onSocialSubmit = async (data: any) => {
		const { meta } = await dispatch(updatePhotographerSkills(data));

		if (meta.requestStatus === "fulfilled" && user?.role === AuthRoles.Photographer) {
			onUpdateInfo();
			dispatch(getPhotographerSkills());
		}
	};
	useEffect(() => {
		reset({ ...photographerSkills });
	}, [photographerSkills]);

	useEffect(() => {
		if (user?.role === AuthRoles.Photographer) {
			dispatch(getPhotographerSkills());
		}
	}, []);

	const { field: skillLevelField } = useController({
		name: "skillLevel",
		control,
		rules: { required: err },
	});
	const { field: assetsField } = useController({
		name: "assets",
		control,
		rules: { required: err },
	});
	const { field: servicesField } = useController({
		name: "additionalServices",
		control,
		rules: { required: err },
	});
	const { field: additionalSkillsField } = useController({
		name: "additionalSkills",
		control,
		rules: { required: err },
	});

	return (
		<SkillForm id="skills" onSubmit={handleSubmit(onSocialSubmit)}>
			<AboutMeFormTitle>Skills</AboutMeFormTitle>
			<SelectWrapper>
				<CustomSelect
					options={skillLevels}
					value={skillLevelField.value}
					onChange={skillLevelField.onChange}
					labelText="Skill Level"
					placeholder="Select Skill Level"
					error={!!errors?.skillLevel}
					ref={skillLevelField.ref}
				/>
				<ErrorWrapper>
					<p style={errors?.skillLevel && { display: "block" }}>
						{errors?.skillLevel && `${errors?.skillLevel?.message}`}
					</p>
				</ErrorWrapper>
			</SelectWrapper>
			<SelectWrapper>
				<MultiSelect
					error={!!errors?.assets}
					setModalOpen={setModalOpen}
					placeholder="Select Assets"
					value={assetsField.value}
					onChange={assetsField.onChange}
					labelText="Assets"
					options={assetsValues}
					ref={skillLevelField.ref}
				/>

				<ErrorWrapper>
					<p style={errors?.assets && { display: "block" }}>
						{errors?.assets && `${errors?.assets?.message}`}
					</p>
				</ErrorWrapper>
			</SelectWrapper>
			<SelectWrapper>
				<MultiSelect
					error={!!errors?.additionalServices}
					setModalOpen={setModalOpen}
					ref={servicesField.ref}
					placeholder="Select Addittion Services"
					value={servicesField.value}
					onChange={servicesField.onChange}
					labelText="Additional Services"
					options={servicesValues}
				/>
				<ErrorWrapper>
					<p style={errors?.additionalServices && { display: "block" }}>
						{errors?.additionalServices && `${errors?.additionalServices?.message}`}
					</p>
				</ErrorWrapper>
			</SelectWrapper>
			<SelectWrapper>
				<MultiSelect
					error={!!errors?.additionalSkills}
					ref={additionalSkillsField.ref}
					placeholder="Select Additional Skills"
					value={additionalSkillsField.value}
					onChange={additionalSkillsField.onChange}
					labelText="Additional Skills"
					options={additionalSkillsValues}
					setModalOpen={setModalOpen}
				/>
				<ErrorWrapper>
					<p style={errors?.additionalSkills && { display: "block" }}>
						{errors?.additionalSkills && `${errors?.additionalSkills?.message}`}
					</p>
				</ErrorWrapper>
			</SelectWrapper>
			<ButtonWrapper>
				<Button type="submit">Update</Button>
			</ButtonWrapper>
			<RequestModal
				modalOpen={modalOpen}
				setModalOpen={() => setModalOpen(false)}
				setIsThanksModalOpen={setIsThanksModalOpen}
			/>
			<ThanksModal modalOpen={isThanksModalOpen} setModalOpen={() => setIsThanksModalOpen(false)} />
		</SkillForm>
	);
};

const SkillForm = styled.form`
	text-align: left;
	margin-bottom: 48px;
`;
const SelectWrapper = styled.div`
	margin-bottom: 24px;
`;
